<template>
  <div class="withdrawal">
    <div class="left">
      <div class="left-type">
        <div
          class="left-type-item"
          :class="{ typeseled: item.id == typeId }"
          v-for="(item, index) in typeList"
          :key="index"
          @click="typeSel(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="left-inputLine">
        <span class="left-inputLine-span">收款账号：</span>
        <input
          class="left-inputLine-input"
          type="text"
          v-model="account"
          oninput="value = value.replace(/\s+/g,'')"
          maxlength="22"
          :placeholder="'请输入' + typeList[typeId - 1].name + '账号'"
        />
      </div>
      <div class="left-inputLine leftPosition">
        <span class="left-inputLine-span">提现金额：</span>
        <input
          ref="getFocus"
          class="left-inputLine-input"
          type="number"
          v-model="money"
          oninput="if(value.length > 9)value = value.slice(0, 9)"
          onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
          @blur="moneyBlur"
          :placeholder="'请输入' + typeList[typeId - 1].name + '提现金额'"
        />
        <div
          class="left-withdrawalAll"
          :class="[userMessage.balance < 100 ? 'btnBan' : 'btnAllow']"
          @click="withdrawalAll"
        >
          全部提现
        </div>
        <span class="left-withdrawalActual" @click="$refs.getFocus.focus()"
          >可提现余额：￥{{ userMessage.balance }}</span
        >
      </div>
      <div class="left-withdrawalTips">
        最低提款额度：<span style="color: #ec0e0e">¥100</span>
      </div>
      <div class="left-tips">
        温馨提示：请务必保证您收款账户的准确性，避免因您收款帐户填写错误而造成您的直接经济损失。
      </div>
      <div class="left-tixian">
        <div
          class="left-btn"
          :class="[
            TXnum < 1 || money < 100 || !account ? 'btnBan' : 'btnAllow',
          ]"
          @click="submit"
        >
          确认提现
        </div>
        <div class="left-desc">本周剩余提现次数：{{ TXnum }}</div>
      </div>
      <div class="left-noteBox">
        <img class="left-tipsbg" src="@/assets/backstage/tipsBG.png" alt="" />
        <div class="left-note">
          <span> 提现须知 </span>
          <span>
            1. 默认每周提现次数为1次，系统会根据近期消费记录自动调整；
          </span>
          <span> 2. 提现服务费6%； </span>
          <span>
            3. 银行卡到账时间取决于收款银行(预计1~3天到账，请耐心等待)</span
          >
        </div>
      </div>
    </div>
    <div class="right">
      <div class="rightBox">
        <span>提现若遇到问题，请联系客服解决</span>
        <div class="wechatQR">
          <img v-image-preview src="@/assets/footer/qd2.jpg" alt="" />
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="440px">
      <div slot="title" class="dialog-title">提现确认：</div>
      <div class="dialog-item">
        <span class="dialog-item-left">提现金额：</span>
        <div class="dialog-item-right">
          <span>{{ money }}</span>
          <span>元</span>
        </div>
      </div>
      <div class="dialog-item">
        <span class="dialog-item-left">服务费：</span>
        <div class="dialog-item-right">
          <span>{{ serviceMoney }}</span>
          <span>元</span>
        </div>
      </div>
      <div class="dialog-item">
        <span class="dialog-item-left">费率：</span>
        <div class="dialog-item-right">
          <span>6%</span>
          <span></span>
        </div>
      </div>
      <div class="dialog-item">
        <span class="dialog-item-left">支付宝实际到账：</span>
        <div class="dialog-item-right">
          <span>{{ realMoney }}</span>
          <span>元</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="footBtnL" @click="dialogVisible = false">取消</div>
        <div class="footBtnR" @click="confirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
    }),
    serviceMoney() {
      return (this.money * 0.06).toFixed(2);
    },
    realMoney() {
      return this.money - this.serviceMoney;
    },
  },
  data() {
    return {
      dialogVisible: false,

      TXnum: 1,
      typeId: 1,
      account: "",
      money: "",
      typeList: [
        {
          id: 1,
          name: "支付宝",
        },
        {
          id: 2,
          name: "微信",
        },
        {
          id: 3,
          name: "对公转账",
        },
      ],
    };
  },
  methods: {
    //提现方式
    typeSel(id) {
      this.typeId = id;
    },
    //金额失焦事件
    moneyBlur() {
      if (Number(this.money) > Number(this.userMessage.balance)) {
        this.money = this.userMessage.balance;
      } else if (
        this.money &&
        Number(this.money) <= 100 &&
        Number(this.userMessage.balance) >= 100
      ) {
        this.money = 100;
      } else if (
        Number(this.money) <= 100 &&
        Number(this.userMessage.balance) < 100
      ) {
        this.money = "";
      }
    },
    //全部提现
    withdrawalAll() {
      if (this.userMessage.balance >= 100) {
        this.money = this.userMessage.balance;
      } else {
        this.$message.error("余额不足100，无法提现");
      }
    },
    //立即提现
    submit() {
      if (!this.account) {
        this.$message.error("请输入账号");
        return;
      }
      if (!this.money) {
        this.$message.error("请输入提现金额");
        return;
      }
      if (this.money < 100 || this.money > this.userMessage.balance) {
        this.$message.error("提现金额不得小于100");
        return;
      }
      this.dialogVisible = true;
    },
    //确定体现
    confirm() {
      this.$confirm(
        "账号：" +
          this.account +
          "；方式：" +
          this.typeList[this.typeId - 1].name +
          " ，确认继续提交？",
        "提示"
      )
        .then((_) => {
          this.dialogVisible = false;
          this.account = "";
          this.money = "";
          this.$alert("已为你发起提现申请，请耐心等待。", "提交成功", {
            confirmButtonText: "返回",
            callback: () => {
              this.$router.go(0);
            },
          });
          setTimeout(() => {
            this.$router.go(0);
          }, 500);
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawal {
  width: 100%;
  height: 680px;
  background-color: #fff;
  display: flex;
}
.right {
  width: 40%;
  height: 680px;
  .rightBox {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  span {
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 18rem;
    color: #999;
  }
  .wechatQR {
    width: 160px;
    height: 160px;
    img {
      width: 160px;
      height: 160px;
      object-fit: contain;
    }
  }
}
.left {
  width: 60%;
  height: 645px;
  display: flex;
  flex-direction: column;
  padding: 35px 0 0 65px;
}
.left-type {
  display: flex;
  align-items: center;
}
.left-type-item {
  margin-right: 10px;
  width: 115px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 18px;
  color: #999999;
  text-align: center;
  line-height: 40px;
  box-sizing: border-box;
  cursor: pointer;
}
.left-type-item:hover {
  border: 1px solid #42a0fd;
  color: #42a0fd;
}
.typeseled {
  background-color: #42a0fd !important;
  border: 1px solid #42a0fd;
  border-radius: 4px;
  color: #fff !important;
}
.left-inputLine {
  display: flex;
  align-items: center;
  margin-top: 20px;
  &-span {
    font-size: 18px;
    color: #999;
    margin-right: 10px;
  }
  &-input {
    width: 300px;
    height: 14px;
    padding: 11px 15px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    font-size: 14px;
    color: #42a0fd;
    outline: none;
  }
  &-input::placeholder {
    font-size: 14px;
    color: #999;
  }
  &-input:focus {
    border: 1px solid #42a0fd;
  }
  &-input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
.left-withdrawalAll {
  width: 114px;
  height: 38px;
  margin-left: 12px;
  background-color: #e2e2e2;
  border-radius: 4px;
  font-size: 17px;
  color: #fff;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}
.leftPosition {
  position: relative;
  z-index: 4;
}
.left-withdrawalActual {
  height: 38px;
  font-size: 12px;
  color: #999;
  line-height: 38px;
  position: absolute;
  left: 300px;
  top: 0;
  z-index: 2;
   
}
.left-withdrawalTips {
  margin: 10px 0 0 110px;
  font-size: 12px;
  color: #999;
  display: flex;
  align-items: center;
   
}
.fontRed {
  color: red;
}
.btnBan {
  background-color: #e2e2e2 !important;
}
.btnAllow {
  background-color: #42a0fd !important;
}
.left-tips {
  width: 420px;
  margin-top: 40px;
  font-size: 14px;
  color: #ec0e0e;
  line-height: 26px;
  letter-spacing: 1px;
}
.left-tixian {
  margin-top: 30px;
  margin-left: 110px;
  display: flex;
  align-items: center;
}
.left-btn {
  width: 114px;
  height: 38px;
  margin-right: 20px;
  background-color: #e2e2e2;
  border-radius: 4px;
  font-size: 17px;
  color: #fff;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}
.left-desc {
  font-size: 12px;
  color: #999;
}
.left-noteBox {
  width: 424px;
  height: 98px;
  padding: 32px 23px;
  margin-top: 40px;
  position: relative;
  z-index: 4;
}
.left-tipsbg {
  width: 459px;
  height: 162px;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.left-note {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  span {
    font-size: 14px;
    color: #999;
    margin-bottom: 7px;
  }
}
.dialog-title {
  height: 20px;
  padding-left: 20px;
  border-left: 10px solid #42a0fd;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}
.dialog-item:first-child {
  margin-top: 0;
}
.dialog-item {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-left {
    font-size: 17px;
    color: #999;
  }
  &-right {
    display: flex;
    align-items: baseline;
    span {
      color: #42a0fd;
    }
    span:first-child {
      font-size: 22px;
      margin-right: 4px;
    }
    span:last-child {
      font-size: 17px;
    }
  }
}
.dialog-footer {
  width: 290px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footBtnL,
.footBtnR {
  width: 130px;
  height: 46px;
  background-color: #45536c;
  border-radius: 4px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 46px;
  cursor: pointer;
}
.footBtnR {
  background-color: #42a0fd !important;
}
</style>
