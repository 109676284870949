import common from "../../../common/common.js";

//state：仓库储存数据的地方
const state = {
  linkUrl: common.getApiUrl(),
  sum: 0,
  // 发送的信息
  chatMsgs: [],
  // 选中的联系人
  toId: 0,
  toName: "",
  toAvatar: "",
  // 联系人列表
  chatPersons: [],
  // 自己的名字
  username: "",
  userId: 0,
  customServiceId: 0,
  userType: 0,
  token: "",
  userType: window.localStorage.getItem("user_type"),
};
//mutations：修改state的唯一手段
const mutations = {
  login(state, payload) {
    console.log("login payload");
    console.log(payload);
    state.currentUser = payload;
    state.token = payload.token;
    state.userId = payload.id;
    state.username = payload.username;
    state.userType = payload.type;
    //存localStorage
    try {
      window.localStorage.setItem("id", payload.id);
      window.localStorage.setItem("account", payload.mobile);
      window.localStorage.setItem("username", payload.nickname);
      window.localStorage.setItem("avatar", "/upload/image/default_avatar.png");
      window.localStorage.setItem("token", payload.token);
      window.localStorage.setItem("user_type", payload.type);
    } catch (error) {
      console.error("登录存储客服数据错误！", error);
    }
  },

  logout(state) {
    state.currentUser = null;
    state.token = "";
    try {
      window.localStorage.clear();
    } catch (error) {
      console.log(error);
    }
  },
  clear_message(state) {
    state.chatMsgs.length = 0;
  },
  //向后追加消息
  append_message(state, payload) {
    state.chatMsgs.push(payload);
  },
  //向前追加消息
  unshift_message(state, payload) {
    state.chatMsgs.unshift(payload);
  },
  //对现有消息按id排序，其实前后端都可以做，但为了强一致性，就放前端算了
  sort_message(state) {
    if (state.chatMsgs instanceof Array) {
      for (let i = 0; i < state.chatMsgs.length; i++) {
        let maxIdx = i;
        for (let j = i; j < state.chatMsgs.length; j++) {
          if (state.chatMsgs[maxIdx].id >= state.chatMsgs[j].id) {
            maxIdx = j;
          }
        }
        if (maxIdx != i) {
          let tempMsg = state.chatMsgs[maxIdx];
          state.chatMsgs[maxIdx] = state.chatMsgs[i];
          state.chatMsgs[i] = tempMsg;
        }
      }
    }
  },

  refresh_chat_persons(state, payload) {
    state.chatPersons = [];
    let newArr = [];
    if (payload instanceof Array) {
      if (state.userType == 1) {
        //客户
        // currentChatPerson 当前有多少个接待人数
        for (let i = 0; i < payload.length; i++) {
          //state.chatPersons.push(payload[i]);
          if (payload[i].currentChatPerson == 0) {
            //currentChatPerson=0 直接分配
            state.chatPersons[0] = payload[i];
            break;
          }
        }
        if (state.chatPersons.length == 0 && payload.length != 0) {
          const minValue = payload.reduce(
            (prev, curr) =>
              prev.currentChatPerson < curr.currentChatPerson ? prev : curr,
            { currentChatPerson: Infinity }
          );
          state.chatPersons[0] = minValue;
        }
      } else {
        //客服
        for (let i = 0; i < payload.length; i++) {
          state.chatPersons.push(payload[i]);
        }
      }
    }
    console.log(state.chatPersons);
    console.log(payload);
  },
  update_current_user(state, payload) {
    state.toId = payload.toId;
    state.toName = payload.mobile;
    state.toAvatar = payload.toAvatar;
  },
  replace_left_message(state, payload) {
    for (let i = 0; i < state.chatPersons.length; i++) {
      if (state.chatPersons[i].fromId == payload.fromId) {
        state.chatPersons[i].content = payload.content;
        if (i != 0) {
          let temp = state.chatPersons[i];
          state.chatPersons[i] = state.chatPersons[0];
          state.chatPersons[0] = temp;
        }
        // break;
      }
    }
  },
};
const actions = {};
//getters：理解为计算属性，用于简化仓库数据，让组件获取仓库的数据更加方便
const getters = {};
//对外暴露Store类的一个实例
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
