<template>
  
  <div class="mynote" v-loading="loading">
    <div class="PD_max">
      <div class="form">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="查询时间">
          <el-date-picker
            v-model="formInline.send_times"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="消息类型">
          <el-select v-model="formInline.category" placeholder="消息类型">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in category_list"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否已读">
          <el-select v-model="formInline.is_read" placeholder="是否已读">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in is_read_list"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    </div>
    
    <div class="title">
      <div class="title-id">序号</div>
      <div class="title-title">标题</div>
      <div class="title-isread">是否已读</div>
      <div class="title-category">消息类型</div>
      <div class="title-content">消息内容</div>
      <div class="title-time">时间</div>
    </div>
    <el-empty
      v-show="noteList.length <= 0 && !loading"
      image="https://www.mtuitui.com/onlineSource/commentEmpty.png"
      description="暂无通知"
    ></el-empty>
    <div class="itembox">

      <div class="itembox-item" v-for="(item, index) in noteList" :key="index">
        <div class="itembox-item-id">
          <span>{{ item.id }}</span>
        </div>
        <div class="itembox-item-title">
          <span>{{ item.title }}</span>
        </div>
        <div class="itembox-item-isread">
          <span>{{ item.is_read == 1 ? "已读" : "未读"}}</span>
        </div>
        <div class="itembox-item-category">
          <span>{{ formatCategory(item) }}</span>
        </div>
        
        <div class="itembox-item-content">
          <span @click="viewDetail(item)">查看详情</span>
        </div>
        <div class="itembox-item-time">
          <span>{{ item.create_time }}</span>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="noteList.length"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      width="1200px"
      top="70px"
      :title="dialogTitle"
      center
      :visible.sync="dialogVisible"
    >
      <div class="dialogBox">
        <p v-html="dialogContent"></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { reqMyNote,reqMyNoteDetail } from "@/api";
export default {
  data() {
    return {
      pagination: 1,
      loading: true,
      dialogVisible: false,
      dialogTitle: "",
      dialogContent: "",
      noteList: [],
      is_read_list: [
      {
          value: "0",
          label: "未读",
        },
        {
          value: "1",
          label: "已读",
        },
        
      ],
      category_list: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "系统通知",
        },
        {
          value: "2",
          label: "活动通知",
        },
        {
          value: "3",
          label: "媒体更新",
        },
        {
          value: "4",
          label: "站内信",
        },
      ],
      formInline: {
        // 查询时间 上个月今天到今天的23:59:59
        send_times: [
          moment().subtract(1, "month").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],

        //是否已读
        is_read:0,
        //种类
        category:'',
        page_size: 10,
        page: 1,
      },
    };
  },
  mounted() {
    this.loading = true;
    //选择第一个
    this.formInline.category = this.category_list[0].value;
    this.formInline.is_read = this.is_read_list[0].value;
    reqMyNote(this.formInline)
      .then((res) => {
        if (res.code == 1) {
          this.noteList = res.data.data;
        } else {
          this.$message.error(res.message || "请求出错");
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    pageChange(e) {
      this.pagination = e;
    },
    //查看详情
    viewDetail(item) {
      this.dialogVisible = true;
      this.dialogTitle = item.title;
      this.dialogContent = item.content;
      this.loading = true;
      //消息内容，请求后端
      reqMyNoteDetail({message_id:item.id})
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    formatCategory(item){
      if(item.category == 1){
        return "系统通知"
      }else if(item.category == 2){
        return "活动通知"
      }else if(item.category == 3){
        return "媒体更新"
      }else if(item.category == 4){
        return "站内信"
      }
    },
    formatIsRead(item){
    },
    onSubmit(){
      this.formInline.page = 1;
      this.loading = true;
      reqMyNote(this.formInline)
      .then((res) => {
        if (res.code == 1) {
          this.noteList = res.data.data;
        } else {
          this.$message.error(res.message || "请求出错");
        }
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.mynote {
  width: 100%;
  height: 700rem;
  padding-bottom: 10rem;
  background-color: #fff;
  .title {
    width: 97%;
    height: 42rem;
    margin: 20rem auto 0;
    background-color: #f5f5f5;
    display: flex;
    &-content,
    &-time ,&-id,&-category,&-isread,&-title {
      width: 70%;
      height: 100%;
      text-align: center;
      line-height: 42rem;
      font-size: 18rem;
      color: #999;
    }
    &-time ,&-id,&-category,&-isread,&-title {
      width: 30%;
    }
  }
  .itembox {
    height: 600rem;
    &-item {
      width: 97%;
      height: 58rem;
      margin: 0 auto;
      display: flex;
      border-bottom: #e2e2e2 1px solid;
      &-id,
      &-category,
      &-isread,
      &-content,
      &-title,
      &-time {
        width: 70%;
        height: 100%;
        line-height: 42rem;
        font-size: 18rem;
        color: #999;
        display: flex;
        span {
          font-size: 18rem;
          color: #999;
          display: inline-block;
        }
      }
      &-time,&-id,&-category,&-isread,&-title {
        width: 30%;
        span {
          width: 100%;
          text-align: center;
          line-height: 58rem;
        }
      }
      
      &-content span:first-child {
        width: 80%;
        padding-left: 20rem;
        line-height: 58rem;
        white-space: nowrap; /*内容超宽后禁止换行显示*/
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /*文字超出部分以省略号显示*/
      }
      &-content span:last-child {
        // width: 92%;
        text-align: center;
        line-height: 58rem;
        white-space: nowrap; /*内容超宽后禁止换行显示*/
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /*文字超出部分以省略号显示*/
        text-decoration: underline #999; /*红色下划线*/
        cursor: pointer;
      }
      &-content span:last-child:hover {
        color: #0982f9;
        text-decoration: underline #0982f9;
      }
    }
  }
  .pagination {
    height: 55rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
.dialogBox {
  height: 720px;
  p {
    text-indent: 2em !important;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
