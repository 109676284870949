<template>
  <div class="fundMgt">
    <div class="nav">
      <span>首页 / 资金管理 / </span>
      <span>{{ navList[navIndex].name }}</span>
    </div>
    <div class="message">
      <div class="message-nav">
        <div
          @click="navClick(item, index)"
          :class="{ navSeled: navIndex == index }"
          v-for="(item, index) in navList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="message-routerBox">
        <router-view v-if="isRouterAliveFund"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$parent.$on("navLocation", (msg) => {
      if (msg) {
        this.navIndex = msg;
      }
    });
  },
  provide() {
    return {
      reloadFund: this.reloadFund,
    };
  },
  data() {
    return {
      isRouterAliveFund: true,
      navIndex: 0,
      navList: [
        {
          name: "在线充值",
          path: "onlineCharge",
        },
        {
          name: "交易记录",
          path: "dealRecord",
        },
        {
          name: "资金提现",
          path: "withdrawal",
        },
      ],
    };
  },
  methods: {
    reloadFund() {
      this.isRouterAliveFund = false;
      this.$nextTick(function () {
        this.isRouterAliveFund = true;
      });
    },
    navClick(item, index) {
      this.navIndex = index;
      this.$parent.$emit("navSideSel", { item1Index: 5, item2Index: index });
      if (this.$route.path == "/backstage/fundMgt/" + item.path) {
        this.reloadFund();
        return;
      }
      this.$router.push("/backstage/fundMgt/" + item.path);
    },
    //father
    fatherMethod(item1Index, item2Index) {
      this.$parent.$emit("navSideSel", {
        item1Index: item1Index,
        item2Index: item2Index,
      });
      if (item1Index == 5 && item2Index == 1) {
        this.navIndex = item2Index;
        if (this.$route.path != "/backstage/fundMgt/dealRecord") {
          this.$router.push("/backstage/fundMgt/dealRecord");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fundMgt {
  width: 100%;
  height: 100%;
  .nav {
    width: 100%;
    height: 50rem;
    background-color: #fff;
    line-height: 50rem;
    span {
      font-size: 14rem;
      color: #999;
    }
    span:first-child {
      margin-left: 20rem;
    }
    span:last-child {
      color: #666;
    }
  }
  .message {
    width: 100%;
    margin-top: 20rem;
    background-color: #fff;
    &-nav {
      width: 97%;
      height: 60rem;
      margin: 0 auto;
      border-bottom: 1px solid #e2e2e2;

      display: flex;
      div {
        width: 114rem;
        height: 100%;
        font-size: 18rem;
        color: #999;
        line-height: 60rem;
        cursor: pointer;
      }
      div:hover {
        color: #42a0fd;
      }
      .navSeled {
        color: #42a0fd;
      }
    }
    &-routerBox {
      width: 100%;
    }
  }
}
</style>
