//当前模块统一管理API
import requests from './request'
import qs from 'qs'

//注册
export const reqRegister = (params) =>
  requests.post('/link/register', qs.stringify(params))
//账号密码登录
export const loginPost = (params) =>
  requests.post('/link/login', qs.stringify(params))
//验证码
export const reqTenxun = (params) =>
  requests.post('/link/tenxun', qs.stringify(params))
//重置密码验证码
export const reqResetpwdcode = (params) =>
  requests.post('/link/send_resetpwd_code', qs.stringify(params))
export const reqResetpwdcode2 = (params) =>
  requests.post('/link/send_resetpwd_code2', qs.stringify(params))
//重置密码
export const reqResetpwd = (params) =>
  requests.post('/index/resetpwd', qs.stringify(params))
export const reqResetpwd2 = (params) =>
  requests.post('/link/resetpwd', qs.stringify(params))
//验证码登录
export const loginCodePost = (params) =>
  requests.post('/link/login_phone', qs.stringify(params))
/**
 * 前台接口
 */
//媒体资源Nav
export const reqFilterNav = (params) =>
  requests.get('/link/mt_serch_nav', { params })
//媒体资源filter
export const reqMediaFilter = (params) =>
  requests.get('/link/mt_serch', { params })
//基础服务Nav
export const reqBasicServiceNav = (params) =>
  requests.get('/link/basicservice_nav', { params })
//渠道商Nav
export const reqCompanyChannelNav = (params) =>
  requests.get('/link/channel_nav', { params })

// 首页轮播图
export const reqBanner = (params) =>
  requests.post('/home/swiper_list', { params })
// 首页服务列表
export const reqServiceList = (params) =>
  requests.post('/home/service_list', { params })
// 友情链接
export const reqLink = (params) =>
  requests.post('/home/friend_links', { params })
// 推荐服务
export const reqRecommend = (params) =>
  requests.post('/home/recommend_service_list', { params })
// 页脚信息
export const reqFooter = (params) => requests.post('/home/footer', { params })

export const reqProvinceList = (params) =>
  requests.get('/link/province_list', { params })

export const reqCityList = (params) =>
  requests.get('/link/city_list', { params })

//基础服务List
export const reqServeGoods = (params) =>
  requests.get('/link/serveGoods', { params })

//渠道服务List
export const reqCompanyChannelList = (params) =>
  requests.get('/link/companyChannelList', { params })
//基础服务Detail
export const reqGoodsDetail = (params) =>
  requests.get('/link/goodsDetail', { params })
//知识学院Nav
export const reqArticleNav = (params) =>
  requests.get('/link/article_nav', { params })
//知识学院List
export const reqArticleList = (params) =>
  requests.get('/link/article', { params })
//知识学院Detail
export const reqArticleDetail = (params) =>
  requests.get('/link/article_detail', { params })
//知识学院热门媒体
export const reqHotMedia = (params) =>
  requests.get('/link/hot_media', { params })

/**
 * 后台接口
 */
//首页代理数据
export const reqRegency = (params) => requests.get('', { params })
//首页统计
export const reqStatistics = (params) =>
  requests.get('/index/statistics', { params })
//折线图
export const reqZhexian = (params) => requests.get('/index/zhexian', { params })
//当月数据
export const thisMonth = (params) =>
  requests.get('/index/statistics?type=1', { params })
//累计数据
export const historyData = (params) =>
  requests.get('/index/statistics?type=2', { params })
//刷新余额
export const reqYue = (params) => requests.get('/index/shuaxin', { params })
//订单列表
export const reqOrderList = (params) =>
  requests.get('/index/order_list', { params })
// 基础服务订单列表
export const reqOrderList2 = (params) =>
  requests.post('/order/goods_order_list', params)
//撤销订单
export const revocationOrder = (params) =>
  requests.get('/index/chexiao', { params })
//加入收藏
export const addCollection = (params) =>
  requests.get('/index/collection', { params })
//取消收藏
export const delCollection = (params) =>
  requests.get('/index/collection_del', { params })
//收藏列表
export const reqCollectionList = (params) =>
  requests.get('/index/collection_list', { params })
//充值记录列表
export const reqBalanceOrder = (params) =>
  requests.post('/index/balance_order', qs.stringify(params))
//我的通知列表
export const reqMyNote = (params) =>
  requests.post('/message/message_list', qs.stringify(params))
//我的通知详情
export const reqMyNoteDetail = (params) =>
  requests.post('/message/read_message', qs.stringify(params))
//问题反馈
export const reqUserMsg = (params) =>
  requests.get('/index/user_message', { params })
//分销详情
export const fenxiaoinfo = (params) =>
  requests.post('/fenxiao/info', qs.stringify(params))
// 积分明细
export const get_score_detail_list = (params) =>
  requests.post('/fenxiao/get_score_detail_list', params)
// 获取被邀请人列表
export const get_invite_list = (params) =>
  requests.post('/fenxiao/get_invited_list', qs.stringify(params))
// 填写邀请人
export const bind_invite = (params) =>
  requests.post('/fenxiao/bind_invite_user', qs.stringify(params))
// 修改昵称
export const change_nickname = (params) =>
  requests.post('/user/modifyUserInfo', qs.stringify(params))
// 优惠券列表
export const get_coupon = (params) =>
  requests.post('/coupon/list', qs.stringify(params))
// 已领取de优惠券
export const my_coupon = (params) =>
  requests.post('/coupon/exchanged_list', qs.stringify(params))
// 兑换优惠券
export const exchange_coupon = (params) =>
  requests.post('/coupon/exchange', qs.stringify(params))

//稿件上传
export const gaojianSubmit = (params) =>
  requests.post('/index/mt_submit', qs.stringify(params))
//wechat_qrCODE
export const reqwxpay = (params) => requests.get('/index/wxpay', { params })
//wechat_pay_callback
export const reqPayStatus = (params) =>
  requests.get('/index/paystatus', { params })
//Alipay_submit
export const reqalipaySubmit = (params) =>
  requests.post('/index/alipay_submit', qs.stringify(params))
export const reqwxpaySubmit = (params) =>
  requests.post('/index/wxpay_submit', qs.stringify(params))

//Alipay_page
export const reqalipay = (params) => requests.get('/index/alipay', { params })
//Alipay_balance
export const reqalipayBalance = (params) =>
  requests.get('/index/alipay_balance', { params })
//Alipay_pay_callback
export const reqAlipauCallback = (params) =>
  requests.get('/index/ali_paystatus', { params })
//余额支付
export const payYue = (params) => requests.post('/index/pay', params)

//  创建订单
export const create_order = (params) =>
  requests.post('/goods/create_order', qs.stringify(params))
// 微信支付
export const wxpay = (params) => requests.post('/goods/pc_wxpay', params)
// 支付宝支付
export const alipay = (params) => requests.post('/goods/pc_alipay', params)
// 轮询支付状态
export const pay_status = (params) =>
  requests.post('/goods/paystatus', qs.stringify(params))
// 余额支付
export const balance_pay = (params) =>
  requests.post('/goods/pay_balance', qs.stringify(params))

//微信余额充值
export const reqWxpayBalance = (params) =>
  requests.post('/index/wxpay_balance', params)
//代理商
//获取首页参数
export const reqPageConfig = (params) =>
  requests.post('/link/configs', qs.stringify(params))
//获取知识学院
export const reqKnowledgeArticle = (params) =>
  requests.post('/index/reqKnowledgeArticle', qs.stringify(params))

//新版首页 获取商品banner_list
export const getGoodsBanner = (params) =>
  requests.post('/goods/get_goods_banner', qs.stringify(params))

//新版首页 获取服务介绍
export const getGoodsVersion = (params) =>
  requests.post('/goods/get_goods_version', qs.stringify(params))

//新版首页 获取商品套餐
export const goodsDetail = (params) =>
  requests.get('/link/goodsDetail?' + params)
//新版首页 获取服务介绍
export const getGoodsIntroduction = (params) =>
  requests.post('/goods/get_goods_introduction', qs.stringify(params))

//新版首页 获取成功案例
export const getGoodsCase = (params) =>
  requests.post('/goods/get_goods_case', qs.stringify(params))
//新版首页 获取成功案例
export const getGoodsQuestion = (params) =>
  requests.post('/goods/get_goods_question', qs.stringify(params))
