<template>
  <div class="agent3_contact">
    <!-- 头部BG -->
    <div class="bg">
      <img class="bg_img" src="@/assets/agent/3/agent3_contact_BG.png" alt="" />
      <div class="bg_text">
        {{
          navId == 0
            ? "随时掌握最新最全的互联网资讯"
            : "致力于为你提供专业的互联网服务"
        }}
      </div>
    </div>
    <!-- nav -->
    <div class="nav">
      <div
        class="nav_left"
        :class="{ navSeled: navId == 0 }"
        @click="nacSel(0)"
      >
        <span class="nav_left_text" :class="{ opacity: navId == 0 }">公司简介</span>
      </div>
      <div
        class="nav_right"
        :class="{ navSeled: navId == 1 }"
        @click="nacSel(1)"
      >
        <span class="nav_right_text" :class="{ opacity: navId == 1 }"
          >联系我们</span
        >
      </div>
    </div>
    <!-- 公司简介 -->
    <div v-show="navId == 0" class="introduce3">
      <div class="introduce3_content" v-html="introduce"></div>
      <div class="introduce3_icon">
        <div class="introduce3_icon_item">
          <img
            class="introduce3_icon_item_img"
            src="@/assets/agent/3/agent3_contact_icon1.png"
            alt=""
          />
          <div class="introduce3_icon_item_text">愿景</div>
        </div>
        <div class="introduce3_icon_item">
          <img
            class="introduce3_icon_item_img"
            src="@/assets/agent/3/agent3_contact_icon2.png"
            alt=""
          />
          <div class="introduce3_icon_item_text">使命</div>
        </div>
        <div class="introduce3_icon_item">
          <img
            class="introduce3_icon_item_img"
            src="@/assets/agent/3/agent3_contact_icon3.png"
            alt=""
          />
          <div class="introduce3_icon_item_text">价值观</div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div v-show="navId == 1" class="contact3">
      <div class="contact3_one">
        <div class="contact3_one_item">
          <img
            class="contact3_one_item_img"
            src="@/assets/agent/3/agent3_contact_address.png"
            alt=""
          />
          <div class="contact3_one_item_text">公司地址：{{ address }}</div>
        </div>
        <div class="contact3_one_item">
          <img
            class="contact3_one_item_img"
            src="@/assets/agent/3/agent3_contact_phone.png"
            alt=""
          />
          <div class="contact3_one_item_text">客服电话：{{ phone }}</div>
        </div>
      </div>
      <div class="contact3_two">
        <div class="contact3_two_item">
          <img :src="qrcode1" alt="" />
        </div>
        <div class="contact3_two_item">
          <img :src="qrcode2" alt="" />
        </div>
      </div>
      <div class="contact3_three">添加客服微信了解更多(二者都可)</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
      ...mapState({
          email: (state) => state.global.agentSiteInfo.email,
          address: (state) => state.global.agentSiteInfo.address,
          phone: (state) => state.global.agentSiteInfo.phone,
          company: (state) => state.global.agentSiteInfo.company_name,
          introduce: (state) => state.global.agentSiteInfo.company_introduce,
          qrcode1: (state) => state.global.agentSiteInfo.qrcode1,
          qrcode2: (state) => state.global.agentSiteInfo.qrcode2,
      }),
    },
  data() {
    return {
      navId: 0,
    };
  },
  methods: {
    nacSel(id) {
      this.navId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.agent3_contact {
  width: 100%;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 头部BG */
.bg {
  width: 100%;
  position: relative;
}
.bg_img {
  display: block;
  width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 2;
}
.bg_text {
  width: 100%;
  font-size: 42px;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 200px;
  left: 0;
  z-index: 4;
}
/* nav */
.nav {
  width: 100%;
  height: 67px;
  margin-top: -67px;
  background-color: rgba(44, 44, 44, 0.23);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;
}
.nav_left,
.nav_right {
  width: 600px;
  height: 67px;
  text-align: center;
  line-height: 67px;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.nav_left_text,
.nav_right_text {
  font-size: 24px;
  color: #fff;
  opacity: 0.8;
}
.navSeled {
  background-color: rgba(51, 51, 51, 0.7);
  transition: 0.2s ease-out;
}
.opacity {
  opacity: 1;
}
/* 公司简介 */
.introduce3 {
  width: 1200px;
  padding: 70px 0 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.introduce3_content {
  width: 1200px;
  font-size: 16px;
  color: #666;
  line-height: 46px;
}
.introduce3_icon {
  width: 1030px;
  margin-top: 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.introduce3_icon_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.introduce3_icon_item_img {
  width: 64px;
  height: 64px;
  margin-bottom: 11px;
  object-fit: contain;
  display: block;
}
.introduce3_icon_item_text {
  font-size: 20px;
  color: #666;
}
/* 联系我们 */
.contact3 {
  width: 1200px;
  padding: 170px 0 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact3_one {
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact3_one_item {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact3_one_item_img {
  width: 90px;
  height: 90px;
  margin-bottom: 60px;
  object-fit: contain;
  display: block;
}
.contact3_one_item_text {
  font-size: 20px;
  color: #999;
}
.contact3_two {
  width: 400px;
  margin: 90px 0 30px;
  display: flex;
  justify-content: space-between;
}
.contact3_two_item {
  width: 120px;
  height: 120px;
  margin: 0 40px 0;
  border: 1px solid #707070;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.contact3_three {
  font-size: 20px;
  color: #999;
}

.nav_left:hover,
.nav_right:hover {
  width: 600px;
  height: 67px;
  text-align: center;
  line-height: 67px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    width: 60px;
    height: 100px;
    background: linear-gradient(
      97deg,
      rgba(255, 255, 255, 0.03) 0%,
      rgba(255, 255, 255, 0.2) 40%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.5;
    transform: skewX(-16deg);
    position: absolute;
    top: 0;
    left: -80px;
    animation: count 1.5s infinite;
    animation-duration: alternate;
    animation-timing-function: ease-in;
  }
}
@keyframes count {
  to {
    left: 680px;
  }
  from {
    left: -80px;
  }
}
</style>
<style lang="scss">
.introduce_content {
  font-size: 14px;
  line-height: 30px;
  color: #999;
  white-space: pre-wrap;
}
.introduce_content p {
  font-size: 14px;
  line-height: 30px;
  color: #999;
}
</style>
