<template>
  <div class="agent2_knowledge">
    <!-- 头部BG -->
    <div class="bg">
      <img
        class="bg_img"
        src="@/assets/agent/2/agent2_knowledge_BG.png"
        alt=""
      />
    </div>
    <!-- 媒体资讯 -->
    <div class="knowledge2">
      <div class="knowledge2_item" @click="goDetail(leftArticleList[0].id)">
        <img
          class="knowledge2_item_img"
          :src="leftArticleList[0].img_url"
          alt=""
        />
        <div class="knowledge2_item_title">
          {{leftArticleList[0].title}}
        </div>
        <div class="knowledge2_item_content">
          {{leftArticleList[0].description}}
        </div>
        <div class="knowledge2_item_bot">
          <div class="knowledge2_item_bot_btn">新闻资讯</div>
          <div class="knowledge2_item_bot_date">{{leftArticleList[0].create_time.slice(0,10)}}</div>
        </div>
      </div>


      <div class="knowledge_box_item">
          <div class="knowledge_box_item_article" @click="goDetail(item.id)" v-for="(item,index) in right_article_list" :key="index">
            <div class="knowledge_box_item_article_left">
              <div class="knowledge_box_item_article_left_title">
                {{item.title}}
              </div>
              <div class="knowledge_box_item_article_left_content">
                {{item.description}}
              </div>
            </div>
            <div class="knowledge_box_item_article_right">
              <div class="knowledge_box_item_article_right_data">{{item.create_time.slice(5,10)}}</div>
              <div class="knowledge_box_item_article_right_year">{{item.create_time.slice(0,4)}}</div>
            </div>
          </div>
        </div>
    </div>
    <!-- 分页 -->
    <el-pagination class="pagination" 
        layout="prev, pager, next"
        :total="total" 
        :pageSize="6"
        :currentPage="page"
        @current-change="pageChange">
    </el-pagination>
    <!-- 公司资讯 -->
    <div class="info2">
      <div class="info2_item" @click="goDetail(item.id)" v-for="(item,index) in company_info_list" :key="index">
        <div class="info2_item_date">
          <div class="info2_item_date_day">{{item.create_time.slice(8,10)}}</div>
          <div class="info2_item_date_month">{{item.create_time.slice(0,7)}}</div>
        </div>
        <div class="info2_item_content">
          <div class="info2_item_content_title">
            {{item.title}}
          </div>
          <div class="info2_item_content_text">
            {{item.description}}
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>

import { mapState } from "vuex";
import {
  loginPost,
  loginCodePost,
  reqTenxun,
  reqArticleNav,
  reqArticleList,
} from "@/api";
export default {
  data() {
    return {
      total:6,
      page:1,
      //公司咨讯
      company_info_list:[
        {
          description: "这是一条公司咨讯详情",
          id: "1",
          img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
          thumb_image_id: "1",
          title: "这是一条公司咨讯标题",
          update_time: "",
          create_time: "2022-11-28 09:10:59",
        }
      ],
      leftArticleList:[
        {
          description: "这是一条咨讯详情",
          id: "1",
          img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
          thumb_image_id: "1",
          title: "这是一条咨讯标题",
          update_time: "",
          create_time: "2022-11-28 09:10:59",
        }
      ],
      right_article_list:[],
    };
  },
  mounted(){
    this.initView();
    this.initCompanyView();
  },
  methods: {
    //翻页
    pageChange(e) {
      this.page = e;
      // this.loadingBot = true;
      this.reqList(1065, "", e);
    },
    reqList(id = 1065, type = null, page = 1) {
      let reqData = {
        type_id: id,
        type: type,
        page: page,
      };
      reqArticleList(reqData)
        .then((res) => {
          if (res.code == 1) {
            // this.contentTopList = res.data.data.slice(0, 4);
            let articleList = res.data.data;
            //清空原有数据
            this.leftArticleList.length = 0;
            this.right_article_list.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
              if(i == 0){
                this.leftArticleList.push(articleList[i]);
              }else{
                this.right_article_list.push(articleList[i]);
              }
              
            }
            this.total = res.data.total;
          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingBot = false;
        });
    },
    initCompanyView(){
      reqArticleList({ type: 1068 })
        .then((res) => {
          if (res.code == 1) {
            // this.contentTopList = res.data.data.slice(0, 4);
            let articleList = res.data.data;
            //清空原有数据
            this.company_info_list.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
              this.company_info_list.push(articleList[i]);
            }

          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingTop = false;
        });
    },
    initView(){
        reqArticleList({ type_id: 1065 })
        .then((res) => {
          if (res.code == 1) {
            // this.contentTopList = res.data.data.slice(0, 4);
            let articleList = res.data.data;
            //清空原有数据
            this.leftArticleList.length = 0;
            this.right_article_list.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
              if(i == 0){
                this.leftArticleList.push(articleList[i]);
              }else{
                this.right_article_list.push(articleList[i]);
              }
              
            }
            this.total = res.data.total;

          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingTop = false;
        });
    },
    goDetail(id) {
      this.$router.push({
          path:'/template2/knowledgein/'+id+ '.html'
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.agent2_knowledge {
  width: 100%;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 头部BG */
.bg {
  width: 100%;
}
.bg_img {
  display: block;
  width: 100%;
  object-fit: contain;
}
/* 媒体资讯 */
.knowledge2 {
  width: 1200px;
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}
.knowledge2_item {
  width: 498px;
  padding: 35px 45px 25px;
  transition: 0.2s ease-out;
  cursor: pointer;
}
.knowledge2_item:hover {
  box-shadow: 0px 6px 12px 1px rgba(59, 61, 71, 0.16);
  transform: translateY(-5px);
  transition: 0.2s ease-out;
}
.knowledge2_item:hover .knowledge2_item_title {
  color: #00c8c8;
  transition: 0.2s ease-out;
}
.knowledge2_item_img {
  width: 498px;
  margin-bottom: 19px;
  object-fit: contain;
  display: block;
}
.knowledge2_item_title {
  font-size: 24px;
  color: #999;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.knowledge2_item_content {
  margin: 20px 0 34px;
  font-size: 16px;
  color: #999;
  line-height: 24px;
  letter-spacing: 1px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.knowledge2_item_bot {
  width: 498px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.knowledge2_item_bot_btn {
  width: 80px;
  height: 27px;
  border: 1px solid #c9c9c9;
  font-size: 12px;
  color: #333;
  text-align: center;
  line-height: 27px;
  transition: 0.2s ease-out;
}
.knowledge2_item_bot_btn:hover {
  border: 1px solid #00c8c8;
  color: #00c8c8;
  transition: 0.2s ease-out;
}
.knowledge2_item_bot_date {
  font-size: 12px;
  color: #999;
}
/* 公司资讯 */
.info2 {
  width: 1200px;
  padding: 90px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info2_item {
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-out;
  cursor: pointer;
}
.info2_item:hover {
  box-shadow: 0px 6px 12px 1px rgba(59, 61, 71, 0.16);
  transform: translateY(-5px);
  transition: 0.2s ease-out;
}
.info2_item:hover .info2_item_content_title {
  color: #00c8c8;
  transition: 0.2s ease-out;
}
.info2_item_date {
  width: 70px;
  height: 70px;
  margin: 0 25px 0 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.info2_item_date_day {
  margin-bottom: 6px;
  font-size: 20px;
  color: #999;
  font-weight: 500;
}
.info2_item_date_month {
  font-size: 12px;
  color: #999;
}
.info2_item_content {
  display: flex;
  flex-direction: column;
}
.info2_item_content_title {
  width: 730px;
  margin-bottom: 10px;
  font-size: 20px;
  color: #333;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.info2_item_content_text {
  width: 730px;
  font-size: 14px;
  color: #999;
  line-height: 22px;
  letter-spacing: 1px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
/* el分页pagination样式 */
.pagination {
  width: 100%;
  height: 40px;
  margin-bottom: 120px;
  background-color: #fff;
  text-align: center;
  :deep {
    .number,
    .more,
    .btn-prev,
    .btn-next {
      // color: #999;
      width: 40px;
      height: 40px;
      margin: 0 10px;
      padding: 0;
      border: 1px solid #f3f3f3;
      font-size: 20px;
      color: #999 !important;
      font-weight: normal;
      text-align: center;
      line-height: 40px;
    }
    .el-icon {
      font-size: 20px;
    }
    .btn-prev:hover,
    .btn-next:hover {
      border: 1px solid #00c8c8 !important;
      color: #00c8c8 !important;
      transition: 0.2s ease-out;
    }
    .more {
      border: none;
    }
    .active {
      border: 1px solid #00c8c8 !important;
      color: #00c8c8 !important;
    }
  }
}
</style>
