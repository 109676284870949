<template>
  <div class="article">
    <div class="article-title">
      <div class="article-title-line">
        <div></div>
        <h1>热门文章</h1>
      </div>
      <span>查看更多</span>
    </div>
    <div class="article-content">
      <el-empty
        v-show="articleList.length <= 0"
        description="暂无文章"
      ></el-empty>
      <div
        class="article-content-item"
        v-for="(item, index) in articleList"
        :key="index"
        @click="goDetail(item.id)"
      >
        <span
          :class="{ first: index == 0, second: index == 1 || index == 2 }"
          >{{ index + 1 }}</span
        >
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { reqArticleList } from "@/api";
export default {
  name: "articles",
  inject: ["reload"],
  computed: {
    ...mapState({
      navBackColor: (state) => state.global.navBackColor,
    }),
  },
  data() {
    return {
      articleList: [],
    };
  },
  mounted() {
    reqArticleList({ type: 2 })
      .then((res) => {
        if (res.code == 1) {
          this.articleList = res.data.data.splice(0, 10);
        } else {
          // this.$message.error(res.message);
          console.log(res.message);
        }
      })
      .catch((res) => {
        this.$message.error(res);
      });
  },
  methods: {
    goDetail(id) {
      if (id) {
        this.$router.push({
          name: "knowledgein",
          params: { KnowledgeId: id + ".html" },
        });
        if (this.$route.path.substring(0, 12) == "/KnowledgeIn") {
          this.reload();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.article {
  width: 100%;
  max-height: 660rem;
  background-color: #fff;
  &-title {
    width: 90%;
    // height: 15px;
    padding: 25rem 0 10rem;
    margin: 0 auto;
    border-bottom: 1px solid #999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-line {
      width: 110rem;
      display: flex;
      align-items: center;
      div {
        width: 4rem;
        height: 22rem;
        margin-right: 3rem;
        background-color: #ff3852;
      }
      h1 {
        font-size: 24rem;
        color: #333;
      }
    }
    span {
      font-size: 16rem;
      color: #999;
      cursor: pointer;
    }
    span:hover {
      color: #0982f9;
    }
  }
  &-content {
    width: 90%;
    // height: 472rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    &-item {
      width: 100%;
      margin-top: 20rem;
      padding-bottom: 20rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f3f3f3;
      cursor: pointer;
      span {
        display: inline-block;
        width: 18rem;
        height: 18rem;
        margin-right: 8rem;
        background-color: #ff9406;
        font-size: 14rem;
        color: #fff;
        line-height: 18rem;
        text-align: center;
      }
      .first {
        background-color: #ff3852;
      }
      .second {
        background-color: #f86400;
      }
      p {
        width: 350rem;
        font-size: 18rem;
        color: #333;
        white-space: nowrap; /*内容超宽后禁止换行显示*/
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /*文字超出部分以省略号显示*/
      }
      p:hover {
        color: #0982f9;
      }
    }
  }
}
</style>
