<template>
  <div class="baseService">
    <!-- 导航栏填充 -->
    <!-- <div class="navBack"></div> -->
    <Topbg :bgimage="bgimage"></Topbg>
    <div class="navbox">
      <div class="hide" @click="showClick"></div>
      <div v-if="isShowClick" class="hideClick" @click="addList"></div>
      <nav class="navbox-nav">
        <span
          v-for="(item, index) in serveNavList"
          :key="index"
          :class="{ navsel: index == navIndex }"
          @click="navSel(item.id, index)"
          >{{ item.title }}</span
        >
      </nav>
      <div class="navbox-subnav">
        <span class="navbox-subnav-title">服务类别：</span>
        <span
          class="navbox-subnav-item"
          :class="{ subnavsel: -1 == subNavIndex }"
          @click="subNavSel(-1, -1)"
          >不限</span
        >
        <!-- 对象的方式进行bind绑定 -->
        <span
          class="navbox-subnav-item"
          :class="{ subnavsel: index == subNavIndex }"
          v-for="(item, index) in serveSubNavList"
          :key="index"
          @click="subNavSel(item.id, index)"
          >{{ item.title }} </span
        >
      </div>
    </div>
    <div class="listbox" v-loading="loading">
      <el-empty
        class="empty"
        v-show="serveList.length <= 0 && !loading"
        image="https://www.mtuitui.com/onlineSource/shopcarEmpty.png"
        description="暂无商品"
      ></el-empty>
      <div
        class="listbox-item"
        @click="goDetail(item)"
        v-for="(item, index) in serveList"
        :key="index"
      >
        <img :src="item.thumb_image" :alt="item.name" />
        <span class="listbox-item-name">{{ item.name }}</span>
        <div class="listbox-item-bot">
          <div class="listbox-item-bot-l">
            <span>￥</span>
            <span class="listbox-item-bot-l-price">{{ item.price }}</span>
            <span>起</span>
          </div>
          <span class="listbox-item-bot-seal">销量：{{ item.sales }}+</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topbg from "@/pages/Mediasource/Topbg";
import { reqBasicServiceNav, reqServeGoods } from "@/api";
export default {
  components: {
    Topbg,
  },
  data() {
    return {
      id:-1,
      index:-1,
      sid:-1,
      sindex:-1,
      isShowClick: false,
      bgimage: "bg-1.png",
      loading: false,

      navIndex: 0,
      subNavIndex: -1,
      serveNavList: [],
      serveSubNavList: [],
      serveList: [],
    };
  },
  async mounted() {
    //获取url上的一级导航index,和二级导航index
    if(this.$route.query.id && typeof this.$route.query.id != 'undefined'){
      this.id = this.$route.query.id;
    }
    if(this.$route.query.index && typeof this.$route.query.index != 'undefined'){
      this.index = this.$route.query.index;
      
    }
    

    
    if(this.$route.query.sid && typeof this.$route.query.sid != 'undefined'){
      this.sid = this.$route.query.sid;
    }
    if(this.$route.query.sindex && typeof this.$route.query.sindex != 'undefined'){
      this.sindex = this.$route.query.sindex;
    }
    // this.changeURLStatic('sindex','0');
    
    try {
      await this.requestNav();
    } catch (error) {
      this.$message.error(error);
    }
    if(this.sid != -1 && this.sindex != -1){
      this.subNavSel(this.sid,this.sindex);
      this.requestList(this.sid);
      this.sid = -1;
      this.sindex = -1;
    }
  
  },
  methods: {
    updateNav(id,navIndex){
      //清空所有url
      history.replaceState(
        null,
        null,
        window.location.href.replace(window.location.search, "")
      );
      this.changeURLStatic('id',id);
      if(navIndex == -1){
        navIndex = '0';
      }
      
      this.changeURLStatic('index',navIndex.toString());
      
    },
    updateSubNav(sid,subNavIndex){

      this.changeURLStatic('sid',sid);
      if(subNavIndex == -1){
        subNavIndex = '0';
      }
      this.changeURLStatic('sindex',subNavIndex.toString());
    },
    changeURLStatic(name, value) {
      let url = this.changeURLParam(location.href, name, value); // 修改 URL 参数
      
      history.replaceState(null, null, url); // 替换地址栏
    },
    changeURLParam(url, name, value) {
      if (typeof value === "string") {
        value = value.toString().replace(/(^\s*)|(\s*$)/, ""); // 移除首尾空格
      }
      let url2;
      if (!value) {
        // remove
        let reg = eval("/(([?|&])" + name + "=[^&]*)(&)?/i");
        let res = url.match(reg);
        if (res) {
          if (res[2] && res[2] === "?") {
            // before has ?
            if (res[3]) {
              // after has &
              url2 = url.replace(reg, "?");
            } else {
              url2 = url.replace(reg, "");
            }
          } else {
            url2 = url.replace(reg, "$3");
          }
        }
      } else {
        let reg = eval("/([?|&]" + name + "=)[^&]*/i");
        if (url.match(reg)) {
          // edit
          url2 = url.replace(reg, "$1" + value);
        } else {
          // add
          let reg = /([?](\w+=?)?)[^&]*/i;
          let res = url.match(reg);
          url2 = url;
          if (res) {
            if (res[0] !== "?") {
              url2 += "&";
            }
          } else {
            url2 += "?";
          }
          url2 += name + "=" + value;
        }
      }
      return url2;
    },
    async showClick() {
      if (this.isShowClick) {
        this.isShowClick = false;
      } else {
        this.isShowClick = true;
      }
      try {
        console.log("requestList5")
        await this.requestList(this.serveNavList[0].sons[0].id);
      } catch (error) {
        this.$message.error(error);
      }
    },
    addList() {
      this.isShowClick = false;
      this.serveList = [];
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let i = 0;
        while (i < 32) {
          this.serveList.push({
            thumb_image: require("@/assets/backstage/portrait/portrait" +
              Math.round(Math.random() * 10) +
              ".png"),
            name: "自媒体平台粉丝增长服务,文章/短视频阅读数提升服务",
            price: Math.round(Math.random() * 900 + 100),
            sales: Math.round(Math.random() * 5000 + 500),
          });
          i++;
        }
      }, 400);
    },
    goDetail(item) {
      this.$router.push("/backstage/serverDetail?id=" + item.id);
    },
    navSel(id, index) {

      this.navIndex = index;
      this.subNavIndex = -1;
      this.serveSubNavList = this.serveNavList[index].sons;
      
      
      
      this.updateNav(this.serveNavList[this.navIndex].id,index);
      if(this.sid == -1 && this.sindex ==-1){
        this.requestList(this.serveNavList[this.navIndex].id);
      }else{
        // this.requestList(this.sid);
        // this.sid = -1;
        // this.sindex = -1;
      }
      

    },
    subNavSel(id, index) {
      console.log(id,"sub_nav_id");
      console.log(index,"sub_nav_index");
      this.subNavIndex = index;
      this.updateSubNav(id,index);
      if (index == -1 || id == -1) {
        console.log("requestList2")
        this.requestList(this.serveNavList[this.navIndex].id);
      } else {
        console.log("requestList1")
        this.requestList(id);
      }
    },
    //请求
    async requestNav() {
      let nav = await reqBasicServiceNav();
      if (nav.code == 1) {
        this.serveNavList = nav.data;
        if (this.serveNavList.length > 0) {
          console.log(this.index,"nav_index")
          console.log(this.id,"nav_id")
          if(this.index != -1 && this.id != -1){
            this.serveSubNavList = this.serveNavList[this.index].sons;
            this.navSel(this.id,this.index);
            this.index = -1;
            this.id = -1;
          }else{
            this.serveSubNavList = this.serveNavList[0].sons;
          }
          
        }
      } else {
        this.$message.error(nav.message || "请求出错");
      }
    },
    async requestList(id) {
      this.loading = true;
      let List = await reqServeGoods({ id: id });
      if (List.code == 1) {
        this.serveList.length = 0;
        this.serveList = List.data;

      } else {
        this.$message.error(List.message || "请求出错");
      }
      if (List) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.baseService {
  width: 100%;
  padding-bottom: 380rem;
  background-color: #f4f4f4;
  .navBack {
    width: 100%;
    height: 70px;
    background-color: #0982f9;
  }
  .navbox {
    position: relative;
    // width: 100%;
    width: 1480rem;
    padding-top: 20rem;
    margin: -180rem auto 0;
    background-color: #fff;
    &-nav {
      width: 92%;
      padding: 0 4%;
      margin: 0 auto;
      border-bottom: 1px solid #e3e3e3;
      span {
        display: inline-block;
        width: 150rem;
        height: 60rem;
        background-color: #fff;
        font-size: 20rem;
        color: #999;
        text-align: center;
        line-height: 60rem;
        cursor: pointer;
        box-sizing: border-box;
      }
      span:hover {
        border-bottom: 3px solid #0982f9;
      }
      .navsel {
        background-color: #0982f9;
        color: #fff;
      }
    }
    &-subnav {
      width: 92%;
      padding: 30rem 4%;
      margin: 0 auto;
      border-bottom: 1px solid #e3e3e3;
      &-title {
        font-size: 18rem;
        color: #585858;
      }
      &-item {
        margin: 0 25rem;
        font-size: 18rem;
        color: #999;
        cursor: pointer;
      }
      &-item:hover {
        color: #42a0fd;
      }
      .subnavsel {
        color: #0982f9 !important;
      }
    }
  }
  .listbox {
    // width: 1608rem;
    width: 1340rem;
    min-height: 700px;
    padding: 0 70rem;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 90;
    &-item {
      width: 240rem;
      height: 348rem;
      margin: 14rem;
      border-radius: 6px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      img {
        width: 200rem;
        height: 200rem;
        margin: 20rem 0;
        object-fit: contain;
      }
      &-name {
        width: 84%;
        font-size: 14rem;
        font-weight: bold;
        color: #999;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /*! autoprefixer: on */
        word-break: break-all;
      }
      &-bot {
        width: 84%;
        margin-top: 20rem;
        font-size: 14rem;
        font-weight: bold;
        color: #ec0e0e;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        &-l {
          &-price {
            margin-right: 2px;
            font-size: 20rem;
          }
        }
        &-seal {
          font-weight: normal;
          color: #999;
        }
      }
    }
    &-item:hover {
      border: 1px solid #0982f9;
      box-shadow: 0px 3px 10px 1px rgba(#eee, 0.2);
      transform: scale(1.03);
      transition: 0.2s ease-out;
    }
  }
}
.hide {
  width: 30rem;
  height: 30rem;
  position: absolute;
  right: -30rem;
  top: 80rem;
}
.hideClick {
  width: 30rem;
  height: 30rem;
  position: absolute;
  right: -30rem;
  top: 164rem;
}
.empty {
  align-self: center;
  width: 100%;
}
</style>
