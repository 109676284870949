<template>
  <div class="page">
    <div>
      <img src="@/assets/contact/banner1.jpg" alt="" />
      <div class="banner2">
        <img src="@/assets/contact/banner2.png" alt="" />
        <img src="@/assets/contact/icon1.png" alt="" />
      </div>
      <div class="banner3">
        <img class="bg" src="@/assets/contact/banner3.jpg" alt="">
        <div class="swiper mySwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="@/assets/contact/1.png" alt="" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/contact/2.png" alt="" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/contact/3.png" alt="" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/contact/4.png" alt="" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/contact/5.png" alt="" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/contact/6.png" alt="" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/contact/7.png" alt="" />
            </div>

            <div class="swiper-slide">
              <img src="@/assets/contact/9.png" alt="" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>

        <div class="slogan">
          <img src="@/assets/contact/10.jpg" alt="" />
          <img src="@/assets/contact/11.jpg" alt="" />
          <img src="@/assets/contact/12.jpg" alt="" />
        </div>
      </div>
      <!-- <div class="bananr4">
        <img src="@/assets/contact/banner4.jpg" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    var mySwiper = new Swiper(".mySwiper", {
      slidesPerView: 5,
      spaceBetween: 30,
      loop: true,
      autoplay:true
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>


.swiper {
  width: 1200rem;
  height: 434rem;
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
img {
  width: 100%;
  object-fit: fill;
}
.banner2 {
  position: relative;
  img:nth-child(2) {
    width: 22%;
    position: absolute;
    top: 5%;
    right: 20%;
    object-fit: cover;
  }
}
.banner3 {
  // background-image: url("@/assets/contact/banner3.jpg");
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  position: relative;
  margin-bottom: 100px;
  .bg{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slogan {
    position: absolute;
    bottom: 4.5%;
    right: 18%;
    img {
      width: 200rem;
      height: 300rem;
      object-fit: cover;
      margin: 0 10px;
      transition: all 0.5s;
      border-radius: 10px;
      
    }
    img:hover {
      transform: scale(1.05);
      transition: all 0.5s;
      cursor: pointer;
    }
  }
}
.page {
  width: 100%;
  padding-top: 70rem;
  // background-image: url("@/assets/contact/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  .box {
    width: 1410rem;
    // width: 100%;
    height: 750rem;
    margin: 80rem auto 0;
    display: flex;
    justify-content: space-between;
    &-left {
      width: 630rem;
      height: 100%;
      color: #fff;
      h1 {
        font-size: 32rem;
        font-weight: bold;
      }
      p {
        width: 630rem;
        margin: 50rem 0;
        font-size: 16rem;
        line-height: 30rem;
        letter-spacing: 1px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 16;
        line-clamp: 16;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      }
      address {
        font-size: 22rem;
      }
    }
    &-right {
      width: 710rem;
      height: 100%;
      background-image: url("@/assets/contact/bg-right.png");
      background-size: 630rem 670rem;
      background-repeat: no-repeat;
      background-position: 0% 0%;
    }
  }
}
</style>
