<template>
  <div class="agent2_footer">
    <div class="box">
      <div class="box_item">
        <div class="box_item_title">媒体资源</div>
        <div class="box_item_content">
          <div
            class="box_item_content_item"
            v-for="(item, index) in mediaList"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="box_item">
        <div class="box_item_title">服务与支持</div>
        <div class="box_item_content">
          <div
            class="box_item_content_item"
            @click="hezuo(item)"
            v-for="(item, index) in supportList"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="box_item">
        <div class="box_item_title">快速入口</div>
        <div class="box_item_content">
          <div
            class="box_item_content_item entrance"
            style="cursor: pointer"
            @click="goSupport(item)"
            v-for="(item, index) in entranceList"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="box_us">
        <div class="box_us_title">关注我们</div>
        <div class="box_us_code">
          <img class="box_us_code_item" :src="qrcode1" alt="" />
          <img class="box_us_code_item" :src="qrcode2" alt="" />
        </div>
        <div class="box_us_phone">
          <div class="box_us_phone_box">
            <img
              class="box_us_phone_img"
              src="@/assets/agent/1/phone.png"
              alt=""
            />
            <div class="box_us_phone_text">服务热线：</div>
          </div>
          <div class="box_us_phone_number">{{ phone }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import agentMixin from "@/mixins/agentId.mixins";
export default {
  data() {
    return {
      mediaList: [
        {
          name: "网站",
        },
        {
          name: "自媒体",
        },
        {
          name: "微信公众号",
        },
        {
          name: "新浪微博",
        },
        {
          name: "短视频",
        },
        {
          name: "海外媒体",
        },
        {
          name: "媒体套餐",
        },
        {
          name: "网站SEO",
        },
        {
          name: "APP",
        },
        {
          name: "小程序",
        },
        {
          name: "百科词条",
        },
      ],
      supportList: [
        {
          name: "媒体资源",
        },
        {
          name: "文案代写",
        },
        {
          name: "合作加盟",
        },
        {
          name: "关于我们",
        },
        {
          name: "知识学院",
        },
      ],
      entranceList: [
        {
          name: "联系我们",
          path: "/contact",
        },
        {
          name: "会员注册/登录",
          path: "/login",
        },
      ],
    };
  },
  mixins: [agentMixin],
  methods: {
    goSupport(item) {
      this.$router.push(
        { path: item.path },
        () => {},
        () => {}
      );
    },
    hezuo(item) {
      console.log(sessionStorage.getItem("agent"));
      if (item.name == "合作加盟") {
        // if (sessionStorage.getItem("agent") == 0) {
        //   this.$store.commit("global/setAgentId", 1);
        //   sessionStorage.setItem("agent", 1);
        // } else if (sessionStorage.getItem("agent") == 1) {
        //   this.$store.commit("global/setAgentId", 2);
        //   sessionStorage.setItem("agent", 2);
        // } else if (sessionStorage.getItem("agent") == 2) {
        //   this.$store.commit("global/setAgentId", 3);
        //   sessionStorage.setItem("agent", 3);
        // } else {
        //   this.$store.commit("global/setAgentId", 0);
        //   sessionStorage.setItem("agent", 0);
        // }
        // this.$router.go(0);
        this.$router.push({path:'/template/3'});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.agent2_footer {
  width: 100%;
  height: 390px;
  background-color: #212831;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box {
  width: 1200px;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box_item,
.box_us {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box_item_title {
  padding: 0 20px;
  margin-bottom: 30px;
  font-size: 22px;
  color: #fff;
  font-weight: bold;
}
.box_item_content {
  height: 210px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.box_item_content_item {
  padding: 0 20px;
  line-height: 34px;
  font-size: 14px;
  color: #fff;
  cursor: default;
}
.box_us_title {
  font-size: 22px;
  color: #fff;
  font-weight: bold;
}
.box_us_code {
  width: 290px;
  margin: 31px 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box_us_code_item {
  width: 130px;
  height: 130px;
  object-fit: contain;
  background-color: white;
}
.box_us_phone {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
.box_us_phone_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box_us_phone_img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.box_us_phone_text {
  margin: 0 4px 0;
  font-size: 16px;
  color: #fff;
}
.box_us_phone_number {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
}

.entrance:hover {
  color: yellow;
}
</style>
