<template>
  <div class="agent2_home">
    <!-- 头部BG -->
    <div class="bg">
      <img class="bg_img" src="@/assets/agent/2/agent2_homeBG.png" alt="" />
    </div>
    <!-- 我的优势 -->
    <div class="advantage">
      <div class="advantage_nav">
        <div
          class="advantage_nav_item"
          :class="{ advantage_nav_itemseled: advantageIndex == index }"
          @click="advantageSel(index)"
          v-for="(item, index) in advantageList"
          :key="index"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="advantage_point">
        <div
          class="advantage_point_top"
          :class="{ line1seled: advantageIndex == 0 }"
        ></div>
        <div class="advantage_point_mid">
          <div
            class="advantage_point_mid_line"
            :class="{ line2seled: advantageIndex == 1 }"
          ></div>
          <div class="advantage_point_mid_point"></div>
        </div>
        <div
          class="advantage_point_bot"
          :class="{ line3seled: advantageIndex == 2 }"
        ></div>
      </div>
      <div class="advantage_content">
        <div class="advantage_content_item">
          {{ advantageList[advantageIndex].content }}
        </div>
      </div>
    </div>
    <!-- 平台资源 -->
    <div class="resource">
      <div class="resource_title">平台资源</div>
      <div class="resource_box">
        <div
          class="resource_box_item"
          v-for="(item, index) in resourceList"
          :key="index"
        >
          <img
            class="resource_box_item_img"
            :src="require('@/assets/agent/1/' + item.img)"
            :alt="item.name"
          />
          <span class="resource_box_item_name">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <!-- 知识学院 -->
    <div class="knowledge">
      <div class="knowledge_title">知识学院</div>
      <div class="knowledge_box">
        <div class="knowledge_box_left">
          <img
            class="knowledge_box_left_img"
            :src="leftContentTopList[0].img_url"
            alt=""
          />
          <div class="knowledge_box_left_content" @click="goDetail(leftContentTopList[0].id)">
            <div class="knowledge_box_left_content_top">
              <div class="knowledge_box_left_content_top_title">
                {{leftContentTopList[0].title}}
              </div>
              <div class="knowledge_box_left_content_top_date">{{leftContentTopList[0].create_time.slice(0,10)}}</div>
            </div>
            <div class="knowledge_box_left_content_bot">
              {{leftContentTopList[0].description}}
            </div>
          </div>
        </div>
        <div class="knowledge_box_right">
          <div
            class="knowledge_box_right_item"
            @click="goDetail(item.id)"
            v-for="(item,index) in rightContentTopList"
            :key="index"
          >
            <div class="knowledge_box_right_item_top">
              <div class="knowledge_box_right_item_top_title">
                {{item.title}}
              </div>
              <div class="knowledge_box_right_item_top_date">{{item.create_time.slice(0,10)}}</div>
            </div>
            <div class="knowledge_box_right_item_bot">
              {{item.description}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作平台 -->
    <div class="cooperation">
      <div class="cooperation_title">合作平台</div>
      <div class="cooperation_box">
        <img
          class="cooperation_box_img"
          v-for="(item, index) in cooperationList"
          :key="index"
          :src="require('@/assets/agent/1/' + item.img)"
          alt=""
        />
      </div>
    </div>
    <!-- 下单流程 -->
    <div class="process">
      <div class="process_title">下单流程</div>
      <div class="process_detail">
        <img
          class="process_detail_img"
          src="@/assets/agent/2/agent2_fagao.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import {
  loginPost,
  loginCodePost,
  reqTenxun,
  reqArticleNav,
  reqArticleList,
} from "@/api";
export default {
  data() {
    return {
      leftContentTopList:[{
          description: "这是一条咨讯详情",
          id: "1",
          img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
          thumb_image_id: "1",
          title: "这是一条咨讯标题",
          update_time: "",
          create_time: "2022-11-28 09:10:59",
        }],
      rightContentTopList:[],
      advantageIndex: 0,
      advantageList: [
        {
          title: "公司优势",
          content:
            "1、专业经验：资深团队专业指导，懂你所想。\n\n2、一手资源：直接对接各大平台和编辑，执行更畅通。\n\n3、专属客服：一对一客服解惑答疑，第一时间解决您的需求。",
        },
        {
          title: "权益保障",
          content:
            "1、技术保障：资深团队，保障平台平稳运行。\n\n2、财务保障：透明流水，充值提现灵活选择。\n\n3、发稿保障：专属客服，随时应答发稿问题。",
        },
        {
          title: "服务对象",
          content: "餐饮、食品、制造、公关、广告、等各领域企事业单位",
        },
      ],
      resourceList: [
        {
          img: "adent1_icon_1.png",
          name: "新闻",
        },
        {
          img: "adent1_icon_2.png",
          name: "自媒体",
        },
        {
          img: "adent1_icon_3.png",
          name: "论坛",
        },
        {
          img: "adent1_icon_4.png",
          name: "短视频",
        },
        {
          img: "adent1_icon_5.png",
          name: "网站",
        },
        {
          img: "adent1_icon_6.png",
          name: "达人",
        },
        {
          img: "adent1_icon_7.png",
          name: "广告",
        },
        {
          img: "adent1_icon_8.png",
          name: "社群",
        },
      ],
      cooperationList: [
        {
          img: "agent1_cor1.png",
        },
        {
          img: "agent1_cor2.png",
        },
        {
          img: "agent1_cor3.png",
        },
        {
          img: "agent1_cor4.png",
        },
        {
          img: "agent1_cor5.png",
        },
        {
          img: "agent1_cor6.png",
        },
        {
          img: "agent1_cor7.png",
        },
        {
          img: "agent1_cor8.png",
        },
        {
          img: "agent1_cor9.png",
        },
        {
          img: "agent1_cor10.png",
        },
      ],
    };
  },
  mounted(){
    this.initView();
  },
  methods: {
    initView(){
        //首页四个
        reqArticleList({ type: 1 })
        .then((res) => {
          if (res.code == 1) {
            // this.contentTopList = res.data.data.slice(0, 4);
            let articleList = res.data.data;
            //清空原有数据
            this.leftContentTopList.length = 0;
            this.rightContentTopList.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
                if(i  == 0){
                  this.leftContentTopList.push(articleList[i]);
                }else{
                  this.rightContentTopList.push(articleList[i]);
                }
            }
            console.log(this.leftContentTopList);
            console.log(this.rightContentTopList);

          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingTop = false;
        });
    },
    advantageSel(index) {
      this.advantageIndex = index;
    },
    goDetail(id) {
      this.$router.push({
          path:'/template2/knowledgein/'+id+ '.html'
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.agent2_home {
  width: 100%;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 头部BG */
.bg {
  width: 100%;
}
.bg_img {
  display: block;
  width: 100%;
  object-fit: contain;
}
/* 我的优势 */
.advantage {
  width: 1200px;
  margin-top: -150px;
  margin-bottom: 90px;
  box-shadow: 0px 5px 20px 1px rgba(132, 132, 132, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.advantage_nav {
  width: 180px;
}
.advantage_nav_item {
  width: 100%;
  height: 65px;
  margin: 3px 0;
  background-color: #fff;
  font-size: 24px;
  color: #666;
  font-weight: bold;
  text-align: center;
  line-height: 65px;
  cursor: pointer;
  transition: 0.1s ease-out;
}
.advantage_nav_item:hover {
  color: #00c8c8;
  transition: 0.1s ease-out;
}
.advantage_nav_itemseled {
  background-color: #00c8c8;
  color: #fff !important;
  transition: 0.1s ease-out;
}
.advantage_point {
  width: 80px;
  margin: 0 35px 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.advantage_point_top {
  width: 70px;
  height: 62px;
  border-top: 2px dashed #707070;
  border-right: 2px dashed #707070;
  box-sizing: border-box;
  transition: 0.1s ease-out;
}
.advantage_point_bot {
  width: 70px;
  height: 62px;
  border-bottom: 2px dashed #707070;
  border-right: 2px dashed #707070;
  box-sizing: border-box;
  transition: 0.1s ease-out;
}
.advantage_point_mid {
  margin: 15px 0;
  display: flex;
  align-items: center;
}
.advantage_point_mid_line {
  width: 52px;
  height: 0px;
  border-bottom: 2px dashed #707070;
  transition: 0.1s ease-out;
}
.advantage_point_mid_point {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  border-radius: 50%;
  background-color: #00c8c8;
}
.line1seled {
  border-top: 2px dashed #00c8c8;
  border-right: 2px dashed #00c8c8;
  transition: 0.1s ease-out;
}
.line2seled {
  border-bottom: 2px dashed #00c8c8;
  transition: 0.1s ease-out;
}
.line3seled {
  border-bottom: 2px dashed #00c8c8;
  border-right: 2px dashed #00c8c8;
  transition: 0.1s ease-out;
}
.advantage_content {
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.advantage_content_item {
  font-size: 24px;
  color: #00c8c8;
  line-height: 30px;
  white-space: pre-line;
}
/* 平台资源 */
.resource {
  width: 100%;
  padding: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resource_title {
  margin-bottom: 60px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.resource_box {
  width: 1380px;
  padding: 90px 270px;
  background-color: #f8f9fa;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.resource_box_item {
  margin: 30px 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resource_box_item_img {
  margin-bottom: 13px;
  display: block;
  width: 72px;
  height: 72px;
  object-fit: contain;
}
.resource_box_item_name {
  font-size: 20px;
  color: #333;
  font-weight: bold;
}
/* 知识学院 */
.knowledge {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.knowledge_title {
  margin-bottom: 70px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.knowledge_box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.knowledge_box_left {
  width: 658px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.knowledge_box_left:hover {
  box-shadow: 0px 6px 12px 1px rgba(59, 61, 71, 0.16);
  transform: translateY(-7px);
  transition: 0.2s ease-out;
}
.knowledge_box_left:hover .knowledge_box_left_content_top_title {
  color: #00c8c8 !important;
  transition: 0.2s ease-out;
}
.knowledge_box_left_img {
  width: 100%;
  height: 370px;
  object-fit: contain;
  display: block;
}
.knowledge_box_left_content {
  width: 626px;
  padding: 16px;
  margin-top: 14px;
}
.knowledge_box_left_content_top {
  margin-bottom: 18px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.knowledge_box_left_content_top_title {
  font-size: 20px;
  color: #999;
  font-weight: 500;
}
.knowledge_box_left_content_top_date {
  font-size: 12px;
  color: #999;
}
.knowledge_box_left_content_bot {
  font-size: 16px;
  color: #999;
  line-height: 24px;

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.knowledge_box_right {
  width: 498px;
  display: flex;
  flex-direction: column;
}
.knowledge_box_right_item {
  width: 466px;
  padding: 16px;
  margin-bottom: 13px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.knowledge_box_right_item:hover {
  box-shadow: 0px 6px 12px 1px rgba(59, 61, 71, 0.16);
  transform: translateY(-16px);
  transition: 0.2s ease-out;
}
.knowledge_box_right_item:hover .knowledge_box_right_item_top_title {
  color: #00c8c8 !important;
  transition: 0.1s ease-out;
}
.knowledge_box_right_item_top {
  margin-bottom: 16px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.knowledge_box_right_item_top_title {
  font-size: 18px;
  color: #999;
  font-weight: 500;
}
.knowledge_box_right_item_top_date {
  font-size: 12px;
  color: #999;
}
.knowledge_box_right_item_bot {
  font-size: 14px;
  color: #999;
  line-height: 24px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
/* 合作平台 */
.cooperation {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cooperation_title {
  margin-bottom: 70px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.cooperation_box {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cooperation_box_img {
  width: 224px;
  margin-bottom: 20px;
  object-fit: contain;
  transition: 0.2s ease-out;
}
.cooperation_box_img:hover {
  box-shadow: 0 3px 8px 1px rgba(9, 130, 249, 0.19);
  transition: 0.2s ease-out;
}
/* 下单流程 */
.process {
  width: 1200px;
  padding-top: 90px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.process_title {
  margin-bottom: 70px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.process_detail {
  width: 1150px;
}
.process_detail_img {
  width: 100%;
  object-fit: contain;
  display: block;
}
</style>
