<template>
  <div class="pasmanage">
    <!-- 设置登录密码 -->
    <div class="setPsd">
      <span class="setPsd-title">修改密码</span>
      <div class="setPsd-input">
        <span>设置密码：</span>
        <input v-model="setPsd" type="password" />
      </div>
      <div class="setPsd-input">
        <span>确认密码：</span>
        <input v-model="setPsdConfirm" type="password" />
      </div>
      <div class="setPsd-input">
        <span>手机号码：</span>
        <input
          v-model="setTel"
          type="number"
          oninput="if(value.length > 11)value = value.slice(0, 11)"
          onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
        />
      </div>
      <div class="setPsd-input">
        <span>手机验证码：</span>
        <input
          class="setPsd-input-code"
          v-model="setTelCode"
          type="number"
          oninput="if(value.length > 4)value = value.slice(0, 4)"
          onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
        />
        <div
          class="setPsd-input-btn"
          :class="{ telInput: codeTime == 0 && setTel.length >= 11 }"
          @click="sendTelCode"
          v-text="codeTime == 0 ? '发送验证码' : codeTime + 's后重新发送'"
        >
        </div>
      </div>
      <button @click="setPsdSub" class="setPsd-btn">确定</button>
    </div>
  </div>
</template>

<script>
import { telFormation } from "@/utils/judge";
import { reqResetpwdcode } from "@/api";
import { reqResetpwd } from "@/api";
export default {
  data() {
    return {
      // isPassword: false,

      setPsd: "",
      setPsdConfirm: "",
      setTel: "",
      setTelCode: "",

      modifyOldPsd: "",
      modifyNewPsd: "",
      modifyNewPsdConfirm: "",

      codeTime: 0,
    };
  },
  methods: {
    //发送验证码
    sendTelCode() {
      if (!this.setTel) {
        this.$message.error("请输入手机账号");
        return;
      }
      if (!telFormation(this.setTel)) {
        this.$message.error("请检查手机号格式");
        return;
      }
      if (this.codeTime != 0) return;
      this.codeTime = 60;
      var interval = setInterval(() => {
        this.codeTime -= 1;
        if (this.codeTime == 0) {
          clearInterval(interval);
        }
      }, 1000);
      reqResetpwdcode({ mobile: this.setTel }).then((res) => {
        if (res.code == 200) {
          this.$message.success("验证码已发送");
        } else {
          this.$message.error("验证码请求失败:" + res.message);
        }
      });
    },
    //重置密码
    setPsdSub() {
      if (!this.setTel) {
        this.$message.error("请输入手机账号");
        return;
      }
      if (!telFormation(this.setTel)) {
        this.$message.error("请检查手机号格式");
        return;
      }
      if (this.setPsd != this.setPsdConfirm) {
        this.$message.error("两次密码不一致");
        return;
      }
      reqResetpwd({
        mobile: this.setTel,
        password: this.setPsd,
        code: this.setTelCode,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("修改成功，请重新登录");
          localStorage.token = "";
          this.$router.push("/login");
        } else {
          this.$message.error("修改失败:" + res.message);
        }
      });
    },
    forgetPsd() {
      this.$router.push("/forgetPsd");
    },
  },
};
</script>

<style lang="scss" scoped>
.pasmanage {
  width: 100%;
  height: 440rem;
  .setPsd {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      margin: 25rem 0 40rem 0;
      font-size: 24rem;
      color: #333;
    }
    &-input {
      width: 415rem;
      margin-bottom: 20rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        font-size: 18rem;
        color: #999;
        white-space: nowrap; /*内容超宽后禁止换行显示*/
        text-overflow: ellipsis; /*文字超出部分以省略号显示*/
      }
      input {
        width: 260rem;
        height: 40rem;
        padding: 0 20rem;
        background-color: #f4f4f4;
        border: #e5e5e5 1px solid;
        border-radius: 2px;
        outline: none;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      &-code {
        width: 130rem !important;
      }
      &-btn {
        width: 114rem;
        height: 40rem;
        margin-left: 14rem;
        border: 1px solid #eee;
        background-color: rgba($color: #eee, $alpha: 1);
        border-radius: 2px;
        font-size: 17rem;
        color: #888;
        box-sizing: content-box;
        text-align: center;
        line-height: 40rem;
        white-space: nowrap;
        cursor: no-drop;
      }
      &-code::-webkit-outer-spin-button,
      &-code::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
    &-btn {
      width: 114rem;
      height: 37rem;
      background-color: #0982f9;
      border-radius: 6px;
      font-size: 17rem;
      color: #fff;
      text-align: center;
      line-height: 37rem;
      border: none;
      cursor: pointer;
    }
  }

  .modifyPsd {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      margin: 25rem 0 40rem 0;
      font-size: 24rem;
      color: #333;
    }
    &-input {
      width: 415rem;
      margin-bottom: 20rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        font-size: 18rem;
        color: #999;
        white-space: nowrap; /*内容超宽后禁止换行显示*/
        text-overflow: ellipsis; /*文字超出部分以省略号显示*/
      }
      input {
        width: 260rem;
        height: 40rem;
        padding: 0 20rem;
        background-color: #f4f4f4;
        border: #e5e5e5 1px solid;
        border-radius: 2px;
        outline: none;
      }
    }
    &-btn {
      width: 415rem;
      display: flex;
      justify-content: flex-end;
      button {
        width: 128rem;
        height: 40rem;
        background-color: #0982f9;
        border-radius: 6px;
        font-size: 17rem;
        color: #fff;
        text-align: center;
        line-height: 37rem;
        border: none;
        cursor: pointer;
      }
      button:last-child {
        margin-left: 45rem;
        background-color: #42a0fd;
      }
    }
  }
}
.telInput {
  background-color: #0982f9 !important;
  color: #fff !important;
  cursor: pointer !important;
}
</style>
