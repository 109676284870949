<template>
  <div class="knowledge">
    <!-- 导航栏填充 -->
    <div class="navBack"></div>
    <!-- 知识学院上方图块 -->
    <div class="title">
      <img src="@/assets/knowledge/topBanner.png" alt="" />
    </div>
    <!-- 下方内容 -->
    <div class="content">
      <!-- 下方左侧内容 -->
      <div class="content-left">
        <!-- 左侧文章内容 -->
        <h1>{{ content.title }}</h1>
        <div class="content-left-subtitle">
          <span>{{ content.subtitle }}</span>
          <span>{{ content.date }}</span>
        </div>
        <p v-html="content.detail"></p>
        <div class="content-left-pre">上一篇：</div>
        <div class="content-left-next">下一篇：</div>
      </div>
      <!-- 下方右侧内容 -->
      <div class="content-right">
        <Articles class="articles"></Articles>
        <Media class="medias"></Media>
      </div>
    </div>
  </div>
</template>

<script>
import Articles from "../Knowledge/Article";
import Media from "../Knowledge/Media";
import { reqArticleDetail } from "@/api";
export default {
  props: ["KnowledgeId"],
  components: {
    Articles,
    Media,
  },
  computed: {
    title() {
      return this.$route.params.KnowledgeId;
    },
  },
  data() {
    return {
      content: {
        title: "",
        subtitle: "",
        date: "",
        detail: ``,
      },
    };
  },
  watch: {
    // title(newValue, oldValue) {
    //   this.title = newValue;
    //   console.log(this.title);
    // },
  },
  mounted() {
    reqArticleDetail({ id: this.$route.params.KnowledgeId.split(".")[0] }).then(
      (res) => {
        if (res.code == 1) {
          this.content.title = res.data.title;
          this.content.subtitle = res.data.author;
          this.content.date = res.data.post_time;
          this.content.detail = res.data.content;
        } else {
          console.log(res.message);
        }
      }
    );
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.knowledge {
  cursor: default;
  width: 100%;
  //   height: 100vh;
  background-color: #f4f4f4;
  padding: 0 0 380rem;
   
  .navBack {
    width: 100%;
    height: 70px;
    background-color: #0982f9;
  }
  .title {
    width: 1430rem;
    height: 200rem;
    margin: 30rem auto 0;
    background-color: #0982f9;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .content {
    width: 1430rem;
    margin: 40rem auto 0;
    display: flex;
    justify-content: space-between;
    &-left {
      width: 960rem;
      padding: 65rem 30rem;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        max-width: 900rem !important;
      }
      h1 {
        width: 1020rem;
        font-size: 32rem;
        color: #333;
        font-weight: bold;
        text-align: center;
        letter-spacing: 2px;
      }
      &-subtitle {
        width: 960rem;
        margin: 17rem 0 32rem;
        text-align: end;
        span {
          font-size: 16rem;
          color: #999;
          margin-left: 20rem;
        }
      }
      &-pre,
      &-next {
        margin: 40rem 0 0;
        font-size: 16rem;
        color: #999;
        align-self: flex-start;
        cursor: pointer;
      }
      &-next {
        margin: 15rem 0 0;
      }
    }

    &-right {
      width: 390rem;
      height: 100%;
      .articles {
        width: 390rem;
        // height: 560rem;
      }
      .medias {
        width: 385rem;
        // height: 710rem;
        margin-top: 22rem;
      }
    }
  }
}
</style>
<style lang="scss">
.content-left p {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 30px;
  width: 100%;
}
.content-left img {
  width: 90%;
}
.content-left {
  overflow: hidden;
}
</style>
