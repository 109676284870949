<template>
  <div class="agent1_knowledge">
    <!-- 头部BG -->
    <div class="bg">
      <img
        class="bg_img"
        src="@/assets/agent/1/agent1_knowledge_BG.png"
        alt=""
      />
    </div>
    <!-- 媒体资讯 -->
    <div class="knowledge">
      <div class="knowledge_title">媒体资讯</div>
      <div class="knowledge_box">
        
        <div class="knowledge_box_item">
          <img
            class="knowledge_box_item_img"
            src="@/assets/agent/1/agent1_know1.png"
            alt=""
          />
          <div class="knowledge_box_item_article" @click="goDetail(item.id)" v-for="(item,index) in left_article_list" :key="index">
            <div class="knowledge_box_item_article_left">
              <div class="knowledge_box_item_article_left_title">
                {{item.title}}
              </div>
              <div class="knowledge_box_item_article_left_content">
                {{item.description}}
              </div>
            </div>
            <div class="knowledge_box_item_article_right">
              
              <div class="knowledge_box_item_article_right_data">{{item.create_time.slice(5,10)}}</div>
              <div class="knowledge_box_item_article_right_year">{{item.create_time.slice(0,4)}}</div>
            </div>
          </div>
        </div>




        <div class="knowledge_box_item">
          <img
            class="knowledge_box_item_img"
            src="@/assets/agent/1/agent1_know2.png"
            alt=""
          />
          <div class="knowledge_box_item_article" @click="goDetail(item.id)" v-for="(item,index) in right_article_list" :key="index">
            <div class="knowledge_box_item_article_left">
              <div class="knowledge_box_item_article_left_title">
                {{item.title}}
              </div>
              <div class="knowledge_box_item_article_left_content">
                {{item.description}}
              </div>
            </div>
            <div class="knowledge_box_item_article_right">
              <div class="knowledge_box_item_article_right_data">{{item.create_time.slice(5,10)}}</div>
              <div class="knowledge_box_item_article_right_year">{{item.create_time.slice(0,4)}}</div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination 
        class="pagination" 
        layout="prev, pager, next" 
        :pageSize="6"
        :currentPage="page"
        @current-change="pageChange"
        :total="total">
      </el-pagination>
    </div>
    <!-- 公司资讯 -->
    <div class="info">
      <div class="info_title">公司资讯</div>
      <div class="info_box_item_article" @click="goDetail(item.id)" v-for="(item,index) in company_info_list" :key="index">
        <div class="info_box_item_article_left">
          <div class="info_box_item_article_left_title">
            {{item.title}}
          </div>
          <div class="info_box_item_article_left_content">
            {{item.description}}
          </div>
        </div>
        <div class="info_box_item_article_right">
          <div class="info_box_item_article_right_data">{{item.create_time.slice(5,10)}}</div>
          <div class="info_box_item_article_right_year">{{item.create_time.slice(0,4)}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import {
  loginPost,
  loginCodePost,
  reqTenxun,
  reqArticleNav,
  reqArticleList,
} from "@/api";
export default {
  data() {
    return {
      page:1,
      total:6,
      //公司咨讯
      company_info_list:[
        {
          description: "这是一条公司咨讯详情",
          id: "1",
          img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
          thumb_image_id: "1",
          title: "这是一条公司咨讯标题",
          update_time: "",
          create_time: "2022-11-28 09:10:59",
        }
      ],
      left_article_list:[
        {
          description: "这是一条公司咨讯详情",
          id: "1",
          img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
          thumb_image_id: "1",
          title: "这是一条公司咨讯标题",
          update_time: "",
          create_time: "2022-11-28 09:10:59",
        }
      ],
      right_article_list:[
        {
          description: "这是一条咨讯详情",
          id: "1",
          img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
          thumb_image_id: "1",
          title: "这是一条咨讯标题",
          update_time: "",
          create_time: "2022-11-28 09:10:59",
        }
      ],
    };
  },
  mounted(){
    this.initView();
    this.initCompanyView();
  },
  methods: {
    //翻页
    pageChange(e) {
      this.page = e;
      this.loadingBot = true;
      this.reqList(this.contentNavId, "", e);
    },
    reqList(id = 1065, type = null, page = 1) {
      let reqData = {
        type_id: id,
        type: type,
        page: page,
      };
      reqArticleList(reqData)
        .then((res) => {
          if (res.code == 1) {
            let articleList = res.data.data;
            //清空原有数据
            this.left_article_list.length = 0;
            this.right_article_list.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
                if(i % 2 == 0){
                  this.left_article_list.push(articleList[i]);
                }else{
                  this.right_article_list.push(articleList[i]);
                }
            }
            this.total = res.data.total;
          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingBot = false;
        });
    },
    initView(){
        reqArticleList({ type_id: 1065})
        .then((res) => {
          if (res.code == 1) {
            // this.contentTopList = res.data.data.slice(0, 4);
            let articleList = res.data.data;
            //清空原有数据
            this.left_article_list.length = 0;
            this.right_article_list.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
                if(i % 2 == 0){
                  this.left_article_list.push(articleList[i]);
                }else{
                  this.right_article_list.push(articleList[i]);
                }
            }
            this.total = res.data.total;
          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingTop = false;
        });
    },
    initCompanyView(){
      reqArticleList({ type: 1068 })
        .then((res) => {
          if (res.code == 1) {
            // this.contentTopList = res.data.data.slice(0, 4);
            let articleList = res.data.data;
            //清空原有数据
            this.company_info_list.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
              this.company_info_list.push(articleList[i]);
            }

          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingTop = false;
        });
    },
    goDetail(id) {
      this.$router.push({
          path:'/template1/knowledgein/'+id+ '.html'
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.agent1_knowledge {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 头部BG */
.bg {
  width: 100%;
}
.bg_img {
  display: block;
  width: 100%;
  object-fit: contain;
}
/* 知识学院 */
.knowledge {
  width: 100%;
  padding: 90px 0 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.knowledge_title {
  margin-bottom: 70px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.knowledge_box {
  width: 1200px;
  margin: 0 auto 90px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.knowledge_box_item {
  width: 580px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.knowledge_box_item_img {
  width: 100%;
  object-fit: contain;
  display: block;
}
.knowledge_box_item_article {
  width: 100%;
  margin-top: 40px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(197, 195, 195, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
}
.knowledge_box_item_article:hover {
  border-bottom: 1px solid rgba(9, 130, 249, 0.3);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article:hover .knowledge_box_item_article_right {
  border-left: 1px solid rgba(9, 130, 249, 0.3);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article:hover .knowledge_box_item_article_left_title {
  color: rgba(8, 114, 219, 0.8);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article:hover .knowledge_box_item_article_left_content {
  color: rgba(8, 114, 219, 0.6);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article:hover .knowledge_box_item_article_right_data {
  color: rgba(8, 114, 219, 0.6);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article:hover .knowledge_box_item_article_right_year {
  color: rgba(8, 114, 219, 0.6);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article_left {
  width: 460px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.knowledge_box_item_article_left_title {
  margin: 0 0 20px;
  font-size: 24px;
  color: #333;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.knowledge_box_item_article_left_content {
  font-size: 16px;
  color: #999;
  letter-spacing: 1px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.knowledge_box_item_article_right {
  width: 90px;
  border-left: 1px solid rgba(197, 195, 195, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.knowledge_box_item_article_right_data {
  margin-bottom: 8px;
  font-size: 24px;
  color: #666;
}
.knowledge_box_item_article_right_year {
  font-size: 16px;
  color: #999;
}
/* el分页pagination样式 */
.pagination {
  width: 100%;
  height: 40px;
  background-color: #fff;
  text-align: center;
  :deep {
    .number,
    .more,
    .btn-prev,
    .btn-next {
      // color: #999;
      width: 40px;
      height: 40px;
      margin: 0 10px;
      padding: 0;
      border: 1px solid #f3f3f3;
      font-size: 20px;
      color: #999 !important;
      font-weight: normal;
      text-align: center;
      line-height: 40px;
    }
    .el-icon {
      font-size: 20px;
    }
    .btn-prev:hover,
    .btn-next:hover {
      border: 1px solid #00c8c8 !important;
      color: #00c8c8 !important;
      transition: 0.2s ease-out;
    }
    .more {
      border: none;
    }
    .active {
      border: 1px solid #00c8c8 !important;
      color: #00c8c8 !important;
    }
  }
}
/* 公司资讯 */
.info {
  width: 1200px;
  margin-top: 90px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info_title {
  margin-bottom: 40px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.info_box_item_article {
  width: 100%;
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(197, 195, 195, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
}
.info_box_item_article:hover {
  border-bottom: 1px solid rgba(9, 130, 249, 0.3);
  transition: 0.2s ease-out;
}
.info_box_item_article:hover .info_box_item_article_right {
  border-left: 1px solid rgba(9, 130, 249, 0.3);
  transition: 0.2s ease-out;
}
.info_box_item_article:hover .info_box_item_article_left_title {
  color: rgba(8, 114, 219, 0.8);
  transition: 0.2s ease-out;
}
.info_box_item_article:hover .info_box_item_article_left_content {
  color: rgba(8, 114, 219, 0.6);
  transition: 0.2s ease-out;
}
.info_box_item_article:hover .info_box_item_article_right_data {
  color: rgba(8, 114, 219, 0.6);
  transition: 0.2s ease-out;
}
.info_box_item_article:hover .info_box_item_article_right_year {
  color: rgba(8, 114, 219, 0.6);
  transition: 0.2s ease-out;
}
.info_box_item_article_left {
  width: 890px;
  padding-right: 110px;
  display: flex;
  flex-direction: column;
}
.info_box_item_article_left_title {
  margin: 0 0 20px;
  font-size: 24px;
  color: #333;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.info_box_item_article_left_content {
  font-size: 16px;
  color: #999;
  letter-spacing: 1px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.info_box_item_article_right {
  width: 200px;
  border-left: 1px solid rgba(197, 195, 195, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.info_box_item_article_right_data {
  margin-bottom: 8px;
  font-size: 24px;
  color: #666;
}
.info_box_item_article_right_year {
  font-size: 16px;
  color: #999;
}
</style>
