<template>
  <div class="shopCar">
    <div class="nav">
      <span>首页 / </span>
      <span>购物车</span>
    </div>
    <div class="box" v-loading="loading">
      <div class="box-title">
        <span class="box-title-allSeled" @click="selectAll">全选</span>
        <span>订单信息</span>
        <span>金额</span>
        <span>操作</span>
      </div>
      <div class="box-itembox">
        <el-empty
          v-show="tempShopCar.length <= 0 && !loading"
          image="https://www.mtuitui.com/onlineSource/shopcarEmpty.png"
          description="购物车为空"
        ></el-empty>
        <div
          class="box-itembox-item"
          v-for="(item, index) in tempShopCar"
          :key="index"
        >
          <div class="box-itembox-item-square" @click="select(item)">
            <img
              v-show="shopCar.some((elem) => elem.id == item.id)"
              src="@/assets/backstage/seled.png"
              alt=""
            />
          </div>
          <div class="box-itembox-item-order">
            <img src="@/assets/mediaSource/netmediadisplay.png" alt="" />
            <span @click="toAnli(item.url)">{{ item.name }}</span>
          </div>
          <div class="box-itembox-item-cash">
            <span>{{ item.price }}</span>
            <span>元</span>
          </div>
          <div class="box-itembox-item-ope">
            <el-popconfirm
              title="确定删除该项吗？"
              @confirm="shopCarReduce(item, index)"
            >
              <span slot="reference">删除</span>
            </el-popconfirm>
          </div>
        </div>
        <el-divider>没有更多了</el-divider>
      </div>
    </div>
    <!-- 下方固定栏 -->
    <div class="botBigBox">
      <div class="leftFill"></div>
      <div class="botFixed">
        <div class="botFixed-left">
          <span class="botFixed-left-black" style="margin-left: 100rem"
            >合计：</span
          >
          <span class="botFixed-left-red">{{ total }}元</span>
          <span class="botFixed-left-black">可用余额：</span>
          <span class="botFixed-left-red">{{ userMessage.balance }}元</span>
          <span class="botFixed-left-blue" @click="goOnlineCharge">充值</span>
        </div>
        <div class="botFixed-right">
          <div class="botFixed-right-seled" @click="checkSeled">
            <span>查看已选({{ shopCar.length }})</span>
            <img
              :class="{ rotate: shoppingAnima }"
              src="@/assets/backstage/mediaRelease/triangle.png"
              alt="triangle"
            />
          </div>
          <span @click="emptyAll">清空已选</span>
          <button @click="goFileUpload">下一步</button>
        </div>
      </div>
    </div>
    <!-- 购物车 -->
    <div
      v-show="shoppingShow && shopCar.length > 0"
      :class="{ anima: !shoppingAnima }"
      class="shopCarBox"
    >
      <div
        class="shopCarBox-item"
        v-for="(item, index) in shopCar"
        :key="index"
      >
        <span>{{ item.name }}</span>
        <span
          @click="shopCarReduce(item, index)"
          style="font-size: 28rem; margin-left: 6rem; cursor: pointer"
          >×</span
        >
      </div>
    </div>
    <!-- 箭头 -->
    <div
      v-show="shoppingShow && tempShopCar.length > 0"
      :class="{ anima: !shoppingAnima }"
      class="trigelB"
    >
      <!-- <div class="trigel"></div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  inject: ["reloadB"],
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
      shopCar: (state) => state.global.shopCar,
    }),
    total() {
      let s = 0;
      this.shopCar.forEach((element) => {
        s += Number(element.price);
      });
      return s;
    },
  },
  data() {
    return {
      loading: true,
      visible: false,

      shoppingAnima: false,
      shoppingShow: false,

      tempShopCar: [],
    };
  },
  mounted() {
    this.tempShopCar = this.shopCar; //暂时仅用于选中动画
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 200);
  },
  methods: {
    //全选
    selectAll() {
      if (this.shopCar.length < this.tempShopCar.length) {
        this.tempShopCar.forEach((item) => {
          if (
            this.shopCar.some((goods) => {
              return goods.id == item.id;
            })
          ) {
          } else {
            this.$store.dispatch("global/shopCartNumAdd", item);
          }
        });
      } else {
        this.$store.dispatch("global/shopCartEmpty");
      }
    },
    //单选
    select(item) {
      if (
        this.shopCar.some((goods) => {
          return goods.id == item.id;
        })
      ) {
        this.$store.dispatch("global/shopCartNumReduce", item);
      } else {
        this.$store.dispatch("global/shopCartNumAdd", item);
      }
    },
    //案例
    toAnli(url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        this.$message.warning("暂无案例");
      }
    },
    //删除指定
    shopCarReduce(item) {
      if (
        this.shopCar.some((goods) => {
          return goods.id == item.id;
        })
      ) {
        this.$store.dispatch("global/shopCartNumReduce", item);
      }
      this.reloadB();
    },
    //充值
    goOnlineCharge() {
      this.$router.push("/backstage/fundMgt/onlineCharge");
      this.$parent.$emit("navSideSel", { item1Index: 5, item2Index: 0 });
    },
    //查看已选
    checkSeled() {
      this.shoppingAnima = !this.shoppingAnima;
      setTimeout(() => {
        this.shoppingShow = !this.shoppingShow;
      }, 200);
    },
    //清空已选
    emptyAll() {
      this.$store.dispatch("global/shopCartEmpty");
      this.reloadB();
    },
    goFileUpload() {
      if (this.shopCar.length <= 0) {
        this.$message.error("请选择媒体");
      } else {
        this.$router.push("/backstage/fileUpload");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shopCar {
  width: 100%;
  height: 100%;
  .nav {
    width: 100%;
    height: 50rem;
    background-color: #fff;
    line-height: 50rem;
    span {
      font-size: 14rem;
      color: #999;
    }
    span:first-child {
      margin-left: 20rem;
    }
    span:last-child {
      color: #666;
    }
  }
  .box {
    width: 100%;
    // min-height: 550rem;
    margin-top: 20rem;
    margin-bottom: 110rem;
    padding: 20rem 0 0;
    background-color: #fff;
    &-title {
      width: 1560rem;
      height: 42rem;
      margin: 0 auto;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      &-allSeled {
        margin: 0 453rem 0 67rem;
        cursor: pointer;
      }
      &-allSeled:hover {
        color: #0982f9;
      }
      span {
        font-size: 18rem;
        color: #999999;
        line-height: 42rem;
      }
      span:nth-child(2) {
        margin-right: 500rem;
      }
      span:nth-child(3) {
        margin-right: 200rem;
      }
    }
    &-itembox {
      height: 600rem;
      padding-bottom: 20rem;
      overflow-y: scroll;
      &-item {
        width: 1560rem;
        height: 97rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        border-bottom: #eaeaea 1px solid;
        &-square {
          width: 24rem;
          height: 24rem;
          margin: 0 70rem 0 72rem;
          border: 1px solid #707070;
          box-sizing: border-box;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &-order {
          width: 900rem;
          height: 90rem;
          margin-right: 20rem;
          display: flex;
          align-items: center;
          img {
            width: 166rem;
            object-fit: contain;
          }
          span {
            margin-left: 40rem;
            font-size: 24rem;
            font-weight: bold;
            color: #333333;
            cursor: pointer;
          }
          span:hover {
            color: #0982f9;
          }
        }
        &-cash {
          width: 120rem;
          height: 90rem;
          margin-left: 5rem;
          margin-right: 120rem;
          display: flex;
          align-items: baseline;
          justify-content: center;
          line-height: 90rem;
          span {
            color: #ec0e0e;
          }
          span:first-child {
            font-size: 28rem;
            font-weight: bold;
          }
          span:last-child {
            font-size: 12rem;
          }
        }
        &-ope {
          width: 100rem;
          height: 90rem;
          margin-left: 10rem;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 18rem;
            color: #999;
            cursor: pointer;
          }
          span:hover {
            color: #0982f9;
          }
        }
      }
    }
    &-itembox::-webkit-scrollbar {
      // display: none !important;
      width: 10px;
      height: 10px;
      background-color: #f5f5f5;
    }
    /*定义滚动条轨道
      内阴影+圆角*/
    &-itembox::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    /*定义滑块
      内阴影+圆角*/
    &-itembox::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }
  }
  .botBigBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    .leftFill {
      width: 14%;
      min-width: 168px;
    }
    .botFixed {
      width: 84%;
      min-width: 600px;
      height: 100rem;
      background-color: #fff;
      box-shadow: 0 -5px 20px 1px rgb(123 123 123 / 16%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left {
        // width: 40%;
        font-size: 18rem;
        display: flex;
        align-items: flex-end;
        &-black {
          margin-left: 45rem;
          color: #333;
        }
        &-red {
          font-size: 24rem;
          color: #f25b1b;
        }
        &-blue {
          margin-left: 27rem;
          color: #0982f9;
          cursor: pointer;
        }
      }
      &-right {
        // width: 50%;
        font-size: 18rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &-seled {
          height: 35rem;
          padding: 0 10rem;
          border: 1px solid #0982f9;
          border-radius: 4px;
          color: #3395f6;
          text-align: center;
          line-height: 35rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            width: 12rem;
            height: 10rem;
            margin-left: 5rem;
            object-fit: contain;
            transition: 0.2s ease-out;
          }
        }
        button {
          width: 120rem;
          height: 46rem;
          margin-right: 50rem;
          background: linear-gradient(90deg, rgba(13, 158, 220, 0.59), #0982f9);
          border: none;
          border-radius: 6px;
          font-size: 24rem;
          color: #fff;
          text-align: center;
          line-height: 46rem;
          cursor: pointer;
        }
        button:hover {
          background-color: #0982f9;
        }
        button:active {
          background-color: rgba($color: #0982f9, $alpha: 0.7);
        }
      }
      &-right > span {
        margin: 0 30rem 0 20rem;
        color: #999;
        cursor: pointer;
         
      }
      &-right > span:hover {
        color: #3395f6;
      }
    }
  }
  .shopCarBox {
    opacity: 1;
    transition: 0.2s ease-out;
    // height: 165px;
    // max-height: 300px;
    max-width: 1000rem;
    padding: 30rem 40rem;
    box-shadow: 0 -5px 20px 1px rgb(123 123 123 / 40%);
    // background-color: rgba(13, 158, 220, 0.59);
    background-color: #fff;
    // border: 1px solid rgba(13, 158, 220, 0.59);
    display: flex;
    flex-wrap: wrap;

    border-radius: 8px;
    position: fixed;
    bottom: 120px;
    // right: 10px;
    right: 90rem;
    &-item {
      height: 37rem;
      padding: 0 12rem;
      margin: 0 20rem 13rem 0;
      font-size: 18rem;
      color: #fff;
      background-color: #0982f9;
      border-radius: 4px;
      display: flex;
      // align-items: center;
      justify-content: center;
      cursor: default;
      span {
        line-height: 37rem;
      }
    }
  }
  .trigelB {
    opacity: 1;
    transition: 0.2s ease-out;

    width: 0rem;
    height: 0rem;
    // border: 22px solid rgba(13, 158, 220, 0.59);
    // border-top-color: rgba(13, 158, 220, 0.59);
    border: 22px solid #fff;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;

    position: fixed;
    bottom: 76px;
    right: 280rem;
    z-index: 19;
    .trigel {
      width: 0rem;
      height: 0rem;
      border: 18px solid rgba(13, 158, 220, 0.59);
      border-top-color: rgba(13, 158, 220, 0.59);
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;

      position: fixed;
      bottom: 76rem;
      right: 334rem;
      z-index: 19;
    }
  }
  .anima {
    opacity: 0;
    transition: 0.2s ease-out;
  }
}
.rotate {
  transform: rotateZ(180deg);
  transition: 0.2s ease-out;
}
</style>
