<template>
  <component :is="agentType"></component>
</template>

<script>
import agentMixin from "@/mixins/agentId.mixins";
import Knowledge from "@/pages/Knowledge/index.vue";
import Agent1_knowledge from "@/pages/Agent1_knowledge";
import Agent2_knowledge from "@/pages/Agent2_knowledge";
import Agent3_knowledge from "@/pages/Agent3_knowledge";
export default {
  name: "",
  components: {
    mtt: Knowledge,
    agent1: Agent1_knowledge,
    agent2: Agent2_knowledge,
    agent3: Agent3_knowledge,
  },
  data() {
    return {};
  },
  mixins: [agentMixin],
  methods: {},
};
</script>

<style></style>
