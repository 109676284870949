<template>
  <div class="fileUpload">
    <div class="top">
      <div class="top-item">
        <span>稿件上传：</span>
        <!-- action="http://www.seo.com/api/index/upload" -->
        <el-upload
          action="https://admin.mtuitui.com/api/index/upload"
          :headers="{ Authorization: token }"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :on-error="handleError"
          :before-remove="beforeRemove"
          :on-exceed="handleExceed"
          :multiple="false"
          :limit="1"
          :file-list="fileList"
          :data="uploadData"
        >
          <div class="docx">可上传docx文档，文件大小不可超过20M</div>
        </el-upload>
      </div>
      <div class="top-item">
        <span>稿件标题：</span>
        <input type="text" v-model="title" />
      </div>
      <div class="top-item">
        <span>视频链接：</span>
        <input
          class="top-item-input"
          type="text"
          v-model="videoUrl"
          placeholder="视频文件超链接"
        />
        <!-- <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-change="handleChange"
          :file-list="fileList"
        >
          <div class="video">上传视频</div>
        </el-upload> -->
      </div>
      <div class="top-item" v-show="isUploadDoc">
        <span class="top-item-areaLable">发稿内容：</span>
        <!-- <textarea class="top-item-area" v-model="content"></textarea> -->
        <quill-editor
          ref="text"
          v-model="content"
          class="top-item-area"
          :options="editorOption"
        />
      </div>
      <div
        class="top-item"
        :style="
          isUploadDoc
            ? 'margin-left: 116rem; margin-top: 80px;'
            : 'margin-left: 116rem;'
        "
      >
        <span>备注：</span>
        <input type="text" v-model="note" />
      </div>
      <div class="top-item">
        <span>所属客户：</span>
        <input type="text" v-model="client" />
      </div>
    </div>
    <div class="bot">
      <span class="bot-title">发稿注意事项</span>
      <span
        class="bot-item"
        v-for="(item, index) in attentionList"
        :key="index"
        >{{ item.text }}</span
      >
    </div>
    <div class="botfixed">
      <div class="botfixed-TC"></div>
      <div class="botfixed-box">
        <div class="botfixed-box-left">
          <div>
            <span class="botfixed-box-left-text">商品总额：</span>
            <span class="botfixed-box-left-price">{{ price1 }}元</span>

            <span class="botfixed-box-left-text yfje">应付金额：</span>
            <span class="botfixed-box-left-price">{{ price }}元</span>
          </div>
          <div>
            <span class="botfixed-box-left-text">可用余额：</span>
            <span class="botfixed-box-left-price"
              >{{ userMessage.balance }}元</span
            >
            <span class="botfixed-box-left-charge" @click="goOnlineCharge"
              >充值</span
            >
          </div>
        </div>
        <div class="botfixed-box-right">
          <div class="botfixed-box-right-yhq" @click="yhqBtn">
            {{
              checkList.length <= 0 ? '优惠券' : `已选择 ${checkList.length} 张`
            }}
          </div>
          <div class="botfixed-box-right-shang" @click="prestep">上一步</div>
          <!-- <div class="botfixed-box-right-view" @click="preview">预览稿件</div> -->
          <div class="botfixed-box-right-btn" @click="submit">提交</div>
        </div>
      </div>
    </div>

    <el-dialog
      width="660rem"
      top="300rem"
      title="确认支付"
      center
      :visible.sync="payVisible"
    >
      <div
        class="pay"
        v-loading="loading"
        element-loading-text="支付状态确认中"
        element-loading-spinner="el-icon-loading"
      >
        <div class="pay-one">
          <span>应付金额：</span>
          <span class="pay-one-cash">{{ price }}</span>
          <span>元</span>
        </div>
        <div>
          <span>优惠</span>
          <!-- <span class="pay-one-cash">{{ title }}</span> -->
        </div>
        <span class="pay-two">选择支付方式</span>
        <div class="pay-type" @click="paySelect(1)">
          <div>
            <img
              class="pay-type-icon"
              src="@/assets/backstage/pay/yuepay.png"
              alt=""
            />
            <span class="pay-type-name">余额支付</span>
            <span class="pay-type-gray">(可用余额{{ balanceDia }}元)</span>
          </div>
          <div class="pay-type-select">
            <img
              v-show="payTypeId == 1"
              src="@/assets/backstage/pay/gougou.png"
              alt=""
            />
          </div>
        </div>
        <div class="pay-type" @click="paySelect(2)">
          <div>
            <img
              class="pay-type-icon"
              src="@/assets/backstage/pay/wechatpay.png"
              alt=""
            />
            <span class="pay-type-name">微信支付</span>
          </div>
          <div class="pay-type-select">
            <img
              v-show="payTypeId == 2"
              src="@/assets/backstage/pay/gougou.png"
              alt=""
            />
          </div>
        </div>
        <div class="pay-type" @click="paySelect(3)">
          <div>
            <img
              class="pay-type-icon"
              src="@/assets/backstage/pay/alipay.png"
              alt=""
            />
            <span class="pay-type-name">支付宝支付</span>
          </div>
          <div class="pay-type-select">
            <img
              v-show="payTypeId == 3"
              src="@/assets/backstage/pay/gougou.png"
              alt=""
            />
          </div>
        </div>
        <div class="pay-btn" @click="payImid">立即支付</div>
      </div>
    </el-dialog>

    <el-dialog
      width="660rem"
      title="可用优惠券"
      center
      :visible.sync="payResultVisible"
    >
      <div class="mak">
        <el-checkbox-group v-model="checkList" v-if="list.length > 0">
          <div
            data-v-ab5167d0=""
            class="coupon-item x-bc"
            v-for="(item, index) in list"
            :key="index"
          >
            <!---->
            <div
              data-v-ab5167d0=""
              class="coupon-money"
              :class="types >= 2 ? 'graybg' : ''"
            >
              <div data-v-ab5167d0="" class="layof">
                <div data-v-ab5167d0="" style="font-size: 16px">
                  <span>￥</span>
                </div>
                <div data-v-ab5167d0="" style="font-size: 28px">
                  <span>{{ item.amount }}</span>
                </div>
              </div>
              <div data-v-ab5167d0="" class="demand">
                {{ item.description }}
              </div>
              <!-- <div data-v-ab5167d0="" class="demand">满1000.00可用</div> -->
            </div>
            <div data-v-ab5167d0="" class="coupon-detail">
              <div data-v-ab5167d0="" style="margin-bottom: 6px">
                <div
                  data-v-ab5167d0=""
                  class="tabsTit"
                  :class="types >= 2 ? 'graybg' : ''"
                  style=""
                >
                  <span>{{ item.title }}</span>
                </div>
                <div
                  data-v-ab5167d0=""
                  class="coupon-title"
                  style="font-size: 15px; color: rgb(51, 51, 51)"
                >
                  <span></span>
                </div>
              </div>
              <div
                data-v-ab5167d0=""
                class="x-bc"
                style="margin: 0px 10px 6px 0px; height: 24px"
              >
                <div
                  data-v-ab5167d0=""
                  style="font-size: 12px; color: rgb(102, 102, 102)"
                >
                  <span v-if="types == 0"
                    >{{ item.end_date }}&nbsp; 截止兑换</span
                  >
                  <span v-if="types == 1"
                    >{{ item.end_time }}&nbsp; 截止使用</span
                  >
                </div>
                <div data-v-ab5167d0="" v-if="types == 0">
                  <div
                    class="btn-Act"
                    data-v-ab5167d0=""
                    style=""
                    v-if="item.max_exchange_count > item.exchanged_count"
                    @click="exchangeCoupon(item.id)"
                  >
                    兑换
                  </div>
                  <div class="btn-ActS" data-v-ab5167d0="" style="" v-else>
                    兑换达到最大次数
                  </div>
                </div>
                <div data-v-ab5167d0="" v-if="types == 1">
                  <!-- <div class="btn-Act" data-v-ab5167d0="" style="" v-if="types == 1">立即使用</div> -->
                  <el-checkbox :label="index"></el-checkbox>
                </div>
              </div>
              <div
                data-v-ab5167d0=""
                style="
                  cursor: pointer;
                  margin-bottom: 6px;
                  color: rgb(153, 153, 153);
                  font-size: 12px;
                  text-decoration: underline;
                "
                @click="UsageRules()"
              >
                使用规则
              </div>
            </div>
            <div
              v-if="types == 2"
              data-v-ab5167d0=""
              class="seal"
              style="position: absolute; right: 5px; bottom: 5px"
            >
              <div data-v-ab5167d0="" class="seal-son">
                <span
                  data-v-ab5167d0=""
                  style="
                    position: absolute;
                    top: 20px;
                    text-align: center;
                    font-size: 17px;
                    transform: rotate(-12deg);
                    left: 4px;
                    color: rgb(210, 210, 210);
                    font-weight: 900;
                  "
                  >已使用</span
                >
              </div>
            </div>
            <div
              v-if="types == 3"
              data-v-ab5167d0=""
              class="seal"
              style="position: absolute; right: 5px; bottom: 5px"
            >
              <div data-v-ab5167d0="" class="seal-son">
                <span
                  data-v-ab5167d0=""
                  style="
                    position: absolute;
                    top: 20px;
                    text-align: center;
                    font-size: 17px;
                    transform: rotate(-12deg);
                    left: 4px;
                    color: rgb(210, 210, 210);
                    font-weight: 900;
                  "
                  >已过期</span
                >
              </div>
            </div>
          </div>
        </el-checkbox-group>
        <div v-else class="emptyTips">
          <span>暂时还没有优惠券快去领取吧~</span>
        </div>
      </div>
      <div class="yhqqr">
        <el-button type="primary" @click="yhqqr">确定</el-button>
      </div>
    </el-dialog>

    <div v-html="content1"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  gaojianSubmit,
  payYue,
  reqwxpay,
  reqPayStatus,
  reqalipay,
  reqAlipauCallback,
  my_coupon,
} from '@/api'
import QRCode from 'qrcode'
export default {
  inject: ['reloadBTop'],
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
      shopCar: (state) => state.global.shopCar,
      token: (state) => state.global.token,
    }),
  },
  data() {
    return {
      price: 0,
      price1: 0,
      checkList: [],
      user_coupon_ids: '',
      isAlipay: false,
      loading: false,
      content1: '',
      list: [],
      types: 1,
      checkedCities: [],
      editorOption: {
        placeholder: '请输入正文',
      },
      payResultVisible: false,
      payVisible: false,

      // orderNumList: [],
      main_order_id: 0,
      balanceDia: '',
      priceDia: '',
      payTypeId: 1,

      tempZid: [],
      fileList: [],
      title: '',
      videoUrl: '',
      content: ``,
      note: '',
      client: '',
      gaojian: '',
      isUploadDoc: false,
      isFile: false,

      previewURL: '',

      uploadData: {
        // uid: 16,
      }, //暂时不用
      attentionList: [
        {
          text: '1、文章内容必须属于合法合规，如有违法、负面、涉政等一律不予发布、封禁账号并上报相关单位。',
        },
        {
          text: '2、文章提交发布后不可修改、取消或删除，请在提交之前确认好文章内容。',
        },
        {
          text: '3、一篇文章的发布时间为1-36小时以内，平均大约花费6小时。稿件时效默认为1个月,如有备注以媒体备注为准。（稿件内容被投诉、网站暂停、账号被封等原因导致链接时效不足一个月，概不处理）',
        },
        {
          text: '4、审稿时间为：周一至周五 09:00-18:00，下午18点后提交的文章在隔天审核发布。',
        },
        {
          text: '5、各个媒体有不同审核标准，个别所选媒体如不能发布，建议您更换媒体。',
        },
        {
          text: '6、不能带联系方式的一律不能带网址、电话、QQ、微信、二维码等信息，百度新闻源根据文章质量不保证100%收录。',
        },
        {
          text: '7、文章标题23字以内，内容800~2000字内，图片3~5张，媒体可能会对文章进行适当的调整。',
        },
        {
          text: '8、文章中包含的网址、电话、图片过大、图片过多等，媒体会根据审核标准进行调整或者删减，属于正常情况。',
        },
        {
          text: '9、word文档上传之后请仔细检查，是否与编辑器内显示一致，请确认一致后再继续操作。',
        },
        {
          text: '10、所有媒体均不保证排版（缩进，加粗，居中，行间距，字体字号 等），有不能识别的繁体字及特殊符号导致出现乱码，概不负责。',
        },
        {
          text: '11、包收录的媒体(带联系方式/医疗保健/微商/加盟/金融/投资/美容/护肤/移民/婚纱/小说/个人宣传/涉党涉政/过度优化/类稿件默认不包收录)，若稿件未收录，请在第二个工作日提交反馈，有特殊要求的媒体请按照媒体备注操作（例：周五出的稿件，在周六日非正常工作日的情况下，最晚周一下班前反馈未收录）',
        },
        {
          text: '12、稿件发布频道不保证，编辑根据稿件内容安排频道。',
        },
        {
          text: '13、所有成功发布的稿件，除了发布在手机端媒体资源的稿件，其他稿件一律不保证手机端可以打开。',
        },
      ],
    }
  },
  mounted() {
    this.shopCar.forEach((element) => {
      this.price += Number(element.price)
      this.price1 += Number(element.price)
    })
    console.log(this.userMessage, 'userMessage')
    window.addEventListener('visibilitychange', this.visibilityState)
  },
  beforeDestroy() {
    window.removeEventListener('visibilitychange', this.visibilityState)
  },
  methods: {
    yhqqr() {
      this.payResultVisible = false
      // 根据选中的索引 遍历优惠券
      this.list.forEach((item, index) => {
        // this.price = this.price1;
        let arr = 0
        if (this.checkList.includes(index)) {
          // 把选中的优惠券的金额相加
          arr += item.amount
          // 优惠券的金额相加后减去总金额
          if (this.price - arr <= 0) {
            this.checkList = []
            this.$message.error('优惠券金额大于总金额')
          } else {
            this.price = this.price1 - arr
            console.log(this.price, this.price1, arr, '优惠券金额')
          }
          let ids = item.id
          // 把选中的优惠券id放到字符串中
          this.user_coupon_ids += ids + ','
        }
      })
    },
    yhqBtn() {
      console.log('优惠券')
      this.payResultVisible = true
      my_coupon({
        coupon_type: 1,
      }).then((res) => {
        this.list = res.data.records
        console.log(res, '优惠券')
      })
    },
    //充值
    goOnlineCharge() {
      this.$router.push('/backstage/fundMgt/onlineCharge')
      this.$parent.$emit('navSideSel', { item1Index: 5, item2Index: 0 })
    },
    //上一步
    prestep() {
      this.$router.go(-1)
    },
    //预览稿件
    preview() {
      if (!this.previewURL) {
        this.$message.error('请上传稿件后点击预览')
      } else {
        this.$bus.$emit('previewURL', this.content)
        let routeUrl = this.$router.resolve({
          path: '/reviewFile', // 这里的路径就可以正常的写，不需要添加_blank: true
          query: {
            data: this.content,
          },
        })
        window.open(routeUrl.href, '_blank')
        // window.open(this.previewURL, "_blank");
      }
    },
    //支付方式选择
    paySelect(id) {
      this.payTypeId = id
    },
    //提交订单
    submit() {
      if (!this.isFile && !this.content) {
        this.$message.warning('——请上传稿件或编辑稿件——')
        return
      }
      this.shopCar.forEach((elem) => this.tempZid.push(elem.id))
      let data = {
        mt_gaojian: this.gaojian,
        mt_title: this.title,
        mt_video_url: this.videoUrl,
        mt_beizhu: this.note,
        mt_customer: this.client,
        mt_zid: this.tempZid,
      }
      gaojianSubmit(data)
        .then((res) => {
          if (res.code == 1) {
            this.balanceDia = res.res.balance
            this.priceDia = res.res.price
            this.main_order_id = res.res.main_order_id
            this.payVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((res) => {
          this.$message.error(res)
        })
    },
    //支付订单  余额1，微信2，支付宝3
    payImid() {
      if (this.payTypeId == 1) {
        //余额支付
        let data = {
          mt_zid: this.tempZid,
          type: this.payTypeId,
          // order: this.orderNumList,
          main_order_id: this.main_order_id,
          user_coupon_ids: this.user_coupon_ids,
        }
        payYue(data).then((res) => {
          if (res.code == 20) {
            this.$parent.$emit('navSideSel', {
              item1Index: 4,
              item2Index: 0,
            })
            this.$router.push('/backstage/orderMgt/orderQuery')
            let userMsg = JSON.parse(localStorage.userMsg)
            userMsg.balance = res.data.balance
            userMsg.score = res.data.score
            localStorage.userMsg = JSON.stringify(userMsg)
            this.$store.dispatch('global/usermsglocal')
            this.reloadBTop()
            this.$message({
              message: res.message,
              showClose: true,
              type: 'success',
            })
          } else {
            this.$message.error(res.message)
          }
        })
      } else if (this.payTypeId == 2) {
        // 微信支付 生成二维码
        reqwxpay({
          type: 1,
          // order: this.orderNumList,
          main_order_id: this.main_order_id,
          price: this.priceDia,
          user_coupon_ids: this.user_coupon_ids,
        })
          .then((res) => {
            let url = res
            this.open(url)
          })
          .catch((res) => {
            this.$message.error(res)
          })
      } else if (this.payTypeId == 3) {
        //支付宝支付跳转页面
        reqalipay({
          type: 1,
          // order: this.orderNumList,
          main_order_id: this.main_order_id,
          user_coupon_ids: this.user_coupon_ids,
          price: this.priceDia,
        })
          .then((res) => {
            if (!res) {
              this.$message.error('无返回')
              return
            }
            this.content1 = res //将后端取到值赋给定义的属性
            setTimeout(() => {
              // 获取到后端返回的form表单name名称,可以自己输出查看
              document
                .getElementsByName('alipaysubmit')[0]
                .setAttribute('target', '_blank') // 新窗口打开
              document.getElementsByName('alipaysubmit')[0].submit()
              this.isAlipay = true
            }, 1000)
          })
          .catch((res) => {
            this.$message.error(res)
          })
          .finally(() => {})
          .catch(() => {})
      } else {
        this.$message.error('支付方式不存在')
      }
    },
    //文件上传操作
    handleSuccess(response) {
      this.isFile = false
      if (response.fuwenben) {
        this.isFile = true
      }
      this.gaojian = response.file_url
      this.content = response.fuwenben
      this.previewURL = response.htmlUlr
      this.title = response.name
      this.isUploadDoc = true
    },
    handleError(err, file, fileList) {
      console.log('文件上传出错', err, file, fileList)
      this.$message.error('err')
      this.isFile = false
    },
    //文件列表移除文件时钩子
    handleRemove(file, fileList) {
      console.log('移除上传文件', file, fileList)
      this.isFile = false
    },
    //文件超出个数限制
    handleExceed() {
      this.$message.warning(`同时只能选择 1 个文件，请移除后重新添加`)
    },
    //移除确认
    beforeRemove(file) {
      return this.$confirm(`是否移除 ${file.name}？`)
    },
    // 判断是否在微信浏览器  1为微信浏览器 2为其他  这个方法定义在外面，后面也会用到
    _isweixin() {
      return new Promise((resolve) => {
        const ua = window.navigator.userAgent.toLowerCase()
        //userAgent 属性是一个只读的字符串，声明了浏览器用于 HTTP 请求的用户代理头的值。
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          resolve(1)
        } else {
          resolve(2)
        }
      })
    },
    // 微信支付弹出框
    async open(u) {
      let url = await QRCode.toDataURL(u)
      this.$alert(`<img src=${url} />`, '请你微信支付', {
        dangerouslyUseHTMLString: true,
        // 中间布局
        center: true,
        // 是否显示取消按钮
        showCancelButton: true,
        // 取消按钮文本内容
        cancelButtonText: '支付遇见问题',
        // 确定按钮的文本内容
        confirmButtonText: '已支付',
        //右上角的叉子没了
        showClose: true,
        // 关闭前的回调
        beforeClose: (action, instance, done) => {
          // ation:区分取消|确认按钮 取消是cancel,确定是confirm
          // instance是本身实例
          // done是个函数,可以手动关闭弹出框
          if (action == 'cancel') {
            alert('有问题请联系客服或管理员')
            clearInterval(this.timer)
            this.timer = null
            // 关闭弹出框
            done()
          } else {
            // 判断是否真的支付了
            if (this.code == 200) {
              clearInterval(this.timer)
              this.timer = null
              done()
              //路由跳转
              this.$router.push('/backstage/orderMgt/orderQuery')
              this.$parent.$emit('navSideSel', {
                item1Index: 4,
                item2Index: 0,
              })
              setTimeout(() => {
                this.$message.success('支付成功')
              }, 200)
            } else {
              this.loading = true
              setTimeout(() => {
                this.loading = false
                if (this.code == 200) {
                  clearInterval(this.timer)
                  this.timer = null
                  done()
                  //路由跳转
                  this.$router.push('/backstage/orderMgt/orderQuery')
                  this.$parent.$emit('navSideSel', {
                    item1Index: 4,
                    item2Index: 0,
                  })
                  setTimeout(() => {
                    this.$message.success('支付成功')
                  }, 200)
                } else {
                  clearInterval(this.timer)
                  this.timer = null
                  done()
                  this.$message.error('支付失败')
                }
              }, 1500)
            }
          }
        },
      })
      //你需要知道支付成功|失败
      //支付成功,路由跳转,如果失败,提示信息
      if (!this.timer) {
        this.timer = setInterval(async () => {
          // 发请求获取用户支付状态
          let result = await reqPayStatus({
            type: 1,
            // order: this.orderNumList,
            main_order_id: this.main_order_id,
          })
          if (result.code == 200) {
            // 第一步清除定时器
            clearInterval(this.timer)
            this.timer = null
            // 保存支付返回状态的code
            this.code = result.code
            // 关闭弹出框
            this.$msgbox.close()
            // 跳转下一步的路由
            this.$router.push('/backstage/orderMgt/orderQuery')
            this.$parent.$emit('navSideSel', { item1Index: 4, item2Index: 0 })
            setTimeout(() => {
              this.$message.success('支付成功')
            }, 366)
          }
        }, 1000)
      }
    },
    //支付宝回调函数
    alipayCallback() {
      this.loading = true
      //66666666
      reqAlipauCallback({ type: 1, main_order_id: this.main_order_id })
        .then((res) => {
          if (res.code == 200) {
            this.isAlipay = false
            this.$router.push('/backstage/orderMgt/orderQuery')
            this.$parent.$emit('navSideSel', { item1Index: 4, item2Index: 0 })
            setTimeout(() => {
              this.$message.success('支付成功')
            }, 200)
          } else {
            this.$message.error(
              res.message + '若已支付请在订单管理查询是否生成订单'
            )
          }
        })
        .catch((res) => {
          this.$message.error(res)
        })
        .finally(() => {
          this.loading = false
        })
    },
    //监听页面显示
    visibilityState() {
      if (document.visibilityState === 'visible' || event.persisted) {
        if (this.isAlipay) {
          this.$confirm('支付宝成功后请点击确定', '提示', {
            confirmButtonText: '已支付',
            nofirmButtonText: '取消',
          })
            .then(() => {
              this.alipayCallback()
            })
            .catch(() => {
              this.isAlipay = false
              this.$message.warning('支付取消!')
            })
        }
      }
      // if (document.visibilityState === "hidden") {
      //   console.log("不要走！");
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.emptyTips {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
// 优惠券样式
.mak {
  max-height: 300px;
  overflow: auto;
}
:deep(.el-checkbox__label) {
  display: none;
}
.yfje {
  margin-left: 30px;
}
.yhqqr {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.seal-son[data-v-ab5167d0] {
  width: 62px;
  height: 62px;
  border: solid 1px #d2d2d2;
  border-radius: 100%;
  background-color: #f5f4f5;
  position: relative;
}
.seal[data-v-ab5167d0] {
  width: 70px;
  height: 70px;
  border: solid 3px #d2d2d2;
  border-radius: 100%;
  background-color: #f5f4f5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabsTit {
  margin-right: 4px;
  background: linear-gradient(
    132deg,
    rgb(193, 25, 32) 0%,
    rgb(238, 38, 38) 100%
  );
  color: rgb(255, 255, 255);
  float: left;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 6px;
}
.graybg[data-v-ab5167d0] {
  background: linear-gradient(132deg, #d2d2d2 0%, #d2d2d2 100%) !important;
}
.btn-Act:hover:after {
  left: 0;
  width: 100%;
}
.btn-Act:hover {
  color: #fff;
}
.btn-Act {
  position: relative;
  cursor: pointer;
  //   width: 60px;
  height: 24px;
  line-height: 24px;
  background-color: rgb(250, 53, 52);
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 12px;
  padding: 0 10px;
}
.btn-Act:after {
  position: absolute;
  content: '';
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
  //   background-color: #0982f9;
  border-radius: 12px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.coupon-item[data-v-ab5167d0] {
  height: 110px;
  border-radius: 5px;
  margin-top: 11px;
  border: 1px solid #ffffff;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #eeeeee;
}
.x-bc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.coupon-item[data-v-ab5167d0]:before {
  width: 20px;
  height: 10px;
  position: absolute;
  left: 106px;
  bottom: -1px;
  border-radius: 20px 20px 0 0;
  content: '';
  display: block;
  background: #f5f5f7;
  border: 1px solid #eeeeee;
  border-bottom: 0px;
}
.coupon-item[data-v-ab5167d0]:after {
  width: 20px;
  height: 10px;
  position: absolute;
  left: 106px;
  top: -1px;
  border-radius: 0 0 20px 20px;
  content: '';
  display: block;
  background: #f5f5f7;
  border: 1px solid #eeeeee;
  border-top: 0px;
}
.coupon-item .coupon-money[data-v-ab5167d0] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 116px;
  height: 100%;
  border-style: none dotted none none;
  border-color: #eeeeee;
  background: linear-gradient(132deg, #c11920 0%, #ee2626 100%);
}
.coupon-item .coupon-money .layof[data-v-ab5167d0] {
  width: 100%;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 6px;
}
.coupon-item .coupon-money .layof[data-v-ab5167d0] {
  width: 100%;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 6px;
}
.coupon-item .coupon-money .demand[data-v-ab5167d0] {
  font-size: 12px;
  color: #ffffff;
}
.coupon-detail[data-v-ab5167d0] {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding-left: 20px;
  height: 100%;
}
.coupon-detail[data-v-ab5167d0] {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding-left: 20px;
  height: 100%;
}
.x-bc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.botfixed-box-right-yhq {
  width: 100px;
  height: 30px;
  margin: 0 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #ccc;
  color: #fff;
  border-radius: 5px;
  background-color: #3395f6;
}
.fileUpload {
  width: 100%;
  padding-bottom: 60rem;
  .top {
    width: 100%;
    padding: 60rem 0 0;
    background-color: #fff;
    color: #666666;

    display: flex;
    flex-direction: column;
    &-item {
      margin: 0 0 30rem 80rem;
      display: flex;
      align-items: center;
      span {
        font-size: 18rem;
      }
      input {
        width: 500rem;
        height: 44rem;
        padding: 0 15rem;
        border-radius: 4px;
        border: 1px solid #e2e2e2;
        font-size: 18rem;
        outline: none;
      }
      input::placeholder {
        color: #999;
      }
      //   &-input {
      //     width: 270px !important;
      //   }
      &-areaLable {
        align-self: flex-start;
      }
      &-area {
        // width: 780rem;
        // height: 280rem;
        width: 1000px;
        height: 500px;
      }
    }
  }
  .bot {
    width: 100%;
    padding: 30rem 0 0;
    margin-top: 20rem;
    background-color: #fff;
    color: #333333;
    font-size: 16rem;
    display: flex;
    flex-direction: column;
    span {
      margin-left: 40rem;
    }
    &-title {
      font-size: 18rem;
      margin-bottom: 40rem;
    }
    &-item {
      margin-bottom: 30rem;
    }
  }
  .botfixed {
    width: 100%;
    height: 97rem;
    box-shadow: 0px -5px 20px 1px rgba(123, 123, 123, 0.3);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    right: 0;
    &-TC {
      width: 15%;
      height: 1px;
      min-width: 168px;
    }
    &-box {
      width: 85%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left {
        min-width: 110px;
        margin-left: 44rem;
        // display: flex;
        align-items: baseline;

        &-text {
          font-size: 18rem;
          color: #333;
        }
        &-price {
          margin-left: 5rem;
          font-size: 24rem;
          color: #f25b1b;
          font-weight: bold;
        }
        &-charge {
          margin-left: 27rem;
          font-size: 18rem;
          color: #0982f9;
          cursor: pointer;
        }
      }
      &-right {
        margin-right: 50rem;
        display: flex;
        align-items: baseline;
        &-shang {
          width: 97rem;
          height: 35rem;
          margin-right: 30px;
          border-radius: 4px;
          border: #3395f6 1px solid;
          font-size: 18rem;
          color: #3395f6;
          text-align: center;
          line-height: 35rem;
          cursor: pointer;
        }
        &-view {
          margin: 0 42rem 0 20rem;
          width: 97rem;
          height: 35rem;
          background-color: #f4d7fc;
          border-radius: 4px;
          border: #d045f3 1px solid;
          font-size: 18rem;
          color: #d045f3;
          text-align: center;
          line-height: 35rem;
          cursor: pointer;
        }
        &-btn {
          width: 120rem;
          height: 46rem;
          background: linear-gradient(
            rgba(13, 158, 220, 0.59),
            rgba(9, 130, 249, 1)
          );
          border-radius: 6px;
          font-size: 24rem;
          color: #fff;
          text-align: center;
          line-height: 46rem;
          cursor: pointer;
        }
      }
    }
  }
}
.docx {
  width: 530rem;
  height: 44rem;
  border-radius: 4px;
  background-color: #0982f9;
  font-size: 18rem;
  color: #ffffff;
  text-align: center;
  line-height: 44rem;
}
.video {
  width: 100rem;
  height: 44rem;
  margin-left: 20rem;
  border-radius: 4px;
  background-color: #42a0fd;
  font-size: 18rem;
  color: #ffffff;
  text-align: center;
  line-height: 44rem;
}
.pay {
  width: 520rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-one {
    display: flex;
    align-items: baseline;
    span {
      font-size: 18rem;
      color: #333;
    }
    &-cash {
      font-size: 24rem !important;
      color: #ec0e0e !important;
      font-weight: bold;
    }
  }
  &-two {
    width: 100%;
    padding-bottom: 10rem;
    font-size: 18rem;
    color: #999;
    align-items: flex-start;
    border-bottom: #e2e2e2 1px solid;
  }
  &-type div {
    padding: 17rem 0;
    display: flex;
    align-items: center;
  }
  &-type {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: #e2e2e2 1px solid;

    cursor: pointer;
    &-icon {
      width: 28rem;
      height: 28rem;
      margin-right: 8rem;
    }
    &-name {
      font-size: 20rem;
      color: #333;
    }
    &-gray {
      font-size: 20rem;
      color: #999;
    }
    &-select {
      width: 22rem;
      height: 22rem;
      padding: 0 !important;
      border-radius: 50%;
      border: 1px solid #42a0fd;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 14rem;
        height: 10rem;
        object-fit: contain;
      }
    }
  }
  &-btn {
    width: 114rem;
    height: 37rem;
    margin-top: 30rem;
    border-radius: 4px;
    background-color: #0982f9;
    font-size: 17rem;
    text-align: center;
    line-height: 37rem;
    color: #fff;
    cursor: pointer;
  }
}
</style>
