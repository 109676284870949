<template>
  <component
    :is="agentType"
    :email="email"
    :phone="phone"
    :address="address"
    :introduce="company_introduce"
    :company="company_name"
    :qrcode1="qrcode1"
    :qrcode2="qrcode2"
  ></component>
</template>

<script>
import agentMixin from "@/mixins/agentId.mixins";
import Contact from "@/pages/Contact/index.vue";
import Agent1_contact from "@/pages/Agent1_contact";
import Agent2_contact from "@/pages/Agent2_contact";
import Agent3_contact from "@/pages/Agent3_contact";
export default {
  name: "",
  components: {
    mtt: Contact,
    agent1: Agent1_contact,
    agent2: Agent2_contact,
    agent3: Agent3_contact,
  },
  data() {
    return {};
  },
  mounted() {},
  mixins: [agentMixin],
  methods: {},
};
</script>

<style></style>
