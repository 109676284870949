<template>
  <div class="baseServer">
    <div class="navbox">
      <div class="hide" @click="showClick"></div>
      <div v-if="isShowClick" class="hideClick" @click="addList"></div>
      <nav class="navbox-nav">
        <span
          v-for="(item, index) in serveNavList"
          :key="index"
          :class="{ navsel: index == navIndex }"
          @click="navSel(item.id, index)"
          >{{ item.title }}</span
        >
      </nav>
      <div class="navbox-subnav">
        <span class="navbox-subnav-title">服务类别：</span>
        <span
          class="navbox-subnav-item"
          :class="{ subnavsel: -1 == subNavIndex }"
          @click="subNavSel(-1, -1)"
          >不限</span
        >
        <span
          class="navbox-subnav-item"
          :class="{ subnavsel: index == subNavIndex }"
          v-for="(item, index) in serveSubNavList"
          :key="index"
          @click="subNavSel(item.id, index)"
          >{{ item.title }}</span
        >
      </div>
    </div>
    <div class="listbox" v-loading="loading">
      <el-empty
        class="empty"
        v-show="serveList.length <= 0 && !loading"
        image="https://www.mtuitui.com/onlineSource/shopcarEmpty.png"
        description="暂无商品"
      ></el-empty>
      <div
        class="listbox-item"
        @click="goDetail(item)"
        v-for="(item, index) in serveList"
        :key="index"
      >
        <img :src="item.thumb_image" :alt="item.name" />
        <span class="listbox-item-name">{{ item.name }}</span>
        <div class="listbox-item-bot">
          <div class="listbox-item-bot-l">
            <span>￥</span>
            <span class="listbox-item-bot-l-price">{{ item.price }}</span>
            <span>起</span>
          </div>
          <span class="listbox-item-bot-seal">销量：{{ item.sales }}+</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reqBasicServiceNav, reqServeGoods } from "@/api";
export default {
  data() {
    return {
      isShowClick: false,
      loading: false,

      navIndex: 0,
      subNavIndex: -1,
      serveNavList: [],
      serveSubNavList: [],
      serveList: [],
    };
  },
  async mounted() {
    try {
      await this.requestNav();
    } catch (error) {
      this.$message.error(error);
    }
    try {
      await this.requestList(this.serveNavList[0].id);
    } catch (error) {
      this.$message.error(error);
    }
  },
  methods: {
    async showClick() {
      if (this.isShowClick) {
        this.isShowClick = false;
      } else {
        this.isShowClick = true;
      }
      try {
        await this.requestList(this.serveNavList[0].sons[0].id);
      } catch (error) {
        this.$message.error(error);
      }
    },
    addList() {
      this.isShowClick = false;
      this.serveList = [];
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let i = 0;
        while (i < 32) {
          this.serveList.push({
            thumb_image: require("@/assets/backstage/portrait/portrait" +
              Math.round(Math.random() * 10) +
              ".png"),
            name: "自媒体平台粉丝增长服务,文章/短视频阅读数提升服务",
            price: Math.round(Math.random() * 900 + 100),
            sales: Math.round(Math.random() * 5000 + 500),
          });
          i++;
        }
      }, 400);
    },
    goDetail(item) {
      this.$router.push("/backstage/serverDetail?id=" + item.id);
    },
    navSel(id, index) {
      this.navIndex = index;
      this.subNavIndex = -1;
      this.serveSubNavList = this.serveNavList[index].sons;
      // this.requestList(this.serveNavList[index].sons[0].id);
      this.requestList(this.serveNavList[index].id);
    },
    subNavSel(id, index) {
      this.subNavIndex = index;
      if (index == -1 || id == -1) {
        this.requestList(this.serveNavList[this.navIndex].id);
      } else {
        this.requestList(id);
      }
    },
    //请求
    async requestNav() {
      let nav = await reqBasicServiceNav();
      if (nav.code == 1) {
        this.serveNavList = nav.data;
        if (this.serveNavList.length > 0) {
          this.serveSubNavList = this.serveNavList[0].sons;
        }
      } else {
        this.$message.error(nav.message || "请求出错");
      }
    },
    async requestList(id) {
      this.loading = true;
      let List = await reqServeGoods({ id: id });
      if (List.code == 1) {
        this.serveList = List.data;
      } else {
        this.$message.error(List.message || "请求出错");
      }
      if (List) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.baseServer {
  width: 100%;
  background-color: #fff;
  .navbox {
    position: relative;
    width: 100%;
    padding-top: 9rem;
    margin: 0 auto;
    background-color: #fff;
    &-nav {
      width: 94%;
      padding: 0 3%;
      margin: 0 auto;
      border-bottom: 1px solid #e3e3e3;
      span {
        display: inline-block;
        width: 150rem;
        height: 60rem;
        background-color: #fff;
        font-size: 20rem;
        color: #999;
        text-align: center;
        line-height: 60rem;
        cursor: pointer;
        box-sizing: border-box;
      }
      span:hover {
        border-bottom: 3px solid #0982f9;
      }
      .navsel {
        background-color: #0982f9;
        color: #fff;
      }
    }
    &-subnav {
      width: 94%;
      padding: 30rem 3%;
      margin: 0 auto;
      border-bottom: 1px solid #e3e3e3;
      &-title {
        font-size: 18rem;
        color: #585858;
      }
      &-item {
        margin: 0 25rem;
        font-size: 18rem;
        color: #999;
        cursor: pointer;
      }
      &-item:hover {
        color: #42a0fd;
      }
      .subnavsel {
        color: #0982f9 !important;
      }
    }
  }
  .listbox {
    width: 1608rem;
    min-height: 700px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    &-item {
      width: 240rem;
      height: 348rem;
      margin: 14rem;
      border-radius: 6px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: 0.2s ease-out;
      img {
        width: 200rem;
        height: 200rem;
        margin: 20rem 0;
        object-fit: contain;
      }
      &-name {
        width: 84%;
        font-size: 14rem;
        font-weight: bold;
        color: #999;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /*! autoprefixer: on */
        word-break: break-all;
      }
      &-bot {
        width: 84%;
        margin-top: 20rem;
        font-size: 14rem;
        font-weight: bold;
        color: #ec0e0e;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        &-l {
          &-price {
            margin-right: 2px;
            font-size: 20rem;
          }
        }
        &-seal {
          font-weight: normal;
          color: #999;
        }
      }
    }
    &-item:hover {
      border: 1px solid #0982f9;
      box-shadow: 0px 3px 10px 1px rgba(#eee, 0.2);
      transform: scale(1.03);
      transition: 0.2s ease-out;
    }
  }
}
.hide {
  width: 30rem;
  height: 30rem;
  position: absolute;
  right: -30rem;
  top: 80rem;
}
.hideClick {
  width: 30rem;
  height: 30rem;
  position: absolute;
  right: -30rem;
  top: 164rem;
}
.empty {
  align-self: center;
  width: 100%;
}
</style>
