/**

 * 配置 rem 缩放比例

 */

 (function (doc, win) {
    var docEl = doc.documentElement,
    resizeEvt = 'orientationcyunhange' in window ? 'orientationchange' : 'resize',

    recalc = function () {
        // 获取当前设备的窗口宽度
        var clientWidth = docEl.clientWidth;
        if (!clientWidth) return;
        // 动态设置全局字体大小
        // if (clientWidth >= 1920) {
        //     docEl.style.fontSize = '100px';
        //   } else {
            // docEl.style.fontSize = 1 * (clientWidth /1920) + 'px';
          // }
        // console.log('RemChange.js   动态设置全局字体大小    fontSize=',docEl.style.fontSize)

          if (clientWidth < 1200) {
            docEl.style.fontSize = '0.6px';
          }else {
            docEl.style.fontSize = 1 * (clientWidth /1920) + 'px';
          }


        // if (docEl.clientWidth > 750) {
        //     docEl.style.fontSize = "100px";
        //     doc.getElementById("app").style.width = "750px";
        // } else {
        //     var width = docEl.clientWidth / 7.5;
        //     docEl.style.fontSize = width + "px";
        //     doc.getElementById("app").style.width = "auto";
        // }
    };

    if (!doc.addEventListener) return;

    // 绑定事件

    win.addEventListener(resizeEvt, recalc, false);

    doc.addEventListener('DOMContentLoaded', recalc, false);

})(document, window);