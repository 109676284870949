<template>
  <div class="backstage">
    <Navside class="navside"></Navside>
    <Navtop v-if="isRouterAliveTop" class="navtop"></Navtop>
    <div style="height:100px"></div>
    <div class="routerBox">
      <div class="leftFill"></div>
      <div class="routerbox2">
        <router-view class="router" v-if="isRouterAliveBack"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Navside from "@/components/Navside-back";
import Navtop from "@/components/Navtop-back";
export default {
  components: { Navside, Navtop },
  mounted() {
    this.$store.dispatch("global/tokenlocal");
    this.$store.dispatch("global/usermsglocal");
    this.$store.dispatch("global/shopcarlocal");
  },
  provide() {
    return {
      reloadB: this.reloadB,
      reloadBTop: this.reloadBTop,
    };
  },
  data() {
    return {
      isRouterAliveTop: true,
      isRouterAliveBack: true,
    };
  },
  methods: {
    reloadBTop() {
      this.isRouterAliveTop = false;
      this.$nextTick(function () {
        this.isRouterAliveTop = true;
      });
    },
    reloadB() {
      this.isRouterAliveBack = false;
      this.$nextTick(function () {
        this.isRouterAliveBack = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.backstage {
  width: 100%;
  min-width: 1000px;
  height: 100vh;
  position: relative;
  background-color: #f4f4f4;
  .navside {
    width: 14%;
    // width: 270rem;
    min-width: 168px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
  }
  .navtop {
    width: 100%;
    min-width: 900px;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .routerBox {
    width: 100%;
    min-width: 1200px;
    // padding-bottom: 20rem;
    background-color: #f4f4f4;
    // position: absolute;
    top: 100px;
    left: 0;
    display: flex;
    .leftFill {
      width: 15%;
    }
    .routerbox2 {
      width: 84%;
      margin-right: 1%;
      .router {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
