<template>
  <div class="agent1_contact">
    <!-- 头部BG -->
    <div class="bg">
      <img class="bg_img" src="@/assets/agent/1/agent1_contact_BG.png" alt="" />
    </div>
    <!-- 公司介绍 -->
    <div class="introduce">
      <div class="introduce_title">{{ company }}</div>
      <div class="introduce_box">
        <div class="introduce_box_left" v-html="introduce"></div>
        <div class="introduce_box_right">
          <img
            class="introduce_box_right_img"
            src="@/assets/agent/1/agent1_display.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="contact">
      <div class="contact_title">联系我们</div>
      <div class="contact_box">
        <div class="contact_box_left">
          <img
            class="contact_box_left_img"
            src="@/assets/agent/1/agent1_icon_address.png"
            alt=""
          />
          <div class="contact_box_left_info">
            <div class="contact_box_left_info_item">邮箱：{{ email }}</div>
            <div class="contact_box_left_info_item">地址：{{ address }}</div>
            <div class="contact_box_left_info_item">电话：{{ phone }}</div>
          </div>
        </div>
        <div class="contact_box_right">
          <div class="contact_box_right_qr">
            <img class="contact_box_right_qr_img" :src="qrcode1" alt="" />
          </div>
          <div class="contact_box_right_qr">
            <img class="contact_box_right_qr_img" :src="qrcode2" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
      ...mapState({
          email: (state) => state.global.agentSiteInfo.email,
          address: (state) => state.global.agentSiteInfo.address,
          phone: (state) => state.global.agentSiteInfo.phone,
          company: (state) => state.global.agentSiteInfo.company_name,
          introduce: (state) => state.global.agentSiteInfo.company_introduce,
          qrcode1: (state) => state.global.agentSiteInfo.qrcode1,
          qrcode2: (state) => state.global.agentSiteInfo.qrcode2,
      }),
    },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.agent1_contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 头部BG */
.bg {
  width: 100%;
}
.bg_img {
  display: block;
  width: 100%;
  object-fit: contain;
}
/* 公司介绍 */
.introduce,
.contact {
  width: 1200px;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.introduce_title {
  margin-bottom: 80px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.introduce_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.introduce_box_left {
  width: 690px;
}
.introduce_box_right {
  width: 460px;
}
.introduce_box_right_img {
  width: 100%;
  object-fit: contain;
  display: block;
}

/* 联系我们 */
.contact {
  margin-top: 100px;
  padding-bottom: 120px;
}
.contact_title {
  margin-bottom: 60px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.contact_box {
  width: 1125px;
  padding: 0 25px 0 50px;
  display: flex;
  justify-content: space-between;
}
.contact_box_left,
.contact_box_right {
  display: flex;
  align-items: center;
}
.contact_box_left_img {
  width: 118px;
  height: 118px;
  margin-right: 35px;
  object-fit: contain;
}
.contact_box_left_info {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.contact_box_left_info_item {
  font-size: 20px;
  color: #999;
}
.contact_box_right_qr {
  width: 160px;
  height: 160px;
  margin: 0 43px;
  border: 1px solid #707070;
}
.contact_box_right_qr_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
<style lang="scss">
.introduce_box_left {
  font-size: 14px;
  line-height: 30px;
  color: #999;
  white-space: pre-wrap;
}
.introduce_box_left p {
  font-size: 14px;
  line-height: 30px;
  color: #999;
}
</style>
