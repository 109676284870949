<template>
  <div class="mediaRelease">
    <div class="navbox">
      <nav class="navbox-nav">
        <span
          v-for="(item, index) in mediaNavList"
          :key="index"
          :class="{ navsel: index == navIndex }"
          @click="navSel(item.id, index)"
          >{{ item.title }}</span
        >
      </nav>
      <div class="navbox-filter">
        <div
          class="navbox-filter-item"
          v-for="(item, index) in navFilterList1"
          :key="index"
        >
          <div class="navbox-filter-item-left">
            <span>{{ item.title }}</span>
            <span
              @click="navFilterSel(item.id, 0, index, item.screen)"
              :class="{
                navFiltersel: navFilterSeled.some((element) => {
                  return (
                    element.index == index &&
                    element.id == item.id &&
                    element.id1 == 0
                  );
                }),
              }"
              >不限</span
            >
          </div>
          <div class="navbox-filter-item-right">
            <span
              v-for="(itm, indx) in item.children"
              :key="indx"
              @click="navFilterSel(item.id, itm.id, index, item.screen)"
              :class="{
                navFiltersel: navFilterSeled.some((element) => {
                  return (
                    element.index == index &&
                    element.id == item.id &&
                    element.id1 == itm.id
                  );
                }),
              }"
              >{{ itm.title }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="routerBox" v-loading="loading">
      <router-view
        class="route"
        :netmediaList1="netmediaList"
        :loading1="loading"
        @fatherMethod="fatherMethod1"
      ></router-view>
    </div>
    <!-- 下方固定栏 -->
    <div class="botBigBox">
      <div class="leftFill"></div>
      <div class="botFixed">
        <div class="botFixed-left">
          <span class="botFixed-left-black" style="margin-left: 100rem"
            >合计：</span
          >
          <span class="botFixed-left-red">{{ total }}元</span>
          <span class="botFixed-left-black">可用余额：</span>
          <span class="botFixed-left-red">{{ userMessage.balance }}元</span>
          <span class="botFixed-left-blue" @click="goOnlineCharge">充值</span>
        </div>
        <div class="botFixed-right">
          <div class="botFixed-right-seled check meet" @click="checkSeled">
            <span>查看已选({{ shopCar.length }})</span>
            <img
              :class="{ rotate: shoppingAnima }"
              src="@/assets/backstage/mediaRelease/triangle.png"
              alt="triangle"
            />
          </div>
          <span @click="emptyAll">清空已选</span>
          <button @click="goFileUpload">下一步</button>
        </div>
      </div>
    </div>
    <!-- 购物车 -->
    <div
      v-show="shoppingShow && shopCar.length > 0"
      :class="{ anima: !shoppingAnima }"
      class="shopCarBox"
    >
      <div
        class="shopCarBox-item"
        v-for="(item, index) in shopCar"
        :key="index"
      >
        <span>{{ item.name }}</span>
        <span
          @click="shopCarReduce(item, index)"
          style="font-size: 28rem; margin-left: 6rem; cursor: pointer"
          >×</span
        >
      </div>
    </div>
    <!-- 箭头 -->
    <div
      v-show="shoppingShow && shopCar.length > 0"
      :class="{ anima: !shoppingAnima }"
      class="trigelB"
    >
      <!-- <div class="trigel"></div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { reqFilterNav, reqMediaFilter } from "@/api";
import { _throttle } from "@/utils/tool";
export default {
  mounted() {
    this.senNavObj["token"] = this.token;
    this.$parent.$on("navLocation", (msg) => {
      if (msg) {
        this.navIndex = msg;
      }
    });
    this.loading = true;
    reqFilterNav()
      .then((res) => {
        if (res.code == 1) {
          this.mediaNavList = res.data;
          this.navFilterList1 = this.mediaNavList[0].sons;
          this.senNavObj["type"] = res.data[0].id;
          for (let i = 0; i < this.navFilterList1.length; i++) {
            let t = {
              index: i,
              id: this.navFilterList1[i].id,
              id1: 0,
            };
            this.navFilterSeled.push(t);
            this.senNavObj[this.navFilterList1[i].screen] = "";
          }
        } else {
          this.$message.error(res.$message);
        }
        reqMediaFilter(this.senNavObj).then((res) => {
          if (res.code == 1) {
            this.netmediaList = res.data;
          } else {
            this.$message.error(res.$message);
          }
        });
      })
      .catch((res) => {
        this.$message.error(res);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
      shopCar: (state) => state.global.shopCar,
      token: (state) => state.global.token,
    }),
    total() {
      let s = 0;
      this.shopCar.forEach((element) => {
        s += Number(element.price);
      });
      return s;
    },
  },
  data() {
    return {
      loading: false,
      navIndex: 0,
      mediaNavList: [],
      navFilterList1: [],
      navFilterSeled: [],
      senNavObj: {
        token: this.token,
      },

      netmediaList: [],
      shoppingAnima: false,
      shoppingShow: false,
    };
  },
  methods: {
    fatherMethod1(value1, value2, pagination, searchText) {
      this.senNavObj["price"] = value1;
      this.senNavObj["quanzhong"] = value2;
      this.senNavObj["page"] = pagination;
      this.senNavObj["like"] = searchText;
      reqMediaFilter(this.senNavObj).then((res) => {
        this.netmediaList = res.data;
      });
    },
    navSel(id, index) {
      this.navIndex = index;
      this.navFilterList1 = this.mediaNavList[index].sons;
      this.senNavObj["type"] = id;
      Object.keys(this.senNavObj).forEach((key) => {
        if (key != "type") {
          this.senNavObj[key] = "";
        }
      });
      this.navFilterSeled = [];
      for (let i = 0; i < this.navFilterList1.length; i++) {
        let t = {
          index: i,
          id: this.navFilterList1[i].id,
          id1: 0,
        };
        this.navFilterSeled.push(t);
      }
      this.bgimage = "bg-" + (index + 1) + ".png";
      reqMediaFilter(this.senNavObj).then((res) => {
        this.netmediaList = res.data;
      });
    },
    navFilterSel: _throttle(function (id, id1, index, ziduan) {
      let t = {
        index: index,
        id: id,
        id1: id1,
      };
      if (this.navFilterSeled.length > 0) {
        this.navFilterSeled.forEach((elem) => {
          if (elem.index == index) {
            elem.id = id;
            elem.id1 = id1;
          } else {
            this.navFilterSeled.push(t);
          }
        });
      } else {
        this.navFilterSeled.push(t);
      }
      if (id1 == 0) {
        this.senNavObj[ziduan] = "";
      } else {
        this.senNavObj[ziduan] = id1;
      }
      this.loading = true;
      reqMediaFilter(this.senNavObj).then((res) => {
        this.netmediaList = res.data;
        this.loading = false;
      });
    }, 500),
    //充值
    goOnlineCharge() {
      this.$router.push("/backstage/fundMgt/onlineCharge");
      this.$parent.$emit("navSideSel", { item1Index: 5, item2Index: 0 });
    },
    //查看已选
    checkSeled() {
      this.shoppingAnima = !this.shoppingAnima;
      setTimeout(() => {
        this.shoppingShow = !this.shoppingShow;
      }, 200);
    },
    //删除指定
    shopCarReduce(item) {
      if (
        this.shopCar.some((goods) => {
          return goods.id == item.id;
        })
      ) {
        this.$store.dispatch("global/shopCartNumReduce", item);
      }
    },
    //清空已选
    emptyAll() {
      this.$store.dispatch("global/shopCartEmpty");
    },
    goFileUpload() {
      if (this.shopCar.length <= 0) {
        this.$message.error("请选择媒体");
      } else {
        this.$router.push("/backstage/fileUpload");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navFiltersel {
  color: #0982f9 !important;
}
.mediaRelease {
  width: 100%;
  .navbox {
    width: 100%;
    // min-height: 550px;
    padding-top: 9rem;
    margin: 0 auto;
    background-color: #fff;
    &-nav {
      width: 90%;
      // padding: 0 80px;
      margin-bottom: 20rem;
      border-bottom: 1px solid #e3e3e3;
      span:first-child {
        margin-left: 60rem;
      }
      span {
        display: inline-block;
        width: 150rem;
        height: 60rem;
        background-color: #fff;
        border-radius: 6px 6px 0 0;
        font-size: 20rem;
        color: #999;
        text-align: center;
        line-height: 60rem;
        cursor: pointer;
        box-sizing: border-box;
      }
      span:hover {
        border-bottom: 3px solid #0982f9;
      }
      .navsel {
        background-color: #0982f9;
        color: #fff;
      }
    }
    &-filter {
      width: 90%;
      // min-height: 450px;
      padding: 0 70rem;
      &-item {
        // width: 1340px;
        width: 100%;
        display: flex;
        &-left {
          // width: 150px;
          width: 12%;
          display: flex;
          justify-content: flex-end;
          font-size: 18rem;
          color: #585858;
          span:first-child {
            font-weight: bold;
          }
          span:last-child:hover {
            color: rgba($color: #0982f9, $alpha: 0.5);
          }
          span:last-child {
            margin-left: 30rem;
            cursor: pointer;
          }
        }
        &-right {
          // width: 1170px;
          width: 87%;
          word-break: normal;
          span {
            display: inline-block;
            margin: 0 0 25rem 25rem;
            font-size: 18rem;
            color: #999;
            cursor: pointer;
          }
          span:hover {
            color: rgba($color: #0982f9, $alpha: 0.5);
          }
        }
      }
    }
  }
  .routerBox {
    padding-bottom: 100rem;
  }
  .botBigBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    .leftFill {
      width: 14%;
      min-width: 168px;
    }
    .botFixed {
      width: 84%;
      min-width: 600px;
      height: 100rem;
      background-color: #fff;
      box-shadow: 0 -5px 20px 1px rgb(123 123 123 / 16%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left {
        // width: 40%;
        font-size: 18rem;
        display: flex;
        align-items: flex-end;
        &-black {
          margin-left: 45rem;
          color: #333;
        }
        &-red {
          font-size: 24rem;
          color: #f25b1b;
        }
        &-blue {
          margin-left: 27rem;
          color: #0982f9;
          cursor: pointer;
        }
      }
      &-right {
        // width: 50%;
        font-size: 18rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &-seled:hover {
          color: #fff;
          box-shadow: rgba($color: #e84118, $alpha: 1) 0 0px 0px 40px inset;
          transition: all 400ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
        }
        &-seled {
          height: 35rem;
          padding: 0 10rem;
          // border: 1px solid #0982f9;
          color: #0982f9;
          box-shadow: #0982f9 0 0 0 1px inset;
          border-radius: 4px;
          color: #3395f6;
          text-align: center;
          line-height: 35rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          img {
            width: 12px;
            height: 10px;
            margin-left: 5rem;
            object-fit: contain;
            transition: 0.2s ease-out;
          }
        }
        button {
          width: 120rem;
          height: 46rem;
          margin-right: 50rem;
          background: linear-gradient(90deg, rgba(13, 158, 220, 0.59), #0982f9);
          border: none;
          border-radius: 6px;
          font-size: 24rem;
          color: #fff;
          text-align: center;
          line-height: 46rem;
          cursor: pointer;
          transition: 0.2s ease;
        }
        button:hover {
          background-color: #0982f9;
          transition: 0.2s ease;
        }
        button:active {
          background-color: rgba($color: #0982f9, $alpha: 0.7);
          transition: 0.2s ease;
        }
      }
      &-right > span {
        margin: 0 30rem 0 20rem;
        color: #999;
        cursor: pointer;
         
      }
      &-right > span:hover {
        color: #3395f6;
      }
    }
  }
  .shopCarBox {
    opacity: 1;
    transition: 0.2s ease-out;

    max-width: 920px;
    // height: 165px;
    // max-height: 300px;
    padding: 30rem 40rem;
    box-shadow: 0 -5px 20px 1px rgb(123 123 123 / 40%);
    // background-color: rgba(13, 158, 220, 0.59);
    background-color: #fff;
    // border: 1px solid rgba(13, 158, 220, 0.59);
    display: flex;
    flex-wrap: wrap;

    border-radius: 8px;
    position: fixed;
    bottom: 120px;
    // right: 10px;
    right: 100rem;
    &-item {
      height: 37rem;
      padding: 0 12rem;
      margin: 0 20rem 13rem 0;
      font-size: 18rem;
      color: #fff;
      background-color: #0982f9;
      border-radius: 4px;
      display: flex;
      // align-items: center;
      justify-content: center;
      cursor: default;
      span {
        line-height: 37rem;
      }
    }
  }
  .trigelB {
    opacity: 1;
    transition: 0.2s ease-out;

    width: 0rem;
    height: 0rem;
    // border: 22px solid rgba(13, 158, 220, 0.59);
    // border-top-color: rgba(13, 158, 220, 0.59);
    border: 22px solid #fff;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;

    position: fixed;
    bottom: 76px;
    right: 280rem;
    z-index: 9;
    .trigel {
      width: 0rem;
      height: 0rem;
      border: 18px solid rgba(13, 158, 220, 0.59);
      border-top-color: rgba(13, 158, 220, 0.59);
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;

      position: fixed;
      bottom: 76rem;
      right: 334rem;
      z-index: 19;
    }
  }
  .anima {
    opacity: 0;
    transition: 0.2s ease-out;
  }
}
.rotate {
  transform: rotateZ(180deg);
  transition: 0.2s ease-out;
}
</style>
