<template>
    <div class="companyChannel">
      <div class="navbox">
        <div class="hide" @click="showClick"></div>
        <div v-if="isShowClick" class="hideClick" @click="addList"></div>
        <nav class="navbox-nav">
          <span
            v-for="(item, index) in serveNavList"
            :key="index"
            :class="{ navsel: index == navIndex }"
            @click="navSel(item.id, index)"
            >{{ item.title }}</span
          >
        </nav>
        <div class="navbox-subnav">
          <span class="navbox-subnav-title">服务类别：</span>
          <span
            class="navbox-subnav-item"
            :class="{ subnavsel: -1 == subNavIndex }"
            @click="subNavSel(-1, -1)"
            >不限</span
          >
          <span
            class="navbox-subnav-item"
            :class="{ subnavsel: index == subNavIndex }"
            v-for="(item, index) in serveSubNavList"
            :key="index"
            @click="subNavSel(item.id, index)"
            >{{ item.title }}</span
          >
        </div>
        <div class="navbox-subnav">
          <el-select
            v-model="province_id"
            placeholder="请选择省"
            @change="changeProvince"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.provinceid"
              :label="item.province"
              :value="item.provinceid"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="city_id"
            placeholder="请选择市"
            @change="changeCity"
          >
            <el-option
              v-for="item in cityList"
              :key="item.cityid"
              :label="item.city"
              :value="item.cityid"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 内容列表 -->
    <div class="netmedia-list">
      <el-empty
        class="empty"
        v-show="serveList.length <= 0 "
        image="https://www.mtuitui.com/onlineSource/contentEmpty.png"
        description="暂无渠道"
      ></el-empty>
      <div
        class="netmedia-list-item"
        v-for="(item, index) in serveList"
        :key="index"
      >
      <div class="netmedia-list-item-img">
        <img alt="img" :src="item.headimg" onerror="javascript:this.src='https://admin.mtuitui.com/upload/default/default_avatar.png'"/>
      </div>
      <div class="netmedia-list-item-title">
        <div class="netmedia-list-item-div">
          <el-tooltip
              effect="light"
              :content="item.realname"
              placement="top-start"
            >
              <span class="titleSPan">姓名:{{
                item.realname
              }}</span>
            </el-tooltip>
        </div>
        <div class="netmedia-list-item-div">
          <el-tooltip
              effect="light"
              :content="item.company_name"
              placement="top-start"
            >
              <span class="titleSPan">公司:{{
                item.company_name
              }}</span>
            </el-tooltip>
        </div>
      </div>

      <div class="netmedia-list-item-title">
        <div class="netmedia-list-item-div">
          <el-tooltip
              effect="light"
              :content="item.mobile"
              placement="top-start"
            >
              <span class="titleSPan">电话:{{
                item.mobile
              }}</span>
            </el-tooltip>
        </div>
        <div class="netmedia-list-item-div">
          <el-tooltip
              effect="light"
              :content="item.province_name + '-'+ item.city_name"
              placement="top-start"
            >
              <span class="titleSPan">地区:{{item.province_name}}-{{
                item.city_name
              }}</span>
            </el-tooltip>
        </div>
      </div>


      <div class="netmedia-list-item-title">
        <div class="netmedia-list-item-div">
          <el-tooltip
              effect="light"
              :content="(item.wechat_no)"
              placement="top-start"
            >
              <span class="titleSPan">微信:{{
                item.wechat_no
              }}</span>
            </el-tooltip>
        </div>
        <div class="netmedia-list-item-div">
          <el-tooltip
              effect="light"
              :content="item.memo"
              placement="top-start"
            >
              <span class="titleSPan">备注:{{item.memo}}
              </span>
            </el-tooltip>
        </div>
      </div>
      </div>
    </div>
      <div class="botBigBox" >
        <div class="leftFill">

        </div>
        <div class="botFixed" @click="applyJoin">
            <!--<div class="botFixed-left">
                <div class="botFixed-left-blue">立即加盟</div>
            </div>-->
            <div class="botFixed-center">申请入驻(限时免费)</div>
        </div>

      </div>
    </div>
  </template>
  
  <script>
  
  import { reqCompanyChannelNav,reqCompanyChannelList,reqProvinceList,reqCityList } from "@/api";
  export default {
    data() {
      return {
        isShowClick: false,
        loading: false,
        province_id:0,
        city_id:0,
  
        navIndex: 0,
        subNavIndex: -1,
        serveNavList: [],
        serveSubNavList: [],
        serveList: [],
        provinceList:[],
        cityList:[]
      };
    },
    async mounted() {

      try {
        await this.requestNav();
        await this.requestProvinceList();
      } catch (error) {
        this.$message.error(error);
      }
      try {
        await this.requestList();
      } catch (error) {
        this.$message.error(error);
      }
      console.log("len");
      console.log(this.serveList.length);
    },
    methods: {
      async showClick() {
        if (this.isShowClick) {
          this.isShowClick = false;
        } else {
          this.isShowClick = true;
        }
        try {
          await this.requestList();
        } catch (error) {
          this.$message.error(error);
        }
      },
      addList() {
        this.isShowClick = false;
        this.serveList = [];
        this.loading = true;
        // setTimeout(() => {
        //   this.loading = false;
        //   let i = 0;
        //   while (i < 32) {
        //     this.serveList.push({
        //       thumb_image: require("@/assets/backstage/portrait/portrait" +
        //         Math.round(Math.random() * 10) +
        //         ".png"),
        //       name: "自媒体平台粉丝增长服务,文章/短视频阅读数提升服务",
        //       price: Math.round(Math.random() * 900 + 100),
        //       sales: Math.round(Math.random() * 5000 + 500),
        //     });
        //     i++;
        //   }
        // }, 400);
      },
      goDetail(item) {
        this.$router.push("/backstage/serverDetail?id=" + item.id);
      },
      navSel(id, index) {
        // alert(index);
        // alert(navIndex);
        this.navIndex = index;
        // alert(navIndex);
        this.subNavIndex = -1;
        this.serveSubNavList = this.serveNavList[index].sons;
        // this.requestList(this.serveNavList[index].sons[0].id);
        this.requestList();
      },
      subNavSel(id, index) {
        
        this.subNavIndex = index;
        this.requestList();
      },
      //请求
      async requestNav() {
        let nav = await reqCompanyChannelNav();
        if (nav.code == 1) {
          this.serveNavList = nav.data;
          if (this.serveNavList.length > 0) {
            this.serveSubNavList = this.serveNavList[0].sons;
          }
        } else {
          this.$message.error(nav.message || "请求出错");
        }
      },
      async requestList() {
        this.loading = true;
        let channel_id = this.navIndex.id == -1?0:this.serveNavList[this.navIndex].id;
        let service_id = this.subNavIndex == -1?0:this.serveSubNavList[this.subNavIndex].id;
        let List = await reqCompanyChannelList({province_id:this.province_id,city_id:this.city_id, channel_id: channel_id,service_id:service_id });
        if (List.code == 1) {
          this.serveList = List.data.data;
          console.log(List);
        } else {
          this.$message.error(List.message || "请求出错");
        }
        if (List) {
          this.loading = false;
        }
      },
      async requestProvinceList(){
        let List = await reqProvinceList();
        if (List.code == 1) {
          this.provinceList.length = 0;
          this.provinceList.push({id:0,provinceid:0,province:'不限'});
          for(let i=0;i<List.data.length;i++){
            this.provinceList.push(List.data[i])
          }
          this.cityList.length = 0;
          this.cityList.push({id:0,cityid:0,city:'不限'});

        } else {
          this.$message.error(List.message || "省市信息请求出错");
        }
      },
      async requestCityList(provinceid){
        if(provinceid == 0){
          this.cityList.length = 0;
          this.cityList.push({id:0,cityid:0,city:'不限'});
          this.city_id = 0;
          this.requestList();
          return;
        }
        let List = await reqCityList({province_id:provinceid});
        if (List.code == 1) {
          this.cityList.length = 0;
          this.cityList.push({id:0,cityid:0,city:'不限'});
          for(let i=0;i<List.data.length;i++){
            this.cityList.push(List.data[i])
          }
          this.requestList();
        } else {
          this.$message.error(List.message || "城市信息请求出错");
        }
      },
      changeProvince(e){
        console.log(e);
        
        this.requestCityList(e);
      },
      changeCity(e){
        console.log(e);
        this.requestList();
      },
      applyJoin(){
        this.$router.push({path:'/backstage/personpage/applyJoin'})
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .companyChannel {
    width: 100%;
    background-color: #fff;
    .navbox {
      position: relative;
      width: 100%;
      padding-top: 9rem;
      margin: 0 auto;
      background-color: #fff;
      &-nav {
        width: 94%;
        padding: 0 3%;
        margin: 0 auto;
        border-bottom: 1px solid #e3e3e3;
        span {
          display: inline-block;
          width: 150rem;
          height: 60rem;
          background-color: #fff;
          font-size: 20rem;
          color: #999;
          text-align: center;
          line-height: 60rem;
          cursor: pointer;
          box-sizing: border-box;
        }
        span:hover {
          border-bottom: 3px solid #0982f9;
        }
        .navsel {
          background-color: #0982f9;
          color: #fff;
        }
      }
      &-subnav {
        width: 94%;
        padding: 30rem 3%;
        margin: 0 auto;
        border-bottom: 1px solid #e3e3e3;
        &-title {
          font-size: 18rem;
          color: #585858;
        }
        &-item {
          margin: 0 25rem;
          font-size: 18rem;
          color: #999;
          cursor: pointer;
        }
        &-item:hover {
          color: #42a0fd;
        }
        .subnavsel {
          color: #0982f9 !important;
        }
      }
    }
    .listbox {
      width: 1608rem;
      min-height: 700px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      &-item {
        width: 240rem;
        height: 348rem;
        margin: 14rem;
        border-radius: 6px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        transition: 0.2s ease-out;
        img {
          width: 200rem;
          height: 200rem;
          margin: 20rem 0;
          object-fit: contain;
        }
        &-name {
          width: 84%;
          font-size: 14rem;
          font-weight: bold;
          color: #999;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;
          /*! autoprefixer: on */
          word-break: break-all;
        }
        &-bot {
          width: 84%;
          margin-top: 20rem;
          font-size: 14rem;
          font-weight: bold;
          color: #ec0e0e;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          &-l {
            &-price {
              margin-right: 2px;
              font-size: 20rem;
            }
          }
          &-seal {
            font-weight: normal;
            color: #999;
          }
        }
      }
      &-item:hover {
        border: 1px solid #0982f9;
        box-shadow: 0px 3px 10px 1px rgba(#eee, 0.2);
        transform: scale(1.03);
        transition: 0.2s ease-out;
      }
    }
  }
  .hide {
    width: 30rem;
    height: 30rem;
    position: absolute;
    right: -30rem;
    top: 80rem;
  }
  .hideClick {
    width: 30rem;
    height: 30rem;
    position: absolute;
    right: -30rem;
    top: 164rem;
  }
  .empty {
    align-self: center;
    width: 100%;
  }

  .botBigBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
}
.botBigBox .leftFill {
    width: 14%;
    min-width: 168px;
}
.botBigBox .botFixed {
    width: 84%;
    min-width: 600px;
    height: 100rem;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 -5px 20px 1px hsl(0deg 0% 48% / 16%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 14px;
    border: 2px solid#0982f9;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
}
.botFixed:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    bottom: 0;
    background: #0982f9;
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: -1;
}
.botFixed:hover {
  color: #5bcaff;
}
.botFixed:hover:after {
    left: -10%;
    width: 120%;
}

.botBigBox .botFixed-left {
    font-size: 18rem;
    display: flex;
    align-items: flex-end;
}
.botBigBox .botFixed-center{
  font-size: 30rem;
  margin: auto;
}
.botBigBox .botFixed-left-blue {
    margin-left: 27rem;
    color: #0982f9;
    cursor: pointer;
}


.netmedia-list {
    width: 100%;
    background-color: #fff;
}

.netmedia-list-item {
    width: 96%;
    height: 110rem;
    margin: 0 auto;
    padding-top: 20rem;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-around;
}
.netmedia-list-item-img {
    width: 111rem;
    height: 111rem;
    margin-right: 40rem;
    border-radius:50%;
}

.netmedia-list-item-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    display: inline-block;

}
.netmedia-list-item-title {
    width: 15%;
    height: 105rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.netmedia-list-item-title-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.netmedia-list-item-title-div .titleSPan {
    font-size: 24rem;
    font-weight: bold;
    color: #333333;
    line-height: 36rem;
    cursor: pointer;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.netmedia-list-item-title-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


  </style>
  