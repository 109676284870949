import Vue from "vue";
import Vuex from "vuex";
//需要使用插件一次
Vue.use(Vuex);
//引入小仓库
import global from "./global";
import chat from "./chatPersons";
//对外暴露Store类的一个实例
export default new Vuex.Store({
  //实现Vuex仓库模块式开发储存数据
  modules: {
    chat,
    global,
  },
});
