<template>
  <div class="agent3_knowledge">
    <!-- 头部BG -->
    <div class="bg">
      <img
        class="bg_img"
        src="@/assets/agent/3/agent3_knowledge_BG.png"
        alt=""
      />
    </div>
    <!-- 新闻资讯 -->
    <div class="knowledge3">
      <div class="knowledge3_box">
        <div
          class="knowledge3_box_item"
          @click="goDetail(item.id)"
          v-for="(item,index) in article_list"
          :key="index"
        >
          <div class="knowledge3_box_item_left">
            <img
              class="knowledge3_box_item_left_img"
              src="@/assets/agent/3/agent3_display.png"
              alt=""
            />
          </div>
          <div class="knowledge3_box_item_mid">
            <div class="knowledge3_box_item_mid_title">
              {{item.title}}
            </div>
            <div class="knowledge3_box_item_mid_content">
              {{item.description}}
            </div>
          </div>
          <div>
            <div class="knowledge3_box_item_right">
              <div class="knowledge3_box_item_right_day">{{item.create_time.slice(8,10)}}</div>
              <div class="knowledge3_box_item_right_xie">/</div>
              <div class="knowledge3_box_item_right_YM">
                <div class="knowledge3_box_item_right_YM_item">{{item.create_time.slice(5,7)}}月</div>
                <div class="knowledge3_box_item_right_YM_item">{{item.create_time.slice(0,4)}}年</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination class="pagination" 
        layout="prev, pager, next"
        :total="total" 
        :pageSize="6"
        :currentPage="page"
        @current-change="pageChange"></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  loginPost,
  loginCodePost,
  reqTenxun,
  reqArticleNav,
  reqArticleList,
} from "@/api";
export default {
  data() {
    return {
      page:1,
      total:6,
      article_list:[
        {
          description: "这是一条咨讯详情",
          id: "1",
          img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
          thumb_image_id: "1",
          title: "这是一条咨讯标题",
          update_time: "",
          create_time: "2022-11-28 09:10:59",
        }
      ],
    };
  },
  mounted(){
    this.initView();
  },
  methods: {
    //翻页
    pageChange(e) {
      this.page = e;
      // this.loadingBot = true;
      this.reqList(1065, "", e);
    },
    reqList(id = 1065, type = null, page = 1) {
      let reqData = {
        type_id: id,
        type: type,
        page: page,
      };
      reqArticleList(reqData)
        .then((res) => {
          if (res.code == 1) {
            let articleList = res.data.data;
            //清空原有数据
            this.article_list.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
              this.article_list.push(articleList[i]);
            }
            this.total = res.data.total;
          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingBot = false;
        });
    },
    initView(){
      reqArticleList({ type_id: 1065 })
        .then((res) => {
          if (res.code == 1) {
            // this.contentTopList = res.data.data.slice(0, 4);
            console.log(res);
            let articleList = res.data.data;
            //清空原有数据
            this.article_list.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
              this.article_list.push(articleList[i]);
            }
            this.total = res.data.total;
          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingTop = false;
        });
    },
    goDetail(id) {
      this.$router.push({
          path:'/template3/knowledgein/'+id+ '.html'
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.agent3_knowledge {
  width: 100%;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 头部BG */
.bg {
  width: 100%;
}
.bg_img {
  display: block;
  width: 100%;
  object-fit: contain;
}
/* 新闻资讯 */
.knowledge3 {
  width: 1200px;
  margin: -90px 0 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.knowledge3_box {
  width: 1200px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.knowledge3_box_item {
  width: 1140px;
  padding: 30px;
  margin-bottom: 14px;
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.knowledge3_box_item:hover {
  box-shadow: 0px 5px 10px 1px rgba(12, 51, 103, 0.21);
  transform: translateY(-8px);
  transition: 0.2s ease-out;
}
.knowledge3_box_item_left {
  width: 150px;
}
.knowledge3_box_item_left_img {
  width: 150px;
  object-fit: contain;
  display: block;
}
.knowledge3_box_item_mid {
  width: 650px;
  margin: 0 180px 0 20px;
  display: flex;
  flex-direction: column;
}
.knowledge3_box_item_mid_title {
  width: 650px;
  margin-bottom: 24px;
  font-size: 20px;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.knowledge3_box_item_mid_content {
  width: 650px;
  font-size: 14px;
  color: #999;
  line-height: 22px;
  letter-spacing: 1px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.knowledge3_box_item_right {
  display: flex;
  align-items: center;
}
.knowledge3_box_item_right_day {
  font-size: 30px;
  color: #333;
  font-weight: 500;
}
.knowledge3_box_item_right_xie {
  margin: 0 6px 0 4px;
  font-size: 30px;
  color: #999;
}
.knowledge3_box_item_right_YM {
  display: flex;
  flex-direction: column;
}
.knowledge3_box_item_right_YM_item {
  font-size: 12px;
  color: #999;
  line-height: 18px;
  white-space: nowrap;
}
/* el分页pagination样式 */
.pagination {
  width: 100%;
  height: 40px;
  margin-bottom: 120px;
  background-color: #fff;
  text-align: center;
  :deep {
    .number,
    .more,
    .btn-prev,
    .btn-next {
      // color: #999;
      width: 40px;
      height: 40px;
      margin: 0 10px;
      padding: 0;
      border: 1px solid #f3f3f3;
      font-size: 20px;
      color: #999 !important;
      font-weight: normal;
      text-align: center;
      line-height: 40px;
    }
    .el-icon {
      font-size: 20px;
    }
    .btn-prev:hover,
    .btn-next:hover {
      border: 1px solid #0266e9 !important;
      color: #0266e9 !important;
      transition: 0.2s ease-out;
    }
    .more {
      border: none;
    }
    .active {
      border: 1px solid #0266e9 !important;
      color: #fff !important;
      background-color: #0266e9;
    }
  }
}
</style>
