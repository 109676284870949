<template>
  <component :is="agentType"></component>
</template>

<script>
import agentMixin from "@/mixins/agentId.mixins";
import Home from "@/pages/Home/index.vue";
import Agent1_home from "@/pages/Agent1_home";
import Agent2_home from "@/pages/Agent2_home";
import Agent3_home from "@/pages/Agent3_home";
export default {
  name: "",
  components: {
    mtt: Home,
    agent1: Agent1_home,
    agent2: Agent2_home,
    agent3: Agent3_home,
  },
  data() {
    return {};
  },
  mixins: [agentMixin],
  methods: {},
};
</script>

<style></style>
