<template>
  <div class="agent1_knowledgeIn">
    <!-- 头部BG -->
    <div class="bg">
      <img
        class="bg_img"
        src="@/assets/agent/2/agent2_knowledge_BG.png"
        alt=""
      />
    </div>
    <!-- 文章主体 -->
    <div class="article">
      <div class="article_title">{{content.title}}</div>
      <div class="article_content" v-html="content.detail"></div>
      <div class="article_preOrnext">
        <div class="article_preOrnext_item">
          下一篇：
        </div>
        <div class="article_preOrnext_item">
          上一篇：
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reqArticleDetail } from "@/api";
export default {
  mounted() {
    reqArticleDetail({ id: this.$route.params.KnowledgeId.split(".")[0] }).then(
      (res) => {
        if (res.code == 1) {
          this.content.title = res.data.title;
          this.content.subtitle = res.data.author;
          this.content.date = res.data.post_time;
          this.content.detail = res.data.content;
        } else {
          console.log(res.message);
        }
      }
    );
  },
  data() {
    return {
      content: {
        title: "",
        subtitle: "",
        date: "",
        detail: ``,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.agent1_knowledgeIn {
  width: 100%;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 头部BG */
.bg {
  width: 100%;
}
.bg_img {
  display: block;
  width: 100%;
  object-fit: cover;
}
/* 文章 */
.article {
  width: 1200px;
  padding-top: 90px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
}
.article_title {
  font-size: 28px;
  color: #333;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.article_content {
  width: 100%;
  margin: 40px auto;
}
.article_preOrnext {
  display: flex;
  flex-direction: column;
}
.article_preOrnext_item {
  margin-bottom: 20px;
  font-size: 20px;
  color: #999;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.article_preOrnext_item:hover {
  color: rgba(8, 114, 219, 0.8);
  transition: 0.2s ease-out;
}
</style>
<style lang="scss">
.article_content p {
  font-size: 16px;
  color: #999;
  letter-spacing: 1px;
  line-height: 30px;
  width: 100%;
}
.article_content img {
  width: 90%;
}
.article_content {
  overflow: hidden;
}
</style>
