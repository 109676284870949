<template>
  <div class="agent1_nav" :style="headStyle" :class="{ colors: colors }">
    <div class="left" @click="navSel(navList[0], 0)">
      <img class="left_logo" :src="logo" alt="" />
    </div>
    <div class="right">
      <nav class="nav_box">
        <div
          class="nav_box_item"
          v-for="(item, index) in navList"
          :key="index"
          @click="navSel(item, index)"
        >
          <span
            class="nav_box_item_text"
            :class="{ nav_seled: navIndex == index }"
            >{{ item.name }}</span
          >
        </div>
      </nav>
      <div v-show="!token" class="login_btn" @click="goLogin(1)">登录</div>
      <div v-show="!token" class="register_btn" @click="goLogin(0)">注册</div>
      <div v-show="token" class="title-portrait">
        <img
          :src="require('@/assets/backstage/portrait/' + portrait)"
          alt=""
          @click="goBackPage"
        />
        
        <span class="title-portrait-nickname" @click="goBackPage"
          >个人中心</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { _throttle } from "@/utils/tool";
import { mapState } from "vuex";
import agentMixin from "@/mixins/agentId.mixins";
export default {
  inject: ["reload"],
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
      token: (state) => state.global.token,
    }),
  },
  data() {
    return {
      isGif: "logo.png",
      portrait: localStorage.portrait || "protrait_exm.png",
      portraitHS1:
        localStorage.portraitHS1 ||
        "https://www.mtuitui.com/onlineSource/gif10.gif",
      portraitHS2:
        localStorage.portraitHS2 ||
        "https://www.mtuitui.com/onlineSource/gif11.gif",
      portraitH:
        localStorage.portraitH ||
        "https://www.mtuitui.com/onlineSource/portrait10.mp4",

      navIndex: 0,
      navList: [
        {
          id: 0,
          name: "首页",
          path: "/template1/index",
        },
        {
          id: 1,
          name: "媒体资源",
          path: "/mediasource",
        },
        {
          id: 2,
          name: "知识学院",
          path: "/template1/knowledge",
        },
        {
          id: 3,
          name: "关于我们",
          path: "/template1/contact",
        },
      ],
      trans: 0,
      transJ: 0,
      // 导航栏样式
      headStyle: {
        background: "rgba(33, 40, 49, 0)",
        color: "rgba(255, 255, 255, 1)",
      },
      colors: false,
    };
  },
  mixins: [agentMixin],
  mounted() {
    window.addEventListener("scroll", this.handleScroll); //监听页面滚动
    let navUrl = this.$route.path;
    if (navUrl == "/") {
      this.navIndex = 0;
    } else if (navUrl == "/mediasource") {
      this.navIndex = 1;
    } else if (navUrl == "/template1/knowledge") {
      this.navIndex = 2;
    } else if (navUrl == "/template1/contact") {
      this.navIndex = 3;
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    //导航点击
    navSel: _throttle(function (item, index) {
      this.navIndex = index;
      this.$router.push(
        { path: item.path },
        () => {},
        () => {}
      );
      if (this.$route.path == item.path.split("?")[0]) {
        this.reload();
      }
    }, 500),
    //登录注册页跳转
    goLogin(lorr) {
      this.$router.push({
        name: "login",
        params: { lorr: lorr },
      });
    },
    //后台
    goBackPage() {
      this.$router.push("/backstage/homepage");
      this.$parent.$emit("navSideSel", { item1Index: 0, item2Index: 0 });
    },
    // 页面移动导航栏改变颜色
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //设置背景颜色的透明度
      if (scrollTop) {
        this.colors = true;
        if (scrollTop >= 250) {
          this.headStyle.background = "rgba(33, 40, 49, 1)";
        } else {
          if (this.transJ <= scrollTop) {
            this.trans += 10;
            this.transJ = scrollTop;
            if (this.trans >= 100) {
              this.trans = 100;
            }
          } else {
            this.trans -= 10;
            this.transJ = scrollTop;
            if (this.trans <= 0) {
              this.trans = 0;
            }
          }
          this.headStyle.background = `rgba(33, 40, 49,${this.trans / 100})`;
        }
      } else if (scrollTop == 0) {
        this.headStyle.background = "rgba(33, 40, 49, 0)";
        this.headStyle.color = "#FFFFFF";
        this.colors = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.agent1_nav {
  width: 100%;
  min-width: 1090px;
  height: 70px;
   
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left {
  padding: 0 300rem;
}
.left_logo {
  width: 160px;
  max-height: 50px;
  object-fit: cover;
  cursor: pointer;
}
.right {
  width: fit-content;
  padding-right: 300rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav_box {
  display: flex;
  align-items: center;
}
.nav_box_item {
  height: 70px;
  margin-left: 55rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.nav_box_item_text {
  border-bottom: 2px solid rgba($color: #fff, $alpha: 0);
  font-size: 20px;
  color: #fff;
  padding: 4px 0;
  white-space: nowrap;
}
.nav_box_item_text:hover {
  border-bottom: 2px solid #fff;
}
.login_btn {
  width: 58px;
  height: 26px;
  margin-left: 180rem;
  border-radius: 6px;
  border: 1px solid #fff;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 26px;
  white-space: nowrap;
  cursor: pointer;
}
.register_btn {
  margin-left: 12rem;
  font-size: 20px;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
}
.nav_seled {
  color: #0872db !important;
  border-bottom: 2px solid #0872db !important;
}

.title-portrait {
  height: 44px;
  margin-left: 180rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 44px;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  video {
    width: 44px;
    height: 100%;
    border-radius: 50%;
  }
  &-nickname {
    width: 80px;
    height: 30px;
    margin-left: 20rem;
    border: #fff 1px solid;
    border-radius: 6px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
  }
}
</style>
