<template>
  <div class="bg">
    <img :src="require('@/assets/mediaSource/' + bgurl)" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    bgimage: String,
    default: "bg-1.png",
  },
  computed: {
    bgurl() {
      return this.bgimage;
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  height: 425rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
