<template>
  <div class="shopCar">
    <div class="nav">
      <span>首页 / </span>
      <span>收藏</span>
    </div>
    <div class="box" v-loading="loading">
      <div class="box-title">
        <div class="box-title-left">
          <span
            @click="titleSelect(item, index)"
            :class="{ titleSeled: titleIndex == index }"
            v-for="(item, index) in collectionNameList"
            :key="index"
            >{{ item.name }}</span
          >
        </div>
        <!-- <div class="box-title-right">
          <div class="box-title-right-group" @click="showNew = true">
            新建分组
          </div>
          <div class="box-title-right-group" @click="deleteCollection">
            删除分组
          </div>
        </div> -->
      </div>
      <div
        class="box-itembox"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
      >
        <el-empty
          v-show="collectionList.length <= 0 && !loading"
          image="https://www.mtuitui.com/onlineSource/collectionEmpty.png"
          description="暂无收藏"
        ></el-empty>
        <div
          class="box-itembox-item"
          v-for="(item, index) in collectionList"
          :key="index"
        >
          <div class="box-itembox-item-square" @click="select(item)">
            <img
              v-show="collectionSelList.some((elem) => elem.id == item.id)"
              src="@/assets/backstage/seled.png"
              alt=""
            />
          </div>
          <div class="box-itembox-item-order">
            <img src="@/assets/mediaSource/netmediadisplay.png" alt="" />
            <span>{{ item.name }}</span>
          </div>
          <div class="box-itembox-item-cash">
            <span>{{ item.price }}</span>
            <span>元</span>
          </div>
          <div class="box-itembox-item-ope">
            <span @click="remove(item)">移除</span>
            <span
              :class="{
                addCar: shopCar.some((goods) => {
                  return goods.id == item.id;
                }),
              }"
              @click="addShopCar(item)"
              >{{
                shopCar.some((goods) => {
                  return goods.id == item.id;
                })
                  ? "移出购物车"
                  : "加入购物车"
              }}</span
            >
          </div>
        </div>
        <el-divider v-if="noMore">没有更多了</el-divider>
      </div>
    </div>
    <!-- 下方固定栏 -->
    <div class="botBigBox">
      <div class="leftFill"></div>
      <div class="botFixed">
        <div class="botFixed-left" @click="selectAll">
          <div class="botFixed-left-square">
            <img
              v-show="collectionSelList.length > 0"
              src="@/assets/backstage/seled.png"
              alt=""
            />
          </div>
          <span>全选</span>
        </div>
        <div class="botFixed-right">
          <!-- <span @click="removeAll">移除</span> -->
          <div class="botFixed-right-button" @click="addShopCarAll">
            加入购物车
          </div>
          <!-- <div class="botFixed-right-button">立即提交</div> -->
        </div>
      </div>
    </div>
    <transition name="el-fade-in">
      <div v-show="showNew" class="newGroup" @touchmove.prevent>
        <div class="newGroup-box">
          <img
            class="newGroup-box-xxx"
            @click="showNew = false"
            src="@/assets/backstage/xxx.png"
            alt=""
          />
          <span class="newGroup-box-title">新建分组</span>
          <div class="newGroup-box-item">
            <span>分组名称：</span>
            <input
              type="text"
              v-model="groupName"
              placeholder="请输入你的分组名称"
            />
          </div>
          <div class="newGroup-box-item" style="margin-top: 30rem">
            <div class="newGroup-box-item-back" @click="showNew = false">
              返回
            </div>
            <div class="newGroup-box-item-create">创建</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { reqCollectionList, delCollection } from "@/api";
export default {
  computed: {
    ...mapState({
      shopCar: (state) => state.global.shopCar,
    }),
    noMore() {
      return !this.isMore;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  data() {
    return {
      isMore: true,
      loading: false,
      groupName: "",
      showNew: false,
      titleIndex: 0,
      collectionNameList: [
        {
          name: "收藏夹",
        },
        // {
        //   name: "分组1",
        // },
      ],
      collectionList: [],
      collectionSelList: [],
      page: 1,
    };
  },
  mounted() {
    this.reqList();
  },
  methods: {
    load() {
      if (this.collectionList.length >= 10 && !this.loading) {
        this.loading = true;
        reqCollectionList({ page: this.page + 1 })
          .then((res) => {
            if (res.code == 1) {
              if (res.data.data.length > 0) {
                this.isMore = true;
                this.collectionList = [
                  ...this.collectionList,
                  ...res.data.data,
                ];
                this.collectionList.concat(res.data.data);
                this.page++;
              } else {
                this.isMore = false;
              }
            } else {
              this.$message.error("请求出错");
            }
          })
          .catch((res) => {
            this.$message.error(res);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    titleSelect(item, index) {
      this.titleIndex = index;
    },
    //单选
    select(item) {
      if (
        this.collectionSelList.some((e) => {
          return e.id == item.id;
        })
      ) {
        let arr = this.collectionSelList.filter((e) => {
          return e.id != item.id;
        });
        this.collectionSelList = arr;
      } else {
        this.collectionSelList.push(item);
      }
    },
    //全选
    selectAll() {
      if (this.collectionSelList.length < this.collectionList.length) {
        this.collectionList.forEach((item) => {
          if (
            this.collectionSelList.some((goods) => {
              return goods.id == item.id;
            })
          ) {
          } else {
            this.collectionSelList.push(item);
          }
        });
      } else {
        this.collectionSelList = [];
      }
    },
    //加入购物车
    addShopCar(item) {
      if (
        this.shopCar.some((goods) => {
          return goods.id == item.id;
        })
      ) {
        this.$store.dispatch("global/shopCartNumReduce", item);
      } else {
        this.$store.dispatch("global/shopCartNumAdd", item);
      }
    },
    //取消收藏
    remove(item) {
      delCollection({ id: item.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.reqList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //选中加入购物车
    addShopCarAll() {
      this.collectionList.forEach((item) => {
        if (
          this.shopCar.some((goods) => {
            return goods.id == item.id;
          })
        ) {
          // this.$store.dispatch("global/shopCartNumReduce", item);
        } else {
          this.$store.dispatch("global/shopCartNumAdd", item);
        }
      });
    },
    //选中移除
    removeAll() {},
    //Delete Confirm
    deleteCollection() {
      this.$confirm(
        "确认删除" + this.collectionNameList[this.titleIndex].name + "?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //请求收藏列表
    reqList() {
      this.loading = true;
      reqCollectionList()
        .then((res) => {
          if (res.code == 1) {
            this.isMore = true;
            this.collectionList = res.data.data;
          } else {
            this.$message.error("请求出错");
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.shopCar {
  width: 100%;
  height: 100%;
  .nav {
    width: 100%;
    height: 50rem;
    background-color: #fff;
    line-height: 50rem;
    span {
      font-size: 14rem;
      color: #999;
    }
    span:first-child {
      margin-left: 20rem;
    }
    span:last-child {
      color: #666;
    }
  }
  .box {
    width: 100%;
    margin-top: 20rem;
    margin-bottom: 110rem;
    padding: 20rem 0 0;
    background-color: #fff;
    &-title {
      width: 1500rem;
      height: 42rem;
      padding-left: 60rem;
      margin: 0 auto;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left {
        display: flex;
        align-items: center;
        span {
          margin-right: 40rem;
          font-size: 18rem;
          color: #999999;
          line-height: 42rem;
          cursor: pointer;
        }
        span:hover {
          color: #0982f9;
        }
      }
      &-right {
        margin-right: 60rem;
        display: flex;
        align-items: center;
        &-group {
          width: 94rem;
          height: 30rem;
          margin-left: 12rem;
          border: 1px solid #707070;
          border-radius: 2px;
          text-align: center;
          line-height: 30rem;
          font-size: 17rem;
          color: #999999;
          cursor: pointer;
        }
        &-group:hover {
          background-color: rgba($color: #707070, $alpha: 0.1);
          color: #666;
        }
      }
    }
    &-itembox {
      height: 570rem;
      padding-bottom: 50rem;
      overflow-y: scroll;
      &-item {
        width: 1560rem;
        height: 97rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        border-bottom: #eaeaea 1px solid;
        &-square {
          width: 24rem;
          height: 24rem;
          margin: 0 70rem 0 72rem;
          border: 1px solid #707070;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &-order {
          width: 900rem;
          height: 90rem;
          margin-right: 20rem;
          display: flex;
          align-items: center;
          img {
            width: 166rem;
            object-fit: contain;
          }
          span {
            margin-left: 40rem;
            font-size: 24rem;
            font-weight: bold;
            color: #333333;
          }
        }
        &-cash {
          width: 120rem;
          height: 90rem;
          margin-right: 120rem;
          display: flex;
          align-items: baseline;
          justify-content: center;
          line-height: 90rem;
          span {
            color: #ec0e0e;
          }
          span:first-child {
            font-size: 28rem;
            font-weight: bold;
          }
          span:last-child {
            font-size: 12rem;
          }
        }
        &-ope {
          width: 100rem;
          height: 90rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span:first-child {
            font-size: 18rem;
            color: #999;
            cursor: pointer;
          }
          span:first-child:hover {
            color: #0982f9;
          }
          span:last-child {
            margin-top: 10rem;
            font-size: 18rem;
            color: #0982f9;
            cursor: pointer;
          }
          .addCar {
            color: #666 !important;
          }
        }
      }
    }
    &-itembox::-webkit-scrollbar {
      // display: none !important;
      width: 10px;
      height: 10px;
      background-color: #f5f5f5;
    }
    /*定义滚动条轨道内阴影+圆角*/
    &-itembox::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    /*定义滑块内阴影+圆角*/
    &-itembox::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }
  }
  .botBigBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    .leftFill {
      width: 14%;
      min-width: 168px;
    }
    .botFixed {
      width: 84%;
      min-width: 600px;
      height: 100rem;
      background-color: #fff;
      box-shadow: 0 -5px 20px 1px rgb(123 123 123 / 16%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 18rem;
        color: #999;
        cursor: pointer;
      }
      span:hover {
        color: #0982f9;
      }
      &-left {
        display: flex;
        align-items: center;
        &-square {
          width: 24rem;
          height: 24rem;
          margin: 0 10rem 0 92rem;
          border: 1px solid #707070;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &-right {
        margin-right: 30rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &-button {
          width: 120rem;
          height: 46rem;
          margin-left: 20rem;
          background: linear-gradient(90deg, rgba(13, 158, 220, 0.59), #0982f9);
          border: none;
          border-radius: 6px;
          font-size: 18rem;
          color: #fff;
          text-align: center;
          line-height: 46rem;
          cursor: pointer;
        }
        &-button:hover {
          background-color: #0982f9;
        }
        &-button:active {
          background-color: rgba($color: #0982f9, $alpha: 0.7);
        }
      }
    }
  }
}
.titleSeled {
  color: #0982f9 !important;
}
.newGroup {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #565656, $alpha: 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  &-box {
    width: 800rem;
    height: 270rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &-xxx {
      width: 22rem;
      height: 22rem;
      position: absolute;
      top: 15rem;
      right: 15rem;
      object-fit: contain;
      cursor: pointer;
    }
    &-title {
      margin: 40rem 0;
      font-size: 24rem;
      font-weight: bold;
      color: #333333;
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 24rem;
        color: #333;
      }
      input {
        width: 440rem;
        height: 48rem;
        padding: 0 15rem;
        border: 1px solid #e2e2e2;
        font-size: 22rem;
        color: black;
        outline: none;
      }
      input::placeholder {
        color: #999;
      }
      &-back,
      &-create {
        width: 80rem;
        height: 34rem;
        border-radius: 4px;
        font-size: 18rem;
        text-align: center;
        line-height: 34rem;
        cursor: pointer;
      }
      &-back {
        margin-right: 60rem;
        border: #e2e2e2 1px solid;
        color: #999;
      }
      &-create {
        background-color: #42a0fd;
        color: #fff;
      }
    }
  }
}
</style>
