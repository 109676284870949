<template>
  <!-- 发送文本框 -->
  <div class="chat-send-text">
    <div class="send-textarea">
      <!-- v-model="$store.state.chat.text" -->
      <textarea
        v-model="text"
        placeholder="请输入"
        ref="textarea"
      ></textarea>
    </div>
    <!-- <div class="logout-button"><button @click="logout">退出</button></div> -->
    <div class="send-button"><button @click="sendText">发送</button></div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      text: '',
      lastSendTime:0
    };
  },
  methods: {
    logout(){
        this.$message.success("退出成功");
        setTimeout(()=>{
          this.$store.commit('chat/logout');
          this.$router.push('/login');
        },1000);
    },
    sendText() {

      if (this.text === null || this.text === "") {
        this.$message("发送消息不能为空");
      } else {

        if(this.$store.state.chat.toId == 0){
          this.$message("还未选择聊天对象");
          return;
        }
        if(Math.abs(new Date().getTime() - this.lastSendTime)<=2000){
            this.$message("发送太频繁了，请稍后再试");
            return;
        }
        //   传入文本框文本和当前正在聊天的人名
        let id = window.localStorage.getItem('id');
        let userType = window.localStorage.getItem('user_type');
        let userId = 0;
        let customerServiceId = 0;
        let fromUserType = 1;
        let toUserType = 1;
        if(userType == 2){
          //客服
          customerServiceId = id;
          userId = this.$store.state.chat.toId;
          fromUserType = 2;
          toUserType = 1;
        }else{
          //普通用户
          userId = id;
          customerServiceId = this.$store.state.chat.toId;
          fromUserType = 1;
          toUserType = 2;
        }
        let obj = {
            content:this.text,//发送的内容
            customerServiceId:customerServiceId,//客服id
            fromId:id,//发送方id，也就是自己的id
            toId:this.$store.state.chat.toId,//接收方id
            fromName:window.localStorage.getItem('username'),//发送方name
            fromAvatar:window.localStorage.getItem('avatar'),//发送方头像
            toAvatar:this.$store.state.chat.toAvatar,//接收方头像
            userId:userId,//会员id
            fromUserType:fromUserType,//发送方user类型 会员1，客服2
            toUserType:toUserType,//接收方用户类型
            toName:this.$store.state.chat.toName,//发送方名称
            code:200,//默认200
            type:'chat-message'//发送类型
        }
        console.log(obj);
        // return ;
        // this.$store.commit("chat/sendText",obj);
        // console.log(this.$store.state.chat.chatMsgs)

        // 文本框清空
        this.$refs.textarea.value = "";
        // 调用ChatClient.vue组件的sendText方法
        this.$bus.$emit('sendText',obj);
        this.lastSendTime = new Date().getTime();

        // console.log(this.$store.state.chat.chatMsgssssss)
      }
    },
  },
};
</script>

<style scoped>
.chat-send-text {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 23%;
  /* padding: 10px; */
  /* background-color: tomato; */
}

.send-textarea {
  height: 80%;
  width: 100%;
  position: relative;
}

.send-textarea textarea {
  position: absolute;
  border: 0;
  resize: none;
  outline: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 75%;
  width: 95%;
  background-color: inherit;
  font-size: 14px;
  font-family: "Microsoft YaHei";
}

.send-button {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.logout-button{
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.send-button button {
  width: 80px;
  height: 26px;
  color: #ffffff;
  background-color: #1389bf;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}
.logout-button button {
  width: 80px;
  height: 26px;
  color: #ffffff;
  background-color: #1389bf;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}

.logout-button button:active {
  background-color: #1ba7e7;
}
.send-button button:active {
  background-color: #1ba7e7;
}
</style>