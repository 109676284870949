//配置路由的地方
import Vue from "vue";
import VueRouter from "vue-router";
//使用插件
Vue.use(VueRouter);

// const Home = () => import("@/pages/home")

//导入页面路由
import Login from "@/pages/Login";
import Home from "@/pages/home";
import Mediasource from "@/pages/Mediasource";
import BaseService from '@/pages/BaseService'
import Cooperation from '@/pages/Cooperation'
import Knowledge from "@/pages/knowledge";
import Knowledgein from "@/pages/knowledgeIn";
import Contact from "@/pages/contact";
import Backstage from "@/pages/Backstage";
import ForgetPsd from "@/pages/ForgetPsd";
import ReviewFile from '@/pages/ReviewFile';

// 支付成功页面
import PaySuccess from '@/pages/PaySuccess';

//导入Backstage子路由
import Homepage from "@/pages/Backstage/Homepage";
import MediaRelease from "@/pages/Backstage/MediaRelease";
import BaseServer from '@/pages/Backstage/BaseServer';
import CompanyChannel from '@/pages/Backstage/CompanyChannel';
import ServerDetail from "@/pages/Backstage/ServerDetail";
import OrderMgt from "@/pages/Backstage/OrderMgt";
import FundMgt from "@/pages/Backstage/FundMgt";
import PersonPage from "@/pages/Backstage/PersonPage";
import Contactus from "@/pages/Backstage/Contactus";
import FileUpload from '@/pages/Backstage/FileUpload';
import ShopCar from '@/pages/Backstage/ShopCar';
import Collection from '@/pages/Backstage/Collection';

//导入Backstage/MediaRelease子路由
import NewsMedia from "@/pages/Backstage/MediaRelease/NewsMedia";
import SelfMedia from "@/pages/Backstage/MediaRelease/SelfMedia";
import ShortMedia from "@/pages/Backstage/MediaRelease/ShortMedia";
import Wechat from "@/pages/Backstage/MediaRelease/Wechat";
import Offline from "@/pages/Backstage/MediaRelease/Offline";

//导入Backstage/OrderMgt子路由
import OrderQuery from "@/pages/Backstage/OrderMgt/OrderQuery";
import BaseOrder from "@/pages/Backstage/OrderMgt/baseOrderQuery";

//导入Backstage/FundMgt子路由
import OnlineCharge from "@/pages/Backstage/FundMgt/OnlineCharge";
import DealRecord from "@/pages/Backstage/FundMgt/DealRecord";
import Withdrawal from "@/pages/Backstage/FundMgt/Withdrawal";

//导入Backstage/PersonPage子路由
import Usermsg from "@/pages/Backstage/PersonPage/Usermsg";
import PasManage from "@/pages/Backstage/PersonPage/PasManage";
import Mynote from "@/pages/Backstage/PersonPage/Mynote";
import ApplyJoin from "@/pages/Backstage/PersonPage/ApplyJoin";


//导入Backstage/Contactus子路由
import Feedback from "@/pages/Backstage/Contactus/Feedback";
import ContactCS from "@/pages/Backstage/Contactus/ContactCS";



//模板页面1
import Agent1Knowledge from '@/pages/Agent1_knowledge/index'
import Agent1KnowledgeIn from '@/pages/Agent1_knowledgeIn/index'
import Agent1Contact from '@/pages/Agent1_contact/index'
import Agent1Home from '@/pages/Agent1_home/index'


import Agent2Knowledge from '@/pages/Agent2_knowledge/index'
import Agent2KnowledgeIn from '@/pages/Agent2_knowledgeIn/index'
import Agent2Contact from '@/pages/Agent2_contact/index'
import Agent2Home from '@/pages/Agent2_home/index'


import Agent3Knowledge from '@/pages/Agent3_knowledge/index'
import Agent3KnowledgeIn from '@/pages/Agent3_knowledgeIn/index'
import Agent3Contact from '@/pages/Agent3_contact/index'
import Agent3Home from '@/pages/Agent3_home/index'



import customerService from '@/pages/customerService/index' //客服

const route =  new VueRouter({
  mode: "history",
  // mode: "hash",
  //切换路由页面置顶
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/login/:lorr?",
      component: Login,
      meta: { footer: false, nav: false, sideicon: true },
      name: "login",
      props: ($route) => {
        return { lorr: $route.params.lorr };
      },
    },
    {
      path: "/",
      component: Home,
      meta: { footer: true, nav: true, sideicon: true },
      name: "home",
    },
    {
      path:"/PaySuccess",
      component:PaySuccess,
      meta: { footer: false, nav: false, sideicon: false },
      name:"PaySuccess"
    },
    {
    path: "/customerService/index",
    component: customerService,
    meta: { footer: false, nav: false, sideicon: false },
    name: "客服聊天",
    },
    {
      path: "/template1/index",
      component: Home,
      meta: { footer: true, nav: true, sideicon: true },
      name: "template1",
    },
    {
      path: "/template2/index",
      component: Home,
      meta: { footer: true, nav: true, sideicon: true },
      name: "template2",
    },
    {
      path: "/template3/index",
      component: Home,
      meta: { footer: true, nav: true, sideicon: true },
      name: "template3",
    },
    {
      path:"/template1/knowledge",
      component: Agent1Knowledge,
      meta: { footer: true, nav: true, sideicon: false },
      name: "Agent1Knowledge",
    },
    {
      path:"/template2/knowledge",
      component: Agent2Knowledge,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent2Knowledge",
    },
    {
      path:"/template3/knowledge",
      component: Agent3Knowledge,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent3Knowledge",
    },

    {
      path:"/template1/knowledgein",
      component: Agent1KnowledgeIn,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent1KnowledgeIn",
    },
    {
      path:"/template2/knowledgein",
      component: Agent2KnowledgeIn,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent2KnowledgeIn",
    },
    {
      path:"/template3/knowledgein",
      component: Agent3KnowledgeIn,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent3KnowledgeIn",
    },

    {
      path:"/template1/contact",
      component: Agent1Contact,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent1Contact",
    },
    {
      path:"/template2/contact",
      component: Agent2Contact,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent2Contact",
    },
    {
      path:"/template3/contact",
      component: Agent3Contact,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent3Contact",
    },

    {
      path:"/template1/home",
      component: Agent1Home,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent1Home",
    },
    {
      path:"/template2/home",
      component: Agent2Home,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent2Home",
    },
    {
      path:"/template3/home",
      component: Agent3Home,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent3Home",
    },
    {
      path: "/mediasource",
      component: Mediasource,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Mediasource",
    },
    {
      path: "/knowledge",
      component: Knowledge,
      meta: { footer: true, nav: true, sideicon: true },
      name: "knowledge",
    },
    {
      path: "/template1/KnowledgeIn/:KnowledgeId?",
      component: Agent1KnowledgeIn,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent1KnowledgeIn",
      props: ($route) => {
        return { KnowledgeId: $route.params.KnowledgeId };
      },
    },
    {
      path: "/template2/KnowledgeIn/:KnowledgeId?",
      component: Agent2KnowledgeIn,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent2KnowledgeIn",
      props: ($route) => {
        return { KnowledgeId: $route.params.KnowledgeId };
      },
    },
    {
      path: "/template3/KnowledgeIn/:KnowledgeId?",
      component: Agent3KnowledgeIn,
      meta: { footer: true, nav: true, sideicon: true },
      name: "Agent3KnowledgeIn",
      props: ($route) => {
        return { KnowledgeId: $route.params.KnowledgeId };
      },
    },
    {
      path: "/KnowledgeIn/:KnowledgeId?",
      component: Knowledgein,
      meta: { footer: true, nav: true, sideicon: true },
      name: "knowledgein",
      props: ($route) => {
        return { KnowledgeId: $route.params.KnowledgeId };
      },
    },
    {
      path: "/baseService",
      component: BaseService,
      meta: { footer: true, nav: true, sideicon: true },
      name: "baseService",
    },
    {
      path: "/contact",
      component: Contact,
      meta: { footer: true, nav: true, sideicon: true },
      name: "contact",
    },
    {
      path: "/cooperation",
      component: Cooperation,
      meta: { footer: true, nav: true, sideicon: true },
      name: "cooperation",
    },
    {
      path: "/forgetPsd",
      component: ForgetPsd,
      meta: { footer: false, nav: false, sideicon: false },
      name: "forgetPsd",
    },
    {
      path: "/reviewFile",
      component: ReviewFile,
      meta: { footer: false, nav: false, sideicon: false },
      name: "reviewFile",
    },
    {
      path: "/backstage",
      component: Backstage,
      meta: { footer: false, nav: false, sideicon: false, navside: false, requireAuth: true},
      children: [
        //首页看板
        {
          path: "homepage",
          component: Homepage,
          meta: {requireAuth: true},
          name: "homepage",
        },
        //媒体发布
        {
          path: "mediaRelease",
          component: MediaRelease,
          meta: {requireAuth: true},
          children: [
            {
              path: "newsMedia",
              component: NewsMedia,
              meta: {requireAuth:true},
              name: "newsMedia",
            },
            {
              path: "selfMedia",
              component: SelfMedia,
              name: "selfMedia",
            },
            {
              path: "shortMedia",
              component: ShortMedia,
              name: "shortMedia",
            },
            {
              path: "wechat",
              component: Wechat,
              name: "wechat",
            },
            {
              path: "offline",
              component: Offline,
              name: "offline",
            },
            {
              path: "",
              redirect: "newsMedia",
            },
          ],
        },
        //基础服务
        {
          path: "baseServer",
          component: BaseServer,
          meta: {requireAuth:true},
          name: "baseServer",
        },
        //寻找官方小伙伴
        {
          path: "companyChannel",
          component: CompanyChannel,
          meta: {requireAuth:true},
          name: "companyChannel",
        },
        //服务详情
        {
          path: "serverDetail",
          component: ServerDetail,
          meta: {requireAuth:false},
          name: "serverDetail",
        },
        //订单管理
        {
          path: "orderMgt",
          component: OrderMgt,
          meta: {requireAuth:true},
          children: [
            {
              path: "orderQuery",
              component: OrderQuery,
              meta: {requireAuth:true},
              name: "orderQuery",
            },{
              path: "BaseOrder",
              component: BaseOrder,
              meta: {requireAuth:true},
              name: "BaseOrder",
            }
          ],
        },
        //资金管理
        {
          path: "fundMgt",
          component: FundMgt,
          meta: {requireAuth:true},
          children: [
            {
              path: "onlineCharge",
              component: OnlineCharge,
              meta: {requireAuth:true},
              name: "onlineCharge",
            },
            {
              path: "dealRecord",
              component: DealRecord,
              meta: {requireAuth:true},
              name: "dealRecord",
            },
            {
              path: "withdrawal",
              component: Withdrawal,
              meta: {requireAuth:true},
              name: "withdrawal",
            },
            {
              path: "",
              redirect: "onlineCharge",
            },
          ],
        },
        //个人中心
        {
          path: "personpage",
          component: PersonPage,
          meta: {requireAuth:true},
          children: [
            {
              path: "usermsg",
              component: Usermsg,
              meta: {requireAuth:true},
              name: "usermsg",
            },

            {
              path: "mynote",
              component: Mynote,
              meta: {requireAuth:true},
              name: "mynote",
            },
            {
              path: "pasManage",
              component: PasManage,
              meta: {requireAuth:true},
              name: "pasManage",
            },
            {
              path: "PointsDetails",
              component: () => import("@/pages/Backstage/PersonPage/PointsDetails/PointsDetails.vue"),
              meta: {requireAuth:true},
              name: "PointsDetails",
            },
            {
              path: "MyInvitation",
              meta: {requireAuth:true},
              component: () => import("@/pages/Backstage/PersonPage/MyInvitation/MyInvitation.vue"),
              name: "MyInvitation",
            },
            {
              path: "",
              redirect: "usermsg",
            },
            {
              path: "applyJoin",
              component: ApplyJoin,
              meta: {requireAuth:true},
              name: "applyJoin",
            },
          ],
        },
        //联系官方
        {
          path: "contactus",
          component: Contactus,
          meta: {requireAuth:true},
          children: [
            {
              path: "feedback",
              component: Feedback,
              meta: {requireAuth:true},
              name: "feedback",
            },
            {
              path: "contactCS",
              component: ContactCS,
              meta: {requireAuth:true},
              name: "contactCS",
            },
            {
              path: "",
              redirect: "contactCS",
            },
          ],
        },
        //稿件上传
        {
          path: "fileUpload",
          component: FileUpload,
          meta: {requireAuth:true},
          name: "fileUpload",
        },
        //购物车
        {
          path: "shopCar",
          component: ShopCar,
          meta: {requireAuth:true},
          name: "shopCar",
        },
        //收藏
        {
          path: "collection",
          component: Collection,
          meta: {requireAuth:true},
          name: "collection",
        },
        {
          path: "",
          redirect: "homepage",
        },
      ],
    },
    //重定向，在项目跑起来的时候，访问/，立马让他定向到首页
    {
      path: "*",
      redirect: "/",
    },
  ],
});

export default route

// function getRoutesList(routes, pre) {
//   return routes.reduce((array, route) => {
//     const path = `${pre}${route.path}`;
 
//     if (route.path !== '*') {
//       array.push(path);
//     }
 
//     if (route.children) {
//       array.push(...getRoutesList(route.children, `${path}/`));
//     }
 
//     return array;
//   }, []);
// }
 
 
// getRoutesList(router.options.routes, 'https://zigamiklic.com');
// function getRoutesXML() {
//   const list = getRoutesList(route.options.routes, 'https://www.mtuitui.com')
//     .map(route => `<url><loc>${route}</loc></url>`)
//     .join('\r\n');
//   return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`;
// }
 
// console.log(getRoutesXML())