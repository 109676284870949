<template>
  <div class="bigBox">
    <div class="leftFill"></div>
    <div class="navtop">
      <div class="navList">
        <span
          class="navList-item"
          v-for="(item, index) in navList"
          :key="index"
          @click="item1Click(item)"
          >{{ item.name }}</span
        >
      </div>
      <div class="navright">
        <!-- <div class="navright-gohome" @click="goHome">返回网站首页</div> -->
        <div class="btn" @click="goHome">
          <span>返回网站首页</span>
          <div class="dot"></div>
        </div>
        <div class="navright-icon" @click="goShopCar">
          <img src="@/assets/backstage/shopcar.png" alt="购物车" />
          <span v-show="shopCar.length > 0">{{ shopCar.length }}</span>
        </div>
        <div class="navright-icon" @click="goCollection">
          <img src="@/assets/backstage/collection.png" alt="收藏" />
        </div>
        <div class="navright-icon" @click="goMyNote">
          <img src="@/assets/backstage/message.png" alt="信息" />
        </div>
        <div
          @mouseenter="showBox(true)"
          @mouseleave="showBox(false)"
          class="navright-portraitBox"
        >
          <div class="navright-portraitBox-portrait">
            <img
              v-if="
                userMessage.tel != 18227984284 &&
                userMessage.tel != 15023607481 &&
                userMessage.tel != 17723046355
              "
              :src="require('@/assets/backstage/portrait/' + portrait)"
              alt="头像"
            />
            <video
              v-if="userMessage.tel == 18227984284"
              :src="portraitH"
              autoplay
              loop
              muted
            ></video>
            <img
              v-if="userMessage.tel == 15023607481"
              :src="portraitHS1"
              alt=""
            />
            <img
              v-if="userMessage.tel == 17723046355"
              :src="portraitHS2"
              alt=""
            />
          </div>
          <span class="navright-portraitBox-nickname">{{
            userMessage.nickname
          }}</span>
        </div>
      </div>
      <div
        v-show="boxShow"
        @mouseenter="showBox(true)"
        @mouseleave="showBox(false)"
        class="detailBox"
      >
        <div class="detailBox-message" @click="goPersonPage">
          <img src="@/assets/backstage/person/person-icon.png" alt="信息" />
          <span>账号信息</span>
        </div>
        <!-- <div class="detailBox-balance">
          <span>余额:</span>
          <span>{{ balance }}元</span>
          <span @click="goOnlineCharge">充值</span>
        </div> -->
        <div class="detailBox-loginout btn-3" @click="loginout">
          <span>退出登录</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  inject: ['reloadB'],
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
      shopCar: (state) => state.global.shopCar,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.nickname = this.userMessage.nickname
      this.balance = this.userMessage.balance
    })
  },
  data() {
    return {
      navList: [
        {
          id: 1,
          name: '媒体资源发布',
          path: '/backstage/mediaRelease/newsMedia',
        },
        // {
        //   name: "广告资源价格",
        // },
        {
          id: 2,
          name: '基础营销服务',
          path: '/backstage/baseServer',
        },
        // {
        //   id: 4,
        //   name: "找官方小伙伴",
        //   path: "/backstage/companyChannel",
        // },
      ],
      nickname: '',
      balance: 0,
      portrait: localStorage.portrait || 'protrait_exm.png',
      portraitHS1:
        localStorage.portraitHS1 ||
        'https://www.mtuitui.com/onlineSource/gif10.gif',
      portraitHS2:
        localStorage.portraitHS2 ||
        'https://www.mtuitui.com/onlineSource/gif11.gif',
      portraitH:
        localStorage.portraitH ||
        'https://www.mtuitui.com/onlineSource/portrait10.mp4',

      //页面参数
      boxShow: false,
    }
  },
  methods: {
    item1Click(item) {
      if (this.$route.path == item.path) {
        this.reloadB()
        return
      }
      //业务跳转
      this.$router.push(item.path)
      this.$parent.$emit('navSideSel', { item1Index: item.id, item2Index: 0 })
    },
    //前台首页
    goHome() {
      let nav = {
        name: '首页',
        color: '#0982F9',
      }
      this.$store.dispatch('global/navColorChange', nav)
      this.$router.push('/')
    },
    //退出登录
    loginout() {
      localStorage.token = ''
      localStorage.userMsg = ''
      localStorage.portrait = ''
      localStorage.portraitH = ''
      localStorage.portraitHS = ''
      this.$store.dispatch('global/tokenlocal')
      this.reloadB()
      this.$router.go(0)
    },
    //购物车
    goShopCar() {
      if (this.$route.path == '/backstage/shopCar') {
        this.reloadB()
        return
      }
      this.$router.push('/backstage/shopCar')
      this.$parent.$emit('navSideSel', { item1Index: 0, item2Index: 0 })
    },
    //收藏
    goCollection() {
      if (this.$route.path == '/backstage/collection') {
        this.reloadB()
        return
      }
      this.$router.push('/backstage/collection')
      this.$parent.$emit('navSideSel', { item1Index: 0, item2Index: 0 })
    },
    //通知
    goMyNote() {
      if (this.$route.path == '/backstage/personpage/mynote') {
        // this.reloadB();
        this.$bus.$emit('noteIndex', 1)
        return
      } else {
        this.$router.push('/backstage/personpage/mynote')
        setTimeout(() => {
          this.$bus.$emit('noteIndex', 1)
        }, 500)
      }
      this.$parent.$emit('navSideSel', { item1Index: 6, item2Index: 1 })
    },
    //个人信息
    goPersonPage() {
      if (this.$route.path == '/backstage/personpage/usermsg') {
        this.reloadB()
        return
      }
      this.$router.push('/backstage/personpage/usermsg')
      this.$parent.$emit('navSideSel', { item1Index: 6, item2Index: 0 })
      // this.$parent.$emit("navLocation", 0);
    },
    //个人信息框
    showBox(boolean) {
      if (boolean) {
        this.boxShow = true
      } else {
        this.boxShow = false
      }
    },
    //充值
    goOnlineCharge() {
      if (this.$route.path == '/backstage/fundMgt/onlineCharge') {
        this.reloadB()
        return
      }
      this.$router.push('/backstage/fundMgt/onlineCharge')
      this.$parent.$emit('navSideSel', { item1Index: 5, item2Index: 0 })
    },
  },
}
</script>

<style lang="scss">
:root {
  --bg: rgba(#3c465c, 0);
  --primary: #0982f9;
  --solid: #fff;
  --btn-w: 8em;
  --dot-w: calc(var(--btn-w) * 0.2);
  --tr-X: calc(var(--btn-w) - var(--dot-w));
}
.bigBox {
  width: 100%;
  display: flex;
  .leftFill {
    width: 15%;
    min-width: 168px;
    height: 100%;
  }
  .navtop {
    width: 85%;
    height: 100%;
    background-color: #fff;
    border-bottom: 2px solid #f4f4f4;
    display: flex;
    justify-content: space-between;
    .navList {
      height: 100%;
      display: flex;
      align-items: center;
      &-item {
        margin: 0 30rem;
        font-size: 18rem;
        color: #666;
        cursor: pointer;
      }
      &-item:hover {
        color: #0982f9;
      }
    }
    .navright {
      height: 100%;
      display: flex;
      align-items: center;
      &-gohome:hover {
        color: white;
        background-color: #0982f9;
        transition: 0.1s ease-out;
      }
      &-gohome {
        // width: 80px;
        height: 30px;
        padding: 0 8px;
        margin-right: 20rem;
        border: #0982f9 1px solid;
        border-radius: 6px;
        font-size: 14px;
        color: #0982f9;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        transition: 0.1s ease-out;
      }
      //返回网站首页
      .btn {
        position: relative;
        // margin: 0 auto;
        margin-right: 20rem;
        width: var(--btn-w);
        color: var(--primary);
        border: 0.15em solid var(--primary);
        border-radius: 5em;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.3em;
        line-height: 2em;
        cursor: pointer;
      }
      .dot {
        content: '';
        position: absolute;
        top: 0;
        width: var(--dot-w);
        height: 100%;
        border-radius: 100%;
        transition: all 300ms ease;
        display: none;
      }
      .dot:after {
        content: '';
        position: absolute;
        left: calc(50% - 0.4em);
        top: -0.4em;
        height: 0.8em;
        width: 0.8em;
        background: var(--primary);
        border-radius: 1em;
        border: 0.25em solid var(--solid);
        box-shadow: 0 0 0.7em var(--solid), 0 0 2em var(--primary);
      }
      .btn:hover .dot,
      .btn:focus .dot {
        animation: atom 2s infinite linear;
        display: block;
      }
      @keyframes atom {
        0% {
          transform: translateX(0) rotate(0);
        }
        30% {
          transform: translateX(var(--tr-X)) rotate(0);
        }
        50% {
          transform: translateX(var(--tr-X)) rotate(180deg);
        }
        80% {
          transform: translateX(0) rotate(180deg);
        }
        100% {
          transform: translateX(0) rotate(360deg);
        }
      }

      &-icon {
        width: 36px;
        height: 36px;
        margin-right: 13rem;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }
        span {
          display: inline-block;
          // min-width: 12px;
          height: 18px;
          padding: 0 3rem;
          border-radius: 9px;
          background-color: #eb2f06;
          font-size: 10px;
          color: #fff;
          line-height: 18px;
          text-align: center;
          position: absolute;
          right: -9rem;
          top: -6rem;
        }
      }
      &-portraitBox {
        height: 100%;
        margin-right: 80rem;
        display: flex;
        align-items: center;
        &-portrait {
          width: 44px;
          height: 44px;
          margin-right: 10rem;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;
          }
          video {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            cursor: pointer;
          }
        }
        &-nickname {
          font-size: 14rem;
          color: #666;
          cursor: pointer;
        }
      }
    }
    .detailBox {
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-radius: 6px 6px 6px 6px;
      display: grid;
      grid-template-rows: 3fr 3fr;

      position: absolute;
      top: 66px;
      right: 75rem;
      &-message {
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 24rem;
          height: 24rem;
          margin-right: 10rem;
        }
        span {
          font-size: 18rem;
          color: #999;
        }
      }
      &-balance {
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 18rem;
          color: #999;
        }
        span:nth-child(2) {
          margin: 0 8rem;
          color: #42a0fd;
        }
        span:nth-child(3) {
          color: #42a0fd;
          cursor: pointer;
        }
      }
      &-loginout {
        border-radius: 0 0 6px 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18rem;
        color: #999;
        cursor: pointer;
        position: relative;
      }
      &-loginout:hover {
        color: #fff;
      }
    }
  }
}

//loginOut Animation
.btn-3 {
  width: 204rem;
  height: 41rem;
  line-height: 41rem;
  text-align: center;
  padding: 0;
  border: none;
}
.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-3:before,
.btn-3:after {
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}
.btn-3:before {
  height: 0%;
  width: 2px;
}
.btn-3:after {
  width: 0%;
  height: 2px;
}
.btn-3:hover {
  background: transparent;
  box-shadow: none;
}
.btn-3:hover:before {
  height: 100%;
}
.btn-3:hover:after {
  width: 100%;
}
.btn-3 span:hover {
  color: rgba(2, 126, 251, 1);
}
.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}
.btn-3 span:before {
  width: 2px;
  height: 0%;
}
.btn-3 span:after {
  width: 0%;
  height: 2px;
}
.btn-3 span:hover:before {
  height: 100%;
}
.btn-3 span:hover:after {
  width: 100%;
}
</style>
