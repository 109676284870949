import Vue from 'vue';
let v = new Vue();
export default {
    //消息提醒
    message(message,type="info",duration=1500,showClose=true,offset=20) {
        console.log(message,type,duration,showClose,offset)
        v.$message({
            message: message,
            type: type,
            duration: duration,
            showClose: showClose,
            offset: offset,
        });
    },
}