<template>
    <div class="applyjoin">
      <!-- 设置登录密码 -->
      <div class="applyJoin">
        <span class="applyJoin-title">申请入驻</span>
        <div class="applyJoin-input">
          <span>渠道：</span>
          <el-select
            v-model="channel_id"
            placeholder="请选择渠道"
            @change="changeChannel"
          >
            <el-option
              v-for="item in serveNavList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="applyJoin-input">
          <span>服务：</span>
          <el-select
            v-model="service_id"
            placeholder="请选择服务"
          >
            <el-option
              v-for="item in serveSubNavList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="applyJoin-input" v-for="(addr,index) in companyChannelAddrList"  :key="index" v-if="index>=1">
          <span>代理城市：</span>
          <el-select
            v-model="addr.province_list[0].id"
            placeholder="请选择省"
            @change="changeProvince($event,0)"
          >
            <el-option
              v-for="item in addr.province_list"
              :key="item.provinceid"
              :label="item.province"
              :value="item.provinceid"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="addr.city_list[0].id"
            placeholder="请选择市"
            @change="changeCity($event,0)"
          >
            <el-option
              v-for="item in addr.city_list"
              :key="item.cityid"
              :label="item.city"
              :value="item.cityid"
            >
            </el-option>
          </el-select>

          <i class="el-icon-delete" style="font-size: 30px;"></i>
        </div>

        <div class="applyJoin-input">
          <span>代理城市：</span>
          <el-select
            v-model="companyChannelAddrList[0].province_list[0].id"
            placeholder="请选择省"
            @change="changeProvince($event,0)"
          >
            <el-option
              v-for="item in companyChannelAddrList[0].province_list"
              :key="item.provinceid"
              :label="item.province"
              :value="item.provinceid"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="companyChannelAddrList[0].city_list[0].id"
            placeholder="请选择市"
            @change="changeCity($event,0)"
          >
            <el-option
              v-for="item in companyChannelAddrList[0].city_list"
              :key="item.cityid"
              :label="item.city"
              :value="item.cityid"
            >
            </el-option>
          </el-select>
        </div>
        <el-button @click="addCompanyChannelAddr">添加地区</el-button>
        <div class="applyJoin-input">
          <span>公司联系人：</span>
          <input v-model="mobile" type="text" />
        </div>
        <div class="applyJoin-input">
          <span>公司联系电话：</span>
          <input v-model="tel" type="number" />
        </div>
        <div class="applyJoin-input">
          <span>手机号码：</span>
          <input
            v-model="mobile"
            type="number"
            oninput="if(value.length > 11)value = value.slice(0, 11)"
            onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
          />
        </div>
        <div class="applyJoin-input">
          <span>微信号：</span>
          <input v-model="wechat_no" type="text" />
        </div>

        <button @click="test" class="applyJoin-btn">确定</button>
      </div>
    </div>
  </template>
  
  <script>
  import { reqCompanyChannelNav,reqProvinceList,reqCityList } from "@/api";
  export default {
    data() {
      return {
        channel_id:0,
        service_id:0,
        provinceList:[],
        cityList:[],
        serveNavList:[],
        serveSubNavList:[],
        province_name:'',
        city_name:'',
        realname:'',
        tel:'',
        mobile:'',
        company_name:'',
        wechat_no:'',
        memo:'',
        companyChannelAddrList:[
          {
            province_list:[
              {
                id:0,
                provinceid:0,
                province:'不限',
              },
            ],
            city_list:[
              {
                id:0,
                cityid:0,
                cityname:'不限'
              },
            ]
          }
        ]
      };
    },
    async mounted() {

        try {
          await this.requestChannel();
          await this.requestProvinceList();
        } catch (error) {
          this.$message.error(error);
        }
    },
    methods: {
      test(){

      },
      changeChannel(e){
          console.log(e);
          for(let i=0;i<this.serveNavList.length;i++){
              if(this.serveNavList[i].id == e){
                this.serveSubNavList = this.serveNavList[i].sons;
                if(this.serveSubNavList.length >=1){
                  this.service_id = this.serveSubNavList[0];
                }else{
                  this.serveSubNavList.push({
                    id:0,
                    title:'请选择'
                  });
                  this.service_id = 0;
                }
                
                break;
              }
          }
          
      },
      changeProvince(e,index){
        console.log('province:'+e+',index:'+index)
      },
      changeCity(e,index){
        console.log('province:'+e+',index:'+index)
      },
      addCompanyChannelAddr(){
        this.companyChannelAddrList.push(
          {
            province_list:[
              {
                id:0,
                provinceid:0,
                province:'不限',
              },
            ],
            city_list:[
              {
                id:0,
                cityid:0,
                cityname:'不限'
              },
            ]
          });
      },
      removeCompanyChannelAddr(i){
        this.companyChannelAddrList.splice(i,1);
      },
      async requestChannel(){
        let list = await reqCompanyChannelNav();
        if (list.code == 1) {
          this.serveNavList = list.data;
          if(this.serveNavList.length>=1){
            this.channel_id = this.serveNavList[0].id;
          }
          if (this.serveNavList.length > 0) {
            this.serveSubNavList = this.serveNavList[0].sons;
            this.service_id = this.serveNavList[0].id;

          }else{
            this.serveSubNavList.length = 0;
            this.serveSubNavList.push({
              id:0,
              title:'不限'
            });
            this.service_id = 0;
          }
        } else {
          this.$message.error(nav.message || "请求出错");
        }
      },
      async requestProvinceList(i){
        let List = await reqProvinceList();
        if (List.code == 1) {
          this.provinceList.length = 0;
          this.provinceList.push({id:0,provinceid:0,province:'请选择'});

          this.companyChannelAddrList[0].province_list.length = 0;
          this.companyChannelAddrList[0].province_list.push({id:0,provinceid:0,province:'请选择'});
          for(let i=0;i<List.data.length;i++){
            this.provinceList.push(List.data[i])
            
            this.companyChannelAddrList[0].province_list.push(List.data[i]);

          }
          this.cityList.length = 0;
          this.cityList.push({id:0,cityid:0,city:'请选择'});



        } else {
          this.$message.error(List.message || "省份信息请求出错");
        }
      },
      async requestCityList(i,provinceid){
        if(provinceid == 0){
          this.cityList.length = 0;
          this.cityList.push({id:0,cityid:0,city:'请选择'});
          this.city_id = 0;
          return;
        }
        let List = await reqCityList({province_id:provinceid});
        if (List.code == 1) {
          this.cityList.length = 0;
          this.cityList.push({id:0,cityid:0,city:'请选择'});
          for(let i=0;i<List.data.length;i++){
            this.cityList.push(List.data[i])
          }
        } else {
          this.$message.error(List.message || "城市信息请求出错");
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .applyjoin {
    width: 100%;
    height: 440rem;
    .applyJoin {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-title {
        margin: 25rem 0 40rem 0;
        font-size: 24rem;
        color: #333;
      }
      &-input {
        width: 415rem;
        margin-bottom: 20rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          font-size: 18rem;
          color: #999;
          white-space: nowrap; /*内容超宽后禁止换行显示*/
          text-overflow: ellipsis; /*文字超出部分以省略号显示*/
        }
        input {
          width: 260rem;
          height: 40rem;
          padding: 0 20rem;
          background-color: #f4f4f4;
          border: #e5e5e5 1px solid;
          border-radius: 2px;
          outline: none;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        &-code {
          width: 130rem !important;
        }
        &-btn {
          width: 114rem;
          height: 40rem;
          margin-left: 14rem;
          border: 1px solid #eee;
          background-color: rgba($color: #eee, $alpha: 1);
          border-radius: 2px;
          font-size: 17rem;
          color: #888;
          box-sizing: content-box;
          text-align: center;
          line-height: 40rem;
          white-space: nowrap;
          cursor: no-drop;
        }
        &-code::-webkit-outer-spin-button,
        &-code::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
      &-btn {
        width: 114rem;
        height: 37rem;
        background-color: #0982f9;
        border-radius: 6px;
        font-size: 17rem;
        color: #fff;
        text-align: center;
        line-height: 37rem;
        border: none;
        cursor: pointer;
      }
    }
  
    .modifyPsd {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        margin: 25rem 0 40rem 0;
        font-size: 24rem;
        color: #333;
      }
      &-input {
        width: 415rem;
        margin-bottom: 20rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          font-size: 18rem;
          color: #999;
          white-space: nowrap; /*内容超宽后禁止换行显示*/
          text-overflow: ellipsis; /*文字超出部分以省略号显示*/
        }
        input {
          width: 260rem;
          height: 40rem;
          padding: 0 20rem;
          background-color: #f4f4f4;
          border: #e5e5e5 1px solid;
          border-radius: 2px;
          outline: none;
        }
      }
      &-btn {
        width: 415rem;
        display: flex;
        justify-content: flex-end;
        button {
          width: 128rem;
          height: 40rem;
          background-color: #0982f9;
          border-radius: 6px;
          font-size: 17rem;
          color: #fff;
          text-align: center;
          line-height: 37rem;
          border: none;
          cursor: pointer;
        }
        button:last-child {
          margin-left: 45rem;
          background-color: #42a0fd;
        }
      }
    }
  }
  .telInput {
    background-color: #0982f9 !important;
    color: #fff !important;
    cursor: pointer !important;
  }
  </style>
  