<template>
  <div class="agent2_contact">
    <!-- 头部BG -->
    <div class="bg">
      <img class="bg_img" src="@/assets/agent/2/agent2_contact_BG.png" alt="" />
    </div>
    <!-- 公司介绍 -->
    <div class="introduce">
      <img
        class="introduce_img"
        src="@/assets/agent/2/agent2_display.png"
        alt=""
      />
      <div class="introduce_title">{{ company }}</div>
      <div class="introduce_content" v-html="introduce"></div>
    </div>
    <!-- 联系我们 -->
    <div class="contact">
      <div class="contact_title">专业成就行业品质</div>
      <div class="contact_box">
        <div class="contact_box_item">
          <div class="contact_box_item_qr">
            <img class="contact_box_item_qr_img" :src="qrcode1" alt="" />
          </div>
          <div class="contact_box_item_text">在线留言</div>
        </div>
        <div class="contact_box_item">
          <div class="contact_box_item_qr">
            <img class="contact_box_item_qr_img" :src="qrcode1" alt="" />
          </div>
          <div class="contact_box_item_text">客服服务</div>
        </div>
        <div class="contact_box_item">
          <div class="contact_box_item_qr">
            <img class="contact_box_item_qr_img" :src="qrcode2" alt="" />
            <!-- <div class="codeImg">二维码</div> -->
          </div>
          <div class="contact_box_item_text">微信帮助</div>
        </div>
      </div>
      <div class="contact_address">公司地址：{{ address }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
      ...mapState({
          email: (state) => state.global.agentSiteInfo.email,
          address: (state) => state.global.agentSiteInfo.address,
          phone: (state) => state.global.agentSiteInfo.phone,
          company: (state) => state.global.agentSiteInfo.company_name,
          introduce: (state) => state.global.agentSiteInfo.company_introduce,
          qrcode1: (state) => state.global.agentSiteInfo.qrcode1,
          qrcode2: (state) => state.global.agentSiteInfo.qrcode2,
      }),
    },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.agent2_contact {
  width: 100%;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 头部BG */
.bg {
  width: 100%;
}
.bg_img {
  display: block;
  width: 100%;
  object-fit: contain;
}
/* 公司介绍 */
.introduce {
  width: 1200px;
  padding-top: 53px;
  word-break: break-all;
}
.introduce_title {
  margin-bottom: 50px;
  font-size: 24px;
  color: #666;
  font-weight: bold;
}
.introduce_content {
  font-size: 16px;
  color: #666;
  line-height: 46px;
}
.introduce_img {
  float: right;
  width: 571px;
  margin: 0 0 30px 30px;
  object-fit: contain;
  display: block;
}
/* 联系我们 */
.contact {
  width: 1200px;
  padding: 150px 0 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact_title {
  margin-bottom: 70px;
  font-size: 32px;
  color: #00c8c8;
  font-weight: bold;
}
.contact_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact_box_item {
  width: 373px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact_box_item_qr {
  width: 373px;
  height: 373px;
  margin-bottom: 40px;
  background-color: #00c8c8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact_box_item_qr_img {
  width: 305px;
  height: 305px;
  object-fit: contain;
  display: block;
}
.contact_box_item_text {
  font-size: 20px;
  color: #666;
}
.contact_address {
  margin-top: 95px;
  font-size: 28px;
  color: #666;
}
.codeImg {
  width: 305px;
  height: 305px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
</style>
<style lang="scss">
.introduce_content {
  font-size: 14px;
  line-height: 30px;
  color: #999;
  white-space: pre-wrap;
}
.introduce_content p {
  font-size: 14px;
  line-height: 30px;
  color: #999;
}
</style>
