<template>
  <div class="chat">
    <!-- 聊天框头部，显示正在聊的人名 -->
    <div class="chat-header">
       <img src="../../../assets/page/Flogo.png" alt="">
      <span>{{ $store.state.chat.toName }}</span>
    </div>
    <div class="chat-aside-record">
      <div class="chat-aside" ref="chatAside">
        <div v-show="$store.state.chat.chatPersons.length == 0 && this.userType == 1">
          <p>暂时没有在线客服哦</p>
          <p>客服上班时间为</p>
          <p>工作日08:00-20:00</p>
        </div>
        <div v-show="$store.state.chat.chatPersons.length == 0 && this.userType == 2">
          <p>暂时无咨询用户</p>
        </div>
        <router-link v-for="(p, index) in $store.state.chat.chatPersons" :key="index" :to="{ routerLink }">
          <div class="chat-person" @click="selectChatUser(index, p)"
            :class="{ active: $store.state.chat.toId === p.toId }">
            <div class="person-container">
              <div class="person-img">
                <img src="../../../assets/backstage/protrait1.png" />
              </div>
              <div class="person-msg">
                <span class="person-name">{{ p.toName||p.mobile }}</span>
                <span :class="[ p.online ? 'online' : 'offline' ]"></span>
                <br />
                <span class="person-chatMsg" style="font-size:12px">({{ p.account }})</span>
                <span class="person-chatMsg">{{ p.content }}</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- 聊天记录框和发文字的框 -->
      <div class="chat-record-text">
        <!-- 聊天记录框 -->
        <div class="chat-record" ref="chatRecord" @scroll.passive="getScroll($event)">
          <ChatRecord></ChatRecord>
        </div>
        <!-- 发送文本框 -->
        <ChatMsgSend />
      </div>
    </div>
  </div>
</template>
<script>
import ChatMsgSend from "./ChatMsgSend";
import ChatRecord from "./ChatRecord";
import common from "../../../../common/common.js";
export default {
  data() {
    return {
      ws: null,
      // 点击的第几个
      idx: 1,
      userId: 0,
      avatar: "",
      account: "",
      username: "",
      userType: "",
      token: "",
      mapping: "message",
      //分页
      pageNum: 1,
      upId: 0,
      downId: 0,
      customerServiceId: 0,
    };
  },
  components: {
    ChatMsgSend,
    ChatRecord,
    // ChatPerson:ChatContacts,
  },
  created() {
    // .chatPersons
    // // console.log(
    //   this.$store.commit('chat/login')
    // );
    // this.myname = this.user.ca
    let userId = window.localStorage.getItem("id");
    let account = window.localStorage.getItem("account");
    let username = window.localStorage.getItem("username");
    let avatar = window.localStorage.getItem("avatar");
    let token = window.localStorage.getItem("token");
    let userType = window.localStorage.getItem("user_type");
    this.userId = userId;
    this.account = account;
    this.username = username;
    this.avatar = avatar;
    this.token = token;
    this.userType = userType;
    // alert(userId+'-------'+token)
    if (userId && token) {
      this.websocketConnect();
    }else{
      this.$router.push("/login");
    }
  },
  mounted() {
    // 控制滚动条到最底下
    let _this = this;
    this.$nextTick(() => {
      this.$refs.chatRecord.scrollTop = _this.$refs.chatRecord.scrollHeight;
    });

    _this.$bus.$on("sendText", (data) => {
      _this.$store.commit("chat/append_message", data);
      _this.$store.commit("chat/sort_message");
      _this.ws.send(JSON.stringify(data));
      _this.$nextTick(() => {
        _this.$refs.chatRecord.scrollTop = _this.$refs.chatRecord.scrollHeight;
      });
    });

    // this.websocketConnect();
  },
  computed: {
    routerLink: () => {
      return "/chat/record";
    },
  },
  methods: {
    //获取targetId
    getTargetId(){
      // debugger;
      let targetId = -1;
      if(this.$store.state.chat.chatMsgs == null||this.$store.state.chat.chatMsgs == undefined){
        return targetId;
      }
      let i=0;
      let len = this.$store.state.chat.chatMsgs.length;
      console.log("len:"+len);
      if(len >0){
        targetId = this.$store.state.chat.chatMsgs[0].id;
      }
      for(i = 0;i<len;i++){
        if(this.$store.state.chat.chatMsgs[i].id <= targetId){
          targetId = this.$store.state.chat.chatMsgs[i].id;
        }
      }
      console.log("targetId:"+targetId);

      return targetId;
    },
    getScroll() {
      // let scrollBottom =
      //     event.target.scrollHeight -
      //     event.target.scrollTop -
      //     event.target.clientHeight;
      // if ( scrollBottom < 0) {
      //     console.log("滚动到底部了");
      // }

      if (this.$refs.chatRecord.scrollTop == 0) {
        console.log("滚动到顶部了");
        let id = window.localStorage.getItem("id");
        let userType = window.localStorage.getItem("user_type");
        let userId = 0;
        let customerServiceId = 0;
        if (userType == 2) {
          //客服
          customerServiceId = id;
          userId = this.$store.state.toId;
        } else {
          customerServiceId = this.$store.state.toId;
          userId = id;
        }
        let targetId = this.getTargetId();
        let direction = "up";
        if (targetId == -1) {
          direction = "center";
        }
        //构造message-list消息体
        let messageListObj = {
          direction: direction,
          pageNum: 1,
          pageSize: 20,
          targetId: targetId,
          customerServiceId: customerServiceId,
          userId: userId,
          type: "message-list",
        };
        // this.$bus.$emit('sendText',messageListObj);
        if (this.ws != null) {
          this.ws.send(JSON.stringify(messageListObj));
        }
      }
    },
    async websocketConnect() {
      this.ws = new WebSocket(
        common.getWsUrl() + this.userId + "/" + this.token
      );
      console.log(this.ws);
      // 连接建立时（上线）执行函数
      this.ws.onopen = function () {
        console.log("");
      };
      // 接收消息时执行函数 离线消息：用一个离线标识一下数据，将离线数据保存在一个list中，等到用户上线标识出现了就再发过来
      this.ws.onmessage = (msg) => {
        console.log(msg);
        let dataStr = msg.data;
        let data = JSON.parse(dataStr);
        // console.log(data);
        switch (data.type) {
          case "chat-message":
            this.$message.success(
              "你收到一条来自" + data.content.fromName + "的新消息"
            );

            if (data.content.fromId == this.$store.state.chat.toId) {
              this.$store.commit("chat/append_message", data.content);
              this.$store.commit("chat/sort_message");
              this.$nextTick(() => {
                this.$refs.chatRecord.scrollTop =
                  this.$refs.chatRecord.scrollHeight;
              });
            } else {
              //在左侧列表中移除消息,并置顶新消息
              // this.$store.commit('chat/pop_chat_person',data.content);
            }
            //遍历左侧,找到fromId相同的，把最新消息换上去
            this.$store.commit("chat/replace_left_message", data.content);
            var flag = false;
            for (let i = 0; i < this.$store.state.chat.chatMsgs.length; i++) {
              if (this.$store.state.chat.chatMsgs[i].id == data.content.id) {
                flag = true;
              }
            }
            if (!flag) {
              var userType = window.localStorage.getItem("user_type");
              if (userType == 2) {
                //刷新右侧数据 var
                let recentlyChatUsersObj = {
                  type: "recently-chat-users",
                };
                if (this.ws != null) {
                  this.ws.send(JSON.stringify(recentlyChatUsersObj));
                }
              }
            }
            break;
          case "message-list":
            if (data.content.records instanceof Array) {
              this.$store.commit("chat/clear_message");
              for (let i = 0; i < data.content.records.length; i++) {
                this.$store.commit(
                  "chat/append_message",
                  data.content.records[i]
                );
              }
              this.$store.commit("chat/sort_message");
              // this.$refs.chatRecord.scrollTop = this.$refs.chatRecord.scrollHeight;
              this.$nextTick(() => {
                this.$refs.chatRecord.scrollTop =
                  this.$refs.chatRecord.scrollHeight;
              });
            }
            break; //在线客服列表
          case "online-customer-services": //users //动态语言，把缺少的属性加上就行了
            if (data.content instanceof Array) {
              for (let i = 0; i < data.content.length; i++) {
                data.content[i].toId = data.content[i].id;
                data.content[i].fromId = data.content[i].id;
                data.content[i].toName = data.content[i].username;
                data.content[i].toAvatar = data.content[i].avatar;
                data.content[i].online = true;
                data.content[i].content = "";
              }
              this.$store.commit("chat/refresh_chat_persons", data.content);
            } // refresh_chat_persons
            break; //最近有过沟通的用户，后端默认查询3天内有过聊天记录的用户
          case "recently-chat-users": //message
            if (data.content instanceof Array) {
              for (let i = 0; i < data.content.length; i++) {
                data.content[i].toId = data.content[i].fromId;
                data.content[i].toName = data.content[i].fromName;
                data.content[i].toAvatar = data.content[i].fromAvatar;
              }
              this.$store.commit("chat/refresh_chat_persons", data.content);
            }
            break; //异地登录 
            case 'kicked' :
            this.$message.error(data.content);
            setTimeout(() => {
              this.$store.commit("chat/logout");
              this.$router.push("/login");
            }, 1000);

            break;
          case "token-error":
            debugger;
            this.$message.error(data.content);
            setTimeout(() => {
              this.$store.commit("chat/logout");
              this.$router.push("/login");
            }, 1000);
            break;
        }
      };
      // 连接关闭时（下线）执行函数
      this.ws.onclose = function () {};

      this.ws.onerror = function (e) {
        console.log("错误是", e);
      };
    },
    closeWebsocket() {
      if (this.ws) {
        this.ws.close();
      }
    },
    selectChatUser(num, p) {
      //清空消息
      this.$store.commit("chat/clear_message");

      this.idx = num;
      this.$store.commit("chat/update_current_user", p);
      let userId = 0;
      let customerServiceId = 0;
      if (this.userType == 1) {
        userId = window.localStorage.getItem("id");
        customerServiceId = this.$store.state.chat.toId;
      } else {
        userId = this.$store.state.chat.toId;
        customerServiceId = window.localStorage.getItem("id");
      }
      this.userId = userId;
      this.customerServiceId = customerServiceId;
      let pageObj = {
        userId: userId,
        direction: "center",
        type: "message-list",
        userType: this.userType,
        pageNum: this.pageNum,
        customerServiceId: customerServiceId,
      };

      //从后端拿数据
      if(this.ws != null){
        this.ws.send(JSON.stringify(pageObj));
      }
    },
  },
};
</script>
<style>
/* 全局滚动条样式 */

/* 整个聊天框容器 */

.chat {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgb(121 146 185 / 54%);
  border-top: #d8d8d8 1px solid;
}

.chat-header {
  background-color: #fff;
  height: 10%;
  /* text-align: center; */
  color: #333;
  font-size: 14px;
  position: relative;
  border-bottom: #e9eaec 1px solid;
}
.chat-header img{
  width:  238px;
  height: 45px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.chat-header span {
  /* display: block; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chat-aside div{
 text-align: center;
 margin: 20px 0;
}
.chat-aside div p {
  font-size: 14px;
  color: #333;
  margin: 0;
  margin: 6px 0;
}
/* 左侧联系人-----------------------------------------start */

.chat-aside-record {
  display: flex;
  width: 100%;
  height: 90%;
}

.chat-aside {
  width: 33%;
  overflow: auto;
  background-color: #ffffff;
  height: 100%;
  border-right: #e9eaec 1px solid;
}

/* 左侧联系人 */

.chat-person {
  /* padding: 50px; */
  width: 100%;
  /* background-color: #333; */
  height: 70px;
  position: relative;
  border-bottom: #d8d8d8 1px solid;
}

/* 鼠标移入好友框 */

.chat-person:hover {
  background-color: #e4e5e6;
  cursor: pointer;
  /* #E4E5E6 */
  /* width: 100%; */
  /* height: 70px; */
}

/* 鼠标点击好友框 */

.chat-person.active {
  background-color: #e4e5e6;
}

.person-container {
  width: 100%;
  height: 70px;
  display: flex;
  /* position: absolute; */
  justify-content: center;
  align-items: center;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
}

/* 头像样式 */

.person-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* 人名儿和发送的最新信息 */

.person-msg {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 120px; */
  width: 70%;
  margin-left: 10px;
}

.person-name {
  font-size: 13px;
}

/* 发送的最新消息 */

.person-chatMsg {
  display: block;
  margin-top: 9px;
  font-size: 12px;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 左侧联系人-----------------------------------------end */
/* 聊天记录框-----------------------------------------start */
/* 包含聊天记录框和文字发送框的容器 */

.chat-record-text {
  /* display: inline-block; */
  width: 67%;
  height: 100%;
  background-color: #f4f5f7;
  /* box-shadow: 0 2px 4px 0 rgb(121 146 185 / 54%) */
}

/* 聊天记录框的容器 */

.chat-record {
  width: 100%;
  height: 77%;
  border-bottom: #d8d8d8 1px solid;
  overflow: scroll;
  /* background-color: chartreuse; */
}

/* 对方发给你的信息，包含对方头像和文字气泡 样式*/

.msg-somebody {
  display: flex;
  /* align-items: center; */
  margin: 20px 0 20px 25px;
  /* width: 100px; */
  /* height: 100px; */
  /* background-color: red; */
}

/* 对方的在聊天记录框的头像样式 */

.msg-somebody img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

/* 对方在聊天记录框的文字气泡 */

.msg-stext {
  /* width: 200px;
  height: 130px; */
  padding: 7px;
  min-width: 30px;
  max-width: 200px;
  margin-left: 10px;
  margin-top: 3px;
  background-color: #ffffff;
  color: #333333;
  text-align: justify;
  border-top-right-radius: 10%;
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* 对方在聊天记录框的文字 */
/* .msg-stext span {
  margin-left: 10px;
  } */
/* 自己发给对方的信息，包含自己头像和文字气泡 样式*/

.msg-me {
  display: flex;
  justify-content: flex-end;
  margin: 20px 25px 20px 0px;
}

/* 对方的在聊天记录框的头像样式 */

.msg-me img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

/* 对方在聊天记录框的文字气泡 */

.msg-mtext {
  /* width: 200px;
  height: 130px; */
  padding: 7px;
  min-width: 30px;
  max-width: 200px;
  margin-right: 10px;
  margin-top: 3px;
  background-color: #ffffff;
  color: #333333;
  text-align: justify;
  border-top-left-radius: 10%;
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* 聊天记录框-----------------------------------------end */
/* 发送文本框-----------------------------------------start */

/* 发送文本框-----------------------------------------end */

/* 去除router-link的下划线 */
.router-link-active {
  text-decoration: none;
  color: black;
}

a {
  text-decoration: none;
  color: black;
}

.online {
  float: right;
  border-width: 1px;
  border-style: solid;
  -moz-border-radius: 11px;
  -khtml-border-radius: 11px;
  -webkit-border-radius: 11px;
  border-radius: 11px;
  padding: 5px;
  background-color: rgb(0, 252, 0);
  color: rgb(0, 233, 0);
}

.offline {
  float: right;
  border-width: 1px;
  border-style: solid;
  -moz-border-radius: 11px;
  -khtml-border-radius: 11px;
  -webkit-border-radius: 11px;
  border-radius: 11px;
  padding: 5px;
  background-color: rgb(170, 170, 170);
  color: rgb(155, 155, 155);
}
</style>