<template>
  <div class="reviewFile">
    <div class="box" v-html="content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: ``,
    };
  },
  mounted() {
    // console.log(this.$route.query.data);
    this.content = this.$route.query.data;
    this.$bus.$on("previewURL", (data) => {
      // console.log(data, "fara");
      this.content = data;
    });
  },
  beforeDestory() {
    this.$bus.$off("previewURL");
  },
};
</script>

<style lang="scss">
.reviewFile {
  width: 100%;
  padding: 100rem 0;
  background-color: #fff;
  .box {
    width: 1100px;
    height: 1800px;
    padding: 50px;
    margin: 0 auto;
    background-color: #e2e2e2;
    border-radius: 10px;
  }
  .box p {
    margin-bottom: 10px;
    font-size: 16px;
    text-indent: 2em;
    display: flex;
    justify-content: flex-start;
  }
  .box p strong {
    font-size: 26px;
    text-indent: 0;
    font-weight: bold;
  }
}
</style>
