//对于axios进行二次封装
import axios from "axios";

// axios.defaults.crossDomain=true
//引入进度条
import nprogress from "nprogress";
//引入进度条样式
import "nprogress/nprogress.css";
//start：进度跳开始   done：进度条结束

//1.利用axios对象的方法create，去创建一个axios实例
const requests = axios.create({
  //配置对象
  //基础路径，发请求的时候，路径当中会出现api
  baseURL: "https://admin.mtuitui.com/api",
  // baseURL: "http://www.xcdl.com/api",
  //代表请求超时的时间5S
  timeout: 5000,
  timeoutErrorMessage: "请求超时",
  //请求头
  headers: {
    // Authorization: "c4ca4238a0b923820dcc509a6f75849b",
    Authorization: localStorage.token
  },
});

//请求拦截器：在发请求之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情
requests.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: localStorage.token, //设置跨域头部
    };
    //config：配置对象，对象里面有一个属性很重要，header请求头
    //进度条开始动
    nprogress.start();
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//响应拦截器
requests.interceptors.response.use(
  (res) => {
    //成功的回调函数，服务器相应数据回来以后，相应拦截器可以检测到，可以做一些事情
    //进度条结束
    nprogress.done();
    return res.data;
  },
  (error) => {
    //响应失败的回调函数
    return Promise.reject(new Error("faile",error));
  }
);

//对外暴露
export default requests;
