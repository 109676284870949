<template>
  <div class="netmedia">
    <!-- 排序方式 -->
    <div class="netmedia-filter">
      <span
        class="netmedia-filter-sel1"
        @click="congheSort"
        :class="{ seled1: zonghe }"
        style="margin-left: 70rem"
        >综合排序</span
      >
      <el-select
        class="netmedia-filter-sel"
        v-model="value1"
        placeholder="价格"
        @change="value1Sel"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        class="netmedia-filter-sel"
        v-model="value2"
        placeholder="权重"
        @change="value2Sel"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- <el-select
        class="netmedia-filter-sel"
        v-model="value3"
        placeholder="新闻收录率"
        @change="value3Sel"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        class="netmedia-filter-sel"
        v-model="value4"
        placeholder="网页收录率"
        @change="value4Sel"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        class="netmedia-filter-sel"
        v-model="value5"
        placeholder="出稿时间"
        @change="value5Sel"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <div class="netmedia-filter-search">
        <input type="text" v-model="searchText" />
        <div class="fill" @click="searchLike">搜索</div>
      </div>
    </div>
    <!-- 内容列表 -->
    <div class="netmedia-list">
      <el-empty
        class="empty"
        v-show="netmediaList.length <= 0 && !loading"
        image="https://www.mtuitui.com/onlineSource/contentEmpty.png"
        description="暂无媒体"
      ></el-empty>
      <div
        class="netmedia-list-item"
        v-for="(item, index) in netmediaList"
        :key="index"
      >
        <div class="netmedia-list-item-img">
          <img src="@/assets/mediaSource/netmediadisplay.png" alt="img" />
        </div>
        <div class="netmedia-list-item-title">
          <div class="netmedia-list-item-title-div">
            <el-tooltip
              effect="light"
              :content="item.name"
              placement="top-start"
            >
              <span class="titleSPan" @click="toAnli(item.url)">{{
                item.name
              }}</span>
            </el-tooltip>
            <img
              v-show="!item.collection"
              @click="shopCollectItem(item)"
              src="@/assets/mediaSource/icon-collect.png"
              alt="收藏"
            />
            <img
              v-show="item.collection"
              @click="shopCollectItem(item)"
              src="@/assets/mediaSource/icon-collected.png"
              alt="已收藏"
            />
          </div>
          <div class="netmedia-list-item-title-price">
            <span class="netmedia-list-item-title-price-putong">价格：</span>
            <span v-show="false" class="netmedia-list-item-title-price-noLogin"
              >登录可见底价</span
            >
            <span
              v-show="true"
              class="netmedia-list-item-title-price-putong"
              style="color: #f90044 !important"
              >￥</span
            >
            <span v-show="true" class="netmedia-list-item-title-price-Login">{{
              item.price
            }}</span>
          </div>
        </div>
        <div class="netmedia-list-item-detail">
          <span
            v-if="typeof item.url == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            style="cursor: pointer"
            @click="toAnli(item.url)"
            ><el-tooltip
              class="item"
              effect="dark"
              :content="item.url"
              placement="top-start"
            >
              <p class="itemp">案例链接</p>
            </el-tooltip>
          </span>
          <span
            v-if="typeof item.screen_one == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            >{{ item.screen_one }}</span
          >
          <span
            v-if="typeof item.screen_two == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            >{{ item.screen_two }}</span
          >
          <span
            v-if="typeof item.screen_three == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            >{{ item.screen_three }}</span
          >
          <span
            v-if="typeof item.screen_four == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            >{{ item.screen_four }}</span
          >
          <span
            v-if="typeof item.screen_five == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            >{{ item.screen_five }}</span
          >
          <span
            v-if="typeof item.screen_six == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            >{{ item.screen_six }}</span
          >
          <span
            v-if="typeof item.screen_seven == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            >{{ item.screen_seven }}</span
          >
          <span
            v-if="typeof item.screen_eight == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            >{{ item.screen_eight }}</span
          >
          <span
            v-if="typeof item.screen_nine == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            >{{ item.screen_nine }}</span
          >
          <span
            v-if="typeof item.screen_ten == 'undefined' ? false : true"
            class="netmedia-list-item-detail-180"
            >{{ item.screen_ten }}</span
          >
          <span
            v-if="typeof item.beizhu == 'undefined' ? false : true"
            class="netmedia-list-item-detail-740"
            >备注：{{ item.beizhu }}</span
          >
        </div>
        <div class="netmedia-list-item-btn">
          <img
            @click="shopCartNumAdd(item, index)"
            src="@/assets/mediaSource/icon-paidcar.png"
            alt=""
          />
          <div
            @click="shopCartNumAdd(item, index)"
            class="button"
            :class="{
              addCar: shopCar.some((goods) => {
                return goods.id == item.id && token;
              }),
            }"
          >
            {{
              shopCar.some((goods) => {
                return goods.id == item.id && token;
              })
                ? "移出购物车"
                : "加入购物车"
            }}
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="netmedia-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="pageChange"
        :currentPage="pagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { addCollection, delCollection } from "@/api";
export default {
  props: ["netmediaList1", "loading1"],
  computed: {
    ...mapState({
      shopCar: (state) => state.global.shopCar,
      token: (state) => state.global.token,
    }),
  },
  data() {
    return {
      pagination: 1,
      searchText: "",
      options: [
        {
          value: 1,
          label: "从大到小",
        },
        {
          value: 2,
          label: "从小到大",
        },
      ],
      zonghe: 1,
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      total: null,
      loading: false,
      netmediaList: [],
    };
  },
  watch: {
    netmediaList1(newValue) {
      this.netmediaList = newValue.data;
      this.total = newValue.total;
    },
    loading1(newValue) {
      this.loading = newValue;
    },
  },
  mounted() {},
  methods: {
    shopCollectItem(item) {
      if (!this.token) {
        this.$message({
          message: "请先登录",
          type: "error",
          duration: "1500",
        });
        this.$router.push("/login");
        return;
      }
      if (item.collection) {
        //已收藏取消收藏
        delCollection({ id: item.id }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            this.$emit(
              "fatherMethod",
              this.value1,
              this.value2,
              this.pagination,
              this.searchText
            );
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        //未收藏加入收藏
        addCollection({ id: item.id }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            this.$emit(
              "fatherMethod",
              this.value1,
              this.value2,
              this.pagination,
              this.searchText
            );
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    shopCartNumAdd(item) {
      if (
        this.shopCar.some((goods) => {
          return goods.id == item.id;
        })
      ) {
        this.$store.dispatch("global/shopCartNumReduce", item);
      } else {
        this.$store.dispatch("global/shopCartNumAdd", item);
      }
    },
    //搜索
    searchLike() {
      this.pagination = 1;
      this.$emit(
        "fatherMethod",
        this.value1,
        this.value2,
        this.pagination,
        this.searchText
      );
    },
    //案例
    toAnli(url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        this.$message.warning("暂无案例");
      }
    },
    //分页
    pageChange(e) {
      this.pagination = e;
      this.$emit(
        "fatherMethod",
        this.value1,
        this.value2,
        this.pagination,
        this.searchText
      );
    },
    //排序方式
    congheSort() {
      this.zonghe = 1;
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.value5 = "";
      this.pagination = 1;
      this.$emit(
        "fatherMethod",
        this.value1,
        this.value2,
        this.pagination,
        this.searchText
      );
    },
    value1Sel(e) {
      this.zonghe = 0;
      this.value1 = e;
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.value5 = "";
      this.pagination = 1;
      this.$emit(
        "fatherMethod",
        this.value1,
        this.value2,
        this.pagination,
        this.searchText
      );
    },
    value2Sel(e) {
      this.zonghe = 0;
      this.value1 = "";
      this.value2 = e;
      this.value3 = "";
      this.value4 = "";
      this.value5 = "";
      this.pagination = 1;
      this.$emit(
        "fatherMethod",
        this.value1,
        this.value2,
        this.pagination,
        this.searchText
      );
    },
    value3Sel(e) {
      this.value1 = "";
      this.value2 = "";
      this.value3 = e;
      this.value4 = "";
      this.value5 = "";
    },
    value4Sel(e) {
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = e;
      this.value5 = "";
    },
    value5Sel(e) {
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.value5 = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.netmedia {
  width: 100%;
  padding-bottom: 70rem;
  background-color: #fff;
  &-filter > div,
  &-filter > span {
    margin: 10px;
  }
  &-filter {
    width: 96%;
    // height: 64rem;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-wrap: wrap;
    &-sel1 {
      width: 110px;
      height: 40px;
      margin-left: 30rem;
      display: inline-block;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      font-size: 14rem;
      color: #bbb;
      line-height: 40px;
      text-align: center;
      transition: 0.2s linear;
      cursor: pointer;
    }
    .seled1 {
      border: 1px solid #409eff !important;
      color: #606266;
    }
    &-sel1:hover {
      border: 1px solid rgba($color: #999, $alpha: 0.6);
      transition: 0.2s linear;
    }
    &-sel {
      width: 120px;
      margin-left: 20rem;
    }
    &-search {
      width: 360rem;
      height: 40px;
      margin: 0 30rem;
      display: flex;
      input {
        width: 200rem;
        padding: 0 20rem;
        outline: none;
      }
      .fill {
        width: 120rem;
        height: 100%;
        background-color: rgba($color: #0982f9, $alpha: 1);
        font-size: 16rem;
        color: #fff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        transition: all 0.5s;
      }
      .fill,
      .fill:before {
        background: rgba(5, 118, 255, 1);
        background: -moz-linear-gradient(
          45deg,
          rgba(5, 118, 255, 1) 0%,
          rgb(19, 100, 250) 100%
        );
        background: -webkit-gradient(
          left bottom,
          right top,
          color-stop(0%, rgba(5, 118, 255, 1)),
          color-stop(100%, rgba(19, 100, 250, 1))
        );
        background: -webkit-linear-gradient(
          45deg,
          rgba(5, 118, 255, 1) 0%,
          rgba(19, 100, 250, 1) 100%
        );
        background: -o-linear-gradient(
          45deg,
          rgba(5, 118, 255, 1) 0%,
          rgba(19, 100, 250, 1) 100%
        );
        background: -ms-linear-gradient(
          45deg,
          rgba(5, 118, 255, 1) 0%,
          rgba(19, 100, 250, 1) 100%
        );
        background: linear-gradient(
          45deg,
          rgba(5, 118, 255, 1) 0%,
          rgba(19, 100, 250, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0576ff', endColorstr='#24f8ff', GradientType=1 );
      }
      .fill:hover {
        filter: hue-rotate(135deg);
      }
    }
  }
  &-list {
    width: 100%;
    // min-height: 130px;
    background-color: #fff;
    &-item {
      // width: 1360px;
      width: 96%;
      height: 110rem;
      margin: 0 auto;
      padding-top: 20rem;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      justify-content: space-around;
      // align-items: center;
      &-img {
        // width: 198px;
        width: 13%;
        height: 111rem;
        margin-right: 40rem;
        img {
          width: 100%;
          height: 100%;
          // object-fit: cover;
          object-fit: contain;
        }
      }
      &-title {
        // width: 180px;
        width: 15%;
        height: 105rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .titleSPan {
            font-size: 24rem;
            font-weight: bold;
            color: #333333;
            line-height: 36rem;
            cursor: pointer;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            /*! autoprefixer: off */
            -webkit-box-orient: vertical;
            /*! autoprefixer: on */
            word-break: break-all;
          }
          .titleSPan:hover {
            color: #0982f9;
          }
          img {
            margin-left: 8rem;
            width: 25rem;
            height: 23rem;
            cursor: pointer;
          }
        }
        &-price {
          display: flex;
          align-items: baseline !important;
          margin-bottom: 5rem;
          &-putong {
            font-size: 16rem;
            color: #666;
            // vertical-align: sub;
            white-space: nowrap;
          }
          &-noLogin {
            color: #0982f9;
            font-size: 16rem;
            cursor: pointer;
            white-space: nowrap;
          }
          &-Login {
            font-size: 28rem;
            color: #f90044;
            font-weight: bold;
            white-space: nowrap;
          }
        }
      }
      &-detail {
        width: 745rem;
        // width: 56%;
        height: 111rem;
        span {
          display: inline-block;
          padding-left: 5rem;
          margin-bottom: 12rem;
          font-size: 16rem;
          color: #999;
          white-space: nowrap; /*内容超宽后禁止换行显示*/
          overflow: hidden; /*超出部分隐藏*/
          text-overflow: ellipsis; /*文字超出部分以省略号显示*/
        }
        &-180 {
          width: 180rem;
        }
        &-740 {
          width: 740rem;
        }
      }
      &-btn {
        // width: 130px;
        width: 10%;
        height: 80rem;
        margin-top: 10rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        img {
          width: 30rem;
          height: 30rem;
          object-fit: cover;
          cursor: pointer;
        }
        .addCar {
          background-color: rgba($color: #0982f9, $alpha: 0.2) !important;
          color: #666 !important;
        }
        .button {
          width: 100rem;
          height: 30rem;
          border-radius: 4px;
          background-color: #0982f9;
          font-size: 16rem;
          color: #fff;
          line-height: 30rem;
          text-align: center;
          cursor: pointer;
          position: relative;
        }
        .button::before {
          position: absolute;
          content: "";
          left: -20px;
          right: -20px;
          top: -20px;
          bottom: -20px;
          pointer-events: none;
          transition: ease-in-out 0.5s;
          background-repeat: no-repeat;
          /* 按钮泡沫效果 */
          background-image: radial-gradient(
              circle,
              #0982f9 20%,
              transparent 20%
            ),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%);
          background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%,
            15% 15%, 10% 10%, 18% 18%, 15% 15%, 20% 20%, 18% 18%, 20% 20%,
            15% 15%, 10% 10%, 20% 20%;
          background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%,
            57% 30%, 65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%,
            41% 70%, 50% 70%, 64% 70%, 80% 71%;
          animation: bubbles ease-in-out 0.75s forwards;
          -webkit-animation: bubbles ease-in-out 0.75s forwards;
          -o-animation: bubbles ease-in-out 0.75s forwards;
          -moz-animation: bubbles ease-in-out 0.75s forwards;
        }

        .button:active {
          transform: scale(0.95);
          background-color: #0982f9;
          box-shadow: 0 2px 25px rgba(#0982f9, 0.5);
        }
        .button:active::before {
          animation: none;
          background-size: 0;
        }
        @keyframes bubbles {
          0% {
            background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%,
              57% 30%, 65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%,
              41% 70%, 50% 70%, 64% 70%, 80% 71%;
          }
          50% {
            background-position: 10% 44%, 0% 20%, 15% 5%, 30% 0%, 42% 0%,
              62% -2%, 75% 0%, 95% -2%, 0% 80%, 95% 55%, 7% 100%, 24% 100%,
              41% 100%, 55% 95%, 68% 96%, 95% 100%;
          }
          100% {
            background-position: 5% 44%, -5% 20%, 7% 5%, 23% 0%, 37% 0, 58% -2%,
              80% 0%, 100% -2%, -5% 80%, 100% 55%, 2% 100%, 23% 100%, 42% 100%,
              60% 95%, 70% 96%, 100% 100%;
            background-size: 0% 0%;
          }
        }
        @-webkit-keyframes bubbles {
          0% {
            background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%,
              57% 30%, 65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%,
              41% 70%, 50% 70%, 64% 70%, 80% 71%;
          }
          50% {
            background-position: 10% 44%, 0% 20%, 15% 5%, 30% 0%, 42% 0%,
              62% -2%, 75% 0%, 95% -2%, 0% 80%, 95% 55%, 7% 100%, 24% 100%,
              41% 100%, 55% 95%, 68% 96%, 95% 100%;
          }
          100% {
            background-position: 5% 44%, -5% 20%, 7% 5%, 23% 0%, 37% 0, 58% -2%,
              80% 0%, 100% -2%, -5% 80%, 100% 55%, 2% 100%, 23% 100%, 42% 100%,
              60% 95%, 70% 96%, 100% 100%;
            background-size: 0% 0%;
          }
        }
        @-o-keyframes bubbles {
          0% {
            background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%,
              57% 30%, 65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%,
              41% 70%, 50% 70%, 64% 70%, 80% 71%;
          }
          50% {
            background-position: 10% 44%, 0% 20%, 15% 5%, 30% 0%, 42% 0%,
              62% -2%, 75% 0%, 95% -2%, 0% 80%, 95% 55%, 7% 100%, 24% 100%,
              41% 100%, 55% 95%, 68% 96%, 95% 100%;
          }
          100% {
            background-position: 5% 44%, -5% 20%, 7% 5%, 23% 0%, 37% 0, 58% -2%,
              80% 0%, 100% -2%, -5% 80%, 100% 55%, 2% 100%, 23% 100%, 42% 100%,
              60% 95%, 70% 96%, 100% 100%;
            background-size: 0% 0%;
          }
        }
        @-moz-keyframes bubbles {
          0% {
            background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%,
              57% 30%, 65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%,
              41% 70%, 50% 70%, 64% 70%, 80% 71%;
          }
          50% {
            background-position: 10% 44%, 0% 20%, 15% 5%, 30% 0%, 42% 0%,
              62% -2%, 75% 0%, 95% -2%, 0% 80%, 95% 55%, 7% 100%, 24% 100%,
              41% 100%, 55% 95%, 68% 96%, 95% 100%;
          }
          100% {
            background-position: 5% 44%, -5% 20%, 7% 5%, 23% 0%, 37% 0, 58% -2%,
              80% 0%, 100% -2%, -5% 80%, 100% 55%, 2% 100%, 23% 100%, 42% 100%,
              60% 95%, 70% 96%, 100% 100%;
            background-size: 0% 0%;
          }
        }
      }
    }
  }
  &-pagination {
    width: 100%;
    margin-top: 40rem;
    text-align: center;
  }
}
.item {
  color: #0982f9 !important;
  cursor: pointer;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}
.itemp {
  width: fit-content;
}
.empty {
  margin: 50px 0;
}
</style>
