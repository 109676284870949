<template>
  <div
    class="onlineCharge"
    v-loading="loading"
    element-loading-text="支付状态确认中"
    element-loading-spinner="el-icon-loading"
  >
    <div class="chargeBox">
      <div class="chargeBox-balance">
        <span>当前余额：</span>
        <span>{{ userMessage.balance }}</span>
        <span>元</span>
        <span class="refreshYUE" @click="shuaxin">刷新</span>
      </div>
      <div class="chargeBox-type">
        <span>充值方式：</span>
        <div :class="{ divSeled: typeId == 0 }" @click="typeSel(0)">微信</div>
        <div :class="{ divSeled: typeId == 1 }" @click="typeSel(1)">支付宝</div>
        <div :class="{ divSeled: typeId == 2 }" @click="typeSel(2)">
          对公转账
        </div>
      </div>
      <div v-show="typeId != 2" class="chargeBox-amount">
        <span>充值金额：</span>
        <div
          v-for="(item, index) in amountList"
          :key="index"
          :class="{ divSeled: amountIndex == index }"
          @click="amountSel(index)"
        >
          {{ item.amount }}
        </div>
        <input
          :class="{ divSeled: amountIndex == 100 }"
          v-model="amountS"
          type="number"
          placeholder="自定义充值金额"
          oninput="if(value.length > 6)value = value.slice(0, 6)"
          onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
          @input="zidingyi"
          @click="amountSel(100)"
        />
      </div>
      <button v-show="typeId != 2" class="chargeBox-btn" @click="pay">
        立即支付
      </button>
      <div v-show="typeId == 2" class="chargeBox-bankCard">
        <div class="chargeBox-bankCard-item">
          <span>收款卡号：</span>
          <span>1126 2000 0007 0916 9</span>
        </div>
        <div class="chargeBox-bankCard-item">
          <span>收款户名：</span>
          <span class="same">重庆媒推推网络科技有限公司</span>
        </div>
        <div class="chargeBox-bankCard-item">
          <span>开户银行：</span>
          <span class="same">华夏银行股份有限公司重庆加州支行</span>
        </div>
        <div class="chargeBox-bankCard-tips">
          <span>*转账后请联系客服进行操作完成充值</span>
        </div>
      </div>
      <div v-show="typeId == 2" class="right">
        <div class="rightBox">
          <span>客服微信</span>
          <div class="wechatQR">
            <img
              v-image-preview
              src="@/assets/footer/mtuitui_kefu.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div v-html="content1"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  reqYue,
  reqwxpaySubmit,
  reqWxpayBalance,
  reqPayStatus,
  reqalipaySubmit,
  reqalipayBalance,
  reqAlipauCallback,
} from '@/api'
import QRCode from 'qrcode'
export default {
  inject: ['reloadFund', 'reloadBTop'],
  computed: {
    ...mapState({
      //右侧需要的是一个函数，当使用这个计算属性的时候，右侧函数会立即执行一次
      //注入一个参数state，其实即为大仓库中的数据
      userMessage: (state) => state.global.userMessage,
    }),
  },
  data() {
    return {
      orderAli: null,
      isAlipay: false,
      loading: false,
      content1: '',
      code: 0,
      amount: 100,
      amountS: '',

      typeId: 0,
      amountIndex: 0,
      main_order_id: 0,
      amountList: [
        {
          amount: 100,
        },
        {
          amount: 300,
        },
        {
          amount: 500,
        },
        {
          amount: 1000,
        },
        {
          amount: 3000,
        },
        {
          amount: 5000,
        },
      ],
    }
  },
  mounted() {
    this.$parent.fatherMethod(5, 0)
    window.addEventListener('visibilitychange', this.visibilityState)
  },
  beforeDestroy() {
    window.removeEventListener('visibilitychange', this.visibilityState)
  },
  methods: {
    shuaxin() {
      reqYue()
        .then((res) => {
          if (res.code == 1) {
            let userMsg = JSON.parse(localStorage.userMsg)
            userMsg.balance = res.data.balance
            userMsg.score = res.data.score

            localStorage.userMsg = JSON.stringify(userMsg)
            this.$store.dispatch('global/usermsglocal')
            this.reloadBTop()
            this.reloadFund()
          } else {
            this.$message.error(res.message || '请求失败')
          }
        })
        .catch((res) => {
          this.$message.error(res)
        })
    },
    typeSel(id) {
      this.typeId = id
    },
    amountSel(index) {
      this.amountIndex = index
      if (this.amountIndex == 100) {
        this.amount = Number(this.amountS)
      } else {
        this.amount = this.amountList[index].amount
      }
    },
    zidingyi() {
      this.amount = Number(this.amountS)
    },
    pay() {
      if (this.amount < 1 && this.amount > 5000) {
        alert('单次充值金额需不低于1元，不高于5000。')
        return
      }
      if (this.typeId == 0) {
        //Wechat
        // 生成二维码
        reqwxpaySubmit({ type: 2, price: this.amount })
          .then((res) => {
            if (res.code == 2) {
              this.main_order_id = res.data.main_order_id
              reqWxpayBalance({ main_order_id: this.main_order_id }).then(
                (res) => {
                  if (res.code == 1) {
                    let url = res.data.qrcode
                    let main_order_id = res.data.main_order_id
                    this.open(url, main_order_id)
                  }
                }
              )
            }
            // let url = res.data.qrcode;
            // let order = res.data.order;
            // this.open(url, order);
          })
          .catch((res) => {
            this.$message.error(res)
          })
      } else if (this.typeId == 1) {
        //Alipay
        //支付宝支付跳转页面
        reqalipaySubmit({ type: 2, price: this.amount }).then((res) => {
          if (res.code == 2) {
            this.main_order_id = res.data.main_order_id
            reqalipayBalance({ main_order_id: this.main_order_id })
              .then((res) => {
                if (!res) {
                  this.$message.error('无返回')
                  return
                }
                this.content1 = res
                setTimeout(() => {
                  document
                    .getElementsByName('alipaysubmit')[0]
                    .setAttribute('target', '_blank') // 新窗口打开
                  document.getElementsByName('alipaysubmit')[0].submit()
                  this.isAlipay = true
                }, 100)
              })
              .catch((res) => {
                this.$message.error(res)
              })
              .finally(() => {})
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.error('支付方式不存在')
      }
    },
    // 微信支付弹出框
    async open(u, o) {
      let url = await QRCode.toDataURL(u)
      this.$alert(`<img src=${url} />`, '请你微信支付', {
        dangerouslyUseHTMLString: true,
        // 中间布局
        center: true,
        // 是否显示取消按钮
        showCancelButton: true,
        // 取消按钮文本内容
        cancelButtonText: '支付遇见问题',
        // 确定按钮的文本内容
        confirmButtonText: '已支付',
        //右上角的叉子没了
        showClose: true,
        // 关闭前的回调
        beforeClose: (action, instance, done) => {
          // ation:区分取消|确认按钮 取消是cancel,确定是confirm
          // instance是本身实例
          // done是个函数,可以手动关闭弹出框
          if (action == 'cancel') {
            alert('有问题请联系客服或管理员')
            clearInterval(this.timer)
            this.timer = null
            // 关闭弹出框
            done()
          } else {
            // 判断是否真的支付了
            if (this.code == 200) {
              clearInterval(this.timer)
              this.timer = null
              done()
              //路由跳转
              this.$router.push('/backstage/fundMgt/dealRecord')
              this.$parent.fatherMethod(5, 1)
              setTimeout(() => {
                this.$message.success('充值成功')
              }, 200)
            } else {
              this.loading = true
              setTimeout(() => {
                this.loading = false
                if (this.code == 200) {
                  clearInterval(this.timer)
                  this.timer = null
                  done()
                  //路由跳转
                  this.$router.push('/backstage/orderMgt/orderQuery')
                  this.$parent.$emit('navSideSel', {
                    item1Index: 4,
                    item2Index: 0,
                  })
                  setTimeout(() => {
                    this.$message.success('支付成功')
                  }, 200)
                } else {
                  clearInterval(this.timer)
                  this.timer = null
                  done()
                  this.$message.error('支付失败，若已支付请刷新余额')
                }
              }, 1500)
            }
          }
        },
      }).catch(() => {}) //注意这里，这里是重点！！！;
      //你需要知道支付成功|失败
      //支付成功,路由跳转,如果失败,提示信息
      if (!this.timer) {
        this.timer = setInterval(async () => {
          // 发请求获取用户支付状态
          let result = await reqPayStatus({
            type: 1,
            main_order_id: o,
          })
          if (result.code == 200) {
            // 第一步清除定时器
            clearInterval(this.timer)
            this.timer = null
            // 保存支付返回状态的code
            this.code = result.code
            // 关闭弹出框
            this.$msgbox.close()
            // 跳转下一步的路由
            this.$router.push('/backstage/fundMgt/dealRecord')
            let userMsg = JSON.parse(localStorage.userMsg)
            userMsg.balance = res.data.balance //余额
            userMsg.score = res.data.score //积分
            localStorage.userMsg = JSON.stringify(userMsg)
            this.$store.dispatch('global/usermsglocal')
            this.reloadBTop()
            this.$parent.fatherMethod(5, 1)
            setTimeout(() => {
              this.$message.success('充值成功')
            }, 200)
          }
        }, 1000)
      }
    },
    //支付宝回调函数
    alipayCallback() {
      this.loading = true
      reqAlipauCallback({ type: 2, main_order_id: this.main_order_id })
        .then((res) => {
          if (res.code == 200) {
            this.isAlipay = false
            this.$parent.fatherMethod(5, 1)
            setTimeout(() => {
              this.$message.success('支付成功')
            }, 200)
          } else {
            this.$message.error(res.message + '若已支付请刷新余额')
          }
        })
        .catch((res) => {
          this.$message.error(res)
        })
        .finally(() => {
          this.loading = false
        })
    },
    //监听页面显示
    visibilityState() {
      if (document.visibilityState === 'visible' || event.persisted) {
        if (this.isAlipay) {
          this.$confirm('支付宝成功后请点击确定', '提示', {
            confirmButtonText: '已支付',
            nofirmButtonText: '取消',
          })
            .then(() => {
              this.alipayCallback()
            })
            .catch(() => {
              this.$message.warning('支付取消!')
              this.isAlipay = false
            })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.onlineCharge {
  width: 100%;
  height: 440rem;
  background-color: #fff;
  .chargeBox {
    width: 900rem;
    height: 100%;
    margin-left: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .right {
      width: 40%;
      height: 680px;
      position: absolute;
      right: -350px;
      top: 50px;
      .rightBox {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      span {
        margin-top: 60px;
        margin-bottom: 20px;
        font-size: 18rem;
        color: #999;
      }
      .wechatQR {
        width: 160px;
        height: 160px;
        img {
          width: 160px;
          height: 160px;
          object-fit: contain;
        }
      }
    }
    span {
      font-size: 18rem;
      color: #999;
    }
    &-balance,
    &-type,
    &-amount {
      width: 100%;
      margin-bottom: 20rem;
      display: flex;
      align-items: center;
    }
    &-balance {
      margin: 35rem 0 40rem;
      align-items: flex-end;
    }
    &-balance span {
      color: #666;
    }
    &-balance span:nth-child(2) {
      font-size: 20rem;
      font-weight: bold;
      color: #ec0e0e;
    }
    &-balance span:nth-child(3) {
      margin-left: 4rem;
      margin-bottom: 2rem;
      font-size: 14rem;
    }
    &-type div {
      width: 114rem;
      height: 40rem;
      margin-right: 10rem;
      border-radius: 4px;
      border: 1px solid #e2e2e2;
      font-size: 18rem;
      color: #999;
      text-align: center;
      line-height: 40rem;
      cursor: pointer;
    }
    &-type div:hover {
      border: 1px solid #42a0fd;
    }
    &-amount div {
      width: 76rem;
      height: 30rem;
      margin-right: 10rem;
      border-radius: 4px;
      border: 1px solid #e2e2e2;
      font-size: 16rem;
      color: #999;
      text-align: center;
      line-height: 30rem;
      cursor: pointer;
    }
    &-amount div:hover {
      border: 1px solid #42a0fd;
    }
    &-amount input {
      width: 160rem;
      height: 30rem;
      margin-right: 10rem;
      border-radius: 4px;
      border: 1px solid #e2e2e2;
      font-size: 16rem;
      color: #999;
      text-align: center;
      line-height: 30rem;
      outline: none;
    }
    &-amount input:hover {
      border: 1px solid #42a0fd;
    }
    // &-amount input:focus {
    //   background-color: #42a0fd;
    //   border: #42a0fd 1px solid;
    //   color: #fff;
    // }
    &-amount input:focus::placeholder {
      color: #42a0fd;
    }
    &-amount input::-webkit-outer-spin-button,
    &-amount input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .divSeled {
      background-color: #42a0fd;
      border: #42a0fd 1px solid;
      color: #fff;
    }
    .divSeled::placeholder {
      color: #fff;
    }
    &-btn {
      width: 114rem;
      height: 37rem;
      margin-top: 50rem;
      background-color: #0982f9;
      border-radius: 4px;
      border: none;
      font-size: 17rem;
      color: #fff;
      text-align: center;
      line-height: 37rem;
      cursor: pointer;
    }
    &-bankCard {
      width: 100%;
      margin-bottom: 20rem;
      align-items: flex-start;
      &-item {
        margin: 15px 0 0;
        display: flex;
        align-items: baseline;
        .same {
          font-size: 16px !important;
        }
        span:first-child {
          font-size: 16px;
        }
        span:last-child {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 22px;
          color: #0982f9;
          letter-spacing: 1px;
        }
      }
      &-tips {
        margin: 40rem 50px 0;
        span {
          font-size: 14px;
          color: #ec0e0e;
          letter-spacing: 1px;
        }
      }
    }
  }
}
.refreshYUE {
  margin-left: 15rem;
  font-size: 14rem !important;
  color: #1b8eff !important;
  cursor: pointer;
}
.refreshYUE:hover {
  color: #0982f9 !important;
}
</style>
