<template>
  <div class="knowledge">
    <!-- 导航栏填充 -->
    <div class="navBack"></div>
    <!-- 知识学院上方图块 -->
    <div class="title">
      <div
        class="title-left"
        v-loading="loadingTop"
        @click="goDetail(contentTopList[0].id)"
      >
        <img :src="contentTopList[0].img_url" alt="" />
        <span>{{ contentTopList[0].title }}</span>
      </div>
      <div class="title-mid" v-loading="loadingTop">
        <div @click="goDetail(contentTopList[1].id)">
          <img :src="contentTopList[1].img_url" alt="" />
          <span>{{ contentTopList[1].title }}</span>
        </div>
        <div @click="goDetail(contentTopList[2].id)">
          <img :src="contentTopList[2].img_url" alt="" />
          <span>{{ contentTopList[2].title }}</span>
        </div>
      </div>
      <div class="title-right">
        <img class="title-right-img" src="./image/title-right.png" alt="登录" />
        <div class="title-right-login" v-if="!token">
          <span>登录</span>
          <input
            type="text"
            oninput="value = value.replace(/\s+/g,'')"
            placeholder="请输入您的账号"
            v-model="userName"
            maxlength="11"
          />
          <input
            v-show="loginType"
            v-model="password"
            type="password"
            oninput="value = value.replace(/\s+/g,'')"
            placeholder="请输入的您的密码"
            maxlength="20"
          />
          <div v-show="!loginType" class="title-right-login-code">
            <input
              type="text"
              oninput="value = value.replace(/\s+/g,'')"
              placeholder="请输入验证码"
              v-model="code"
              maxlength="4"
            />
            <div
              class="title-right-login-code-codeBtn"
              id="codeTel"
              @click="sendTelCode"
            >
              获取验证码
            </div>
          </div>
          <div class="title-right-login-forget">
            <span
              class="title-right-login-forget-text login"
              @click="loginChange"
              >{{ loginType ? '手机登录' : '密码登录' }}</span
            >
            <span
              v-show="loginType"
              class="title-right-login-forget-text forget"
              @click="forgetPsd"
              >忘记密码?</span
            >
          </div>
          <button @click="login">登录</button>
          <span @click="goLogin(0)">立即注册</span>
        </div>

        <div v-if="token" class="title-right-logined">
          <img
            v-if="userMessage.tel != 18227984284"
            class="title-right-logined-portrait"
            :src="require('@/assets/backstage/portrait/' + portrait)"
            alt=""
          />
          <video
            v-if="userMessage.tel == 18227984284"
            :src="portraitH"
            class="title-right-logined-portrait"
            autoplay
            loop
            muted
          ></video>
          <span class="title-right-logined-nickname">{{ nickname }}</span>
          <img
            @click="goVip"
            v-show="isVip"
            class="title-right-logined-vipp"
            src="@/assets/backstage/Vip.png"
            alt=""
          />

          <div class="title-right-logined-vip" @click="goVip" v-show="!isVip">
            <img src="@/assets/backstage/Vip_none.png" alt="" />
            <span>开通会员</span>
          </div>
          <span class="title-right-logined-item">余额：{{ balance }}元</span>
          <span class="title-right-logined-item">积分：{{ score }}</span>
          <div
            class="title-right-logined-charge btn-15"
            @click="goOnlineCharge"
          >
            立即充值
          </div>
        </div>
      </div>
    </div>
    <!-- 下方内容 -->
    <div class="content">
      <!-- 下方左侧内容 -->
      <div class="content-left">
        <!-- 左侧列表导航 -->
        <div class="content-left-nav">
          <span
            class="content-left-nav-item"
            :class="{
              'content-left-nav-itemOutLine': item.id == contentNavId,
            }"
            @click="contentNavSel(item)"
            v-for="(item, index) in contentNavList"
            :key="index"
            >{{ item.title }}</span
          >
        </div>
        <!-- 左侧详细列表 -->
        <div class="content-left-text" v-loading="loadingBot" v-if="!showBot">
          <el-empty
            v-show="contentTextList.length <= 0 && !loadingBot"
            description=""
          ></el-empty>
          <div
            class="content-left-text-item"
            v-for="(item, index) in contentTextList"
            :key="index"
          >
            <div class="content-left-text-item-img">
              <img
                :src="item.img_url"
                :alt="item.title"
                @click="goDetail(item.id)"
                :onerror="defaultImg"
              />
            </div>
            <div class="content-left-text-item-detail">
              <h2 @click="goDetail(item.id)">{{ item.title }}</h2>
              <p @click="goDetail(item.id)">{{ item.description }}</p>
              <div class="content-left-text-item-detail-bot">
                <button @click="goDetail(item.id)">查看详情</button>
                <span>{{ item.update_time }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 左侧列表分页 -->
        <div class="content-left-pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :pageSize="6"
            :currentPage="page"
            @current-change="pageChange"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 下方右侧内容 -->
      <div class="content-right">
        <Articles class="articles"></Articles>
        <Media class="medias"></Media>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  loginPost,
  loginCodePost,
  reqTenxun,
  reqArticleNav,
  reqArticleList,
} from '@/api'
import { telFormation } from '@/utils/judge'
import Articles from './Article/index.vue'
import Media from './Media/index.vue'
// import { set } from "vue";
export default {
  inject: ['reload'],
  components: {
    Articles,
    Media,
  },
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
      token: (state) => state.global.token,
      navBackColor: (state) => state.global.navBackColor,
    }),
  },
  data() {
    return {
      showBot: false,
      loadingTop: false,
      loadingBot: false,
      page: 1,
      total: null,

      portrait: localStorage.portrait || 'protrait_exm.png',
      portraitH:
        localStorage.portraitH ||
        'https://www.mtuitui.com/onlineSource/portrait10.mp4',
      userName: '',
      password: '',
      code: '',
      codeTime: 0,

      nickname: '',
      isVip: '',
      balance: '',
      score: '',

      loginType: true,
      defaultImg: `this.src = '${require('@/assets/baidu.jpg')}'`,
      contentTopList: [
        {
          description: '',
          id: '',
          img_url: 'https://www.mtuitui.com/onlineSource/title-left.png',
          thumb_image_id: '',
          title: '',
          update_time: '',
        },
        {
          description: '',
          id: '',
          img_url: 'https://www.mtuitui.com/onlineSource/title-midtop.png',
          thumb_image_id: '',
          title: '',
          update_time: '',
        },
        {
          description: '',
          id: '',
          img_url: 'https://www.mtuitui.com/onlineSource/title-midbot.png',
          thumb_image_id: '',
          title: '',
          update_time: '',
        },
      ],
      contentNavId: 1065,
      contentNavList: [],
      contentTextList: [],
    }
  },
  mounted() {
    this.loadingTop = true
    this.loadingBot = true
    reqArticleNav()
      .then((res) => {
        if (res.code == 1) {
          this.contentNavList = res.data
        } else {
          // this.$message.error(res.message);
          console.log(res.message)
        }
      })
      .catch((res) => {
        this.$message.error(res)
      })
    this.reqList()
    //上方三个
    reqArticleList({ type: 1 })
      .then((res) => {
        if (res.code == 1) {
          if (res.data.data.length >= 3) {
            this.contentTopList = res.data.data.splice(0, 3)
          }
        } else {
          // this.$message.error(res.message);
          console.log(res.message)
        }
      })
      .catch((res) => {
        this.$message.error(res)
      })
      .finally(() => {
        this.loadingTop = false
      })
    //获取全局用户信息
    this.$nextTick(() => {
      this.nickname = this.userMessage.nickname
      this.isVip = this.userMessage.vip
      this.balance = this.userMessage.balance
      this.score = this.userMessage.score
    })
  },
  methods: {
    //发送验证码
    sendTelCode() {
      if (!this.userName) {
        this.$message.error('请输入手机账号')
        return
      }
      if (!telFormation(this.userName)) {
        this.$message.error('请检查手机号格式')
        return
      }
      if (this.codeTime != 0) return
      this.codeTime = 60
      document.getElementById('codeTel').innerHTML =
        this.codeTime + 's后重新发送'
      var interval = setInterval(() => {
        this.codeTime -= 1
        document.getElementById('codeTel').innerHTML =
          this.codeTime + 's后重新发送'
        if (this.codeTime == 0) {
          clearInterval(interval)
          document.getElementById('codeTel').innerHTML = '获取验证码'
        }
      }, 1000)
      reqTenxun({ mobile: this.userName })
        .then((res) => {
          if (res.result == 0) {
            this.$message.success('验证码已发送')
          } else {
            this.$message.error('验证码请求失败')
          }
        })
        .catch((res) => {
          this.$message.error(res)
        })
    },
    //Nav点击
    contentNavSel(item) {
      this.contentNavId = item.id
      this.loadingBot = true
      this.page = 1
      this.showBot = true
      this.showBot = false
      this.reqList(item.id)
    },
    //跳转文章详情页
    goDetail(id) {
      if (id) {
        this.$router.push({
          name: 'knowledgein',
          params: { KnowledgeId: id + '.html' },
        })
        if (this.$route.path.substring(0, 12) == '/KnowledgeIn') {
          this.reload()
        }
      }
    },
    //翻页
    pageChange(e) {
      this.page = e
      this.loadingBot = true
      this.reqList(this.contentNavId, '', e)
    },
    //登录
    login() {
      /**
       * 你写的什么垃圾代码，比屎山还要屎。 还有脸写这些？ 你配吗？？？
       */
      //setPortrait
      // if (this.userName == "18227984284") {
      //   localStorage.portrait = "portrait10.png";
      //   localStorage.portraitH =
      //     "https://www.mtuitui.com/onlineSource/portrait10.mp4";
      // }
      // if (this.userName == 15023607481) {
      //   localStorage.portraitHS1 =
      //     "https://www.mtuitui.com/onlineSource/gif10.gif";
      // }
      // if (this.userName == 17723046355) {
      //   localStorage.portraitHS2 =
      //     "https://www.mtuitui.com/onlineSource/gif11.gif";
      // }
      if (this.loginType) {
        if (!this.userName) {
          this.$message.error('请输入账号')
          return
        }
        if (!this.password) {
          this.$message.error('请输入密码')
          return
        }
        loginPost({ username: this.userName, password: this.password })
          .then((res) => {
            let temp = res.data[0]
            if (res.code == 1) {
              //保存信息
              let userMsg = {
                uid: temp.id,
                nickname: temp.nickname,
                vip: true,
                tel: temp.mobile,
                email: temp.email,
                balance: temp.balance,
                score: 7777,
                type: temp.type,
              }
              this.$store.dispatch('global/userMessageModify', userMsg)
              //登陆成功
              localStorage.token = temp.token
              localStorage.userMsg = JSON.stringify(userMsg)
              this.$store.dispatch('global/tokenlocal')
              this.$store.dispatch('global/usermsglocal')
              this.$store.dispatch('global/shopcarlocal')
            } else {
              localStorage.token = ''
              this.$message.error(res.message)
            }
            this.password = ''
            this.reload()
          })
          .catch(() => {
            localStorage.token = ''
            this.$message.error('登录出错')
            this.password = ''
          })
      } else {
        //验证码登录
        if (!this.userName) {
          this.$message.error('请输入手机号')
          return
        }
        if (!this.code) {
          this.$message.error('请输入验证码')
          return
        }
        let data = {
          phone: this.userName,
          code: this.code,
        }
        loginCodePost(data)
          .then((res) => {
            if (res.code == 1) {
              let temp = res.data
              //保存信息
              let userMsg = {
                uid: temp.id,
                nickname: temp.nickname,
                vip: true,
                tel: temp.mobile,
                email: temp.email,
                balance: Number(temp.balance),
                score: 0,
                type: temp.type,
              }
              this.$store.dispatch('global/userMessageModify', userMsg)
              //登陆成功
              localStorage.token = temp.token
              localStorage.userMsg = JSON.stringify(userMsg)
              this.$router.push('/')
              setTimeout(() => {
                let routeUrl = this.$router.resolve({
                  path: '/backstage',
                })
                window.open(routeUrl.href, '_blank')
              }, 500)
            } else {
              this.$message.warning(res.message)
            }
          })
          .catch((res) => {
            this.$message.error(res)
          })
      }
    },
    //切换登录方式
    loginChange() {
      this.loginType = !this.loginType
    },
    //登录注册页跳转
    goLogin(lorr) {
      this.$router.push({
        name: 'login',
        params: { lorr: lorr },
      })
    },
    //忘记密码
    forgetPsd() {
      this.$router.push('/forgetPsd')
    },
    //会员
    goVip() {
      // this.isVip = !this.isVip; //......
    },
    //跳转充值
    goOnlineCharge() {
      this.$router.push('/backstage/fundMgt/onlineCharge')
      this.$parent.$emit('navSideSel', { item1Index: 5, item2Index: 0 })
    },

    //接口方法
    //请求文章列表
    reqList(id = 1065, type = null, page = 1) {
      let reqData = {
        type_id: id,
        type: type,
        page: page,
      }
      reqArticleList(reqData)
        .then((res) => {
          if (res.code == 1) {
            this.contentTextList = res.data.data
            this.total = res.data.total
          } else {
            // this.$message.error(res.message);
            console.log(res.message)
          }
        })
        .catch((res) => {
          this.$message.error(res)
        })
        .finally(() => {
          this.loadingBot = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.knowledge {
  cursor: default;
  width: 100%;
  background-color: #f4f4f4;
  padding: 0 0 380rem;

  .navBack {
    width: 100%;
    height: 70px;
    background-color: #0982f9;
  }
  .title {
    width: 1430rem;
    height: 410rem;
    margin: 37rem auto 0;
    display: flex;
    &-left {
      width: 600rem;
      height: 100%;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      span {
        width: 540rem;
        position: absolute;
        bottom: 23rem;
        left: 30rem;
        color: #fff;
        font-size: 26rem;
        z-index: 88;
      }
    }
    &-mid {
      width: 420rem;
      height: 100%;
      margin: 0 20rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div {
        width: 420rem;
        height: 195rem;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }
        span {
          width: 400rem;
          position: absolute;
          bottom: 11rem;
          left: 10rem;
          color: #fff;
          z-index: 88;
          font-size: 20rem;
        }
      }
    }
    &-right {
      width: 370rem;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &-login > span:first-child {
        margin: 25rem 190rem 20rem 30rem;
        font-size: 26rem;
        color: #333;
      }
      &-login > span:last-child {
        text-align: center;
        font-size: 12rem;
        color: #999;
        cursor: pointer;
      }
      &-login > span:last-child:hover {
        color: #0982f9;
      }
      &-login {
        position: relative;
        z-index: 1;
        width: 278rem;
        height: 334rem;
        background-color: #fff;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        input {
          outline: none;
          width: 208rem;
          height: 15rem;
          padding: 12rem 6rem;
          margin: 0 auto 13rem;
          background-color: #f3f3f3;
          border: none;
          border-radius: 6px;
          font-size: 14rem;
        }
        input::placeholder {
          color: #999999;
          font-size: 14rem;
        }
        &-code {
          width: 220rem;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          input {
            width: 120rem;
            margin: 0 0 13rem;
          }
          &-codeBtn {
            width: 80rem;
            height: 39rem;
            font-size: 14rem;
            color: #fff;
            text-align: center;
            line-height: 39rem;
            background: #7f7fd5; /* fallback for old browsers */
            background: -webkit-linear-gradient(
              to right,
              #86a8e7,
              #7f7fd5
            ); /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(
              to right,
              #86a8e7,
              #7f7fd5
            ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            border-radius: 6px;
            cursor: pointer;
          }
        }
        &-forget {
          width: 220rem;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          &-text {
            font-size: 12rem;
            color: #999;
            cursor: pointer;
          }
          &-text:hover {
            color: #0982f9;
          }
        }
        button {
          width: 167rem;
          height: 39rem;
          margin: 39rem auto 10rem;
          font-size: 20rem;
          color: #fff;
          text-align: center;
          line-height: 39rem;
          background: linear-gradient(-90deg, #b83af3, #6950fb);
          border: none;
          border-radius: 8px;
          cursor: pointer;
        }
      }
      &-logined {
        position: relative;
        z-index: 1;
        width: 278rem;
        height: 334rem;
        background-color: #fff;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-portrait {
          margin: 40rem 0 10rem;
          width: 50rem;
          height: 50rem;
          object-fit: contain;
          border-radius: 50%;
        }
        &-nickname {
          font-size: 24rem;
          color: #666;
        }
        &-vipp {
          margin: 0 0 20rem;
          width: 30rem;
          height: 30rem;
          object-fit: contain;
        }
        &-vip {
          // width: 96rem;
          height: 30rem;
          margin: 0 0 20rem;
          padding: 0 10rem;
          border: 1px solid #e2e2e2;
          border-radius: 6px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          cursor: pointer;
          img {
            width: 20rem;
            height: 20rem;
            object-fit: contain;
          }
          span {
            font-size: 14rem;
            color: #999;
            margin-left: 4rem;
          }
        }
        &-item {
          margin: 0 0 10rem 0;
          span {
            font-size: 16rem;
            color: #666;
          }
        }
        &-charge {
          width: 106rem;
          height: 29rem;
          margin: 20rem 0;
          border-radius: 6px;
          background: linear-gradient(#71b6fa, #0982f9);
          font-size: 16rem;
          color: #fff;
          text-align: center;
          line-height: 29rem;
          cursor: pointer;
        }
      }
      &-img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .content {
    width: 1430rem;
    // height: 1510rem;
    margin: 40rem auto 0;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    &-left {
      width: 1020rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-nav {
        width: 100%;
        height: 60rem;
        margin-bottom: 20rem;
        background-color: #fff;
        display: flex;
        justify-content: space-around;
        &-item {
          display: inline-block;
          width: 146rem;
          height: 17rem;
          padding: 19rem 0;
          font-size: 22rem;
          text-align: center;
          line-height: 22rem;
          color: #333;
          cursor: pointer;
        }
        &-itemOutLine {
          border-bottom: 5px solid #0982f9;
        }
      }
      &-text {
        width: 100%;
        max-height: 1345rem;
        padding: 65rem 0 20rem;
        background-color: #fff;
        &-item {
          width: 936rem;
          height: 200rem;
          margin: 0 auto 22rem;
          display: flex;
          justify-content: space-between;
          &-img {
            width: 300rem;
            height: 200rem;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: 0.3s ease-out;
            }
            img:hover {
              transform: scale(1.03);
              transition: 0.3s ease-out;
            }
          }
          &-detail {
            width: 605rem;
            height: 190rem;
            padding-bottom: 10rem;
            border-bottom: #f3f3f3 0.5px solid;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h2 {
              font-size: 24rem;
              font-weight: bold;
              color: #333;
              cursor: pointer;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            h2:hover {
              width: 605rem;
              color: #0982f9;
            }
            p {
              font-size: 16rem;
              color: #666;
              letter-spacing: 1px;
              cursor: pointer;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              line-height: 1.8;
              text-indent: 2em;
              /* autoprefixer: ignore next */
              -webkit-box-orient: vertical;
            }
            p:hover {
              color: #0982f9;
            }
            &-bot {
              width: 605rem;
              height: 35rem;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              button {
                width: 100rem;
                height: 35rem;
                border: #f3f3f3 1px solid;
                font-size: 16rem;
                color: #999;
                line-height: 20rem;
                text-align: center;
                background-color: rgba($color: #000000, $alpha: 0);
                cursor: pointer;
              }
              button:hover {
                color: #eee;
                background-color: rgba($color: #0982f9, $alpha: 0.7);
                border: 1px solid #bbb;
              }
              button:active {
                color: #fff;
                background-color: rgba($color: #0982f9, $alpha: 1);
                border: 1px solid #bbb;
              }
              span {
                font-size: 14rem;
                color: #999;
              }
            }
          }
        }
      }
      &-pagination {
        margin-top: 40rem;
      }
    }
    &-right {
      width: 390rem;
      height: 100%;
      .articles {
        width: 390rem;
      }
      .medias {
        width: 385rem;
        margin-top: 22rem;
      }
    }
  }
}

/* 15 */
.btn-15 {
  // background: #b621fe;
  border: none;
  z-index: 1;
  position: relative;
}
.btn-15:after {
  position: absolute;
  content: '';
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #0982f9;
  border-radius: 5px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.btn-15:hover {
  color: #fff;
}
.btn-15:hover:after {
  left: 0;
  width: 100%;
}
.btn-15:active {
  top: 2px;
}
</style>
