<template>
  <div class="cooperation">
    <div
      class="popup"
      v-show="sloading"
      v-loading="sloading"
      element-loading-text="提交中"
    ></div>
    <div class="banner">
      <img class="banner-bg" src="@/assets/cooperation/cooBG.png" alt="" />
      <div class="banner_title">合作加盟</div>
      <div class="banner_text">
        成熟的建站技术和庞大的媒体库存支持，打通行业信息差的壁垒，让广告主省钱，媒体主挣钱！
      </div>
    </div>
    <div class="c-content">
      <div class="c-item">
        <div class="c-title">OEM合作优势</div>
        <div class="c-box">
          <div class="c1">
            <div class="c1-item" v-for="(item, index) in c1List" :key="index">
              <img
                class="c1-item-img"
                :src="require('@/assets/cooperation/' + item.imgbg)"
                alt=""
              />
              <div class="c1-item-title">{{ item.title }}</div>
              <div class="c1-item-text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-item">
        <div class="c-title">代理商优势</div>
        <div class="c-box">
          <div class="c2">
            <div class="c2-w"></div>
            <div class="c2-b"></div>
            <div class="c2-nav">
              <div
                class="c2-nav-item"
                @click="c2Click(item)"
                v-for="(item, index) in c2List"
                :key="index"
              >
                <div
                  class="c2-nav-item-w"
                  :class="[item.id == c2Id ? 'c2-nav-item-wSeled' : '']"
                ></div>
                <div
                  class="c2-nav-item-b"
                  :class="[item.id == c2Id ? 'c2-nav-item-bSeled' : '']"
                ></div>
                <div
                  class="c2-nav-item-text"
                  :class="[item.id == c2Id ? 'textcolor' : '']"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
            <div class="c2-content">
              <img
                id="c2-1"
                class="c2-content-icon"
                src="@/assets/cooperation/icon_r.png"
                alt=""
              />
              <div id="c2-2" class="c2-content-mid">
                <div class="c2-content-mid-title">{{ bottitle }}</div>
                <div class="c2-content-mid-text">
                  {{ bottext }}
                </div>
              </div>
              <img
                id="c2-3"
                class="c2-content-icon"
                src="@/assets/cooperation/icon_l.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="c-item">
        <div class="c-title">代理商等级权益</div>
        <div class="c-box">
          <div class="c3">
            <img
              class="c3-bg"
              src="@/assets/cooperation/agency_replace.png"
              alt=""
            />
            <!-- <img class="c3-bg" src="@/assets/cooperation/agency_b.png" alt="" />
            <img class="c3-l" src="@/assets/cooperation/agency_l.png" alt="" />
            <img class="c3-r" src="@/assets/cooperation/agency_r.png" alt="" /> -->
          </div>
        </div>
      </div>
      <div class="c-item">
        <div class="c-title">代理商系统简介</div>
        <div class="c-box">
          <div class="c4">
            <div class="c4-box">
              <div
                class="c4-box-item"
                :class="{
                  'c4-box-bot': index % 2 == 1,
                  'wobble-hor-top': c4Index == index && index % 2 == 1,
                  'wobble-hor-bottom': c4Index == index && index % 2 == 0,
                }"
                @mouseenter="c4Index = index"
                @mouseleave="c4Index = -1"
                v-for="(item, index) in c4List"
                :key="index"
              >
                <img
                  class="c4-box-item-img"
                  :src="require('@/assets/cooperation/' + item.img)"
                  alt=""
                />
                <div class="c4-box-item-text">{{ item.text }}</div>
              </div>
            </div>
            <div class="c4-line">
              <div
                class="c4-line-circle"
                :class="{ 'c4-circlehover': c4Index + 1 == index }"
                @mouseenter="c4Index = index - 1"
                @mouseleave="c4Index = -1"
                v-for="index in 8"
                :key="index"
              >
                <div class="c4-line-circle-in"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-item">
        <div class="c-title">代理流程</div>
        <div class="c-box">
          <div class="c5">
            <img
              class="c5-img"
              src="@/assets/cooperation/agency-process.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="inputBox">
      <div class="inputB">
        <input
          class="inputB-input w185"
          v-model="name"
          type="text"
          placeholder="请输入姓名"
          maxlength="8"
        />
        <input
          class="inputB-input w352"
          v-model="tel"
          type="number"
          placeholder="请输入手机号"
          oninput="if(value.length > 11)value = value.slice(0, 11)"
          onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
        />
        <input
          class="inputB-input w427"
          v-model="note"
          type="text"
          placeholder="备注(选填)"
          maxlength="100"
        />
        <div class="inputB-btn" @click="inputBSubmit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { telFormation } from "@/utils/judge";
import { _throttle } from "@/utils/tool";
export default {
  data() {
    return {
      c1List: [
        {
          imgbg: "bg_advantage-1.png",
          title: "全网海量底价资源云服务",
          text: "汇聚市场品牌营销所需的各种媒体和推广渠道服务于一体的SAAS级，媒体云服务，更重要的是底价共享",
        },
        {
          imgbg: "bg_advantage-2.png",
          title: "全网海量底价资源云服务",
          text: "汇聚市场品牌营销所需的各种媒体和推广渠道服务于一体的SAAS级，媒体云服务，更重要的是底价共享",
        },
        {
          imgbg: "bg_advantage-3.png",
          title: "全网海量底价资源云服务",
          text: "汇聚市场品牌营销所需的各种媒体和推广渠道服务于一体的SAAS级，媒体云服务，更重要的是底价共享",
        },
        {
          imgbg: "bg_advantage-4.png",
          title: "全网海量底价资源云服务",
          text: "汇聚市场品牌营销所需的各种媒体和推广渠道服务于一体的SAAS级，媒体云服务，更重要的是底价共享",
        },
      ],
      c2Id: 0,
      bottitle: "",
      bottext: "",
      c2List: [
        {
          id: 0,
          title: "渠道全价格",
          bottitle: "渠道全价格低",
          bottext: "即可拥有丰富的发稿渠道，超低的软文媒体价格",
        },
        {
          id: 1,
          title: "高利润丰厚回报",
          bottitle: "高利润丰厚回报",
          bottext: "即可拥有丰富的高利润，超低的丰厚回报价格",
        },
        {
          id: 2,
          title: "独立域名个性平台",
          bottitle: "独立域名个性平台",
          bottext: "即可拥有丰富的独立域名，超低的个性平台价格",
        },
        {
          id: 3,
          title: "收益保障无手续费",
          bottitle: "收益保障无手续费",
          bottext: "即可拥有丰富的收益保障，超低的手续费价格",
        },
        {
          id: 4,
          title: "专业培训相关资料",
          bottitle: "专业培训相关资料",
          bottext: "即可拥有丰富的专业培训，超低的相关资料价格",
        },
        {
          id: 5,
          title: "独立平台系统推送",
          bottitle: "独立平台系统推送",
          bottext: "即可拥有丰富的独立平台，超低的系统推送价格",
        },
        {
          id: 6,
          title: "OEM平台免费提供",
          bottitle: "OEM平台免费提供",
          bottext: "即可拥有丰富的OEM平台，超低的OEM平台价格",
        },
        {
          id: 7,
          title: "地域共享共享讨论",
          bottitle: "地域共享共享讨论",
          bottext: "即可拥有丰富的地域共享，超低的共享讨论价格",
        },
      ],
      c4Index: -1,
      c4List: [
        {
          img: "icon_1.png",
          text: "独立域名",
        },
        {
          img: "icon_2.png",
          text: "自定义价格",
        },
        {
          img: "icon_3.png",
          text: "自定义LOGO",
        },
        {
          img: "icon_4.png",
          text: "用户管理",
        },
        {
          img: "icon_5.png",
          text: "多套模板",
        },
        {
          img: "icon_6.png",
          text: "海量媒体资源",
        },
        {
          img: "icon_7.png",
          text: "独立用户后台",
        },
        {
          img: "icon_8.png",
          text: "免费升级",
        },
      ],

      name: "",
      tel: "",
      note: "",

      sloading: false,
    };
  },
  mounted() {
    this.bottitle = this.c2List[0].bottitle;
    this.bottext = this.c2List[0].bottext;
  },
  methods: {
    //c2_Nav_click
    c2Click: _throttle(function (item) {
      if (item.id == this.c2Id) return;
      this.c2Id = item.id;

      let c21 = document.getElementById("c2-1");
      let c22 = document.getElementById("c2-2");
      let c23 = document.getElementById("c2-3");
      c21.style.opacity = "0";
      c22.style.opacity = "0";
      c23.style.opacity = "0";
      c21.style.transitionDuration = "0.2s";
      c22.style.transitionDuration = "0.2s";
      c23.style.transitionDuration = "0.2s";
      c21.style.animationTimingFunction = "ease-in";
      c22.style.animationTimingFunction = "ease-in";
      c23.style.animationTimingFunction = "ease-in";
      setTimeout(() => {
        c21.style.opacity = "1";
        c22.style.opacity = "1";
        c23.style.opacity = "1";
        c21.style.transitionDuration = "0.6s";
        c22.style.transitionDuration = "0.6s";
        c23.style.transitionDuration = "0.6s";
        c21.style.animationTimingFunction = "ease-out";
        c22.style.animationTimingFunction = "ease-out";
        c23.style.animationTimingFunction = "ease-out";

        this.bottitle = item.bottitle;
        this.bottext = item.bottext;
      }, 200);
    }, 700),
    //底部输入提交
    inputBSubmit() {
      if (this.sloading) {
        this.$message.error("提交中···");
        return;
      }
      if (!this.name || !this.tel) {
        this.$message.error("姓名或手机号不能为空");
        return;
      }
      if (!telFormation(this.tel)) {
        this.$message.error("手机号格式错误");
        return;
      }
      this.sloading = true;
      setTimeout(() => {
        this.name = "";
        this.tel = "";
        this.note = "";
        this.sloading = false;
        this.$message.success("提交成功");
      }, 1500);
    },
  },
};
</script>

<style scoped>
@import "./css/cooperation.scss";
</style>
