<template>
  <div class="msg-me">
      <div class="msg-mtext" v-html="text"></div>
      <img src="../../../assets/backstage/protrait1.png" />
  </div>
</template>

<script>
export default {
  props:['text'],
  data(){
    return{
      username:'',
    };
  },
}
</script>

<style scoped>
.msg-me {
  display: flex;
  justify-content: flex-end;
  margin: 20px 25px 20px 0px;
}
/* 对方的在聊天记录框的头像样式 */

.msg-me img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
/* 对方在聊天记录框的文字气泡 */

.msg-mtext {
  /* width: 200px;
        height: 130px; */
  padding: 7px;
  min-width: 30px;
  max-width: 200px;
  margin-right: 10px;
  margin-top: 3px;
  background-color: #9EEA6A;
  color: #333333;
  text-align: justify;
  border-top-left-radius: 10%;
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>