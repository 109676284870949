<template>
  <div class="orderQuery">
    <div class="bigBox">
      <!-- 搜索框 -->
      <div class="search">
        <div class="search-left">
          <input
            v-model="orderNum"
            class="search-left-num"
            type="text"
            placeholder="订单号"
          />
          <input v-model="title" type="text" placeholder="商品名称" />
          <input v-model="plateName" type="text" placeholder="套餐名称" />
          <!-- <input v-model="clientName" type="text" placeholder="客户名称" /> -->
          <div class="search-left-btn" @click="search">搜索</div>
          <div class="search-left-date">
            <el-date-picker
              style="height: 100%"
              v-model="timeRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="dataPicker"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="search-right">
          <div class="search-right-box">
            <el-select
              v-model="state"
              class="topselect"
              placeholder="发布状态"
              @change="selectChange"
            >
              <el-option
                style="width: 100%; height: 100%"
                v-for="item in cities"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span
                    :style="
                      'width:16rem;height:16rem;display: inline-block;background-color:' +
                      item.color
                    "
                  ></span>
                  <span
                    style="color: #999; margin-left: 5rem; font-size: 16rem"
                    >{{ item.label }}</span
                  >
                </div>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 标题 -->
      <div class="title">
        <span class="per18">订单号</span>
        <span class="per13">商品名称</span>
        <!-- <span class="per9">订单状态</span> -->
        <!-- <span class="per9">平台名称</span> -->
        <span class="per9">套餐名称</span>
        <span class="per9">金额</span>
        <span class="per12">支付方式</span>
        <span class="per9">支付状态</span>
        <span class="per9" style="color: #037aef">订单状态</span>

        <span class="per12">下单时间</span>
      </div>
      <!-- 内容 -->
      <div class="content" v-loading="loading">
        <el-empty
          v-show="contentList.length <= 0 && !loading"
          description="暂无订单"
        ></el-empty>
        <div
          class="content-item"
          v-for="(item, index) in contentList"
          :key="index"
        >
          <span class="per18">{{ item.order_no }}</span>
          <span class="per13">{{ item.goods_name }}</span>

          <span class="per9">{{ item.goods_package_name }}</span>
          <span class="per9">{{ item.total_price }}</span>
          <span class="per12">{{
            item.pay_type == 'balance'
              ? '余额'
              : item.pay_type == 'wxpay'
              ? '微信'
              : '支付宝'
          }}</span>
          <div class="per9 kuai">
            <span
              :class="{
                ap: item.pay_status == 0,
                zx: item.pay_status == 1,
                wc: item.pay_status == 3,
                jj: item.pay_status == 4,
                cx: item.pay_status == 5,
              }"
              >{{
                item.pay_status == 0
                  ? '未支付'
                  : item.pay_status == 1
                  ? '已支付'
                  : item.pay_status == 3
                  ? '已完成'
                  : item.pay_status == 4
                  ? '被拒绝'
                  : '已撤销'
              }}</span
            >
          </div>
          <!-- <span class="per9">{{ item.name }}</span> -->
          <!-- <span class="per9">{{ item.plateName }}</span> -->
          <!-- 0未支付 1未开始2执行中3执行完成4暂停中 -->

          <span
            class="per9 urlD"
            :class="{
              cxdd: item.order_status == 0,
              zzzx: item.order_status == 1,
              djqw: item.order_status == 2,
              jjyy: item.order_status == 3,
              ycx: item.order_status == 4,
            }"
            @click="toAnli(item)"
          >
            {{
              item.order_status == 0
                ? '未支付'
                : item.order_status == 1
                ? '未开始'
                : item.order_status == 2
                ? '执行中'
                : item.order_status == 3
                ? '执行完成'
                : '暂停中'
            }}</span
          >
          <span class="per12">{{ item.create_time }}</span>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { reqOrderList2, revocationOrder, reqYue } from '@/api'
export default {
  inject: ['reloadBTop', 'reloadB'],
  data() {
    return {
      loading: true,

      orderNum: '',
      title: '',
      plateName: '',
      clientName: '',
      timeRange: [],
      startTime: '',
      endTime: '',

      state: '',
      cities: [
        {
          value: '',
          label: '全部',
          color: 'rgba(#FFF,0)',
        },
        {
          value: 1,
          label: '待安排',
          color: '#10B910',
        },
        {
          value: 2,
          label: '执行中',
          color: '#07B9CD',
        },
        {
          value: 3,
          label: '已完成',
          color: '#0982F9',
        },
        {
          value: 4,
          label: '被拒绝',
          color: '#ea2027',
        },
        {
          value: 5,
          label: '已撤销',
          color: '#F67B33',
        },
      ],
      total: null,
      contentList: [],
    }
  },
  mounted() {
    this.loading = true
    reqOrderList2()
      .then((res) => {
        if (res.code == 1) {
          this.contentList = res.data.data
          this.total = res.data.total
        } else {
          // this.$router.push("/login");
          this.$message.error('请求出错' + res.msg || res.message || '')
        }
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    //案例
    toAnli(item) {
      // if (item.mt_status == 1) {
      //   this.$confirm("此操作将撤销订单, 是否继续?", "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   })
      //     .then(() => {
      //       revocationOrder({ id: item.id })
      //         .then((res) => {
      //           if (res.code == 1) {
      //             reqYue()
      //               .then((res) => {
      //                 if (res.code == 1) {
      //                   let userMsg = JSON.parse(localStorage.userMsg);
      //                   userMsg.balance = res.data.balance;
      //                   userMsg.score = res.data.score;
      //                   localStorage.userMsg = JSON.stringify(userMsg);
      //                   this.$store.dispatch("global/usermsglocal");
      //                   this.reloadBTop();
      //                   this.reloadB();
      //                 } else {
      //                   this.$message.error(res.message || "请求失败");
      //                 }
      //               })
      //               .catch((res) => {
      //                 this.$message.error(res);
      //               });
      //             this.$message({
      //               type: "success",
      //               message: "删除成功!",
      //             });
      //           } else {
      //             this.$message.error(res.message);
      //           }
      //         })
      //         .catch((res) => {
      //           this.$message.error(res);
      //         });
      //     })
      //     .catch(() => {
      //       this.$message({
      //         type: "info",
      //         message: "已取消撤销",
      //       });
      //     });
      // } else if (item.mt_status == 2) {
      //   this.$message("执行中，请耐心等待···");
      // } else if (item.mt_status == 3) {
      //   if (!item.mt_url || typeof item.mt_url == "undefined") {
      //     this.$message.error("暂无链接···");
      //   } else {
      //     window.open(item.mt_url, "_blank");
      //   }
      // } else if (item.mt_status == 4) {
      //   this.$alert(item.mt_url || "暂无原因", "拒绝原因", {
      //     confirmButtonText: "确定",
      //   });
      // } else {
      //   this.$message.warning("订单已经撤销");
      // }
    },
    selectChange() {
      let data = {
        order_no: this.orderNum,
        goods_package_name: this.plateName,
        goods_name: this.title,
        mt_customer: this.clientName,
        order_status: this.state,
        start_time: this.startTime,
        end_time: this.endTime,
      }
      reqOrderList2(data).then((res) => {
        this.contentList = res.data.data
        this.total = res.data.total
      })
    },
    search() {
      let data = {
        order_no: this.orderNum,
        goods_package_name: this.plateName,
        goods_name: this.title,
        mt_customer: this.clientName,
        order_status: this.state,
        start_time: this.startTime,
        end_time: this.endTime,
      }
      reqOrderList2(data).then((res) => {
        this.contentList = res.data.data
        this.total = res.data.total
      })
    },
    dataPicker() {
      if (this.timeRange) {
        this.startTime = this.timeRange[0]
        this.endTime = this.timeRange[1]
      } else {
        this.startTime = ''
        this.endTime = ''
      }
      let data = {
        order_no: this.orderNum,
        goods_package_name: this.plateName,
        goods_name: this.title,
        mt_customer: this.clientName,
        order_status: this.state,
        start_time: this.startTime,
        end_time: this.endTime,
      }
      reqOrderList2(data).then((res) => {
        this.contentList = res.data.data
        this.total = res.data.total
      })
    },
    //分页
    pageChange(e) {
      let data = {
        order_no: this.orderNum,
        goods_package_name: this.plateName,
        goods_name: this.title,
        mt_customer: this.clientName,
        order_status: this.state,
        startime: this.startTime,
        endtime: this.endTime,
        page: e,
      }
      reqOrderList2(data).then((res) => {
        this.contentList = res.data.data
        this.total = res.data.total
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  width: 100%;
  padding: 15px 0;
  text-align: center;
}
.orderQuery {
  width: 100%;
  // height: 100%;
  background-color: #fff;
  .bigBox {
    width: 98%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .search {
      width: 100%;
      margin: 11rem 0 10rem;
      display: flex;
      &-left {
        width: 75%;
        display: flex;
        flex-wrap: wrap;
        &-btn {
          width: 154rem;
          height: 38px;
          border-radius: 6px;
          background-color: #037aef;
          font-size: 18rem;
          color: #fff;
          text-align: center;
          line-height: 38px;
          cursor: pointer;
          position: relative;
        }
        &-btn::before {
          position: absolute;
          content: '';
          left: -30px;
          right: -30px;
          top: -20px;
          bottom: -20px;
          pointer-events: none;
          transition: ease-in-out 0.5s;
          background-repeat: no-repeat;
          /* 按钮泡沫效果 */
          background-image: radial-gradient(
              circle,
              #0982f9 20%,
              transparent 20%
            ),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%),
            radial-gradient(circle, #0982f9 20%, transparent 20%);
          background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%,
            15% 15%, 10% 10%, 18% 18%, 15% 15%, 20% 20%, 18% 18%, 20% 20%,
            15% 15%, 10% 10%, 20% 20%;
          background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%,
            57% 30%, 65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%,
            41% 70%, 50% 70%, 64% 70%, 80% 71%;
          animation: bubbles ease-in-out 0.75s forwards;
          -webkit-animation: bubbles ease-in-out 0.75s forwards;
          -o-animation: bubbles ease-in-out 0.75s forwards;
          -moz-animation: bubbles ease-in-out 0.75s forwards;
        }

        &-btn:active {
          transform: scale(0.95);
          background-color: #0982f9;
          box-shadow: 0 2px 25px rgba(#0982f9, 0.5);
        }
        &-btn:active::before {
          animation: none;
          background-size: 0;
        }
        @keyframes bubbles {
          0% {
            background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%,
              57% 30%, 65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%,
              41% 70%, 50% 70%, 64% 70%, 80% 71%;
          }
          50% {
            background-position: 10% 44%, 0% 20%, 15% 5%, 30% 0%, 42% 0%,
              62% -2%, 75% 0%, 95% -2%, 0% 80%, 95% 55%, 7% 100%, 24% 100%,
              41% 100%, 55% 95%, 68% 96%, 95% 100%;
          }
          100% {
            background-position: 5% 44%, -5% 20%, 7% 5%, 23% 0%, 37% 0, 58% -2%,
              80% 0%, 100% -2%, -5% 80%, 100% 55%, 2% 100%, 23% 100%, 42% 100%,
              60% 95%, 70% 96%, 100% 100%;
            background-size: 0% 0%;
          }
        }
        @-webkit-keyframes bubbles {
          0% {
            background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%,
              57% 30%, 65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%,
              41% 70%, 50% 70%, 64% 70%, 80% 71%;
          }
          50% {
            background-position: 10% 44%, 0% 20%, 15% 5%, 30% 0%, 42% 0%,
              62% -2%, 75% 0%, 95% -2%, 0% 80%, 95% 55%, 7% 100%, 24% 100%,
              41% 100%, 55% 95%, 68% 96%, 95% 100%;
          }
          100% {
            background-position: 5% 44%, -5% 20%, 7% 5%, 23% 0%, 37% 0, 58% -2%,
              80% 0%, 100% -2%, -5% 80%, 100% 55%, 2% 100%, 23% 100%, 42% 100%,
              60% 95%, 70% 96%, 100% 100%;
            background-size: 0% 0%;
          }
        }
        @-o-keyframes bubbles {
          0% {
            background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%,
              57% 30%, 65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%,
              41% 70%, 50% 70%, 64% 70%, 80% 71%;
          }
          50% {
            background-position: 10% 44%, 0% 20%, 15% 5%, 30% 0%, 42% 0%,
              62% -2%, 75% 0%, 95% -2%, 0% 80%, 95% 55%, 7% 100%, 24% 100%,
              41% 100%, 55% 95%, 68% 96%, 95% 100%;
          }
          100% {
            background-position: 5% 44%, -5% 20%, 7% 5%, 23% 0%, 37% 0, 58% -2%,
              80% 0%, 100% -2%, -5% 80%, 100% 55%, 2% 100%, 23% 100%, 42% 100%,
              60% 95%, 70% 96%, 100% 100%;
            background-size: 0% 0%;
          }
        }
        @-moz-keyframes bubbles {
          0% {
            background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%,
              57% 30%, 65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%,
              41% 70%, 50% 70%, 64% 70%, 80% 71%;
          }
          50% {
            background-position: 10% 44%, 0% 20%, 15% 5%, 30% 0%, 42% 0%,
              62% -2%, 75% 0%, 95% -2%, 0% 80%, 95% 55%, 7% 100%, 24% 100%,
              41% 100%, 55% 95%, 68% 96%, 95% 100%;
          }
          100% {
            background-position: 5% 44%, -5% 20%, 7% 5%, 23% 0%, 37% 0, 58% -2%,
              80% 0%, 100% -2%, -5% 80%, 100% 55%, 2% 100%, 23% 100%, 42% 100%,
              60% 95%, 70% 96%, 100% 100%;
            background-size: 0% 0%;
          }
        }
        input {
          width: 154rem;
          height: 38px;
          padding-left: 11rem;
          margin: 0 30rem 10rem 0;
          background-color: #f5f5f5;
          border-radius: 2px;
          outline: none;
          border: none;
          font-size: 16rem;
          color: #666;
        }
        input::placeholder {
          color: #bbb;
        }
        &-num {
          width: 260rem;
        }
        &-num::-webkit-outer-spin-button,
        &-num::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        &-date {
          height: 38px;
          margin-left: 50rem;
        }
      }
      &-right {
        width: 25%;
        display: flex;
        justify-content: center;
        &-box {
          width: 160rem;
          height: 40rem;
          .topselect {
            :deep {
              .el-input__inner {
                background-color: #0982f9;
                font-size: 18rem;
                color: #fff;
              }
              .el-input__inner::placeholder {
                color: #fff;
              }
              .el-input__icon {
                color: #fff;
                font-size: 18rem;
              }
            }
          }
        }
      }
    }
    .title {
      width: 100%;
      height: 40px;
      background-color: #f5f5f5;
      span {
        display: inline-block;
        font-size: 18px;
        color: #999;
        text-align: center;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .content {
      width: 100%;
      &-item {
        width: 100%;
        height: 56px;
        border-bottom: #e2e2e2 1px solid;
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          font-size: 16px;
          color: #999;
          text-align: center;
          line-height: 56px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
.per18 {
  width: 18%;
}
.per13 {
  width: 13%;
}
.per9 {
  width: 9%;
}
.per12 {
  width: 12%;
}
.urlD {
  cursor: pointer;
}
.kuai {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: inline-block;
    width: 80rem;
    height: 30px;
    color: #fff !important;
    line-height: 30px !important;
  }
}
.ap {
  background-color: #10b910;
}
.zx {
  background-color: #07b9cd;
}
.wc {
  background-color: #037aef;
}
.jj {
  background-color: #ea2027;
}
.cx {
  background-color: #f67b33;
}

.cxdd {
  color: #10b910 !important;
}
.zzzx {
  color: #07b9cd !important;
  cursor: no-drop !important;
}
.djqw {
  color: #999 !important;
}
.jjyy {
  color: #ea2027 !important;
}
.ycx {
  color: #f67b33 !important;
  cursor: no-drop !important;
}

.cxdd:hover,
.jjyy:hover,
.djqw:hover {
  color: #037aef !important;
}
</style>
