<template>
  <div class="msg-somebody">
    <!-- 头像 -->
    <img src="../../../assets/backstage/protrait1.png" />
    <!-- 文字气泡 -->
    <div class="msg-stext">
      <span
        >{{text}}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props:['text']
};
</script>

<style scoped>
.msg-somebody {
  display: flex;
  /* align-items: center; */
  margin: 20px 0 20px 25px;
  /* width: 100px; */
  /* height: 100px; */
  /* background-color: red; */
}
/* 对方的在聊天记录框的头像样式 */

.msg-somebody img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
/* 对方在聊天记录框的文字气泡 */

.msg-stext {
  /* width: 200px;
        height: 130px; */
  padding: 7px;
  min-width: 30px;
  max-width: 200px;
  margin-left: 10px;
  margin-top: 3px;
  background-color: #ffffff;
  color: #333333;
  text-align: justify;
  border-top-right-radius: 10%;
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>