function getToken(){
    let token = '';
    try {
      token = window.localStorage.getItem('token');
    } catch (error) {
      console.log(error);
    }
    return token;
}
function getApiUrl(){
  // if(process.env.NODE_ENV == 'development'){
    // return 'http://127.0.0.1:8081/';
  // }else{
    return 'https://admin.mtuitui.com/';
  // }
  
}
function getWsUrl(){
  // if(process.env.NODE_ENV == 'development'){
    // return 'ws://127.0.0.1:8010/socket/';
  // }else{
     return 'ws://43.142.152.12:8010/socket/';
  // }
}

function isMobile(str){
  var mobile_reg=/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;  
  return mobile_reg.test(str);
} 
module.exports = {
    getToken: getToken,
    getApiUrl:getApiUrl,
    getWsUrl:getWsUrl,
    isMobile:isMobile,
};