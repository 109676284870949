<template>
  <div class="agent1_home">
    <!-- 头部BG -->
    <div class="bg">
      <img class="bg_img" src="@/assets/agent/1/agent1_homeBG.png" alt="" />
    </div>
    <!-- 我的优势 -->
    <div class="advantage">
      <div class="advantage_title">我的优势</div>
      <div class="advantage_box">
        <img
          class="advantage_box_bg"
          src="@/assets/agent/1/agent1_advantage.png"
          alt=""
        />
        <div
          class="advantage_box_item"
          v-for="(item, index) in advantageList"
          :key="index"
        >
          <div class="advantage_box_item_title">{{ item.title }}</div>
          <div class="advantage_box_item_content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <!-- 平台资源 -->
    <div class="resource">
      <div class="resource_title">平台资源</div>
      <div class="resource_box">
        <div
          class="resource_box_item"
          v-for="(item, index) in resourceList"
          :key="index"
        >
          <img
            class="resource_box_item_img"
            :src="require('@/assets/agent/1/' + item.img)"
            :alt="item.name"
          />
          <span class="resource_box_item_name">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <!-- 知识学院 -->
    <div class="knowledge">
      <div class="knowledge_title">知识学院</div>
      <div class="knowledge_box">
        <div class="knowledge_box_item">
          <img
            class="knowledge_box_item_img"
            src="@/assets/agent/1/agent1_know1.png"
            alt=""
          />
          <div class="knowledge_box_item_article" @click="goDetail(item.id)" v-for="(item,index) in leftContentTopList" :key="index">
            <div class="knowledge_box_item_article_left">
              <div class="knowledge_box_item_article_left_title">
                {{item.title}}
              </div>
              <div class="knowledge_box_item_article_left_content">
                {{item.description}}
              </div>
            </div>
            <div class="knowledge_box_item_article_right">
              <div class="knowledge_box_item_article_right_data">{{item.create_time.slice(5,10)}}</div>
              <div class="knowledge_box_item_article_right_year">{{item.create_time.slice(0,4)}}</div>
            </div>
          </div>
        </div>
        <div class="knowledge_box_item">
          <img
            class="knowledge_box_item_img"
            src="@/assets/agent/1/agent1_know2.png"
            alt=""
          />
          <div
            class="knowledge_box_item_article"
            @click="goDetail(item.id)"
            v-for="(item,index) in rightContentTopList"
            :key="index"
          >
            <div class="knowledge_box_item_article_left">
              <div class="knowledge_box_item_article_left_title">
                {{item.title}}
              </div>
              <div class="knowledge_box_item_article_left_content">
                {{item.description}}
              </div>
            </div>
            <div class="knowledge_box_item_article_right">
              <div class="knowledge_box_item_article_right_data">{{item.create_time.slice(5,10)}}</div>
              <div class="knowledge_box_item_article_right_year">{{item.create_time.slice(0,4)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下单流程 -->
    <div class="process">
      <img class="process_img" src="@/assets/agent/1/agent1_fagao.png" alt="" />
    </div>
    <!-- 合作平台 -->
    <div class="cooperation">
      <div class="cooperation_title">合作平台</div>
      <div class="cooperation_box">
        <img
          class="cooperation_box_img"
          v-for="(item, index) in cooperationList"
          :key="index"
          :src="require('@/assets/agent/1/' + item.img)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  loginPost,
  loginCodePost,
  reqTenxun,
  reqArticleNav,
  reqArticleList,
} from "@/api";
export default {
  data() {
    return {
      leftContentTopList:[{
          description: "这是一条咨讯详情",
          id: "1",
          img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
          thumb_image_id: "1",
          title: "这是一条咨讯标题",
          update_time: "",
          create_time: "2022-11-28 09:10:59",
        }],
      rightContentTopList:[
        // {
        //   description: "这是一条咨讯详情",
        //   id: "1",
        //   img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
        //   thumb_image_id: "1",
        //   title: "这是一条咨讯标题",
        //   update_time: "",
        //   create_time: "2022-11-28 09:10:59",
        // }
      ],
      advantageList: [
        {
          title: "公司优势",
          content:
            "1、专业经验：资深团队专业指导，懂你所想。\n\n2、一手资源：直接对接各大平台和编辑，执行更畅通。\n\n3、专属客服：一对一客服解惑答疑，第一时间解决您的需求。",
        },
        {
          title: "权益保障",
          content:
            "1、技术保障：资深团队，保障平台平稳运行。\n\n2、财务保障：透明流水，充值提现灵活选择。\n\n3、发稿保障：专属客服，随时应答发稿问题。",
        },
        {
          title: "服务对象",
          content: "餐饮、食品、制造、公关、广告、等各领域企事业单位",
        },
      ],
      article_list:[
        {
          description: "这是一条咨讯详情",
          id: "1",
          img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
          thumb_image_id: "1",
          title: "这是一条咨讯标题",
          update_time: "",
          create_time: "2022-11-28 09:10:59",
        }
      ],
      resourceList: [
        {
          img: "adent1_icon_1.png",
          name: "新闻",
        },
        {
          img: "adent1_icon_2.png",
          name: "自媒体",
        },
        {
          img: "adent1_icon_3.png",
          name: "论坛",
        },
        {
          img: "adent1_icon_4.png",
          name: "短视频",
        },
        {
          img: "adent1_icon_5.png",
          name: "网站",
        },
        {
          img: "adent1_icon_6.png",
          name: "达人",
        },
        {
          img: "adent1_icon_7.png",
          name: "广告",
        },
        {
          img: "adent1_icon_8.png",
          name: "社群",
        },
      ],
      knowledgeList: [
        {
          title: "标题标题标题标题标题标题标题标题",
          content:
            "托马斯小货车平台是一站式智能品牌投放平台，凭借着市场的新风口，平台的新模式，一举改变了广告行业原本价格信息差的混淆。原本价格信息差的混淆。",
        },
      ],
      cooperationList: [
        {
          img: "agent1_cor1.png",
        },
        {
          img: "agent1_cor2.png",
        },
        {
          img: "agent1_cor3.png",
        },
        {
          img: "agent1_cor4.png",
        },
        {
          img: "agent1_cor5.png",
        },
        {
          img: "agent1_cor6.png",
        },
        {
          img: "agent1_cor7.png",
        },
        {
          img: "agent1_cor8.png",
        },
        {
          img: "agent1_cor9.png",
        },
        {
          img: "agent1_cor10.png",
        },
      ],
    };
  },
  mounted(){
    this.initView();
  },
  methods: {
    initView(){
        reqArticleList({ type: 1 })
        .then((res) => {
          if (res.code == 1) {
            // this.contentTopList = res.data.data.slice(0, 4);
            let articleList = res.data.data;
            //清空原有数据
            this.leftContentTopList.length = 0;
            this.rightContentTopList.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
                if(i % 2 == 0){
                  this.leftContentTopList.push(articleList[i]);
                }else{
                  this.rightContentTopList.push(articleList[i]);
                }
            }
            console.log(this.leftContentTopList);
            console.log(this.rightContentTopList);

          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingTop = false;
        });
    },
    goDetail(id) {
      this.$router.push({
          path:'/template1/knowledgein/'+id+ '.html'
        });
    },
    
    // test() {
    // this.$global.message("这是一条信息", undefined, 2000, undefined, 200);
    // },
  },
};
</script>

<style>
.agent1_home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 头部BG */
.bg {
  width: 100%;
}
.bg_img {
  display: block;
  width: 100%;
  object-fit: contain;
}
/* 我的优势 */
.advantage {
  width: 100%;
  margin: 90px 0 0;
  padding: 20px 0;
  background-color: #fff;
}
.advantage_title {
  width: 100%;
  margin: 0 auto 70px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.advantage_box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
.advantage_box_bg {
  width: 714px;
  object-fit: contain;
  position: absolute;
  right: -80px;
  z-index: 2;
}
.advantage_box_item {
  width: 1200px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 4;
}
.advantage_box_item_title {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 24px;
  color: #666;
}
.advantage_box_item_content {
  margin-bottom: 45px;
  font-size: 20px;
  color: #999;
  white-space: pre-line;
}
/* 平台资源 */
.resource {
  width: 100%;
  padding: 60px 0 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resource_title {
  margin-bottom: 60px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.resource_box {
  width: 1380px;
  padding: 90px 270px;
  background-color: #f8f9fa;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.resource_box_item {
  margin: 30px 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resource_box_item_img {
  margin-bottom: 13px;
  display: block;
  width: 72px;
  height: 72px;
  object-fit: contain;
}
.resource_box_item_name {
  font-size: 20px;
  color: #333;
  font-weight: bold;
}
/* 知识学院 */
.knowledge {
  width: 100%;
  padding: 90px 0 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.knowledge_title {
  margin-bottom: 70px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.knowledge_box {
  width: 1200px;
  margin: 0 auto 90px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.knowledge_box_item {
  width: 580px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.knowledge_box_item_img {
  width: 100%;
  object-fit: contain;
  display: block;
}
.knowledge_box_item_article {
  width: 100%;
  margin-top: 40px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(197, 195, 195, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
}
.knowledge_box_item_article:hover {
  border-bottom: 1px solid rgba(9, 130, 249, 0.3);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article:hover .knowledge_box_item_article_right {
  border-left: 1px solid rgba(9, 130, 249, 0.3);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article:hover .knowledge_box_item_article_left_title {
  color: rgba(8, 114, 219, 0.8);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article:hover .knowledge_box_item_article_left_content {
  color: rgba(8, 114, 219, 0.6);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article:hover .knowledge_box_item_article_right_data {
  color: rgba(8, 114, 219, 0.6);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article:hover .knowledge_box_item_article_right_year {
  color: rgba(8, 114, 219, 0.6);
  transition: 0.2s ease-out;
}
.knowledge_box_item_article_left {
  width: 460px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.knowledge_box_item_article_left_title {
  margin: 0 0 20px;
  font-size: 24px;
  color: #333;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.knowledge_box_item_article_left_content {
  font-size: 16px;
  color: #999;
  letter-spacing: 1px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.knowledge_box_item_article_right {
  width: 90px;
  border-left: 1px solid rgba(197, 195, 195, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.knowledge_box_item_article_right_data {
  margin-bottom: 8px;
  font-size: 24px;
  color: #666;
}
.knowledge_box_item_article_right_year {
  font-size: 16px;
  color: #999;
}
/* 下单流程 */
.process {
  width: 100%;
}
.process_img {
  width: 100%;
  object-fit: contain;
  display: block;
}
/* 合作平台 */
.cooperation {
  width: 100%;
  padding: 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cooperation_title {
  margin-bottom: 70px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.cooperation_box {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cooperation_box_img {
  width: 224px;
  margin-bottom: 20px;
  object-fit: contain;
  transition: 0.2s ease-out;
}
.cooperation_box_img:hover {
  box-shadow: 0 3px 8px 1px rgba(9, 130, 249, 0.19);
  transition: 0.2s ease-out;
}
</style>
