<template>
  <div class="app">
    <div class="desc">
      <div class="logo" @click="goHome()">
        <img src="@/assets/login/logo.png" alt="" />
      </div>
      <div class="Company_Profile">
        <p>
          媒推推是一个广告媒体综合管理平台。媒推推整合了全国上万家主流体、自媒体资源、形成强势媒体联盟，提供媒体推广资源、广告投放台。凭借多年资源的积累和客户良好口碑的拓展，服务的业家户行包括互联网、餐饮、人力资源、银行、汽车、能源、房产、旅游、店、零售等。
        </p>
        <p>
          媒推推秉承诚信立足，创新致远的服务理念，始终坚持为全球企业发提供有价值的性价比服务!
        </p>
      </div>
      <div class="Platform_concept">
        <h2>平台理念</h2>
        <p>始终坚持为全球企业发展提供有价值的性价比服务! 一手技术、真诚服务</p>
      </div>
      <div class="Service">
        <h2>服务承诺</h2>
        <div>
          <img src="@/assets/login/1.png" alt="" />
          <img src="@/assets/login/2.png" alt="" />
          <img src="@/assets/login/3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="login">
      <!-- <div class="login-toHome" @click="test(data1)">返回首页2</div> -->
      <div class="login-title">
        <span
          @click="changeLR(0)"
          :class="[lr ? 'login-title-a' : 'login-title-b']"
          >登录</span
        >
        <span>/</span>
        <span
          @click="changeLR(1)"
          :class="[lr ? 'login-title-b' : 'login-title-a']"
          >注册</span
        >
      </div>
      <div class="login-sel">
        <span v-show="lr" @click="changeL(0)" :class="[l ? 'login-sel-a' : '']"
          >手机号登录</span
        >
        <span v-show="lr" @click="changeL(1)" :class="[l ? '' : 'login-sel-a']"
          >密码登录</span
        >
        <span
          v-show="!lr"
          style="
            padding-bottom: 5pt;
            border-bottom: 2px solid #0982f9;
            color: #0982f9;
          "
          >账号注册</span
        >
      </div>
      <div class="login-line" v-show="lr">
        <div
          class="login-line-move"
          :class="[l ? 'login-line-move1' : 'login-line-move2']"
        ></div>
      </div>
      <div class="login-input">
        <input
          v-show="!l"
          type="text"
          v-model="userName"
          maxlength="11"
          oninput="value = value.replace(/\s+/g,'')"
          placeholder="请输入账号"
          @keyup.enter="submitForm('ruleForm')"
        />
        <input
          v-show="l"
          type="number"
          v-model="tel"
          oninput="if(value.length > 11)value = value.slice(0, 11)"
          onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
          placeholder="请输入手机号"
          @keyup.enter="submitForm('ruleForm')"
        />
        <input
          v-show="!l"
          type="password"
          v-model="password"
          maxlength="20"
          oninput="value = value.replace(/\s+/g,'')"
          placeholder="请输入密码"
          @keyup.enter="submitForm('ruleForm')"
        />
        <input
          v-show="l && !lr"
          type="password"
          v-model="passwordR"
          maxlength="20"
          oninput="value = value.replace(/\s+/g,'')"
          placeholder="请输入密码"
          @keyup.enter="submitForm('ruleForm')"
        />
        <input
          v-show="l && !lr"
          type="password"
          v-model="passwordR1"
          maxlength="20"
          oninput="value = value.replace(/\s+/g,'')"
          placeholder="请确认密码"
          @keyup.enter="submitForm('ruleForm')"
        />
        <input
          v-show="l && !lr"
          type="text"
          v-model="Invitation_code"
          maxlength="20"
          oninput="value = value.replace(/\s+/g,'')"
          placeholder="邀请码（选填）"
          @keyup.enter="submitForm('ruleForm')"
        />
        <div v-show="l" class="login-input-code">
          <input
            class="code"
            type="text"
            v-model="code"
            maxlength="4"
            oninput="value = value.replace(/\s+/g,'')"
            placeholder="请输入验证码"
            @keyup.enter="submitForm('ruleForm')"
          />
          <button
            class="codeBtn"
            :class="{ telInput: codeTime == 0 && tel.length >= 11 }"
            @click="sendTelCode"
            id="codeTel"
          >
            获取验证码
          </button>
        </div>
        <div v-if="lr && !l" class="slide"><slider ref="slider"></slider></div>
        <span v-show="!l" class="login-input-forget" @click="forgetPsd"
          >忘记密码?</span
        >
        <span v-show="l" class="login-input-forget" style="cursor: default">
          &nbsp;
        </span>
        <button class="inputBtn" @click="login(lr)">
          {{ lr ? "登录" : "注册" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import slider from "@/components/slider";
import { telFormation } from "@/utils/judge";
import { loginPost, loginCodePost, reqTenxun, reqRegister } from "@/api";
export default {
  props: ["lorr"],
  data() {
    return {
      data1:
        '{"path":"dashboard","name":"控制台1","c":"pages/dashboard/workplace","meta":{"authority":{"permission":"*"},"icon":"file-excel","pAuthorities":[{"permission":"*"}]},"children":[{"path":"workplace","children":[{"path":"workplace","name":"工作台","c":"pages/dashboard/workplace/WorkPlace","meta":{"authority":{"permission":"*"},"icon":"file-excel","pAuthorities":[{"permission":"*"},{"permission":"*"}]},"fullPath":"/dashboard/workplace"},{"path":"analysis","name":"分析页","meta":{"authority":{"permission":"*"},"pAuthorities":[{"permission":"*"},{"permission":"*"}]},"fullPath":"/dashboard/analysis"}],"name":"工作台","c":"pages/dashboard/workplace/WorkPlace","meta":{"authority":{"permission":"*"},"icon":"file-excel","pAuthorities":[{"permission":"*"},{"permission":"*"}]},"fullPath":"/dashboard/workplace"},{"path":"analysis","name":"分析页","meta":{"authority":{"permission":"*"},"pAuthorities":[{"permission":"*"},{"permission":"*"}]},"fullPath":"/dashboard/analysis"}],"fullPath":"/dashboard"}',
      data: {},

      userName: "",
      password: "",
      passwordR: "",
      passwordR1: "",
      Invitation_code: "",
      tel: "",
      code: "",

      slideShow: true,

      lr: 1, //0为注册，1为登录
      l: 0, //0为密码登录，1为手机号登录
      codeTime: 0,
      testdata: [
        {
          noticeId: 345646,
          satName: "ZOHREH-2",
          country: "IRN",
          freqMin: 456.0,
          freqMax: 456.0,
          beamInfoList: [
            {
              beamName: "RS49",
              freqMin: 3456.0,
              freqMax: 654.0,
              groupInfoList: [
                {
                  groupId: 567.34,
                  freqMin: 768.0,
                  freqMax: 678.0,
                  pwrMax: 1.0,
                },
                {
                  groupId: 10600362,
                  freqMin: 11450.0,
                  freqMax: 11700.0,
                  pwrMax: 2.0,
                },
                {
                  groupId: 10600363,
                  freqMin: 14000.0,
                  freqMax: 14500.0,
                  pwrMax: 3.0,
                },
              ],
            },
          ],
        },
      ],
    };
  },
  components: {
    slider,
  },
  mounted() {
    if (
      this.$route.params.lorr != null ||
      ("" && typeof (this.$route.params.lorr != null) != "undefined")
    ) {
      this.lr = this.$route.params.lorr;
      if (!this.lr) {
        this.l = 1;
      }
    }
  },
  methods: {
    //切换登录注册
    changeLR(data) {
      this.userName = "";
      this.password = "";
      this.passwordR = "";
      this.passwordR1 = "";
      this.tel = "";
      this.code = "";

      if (data) {
        this.lr = 0;
        this.l = 1;
      } else {
        this.lr = 1;
      }
    },
    //切换登录方式
    changeL(data) {
      this.tel = "";
      this.code = "";
      this.userName = "";
      this.password = "";
      if (data) {
        this.l = 0;
      } else {
        this.l = 1;
      }
    },
    //发送验证码
    sendTelCode() {
      if (!this.tel) {
        this.$message.error("请输入手机账号");
        return;
      }
      if (!telFormation(this.tel)) {
        this.$message.error("请检查手机号格式");
        return;
      }
      if (this.codeTime != 0) return;
      this.codeTime = 60;
      document.getElementById("codeTel").innerHTML =
        this.codeTime + "s后重新发送";
      var interval = setInterval(() => {
        this.codeTime -= 1;
        document.getElementById("codeTel").innerHTML =
          this.codeTime + "s后重新发送";
        if (this.codeTime == 0) {
          clearInterval(interval);
          document.getElementById("codeTel").innerHTML = "获取验证码";
        }
      }, 1000);
      reqTenxun({ mobile: this.tel }).then((res) => {
        if (res.result == 0) {
          this.$message.success("验证码已发送");
        } else {
          this.$message.error("验证码请求失败");
        }
      });
    },
    //登录
    login(data) {
      if (data && !this.l) {
        //账号密码登录
        if (!this.userName) {
          this.$message.error("请输入账号");
          return;
        }
        if (!this.password) {
          this.$message.error("请输入密码");
          return;
        }
        if (this.$refs["slider"]) {
          // 未通过验证时，提示错误信息并返回
          if (!this.$refs["slider"].confirmSuccess) {
            this.$message.error("请拖动滑块验证");
            return;
          } else {
            // this.$message.success("验证成功，可提交");
            loginPost({ username: this.userName, password: this.password })
              .then((res) => {
                if (res.code == 1) {
                  let temp = res.data[0];
                  //保存信息
                  let userMsg = {
                    uid: temp.id,
                    nickname: temp.nickname,
                    vip: true,
                    tel: temp.mobile,
                    email: temp.email,
                    balance: Number(temp.balance),
                    score: 0,
                    type: temp.type,
                  };
                  this.$store.dispatch("global/userMessageModify", userMsg);
                  //登陆成功
                  localStorage.token = temp.token;
                  localStorage.userMsg = JSON.stringify(userMsg);

                  // 在需要需要调用vuex，解析客服的需要的数据
                  this.$store.commit("chat/login", temp);

                  this.$router.push("/");
                  setTimeout(() => {
                    let routeUrl = this.$router.resolve({
                      path: "/backstage", // 不添加_blank: true
                    });
                    window.open(routeUrl.href, "_blank");
                  }, 500);
                } else if (res.code == 1002) {
                  localStorage.token = "";
                  this.$message.error(res.message + "请先注册");
                  this.lr = 0;
                  this.l = 1;
                } else {
                  localStorage.token = "";
                  this.$message.error(res.message);
                }
                this.password = "";
              })
              .catch(() => {
                localStorage.token = "";
                this.$message.error("登录出错");
                this.password = "";
              });
          }
        }
      } else if (data && this.l) {
        //验证码登录
        if (!this.tel) {
          this.$message.error("请输入手机号");
          return;
        }
        if (!this.code) {
          this.$message.error("请输入验证码");
          return;
        }
        let data = {
          phone: this.tel,
          code: this.code,
        };
        loginCodePost(data).then((res) => {
          if (res.code == 1) {
            let temp = res.data;
            //保存信息
            let userMsg = {
              uid: temp.id,
              nickname: temp.nickname,
              vip: true,
              tel: temp.mobile,
              email: temp.email,
              balance: Number(temp.balance),
              score: 0,
              type: temp.type,
            };
            this.$store.dispatch("global/userMessageModify", userMsg);
            //登陆成功
            localStorage.token = temp.token;
            localStorage.userMsg = JSON.stringify(userMsg);
            this.$router.push("/");
            setTimeout(() => {
              let routeUrl = this.$router.resolve({
                path: "/backstage",
              });
              window.open(routeUrl.href, "_blank");
            }, 500);
          } else {
            this.$message.warning(res.message);
          }
        });
      } else {
        //注册
        if (!this.tel) {
          this.$message.error("请输入手机号");
          return;
        }
        if (!this.passwordR) {
          this.$message.error("请输入密码");
          return;
        }
        if (!this.passwordR1 || this.passwordR != this.passwordR1) {
          this.$message.error("两次密码输入不一致");
          return;
        }
        if (!this.code) {
          this.$message.error("请输入验证码");
          return;
        }
        let register = {
          mobile: this.tel,
          password: this.passwordR,
          code: this.code,
          invite_code: this.inviteCode,
        };
        reqRegister(register)
          .then((res) => {
            if (res.code == 1) {
              let userMsg = {
                uid: res.data.id,
                nickname: res.data.nickname,
                vip: true,
                tel: res.data.mobile,
                email: res.data.email,
                balance: Number(res.data.balance),
                score: 0,
              };
              this.$store.dispatch("global/userMessageModify", userMsg);
              //登陆成功
              localStorage.token = res.data.token;
              localStorage.userMsg = JSON.stringify(userMsg);
              this.$message.success("验证成功，可提交");
              this.$router.push("/");
              setTimeout(() => {
                let routeUrl = this.$router.resolve({
                  path: "/backstage", // 这里的路径就可以正常的写，不需要添加_blank: true
                });
                window.open(routeUrl.href, "_blank");
              }, 500);
            } else {
              localStorage.token = "";
              this.$message.error(res.message);
            }
          })
          .finally(() => {
            this.tel = "";
            this.passwordR = "";
            this.passwordR1 = "";
          });
      }
    },
    //忘记密码
    forgetPsd() {
      this.$router.push("/forgetPsd");
    },
    //回到首页
    goHome() {
      let nav = {
        name: "首页",
        color: "#0982F9",
      };
      this.$store.dispatch("global/navColorChange", nav);
      this.$router.push("/");
    },
    //回车事件
    submitForm() {
      this.login(this.lr);
    },
    /* Route_test
    test(tree) {
      this.data = JSON.parse(tree);
    },
    bianli(tree) {
      tree.component = this.routerCom(tree.c);
      if (this.exists(tree)) {
        for (let i = 0; i < tree.children.length; i++) {
          this.bianli(tree.children[i]);
        }
      }
    },
    exists(rows) {
      if ("children" in rows) return true;
      return false;
    },
    routerCom(path) {
      //对路由的component解析
      // return (resolve) => require([`@/views/${path}`], resolve);
      return "(resolve) => require([`@/views/" + path + "`], resolve)";
    },
    */
  },
};
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
  height: 100vh;
  display: flex;
}
.desc {
  width: calc(20% - 50px);
  height: calc(100vh - 100px);
  min-height: 700px;
  background-image: url("@/assets/login/bg2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 0% 0%;
  padding: 50px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    width: 150px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .Company_Profile {
    font-size: 12px;
    p {
      line-height: 20px;
    }
    p:nth-child(2) {
      margin-top: 30px;
    }
  }
  .Platform_concept {
    p {
      margin-top: 8px;
    }
  }
  .Service {
    div {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      img {
        width: calc(100% / 3.5);
        height: calc(100% / 3.5);
      }
    }
  }
}
.login {
  position: relative;
  width: 80%;
  height: 100%;
  min-height: 700px;
  background-image: url("@/assets/login/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 0% 0%;
  &-toHome {
    max-width: 150px;
    max-height: 50px;
    width: 150rem;
    height: 50rem;
    border-radius: 8px;
    border: 2px solid #fff;
    font-size: 20px;
    color: #fff;
    text-align: center;
    line-height: 50rem;
    position: absolute;
    top: 30rem;
    left: 50rem;
    z-index: 9;
    cursor: pointer;
  }
  &-title {
    width: 150pt;
    position: absolute;
    left: 41%;
    top: 16%;
    &-a {
      width: 50pt;
      height: 25pt;
      color: #0982f9;
      font-size: 25pt;
      font-weight: Bold;
      cursor: pointer;
    }
    &-b {
      width: 50pt;
      height: 18pt;
      font-size: 18pt;
      cursor: pointer;
    }
    span:nth-child(2) {
      font-size: 20pt;
      cursor: default;
      margin: 0 8px;
    }
  }
  &-sel {
    position: absolute;
    left: 41%;
    top: 32%;
    &-a {
      color: #0982f9;
    }
    span {
      font-size: 12pt;
      cursor: pointer;
    }
    span:nth-child(2) {
      margin-left: 20pt;
    }
  }
  &-line {
    width: 131pt;
    height: 1pt;
    background-color: #eaeaea;
    position: absolute;
    border-radius: 1px;
    left: 41%;
    top: 36%;
    &-move1 {
      width: 60pt;
      height: 1pt;
      margin-left: 0;
      transition: 0.2s ease-out;
      background-color: #0982f9;
    }
    &-move2 {
      width: 50pt;
      height: 1pt;
      margin-left: 80pt;
      transition: 0.2s ease-out;
      background-color: #0982f9;
    }
  }
  &-input {
    position: absolute;
    left: 41%;
    top: 39%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-forget {
      margin: -10pt 0 10pt;
      align-self: flex-end;
      color: #707070;
      cursor: pointer;
    }
    &-forget:hover {
      color: #0982f9;
    }
    &-code {
      display: flex;
      .code {
        outline: none;
        width: 136pt;
        height: 8pt;
        padding: 11pt;
        font-size: 8.5pt;
        border-radius: 6px;
        border: 1px solid #707070;
      }
      .codeBtn {
        width: 109pt;
        height: 30pt;
        font-size: 8.5pt;
        border-radius: 3pt;
        text-align: center;
        border: 1px solid #eee;
        background-color: rgba($color: #eee, $alpha: 1);
        color: #888;
        box-sizing: content-box;
        margin-left: 8pt;
        cursor: no-drop;
      }
      // .codeBtn:hover {
      //   background-color: rgba($color: #eee, $alpha: 0.8);
      //   color: #333;
      //   border: 1px solid #404040;
      // }
      // .codeBtn:active {
      //   background-color: #ccc;
      //   color: #111;
      //   border: 1px solid #101010;
      // }
    }
    input {
      outline: none;
      width: 253pt;
      height: 8pt;
      padding: 11pt;
      font-size: 8.5pt;
      border-radius: 6px;
      margin-bottom: 20pt;
      border: 1px solid #707070;
    }
    input::placeholder {
      color: #999999;
    }
    input:focus {
      border: 1px solid #369bff;
    }
    input:focus::placeholder {
      color: #369bff;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .inputBtn {
      width: 119.5pt;
      height: 30pt;
      font-size: 12pt;
      background-color: rgba($color: #0982f9, $alpha: 0.8);
      color: #fff;
      border: none;
      border-radius: 6px;
      cursor: pointer;
    }
    .inputBtn:hover {
      background-color: rgba($color: #0982f9, $alpha: 0.9);
    }
    .inputBtn:active {
      background-color: #0982f9;
    }
  }
}
.telInput {
  background-color: #0982f9 !important;
  color: #fff !important;
  cursor: pointer !important;
}
.slide {
  margin-top: -5pt;
  margin-bottom: 10pt;
  width: 275pt;
}
</style>
