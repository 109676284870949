<template>
  <div class="navside">
    <div class="topImg">
      <!-- <img @click="goPage" src="@/assets/backstage/topImg.png" alt="媒推推" /> -->
      <img
        @mouseleave="isGif = 'logo.png'"
        @mouseenter="isGif = 'logo.gif'"
        :src="require('@/assets/page/' + isGif)"
        alt="媒推推"
        @click="goPage"
      />
    </div>
    <div class="navList">
      <div class="navList-item" v-for="(item, index) in navList" :key="index">
        <span
          class="navList-item-title"
          :class="{ item1Sel: item1Index == index }"
          @click="item1Click(item, index)"
          >{{ item.name }}</span
        >
        <div
          :id="'subList' + index"
          class="navList-item-List"
          :class="[item1Index == index ? '' : 'sub0']"
        >
          <span
            class="navList-item-List-subtitle"
            :class="{ subtitleSeled: item2Index == indx }"
            @click="item2Click(item, itm, indx)"
            v-for="(itm, indx) in item.child"
            :key="indx"
            >{{ itm.name }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reloadB'],
  mounted() {
    //点击账号信息跳转
    this.$parent.$on('navSideSel', (msg) => {
      this.item1Index = msg.item1Index
      this.item2Index = msg.item2Index
      // console.log(msg, 'msg')
      // this.$parent.$emit("navLocation", msg.item2Index);
      let subList = document.getElementById('subList' + this.item1Index)
      subList.style.height =
        41 * this.navList[this.item1Index].child.length + 'rem'
      subList.style.transitionDuration = '0.5s'
      subList.style.transitionTimingFunction = 'ease-out'
    })
    //刷新菜单定位
    this.navRefresh()
  },
  data() {
    return {
      isGif: 'logo.png',
      item1Index: 0,
      item2Index: 0,
      navList: [
        {
          name: '首页看板',
          path: '/backstage/homepage',
          id: '0',
          child: [],
        },
        {
          name: '媒体发布',
          path: '/backstage/mediaRelease/newsMedia',
          id: '1',
          child: [
            // {
            //   name: "新闻媒体",
            //   path: "/newsMedia",
            //   id: "0",
            // },
            // {
            //   name: "自媒体",
            //   path: "/selfMedia",
            //   id: "1",
            // },
            // {
            //   name: "短视频",
            //   path: "/shortMedia",
            //   id: "2",
            // },
            // {
            //   name: "微信",
            //   path: "/wechat",
            //   id: "3",
            // },
            // {
            //   name: "线下资源",
            //   path: "/offline",
            //   id: "4",
            // },
          ],
        },
        {
          name: '基础服务',
          // path: "/backstage/mediaRelease/newsMedia",
          path: '/backstage/baseServer',
          id: '3',
          child: [],
        },
        {
          name: '订单管理',
          path: '/backstage/orderMgt/orderQuery',
          id: '4',
          child: [
            {
              name: '媒体订单',
              path: '/backstage/orderMgt/orderQuery',
              id: '0',
            },
            {
              name: '基础订单',
              path: '/backstage/orderMgt/BaseOrder',
              id: '1',
            },
          ],
        },
        {
          name: '资金管理',
          path: '/backstage/fundMgt/onlineCharge',
          id: '5',
          child: [
            {
              name: '在线充值',
              path: '/backstage/fundMgt/onlineCharge',
              id: '0',
            },
            {
              name: '交易记录',
              path: '/backstage/fundMgt/dealRecord',
              id: '1',
            },
            {
              name: '资金提现',
              path: '/backstage/fundMgt/withdrawal',
              id: '2',
            },
          ],
        },
        {
          name: '个人中心',
          path: '/backstage/personpage/usermsg',
          id: '6',
          child: [
            {
              name: '用户信息',
              path: '/backstage/personpage/usermsg',
              id: '0',
            },
            {
              name: '我的通知',
              path: '/backstage/personpage/mynote',
              id: '1',
            },
            {
              name: '密码管理',
              path: '/backstage/personpage/pasManage',
              id: '2',
            },
            {
              name: '积分详情',
              path: '/backstage/personpage/PointsDetails',
              id: '3',
            },
            {
              name: '我的邀请',
              path: '/backstage/personpage/MyInvitation',
              id: '4',
            },
          ],
        },
        {
          name: '联系官方',
          path: '/backstage/contactus/contactCS',
          id: '7',
          child: [
            {
              name: '联系客服',
              path: '/backstage/contactus/contactCS',
              id: '0',
            },
            {
              name: '问题反馈',
              path: '/backstage/contactus/feedback',
              id: '1',
            },
          ],
        },
      ],
    }
  },
  methods: {
    goPage() {
      if (this.$route.path == '/backstage/homepage') {
        this.reloadB()
        return
      }
      this.$router.push('/backstage/homepage')
      this.$parent.$emit('navSideSel', { item1Index: 0, item2Index: 0 })
    },
    item1Click(item, index) {
      this.item1Index = index
      this.item2Index = 0
      if (this.$route.path == item.path) {
        this.reloadB()
        return
      }
      //子标题动画
      let subList = document.getElementById('subList' + index)
      subList.style.height = 41 * item.child.length + 'rem'
      subList.style.transitionDuration = '0.5s'
      subList.style.transitionTimingFunction = 'ease-out'

      //业务跳转
      this.$router.push(item.path)
    },
    item2Click(item, itm, indx) {
      if (this.$route.path == itm.path) {
        // this.reloadB();
        return
      }
      this.item2Index = indx
      this.$router.push(itm.path)
      this.$parent.$emit('navLocation', itm.id)
    },
    //fengzhuang 侧边栏展开
    navRefresh() {
      let navUrl = this.$route.path
      if (navUrl == '/backstage/homepage') {
        this.item1Index = 0
        this.unfold(this.item1Index)
        this.item2Index = 0
      } else if (navUrl == '/backstage/mediaRelease/newsMedia') {
        this.item1Index = 1
        this.unfold(this.item1Index)
        this.item2Index = 0
      }
      // else if (navUrl == '/backstage/mediaRelease/newsMedia') {
      //   this.item1Index = 2
      //   this.unfold(this.item1Index)
      //   this.item2Index = 0
      // }
      else if (navUrl == '/backstage/baseServer') {
        this.item1Index = 3
        this.unfold(this.item1Index)
        this.item2Index = 0
      } else if (navUrl == '/backstage/orderMgt/orderQuery') {
        this.item1Index = 4
        this.unfold(this.item1Index)
        this.item2Index = 0
      } else if (navUrl == '/backstage/fundMgt/onlineCharge') {
        this.item1Index = 5
        this.unfold(this.item1Index)
        this.item2Index = 0
      } else if (navUrl == '/backstage/fundMgt/dealRecord') {
        this.item1Index = 5
        this.unfold(this.item1Index)
        this.item2Index = 1
      } else if (navUrl == '/backstage/fundMgt/withdrawal') {
        this.item1Index = 5
        this.unfold(this.item1Index)
        this.item2Index = 2
      } else if (navUrl == '/backstage/personpage/usermsg') {
        this.item1Index = 6
        this.unfold(this.item1Index)
        this.item2Index = 0
      } else if (navUrl == '/backstage/personpage/mynote') {
        this.item1Index = 6
        this.unfold(this.item1Index)
        this.item2Index = 1
      } else if (navUrl == '/backstage/personpage/pasManage') {
        this.item1Index = 6
        this.unfold(this.item1Index)
        this.item2Index = 2
      } else if (navUrl == '/backstage/personpage/PointsDetails') {
        this.item1Index = 6
        this.unfold(this.item1Index)
        this.item2Index = 3
      } else if (navUrl == '/backstage/personpage/MyInvitation') {
        this.item1Index = 6
        this.unfold(this.item1Index)
        this.item2Index = 4
      } else if (navUrl == '/contbackstage/personpage/pasManageact') {
        this.item1Index = 6
        this.unfold(this.item1Index)
        this.item2Index = 2
      } else if (navUrl == '/backstage/contactus/contactCS') {
        this.item1Index = 7
        this.unfold(this.item1Index)
        this.item2Index = 0
      } else if (navUrl == '/backstage/contactus/feedback') {
        this.item1Index = 7
        this.unfold(this.item1Index)
        this.item2Index = 1
      }
    },
    unfold(item1Index) {
      let subList = document.getElementById('subList' + item1Index)
      subList.style.height = 41 * this.navList[item1Index].child.length + 'rem'
      subList.style.transitionDuration = '0.5s'
      subList.style.transitionTimingFunction = 'ease-out'
    },
  },
}
</script>

<style lang="scss" scoped>
.navside {
  width: 100%;
  height: 100%;
  background-color: #0982f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topImg {
    // width: 160rem;
    width: 192rem;
    // height: 33rem;
    height: 40rem;
    margin-top: 60rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }
  .navList {
    width: 100%;
    margin-top: 50rem;
    &-item {
      width: 100%;
      margin-top: 40rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        display: inline-block;
        // min-width: 80px;
        padding: 0 6rem;
        font-size: 20rem;
        color: #fff;
        //   box-sizing: border-box;
        cursor: pointer;
      }
      .item1Sel {
        border-left: 6px solid #0cda86;
      }
      &-List {
        width: 100%;
        height: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-subtitle {
          display: inline-block;
          // min-width: 72px;
          height: 20rem;
          margin-top: 20rem;
          font-size: 18rem;
          color: rgba($color: #fff, $alpha: 0.8);
          cursor: pointer;
        }
        &-subtitle:hover {
          color: #fff;
        }
        .subtitleSeled {
          color: #fff;
        }
      }
      .sub0 {
        height: 0 !important    ;
        transition: 1s ease-out;
      }
    }
  }
}
</style>
