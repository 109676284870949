<template>
  <div class="tips">
    <el-result
      icon="success"
      title="支付成功"
      subTitle="感谢您的支持，我们将全力以赴"
    >
      <template slot="extra">
        <el-button type="primary" size="medium" @click="goOrder()"
          >查看订单</el-button
        >
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "成功",
    };
  },
  methods: {
    // 跳转到订单页
    goOrder() {
      this.$parent.$emit("navSideSel", { item1Index: 4, item2Index: 0 });
      this.$router.push("/backstage/orderMgt/orderQuery");
    },
  },
};
</script>

<style>
.tips {
  height: 100vh;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
