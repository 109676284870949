<template>
  <div class="dealRecord" v-loading="loading">
    <div class="PD_max">
      <div class="form">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="查询时间">
          <el-date-picker
            v-model="formInline.order_times"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付方式">
          <el-select v-model="formInline.pay_type" placeholder="支付方式">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in pay_type_list"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型">
          <el-select v-model="formInline.type" placeholder="订单类型">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in type_list"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    </div>
    <div class="recordNav">
      <div
        class="recordNav-item"
        v-for="(item, index) in navIndex"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="recordListBox">
      <el-empty
        v-show="recordList.length <= 0 && !loading"
        description="暂无记录"
      ></el-empty>
      <div
        class="recordListBox-item"
        v-for="(item, index) in recordList"
        :key="index"
      >
        <span>{{ item.order_no }}</span>
        <span>{{ formatOrderType(item.type) }}</span>
        <span
          :class="{ AmountZ: item.type == 3, AmountF: item.type != 3 }"
        >
          {{ item.type == 3 ? "+" : "-" }}{{ item.total_price }}
        </span>
        <span
          :class="{ AmountZ: item.type == 3, AmountF: item.type != 3 }"
        >
          {{ item.type == 3 ? "+" : "-" }}{{ item.pay_price }}
        </span>
        <span>{{ formatPayType(item.pay_type) }}</span>
        <span>{{ item.create_time }}</span>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { reqBalanceOrder } from "@/api";
export default {
  data() {
    return {
      formInline: {
        // 查询时间 上个月今天到今天的23:59:59
        order_times: [
          moment().subtract(1, "month").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],

        //是否已读
        type:0,
        //种类
        pay_type:'all',
        page_size: 10,
        page: 1,
      },
      type_list: [
      {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "媒体发布",
        },
        {
          value: "2",
          label: "基础服务",
        },
        {
          value: "3",
          label: "余额充值",
        },
      ],
      pay_type_list: [
      {
          value: "all",
          label: "全部",
        },
        {
          value: "wxpay",
          label: "微信支付",
        },
        {
          value: "alipay",
          label: "支付宝支付",
        },
        {
          value: "balance",
          label: "余额支付",
        },
      ],
      loading: true,

      pagination: 1,
      total: null,
      navIndex: [
        {
          name: "订单号",
        },
        {
          name: "类型",
        },
        {
          name: "订单金额（元）",
        },
        {
          name: "实际支付金额（元）",
        },
        {
          name: "支付类型",
        },
        {
          name: "创建时间",
        },
      ],
      recordList: [],
    };
  },
  mounted() {
    this.$parent.fatherMethod(5, 1);
    this.loading = true;
    this.formInline.type = this.type_list[0].value;
    this.formInline.pay_type = this.pay_type_list[0].value;
    reqBalanceOrder(this.formInline)
      .then((res) => {
        if (res.code == 1) {
          this.recordList = res.data.data.splice(0, 10);
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      })
      .catch((res) => {
        this.$message.error(res);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    onSubmit(){
      this.formInline.page = 1;
      this.loading = true;
      reqBalanceOrder(this.formInline)
      .then((res) => {
        if (res.code == 1) {
          this.recordList = res.data.data.splice(0, 10);
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      })
      .catch((res) => {
        this.$message.error(res);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    formatOrderType(type){
      if(type == 1){
        return "媒体发布"
      }else if(type == 2){
        return "基础服务"
      }else if(type == 3){
        return "余额充值"
      }else{
        return "其他"
      }
    },
    formatPayType(type){
      if(type == 'wxpay'){
        return "微信支付"
      }else if(type == 'alipay'){
        return "支付宝支付"
      }else if(type == 'balance'){
        return "余额支付"
      }else{
        return "其他"
      }
    },
    //分页
    pageChange(e) {
      this.pagination = e;
      this.formInline.page = e;
      reqBalanceOrder(this.formInline)
        .then((res) => {
          if (res.code == 1) {
            this.recordList = res.data.data.splice(0, 10);
            this.total = res.data.total;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        });
    },
    //时间戳转换
    getData(n) {
      let now = new Date(n),
        y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
      return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      //  + " " + now.toTimeString().substr(0, 8);    //时分秒转换
    }
  },
};
</script>

<style lang="scss" scoped>
.dealRecord {
  width: 100%;
  background-color: #fff;
  .recordNav {
    width: 97%;
    height: 40px;
    margin: 1px auto 0;
    background-color: #f5f5f5;
    display: flex;
    &-item {
      width: 20%;
      height: 40px;
      font-size: 17px;
      color: #999;
      text-align: center;
      line-height: 40px;
    }
  }
  .recordListBox::-webkit-scrollbar {
    display: none !important;
  }
  .recordListBox {
    width: 97%;
    height: 620px;
    overflow-y: hidden;
    margin: 0 auto;
    &-item {
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #e2e2e2;
      span {
        width: 16.6%;
        height: 100%;
        display: inline-block;
        font-size: 16px;
        color: #999;
        text-align: center;
        line-height: 60px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .AmountZ {
        color: #0982f9;
      }
      .AmountF {
        color: #f25b1b;
      }
    }
  }
  .pagination {
    width: 100%;
    padding: 10px 0;
    background-color: #fff;
    text-align: center;
    :deep {
      .number {
        // color: #999;
      }
    }
  }
}
</style>
