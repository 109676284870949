<template>
  <div class="feedback">
    <div
      class="content"
      v-loading="loading"
      element-loading-text="提交中···"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.8)"
    >
      <div class="content-desp">
        <span>问题描述：</span>
        <textarea
          class="content-desp-area"
          v-model="description"
          placeholder="请简述您的问题，我们会尽快给您答复......"
          :draggable="false"
        ></textarea>
      </div>
      <div class="content-note">
        <span>备注：</span>
        <textarea class="content-note-area" v-model="note"></textarea>
      </div>
      <button class="content-btn btn-12" @click="feedbackSubmit">
        <span>提交</span><span>填写反馈</span>
      </button>
    </div>
    <!-- 弹窗 -->
    <div
      @click="closeWrapper"
      class="messageBox"
      :class="
        FadeAnimate
          ? 'FriendUrlCreateWindowFadeInM'
          : 'FriendUrlCreateWindowFadeOutM'
      "
      v-show="Wrapper"
    >
      <div
        @click.stop="wu"
        class="messageBox-content"
        :class="
          FadeAnimate
            ? 'FriendUrlCreateWindowFadeIn'
            : 'FriendUrlCreateWindowFadeOut'
        "
      >
        <img
          class="messageBox-content-top"
          @click="closeWrapper"
          src="@/assets/backstage/contactUs/cha.png"
          alt="关闭"
        />
        <div class="messageBox-content-bot">
          <img
            src="@/assets/backstage/contactUs/icon-success.png"
            alt="成功反馈"
          />
          <span>感谢你的反馈，我们将尽快为你解决</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reqUserMsg } from "@/api";
export default {
  data() {
    return {
      description: "",
      note: "",

      loading: false,

      Wrapper: false,
      FadeAnimate: false,
    };
  },
  methods: {
    //提交反馈
    feedbackSubmit() {
      if (!this.description) {
        alert("请填写反馈内容");
        return;
      }
      if (this.isSub) {
        alert("提交请勿重复提交");
        return;
      }
      this.loading = true;
      reqUserMsg({
        type: "mail",
        title: 1,
        content: this.description,
        ext: this.note,
      })
        .then((res) => {
          if (res.code == 1) {
            this.Wrapper = true;
            this.FadeAnimate = true;

            this.description = "";
            this.note = "";
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => [(this.loading = false)]);
    },
    //关闭邮箱弹窗
    closeWrapper() {
      this.FadeAnimate = false;
      this.Wrapper = false;
    },
    wu() {},
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .content {
    width: 1100rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &-desp {
      margin-top: 30rem;
      display: flex;
      span {
        font-size: 18rem;
        color: #666;
      }
      &-area {
        width: 900rem;
        height: 380rem;
        padding: 20rem;
        border-radius: 6px;
        font-size: 17rem;
        outline: none;
        resize: none;
      }
      &-area::placeholder {
        color: #999999;
      }
    }
    &-note {
      margin-top: 20rem;
      display: flex;
      span {
        font-size: 18rem;
        color: #666;
      }
      &-area {
        width: 900rem;
        height: 120rem;
        padding: 20rem;
        border-radius: 6px;
        font-size: 17rem;
        outline: none;
        resize: none;
      }
      &-area::placeholder {
        color: #999999;
      }
    }
    &-btn {
      width: 114rem;
      height: 37rem;
      margin: 50rem auto;
      color: #fff;
      border-radius: 5px;
      padding: 10px 25px;
      font-family: "Lato", sans-serif;
      font-weight: 500;
      background: transparent;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      display: inline-block;
      outline: none;
    }
  }
}
//按钮动画
.btn-12 {
  position: relative;
  right: 20px;
  bottom: 20px;
  border: none;
  box-shadow: none;
  width: 114rem;
  height: 37rem;
  line-height: 37rem;
  -webkit-perspective: 230px;
  perspective: 230px;
}
.btn-12 span {
  background: rgb(0, 172, 238);
  background: linear-gradient(
    0deg,
    rgba(0, 172, 238, 1) 0%,
    rgba(2, 126, 251, 1) 100%
  );
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  font-size: 17rem;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-12 span:nth-child(1) {
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.btn-12:hover span:nth-child(1) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.btn-12:hover span:nth-child(2) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
//弹窗样式
.messageBox {
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #565656, $alpha: 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
  &-content {
    width: 400rem;
    height: 270rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: relative;
    &-top {
      width: 15rem;
      height: 15rem;
      object-fit: contain;
      position: absolute;
      right: 22rem;
      top: 22rem;
      cursor: pointer;
    }
    &-bot {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 90rem;
        height: 90rem;
        object-fit: contain;
      }
      span {
        margin-top: 30rem;
        font-size: 18rem;
        color: #999;
      }
    }
  }
}
//弹窗动画
.FriendUrlCreateWindowFadeIn {
  animation: FadeIn 0.2s ease-out;
}
.FriendUrlCreateWindowFadeOut {
  animation: FadeOut 0.2s ease-out;
}
@keyframes FadeIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes FadeOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}
.FriendUrlCreateWindowFadeInM {
  animation: FadeInM 0.2s ease-out;
}
.FriendUrlCreateWindowFadeOutM {
  animation: FadeOutM 0.2s ease-out;
}
@keyframes FadeInM {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes FadeOutM {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
