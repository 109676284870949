<template>
  <div id="app">
    <Nav v-if="agentType == 'mtt'" v-show="$route.meta.nav" class="nav"></Nav>
    <Agent1Nav
      v-if="agentType == 'agent1'"
      v-show="$route.meta.nav"
      class="nav1"
    ></Agent1Nav>
    <Agent2Nav
      v-if="agentType == 'agent2'"
      v-show="$route.meta.nav"
      class="nav1"
    ></Agent2Nav>
    <Agent3Nav
      v-if="agentType == 'agent3'"
      v-show="$route.meta.nav"
      class="nav1"
    ></Agent3Nav>
    <router-view v-if="isRouterAlive"></router-view>
    <!-- <Sideicon v-show="$route.meta.sideicon" class="sideIcon"></Sideicon> -->
    <Sideicon v-show="$route.meta.sideicon" class="sideIcon"></Sideicon>

    <Footer
      v-if="agentType == 'mtt'"
      v-show="$route.meta.footer"
      class="footer"
    ></Footer>
    <Agent1Footer
      v-if="agentType == 'agent1'"
      v-show="$route.meta.footer"
    ></Agent1Footer>
    <Agent2Footer
      v-if="agentType == 'agent2'"
      v-show="$route.meta.footer"
    ></Agent2Footer>
    <Agent3Footer
      v-if="agentType == 'agent3'"
      v-show="$route.meta.footer"
    ></Agent3Footer>
  </div>
</template>

<script>
import agentMixin from '@/mixins/agentId.mixins'
import Sideicon from './components/Sideicon'
//mtt
import Nav from './components/Nav'
import Footer from './components/Footer'
//Agent1
import Agent1Nav from './components/Agent1_nav'
import Agent1Footer from './components/Agent1_footer'
import Agent2Footer from './components/Agent2_footer'
import Agent3Footer from './components/Agent3_footer'
//Agent2
import Agent2Nav from './components/Agent2_nav'
//Agent3
import Agent3Nav from './components/Agent3_nav'

export default {
  name: 'App',
  components: {
    Nav,
    Footer,
    Agent1Nav,
    Agent1Footer,
    Agent2Footer,
    Agent3Footer,
    Agent2Nav,
    Agent3Nav,
    Sideicon,
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  mixins: [agentMixin],
  created() {
    //解决vuex数据在页面刷新被重置的问题

    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  mounted() {
    let app = document.getElementById('app')
    app.style.minWidth = '1200px'
    if (sessionStorage.getItem('agent')) {
      this.$store.commit('global/setAgentId', sessionStorage.getItem('agent'))
    } else {
      this.$store.commit('global/setAgentId', 0)
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
  },
}
</script>

<style lang="scss">
#app {
  min-width: 1200px;
  font-family: 12px/1.5 'PingFang SC', 'Helvetica Neue', Helvetica,
    'Hiragino Sans GB', 'Microsoft YaHei', Arial, sans-serif;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
.nav1 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
}
.sideIcon {
  width: 53px;
  height: 175px;
  position: fixed;
  top: 700rem;
  right: 0px;
  z-index: 999;
}
.footer {
  display: block;
  margin-top: -70rem;
  position: relative;
  z-index: 990;
}

.btnTime1 {
  // width: 100px;
  // height: 40px;
  border-radius: 10px;
  background-color: #00b16a;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnTime1::before {
  content: '';
  display: block;
  width: 25px;
  height: 50px;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-25deg);
  position: absolute;
  left: -35px;
  animation: guang infinite 1s ease-in;
}
@keyframes guang {
  50% {
    left: 0%;
  }

  100% {
    left: 100%;
  }
}
body {
  padding-right: 0px !important;
}
</style>
