<template>
  <div class="orderMgt">
    <div class="nav">
      <span>首页 / 订单管理 / </span>
      <span>{{ navList[navIndex].name }}</span>
    </div>
    <router-view class="router"></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$parent.$on("navLocation", (msg) => {
      if (msg) {
        this.navIndex = msg;
      }
    });
  },
  data() {
    return {
      navIndex: 0,
      navList: [
        {
          name: "媒体订单查询",
          path: "orderQuery",
        },
        {
          name:"基础订单查询",
          path:"baseOrderQuery"
        }
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.orderMgt {
  width: 100%;
  height: 100%;
  .nav {
    width: 100%;
    height: 50rem;
    background-color: #fff;
    line-height: 50rem;
    span {
      font-size: 14rem;
      color: #999;
    }
    span:first-child {
      margin-left: 20rem;
    }
    span:last-child {
      color: #666;
    }
  }
  .router {
    width: 100%;
    // height: 890rem;
    margin-top: 20rem;
  }
}
</style>
