<template>
  <div class="chatPage">
    <!-- 装聊天框组件的容器 -->
    <div class="chatContainer">
      <ChatClient :user="user" />
    </div>
  </div>
</template>
<script>
import ChatClient from "./components/ChatClient";
export default {
  components: {
    ChatClient,
  },
  data() {
    return {
      user: {},
    };
  },
  created() {},
};
</script>
<style>
.chatPage {
  position: relative;
  /* background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%); */
  background: #0982f9;
  /* 阴影 */
  /* box-shadow: 0 0 10px rgba(189, 13, 13, 0.2); */
  width: 100vw;
  height: 100vh;
}
.chatContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 666px;
  height: 60%;
}
</style>
