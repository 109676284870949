<template>
  <div class="agent3_home">
    <!-- 头部BG -->
    <div class="bg">
      <img class="bg_img" src="@/assets/agent/3/agent3_home_BG.png" alt="" />
      <div class="bg_box">
        <div class="bg_box_title">为每位客户提供更好的服务</div>
        <div class="bg_box_content">
          用专业为一个个客户带去品牌的传播和销量的提升
        </div>
      </div>
    </div>
    <!-- 我的优势 -->
    <div class="advantage">
      <div class="advantage_item">
        <img
          class="advantage_item_bg"
          src="@/assets/agent/3/agent3_advantage1.png"
          alt=""
        />
        <div class="advantage_item_content">{{ advantageList[0].content }}</div>
      </div>
      <div class="advantage_item">
        <img
          class="advantage_item_bg"
          src="@/assets/agent/3/agent3_advantage2.png"
          alt=""
        />
        <div class="advantage_item_content">{{ advantageList[1].content }}</div>
      </div>
      <div class="advantage_item">
        <img
          class="advantage_item_bg"
          src="@/assets/agent/3/agent3_advantage3.png"
          alt=""
        />
        <div class="advantage_item_content">{{ advantageList[2].content }}</div>
      </div>
    </div>
    <!-- 平台资源 -->
    <div class="resource">
      <div class="resource_title">平台资源</div>
      <div class="resource_box">
        <div
          class="resource_box_item"
          v-for="(item, index) in resourceList"
          :key="index"
        >
          <img
            class="resource_box_item_img"
            :src="require('@/assets/agent/3/' + item.img)"
            alt=""
          />
          <div class="resource_box_item_name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- 下单流程 -->
    <div class="process">
      <div class="process_title">下单流程</div>
      <div class="process_detail">
        <img
          class="process_detail_img"
          src="@/assets/agent/3/agent3_fagao.png"
          alt=""
        />
      </div>
    </div>
    <!-- 知识学院 -->
    <div class="knowledge">
      <div class="knowledge_title">知识学院</div>
      <div class="knowledge_box">
        <div class="knowledge_box_left" @click="goDetail(leftContentTopList[0].id)">
          <img
            class="knowledge_box_left_img"
            src="@/assets/agent/2/agent2_display.png"
            alt=""
          />
          <div class="knowledge_box_left_title">
            {{leftContentTopList[0].title}}
          </div>
          <div class="knowledge_box_left_content">
            {{leftContentTopList[0].description}}
          </div>
        </div>
        <div class="knowledge_box_right">
          <div
            class="knowledge_box_right_item"
            @click="goDetail(item.id)"
            v-for="(item,index) in rightContentTopList"
            :key="index"
          >
            <img
              class="knowledge_box_right_item_img"
              :src="item.img_url"
              alt=""
            />
            <div class="knowledge_box_right_item_content">
              <div class="knowledge_box_right_item_content_title">
                <div class="knowledge_box_right_item_content_title_l">
                  {{item.title}}
                </div>
                <div class="knowledge_box_right_item_content_title_r">
                  {{item.create_time.slice(0,10)}}
                </div>
              </div>
              <div class="knowledge_box_right_item_content_text">
                {{item.description}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作平台 -->
    <div class="cooperation">
      <div class="cooperation_title">合作平台</div>
      <div class="cooperation_box">
        <img
          class="cooperation_box_img"
          v-for="(item, index) in cooperationList"
          :key="index"
          :src="require('@/assets/agent/1/' + item.img)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  loginPost,
  loginCodePost,
  reqTenxun,
  reqArticleNav,
  reqArticleList,
} from "@/api";
export default {
  data() {
    return {
      leftContentTopList:[{
          description: "这是一条咨讯详情",
          id: "1",
          img_url: "https://www.mtuitui.com/onlineSource/title-left.png",
          thumb_image_id: "1",
          title: "这是一条咨讯标题",
          update_time: "",
          create_time: "2022-11-28 09:10:59",
        }],
      rightContentTopList:[],
      advantageList: [
        {
          title: "公司优势",
          content:
            "1、专业经验：资深团队专业指导，懂你所想。\n\n2、一手资源：直接对接各大平台和编辑，执行更畅通。\n\n3、专属客服：一对一客服解惑答疑，第一时间解决您的需求。",
        },
        {
          title: "权益保障",
          content:
            "1、技术保障：资深团队，保障平台平稳运行。\n\n2、财务保障：透明流水，充值提现灵活选择。\n\n3、发稿保障：专属客服，随时应答发稿问题。",
        },
        {
          title: "服务对象",
          content: "餐饮、食品、制造、公关、广告、等各领域企事业单位",
        },
      ],
      resourceList: [
        {
          img: "agent3_icon_1.png",
          name: "新闻",
        },
        {
          img: "agent3_icon_2.png",
          name: "自媒体",
        },
        {
          img: "agent3_icon_3.png",
          name: "论坛",
        },
        {
          img: "agent3_icon_4.png",
          name: "短视频",
        },
        {
          img: "agent3_icon_5.png",
          name: "网站",
        },
        {
          img: "agent3_icon_6.png",
          name: "达人",
        },
        {
          img: "agent3_icon_7.png",
          name: "广告",
        },
        {
          img: "agent3_icon_8.png",
          name: "社群",
        },
      ],
      cooperationList: [
        {
          img: "agent1_cor1.png",
        },
        {
          img: "agent1_cor2.png",
        },
        {
          img: "agent1_cor3.png",
        },
        {
          img: "agent1_cor4.png",
        },
        {
          img: "agent1_cor5.png",
        },
        {
          img: "agent1_cor6.png",
        },
        {
          img: "agent1_cor7.png",
        },
        {
          img: "agent1_cor8.png",
        },
        {
          img: "agent1_cor9.png",
        },
        {
          img: "agent1_cor10.png",
        },
      ],
    };
  },
  mounted(){
    this.initView();
  },
  methods: {
    initView(){
        //首页四个
        reqArticleList({ type: 1 })
        .then((res) => {
          if (res.code == 1) {
            // this.contentTopList = res.data.data.slice(0, 4);
            let articleList = res.data.data;
            //清空原有数据
            this.leftContentTopList.length = 0;
            this.rightContentTopList.length = 0;
            for(let i=0;i<articleList.length;i++){
              if(articleList[i].create_time == undefined || articleList[i].create_time == null){
                articleList[i].create_time = articleList[i].update_time;
              }
                if(i  == 0){
                  this.leftContentTopList.push(articleList[i]);
                }else{
                  this.rightContentTopList.push(articleList[i]);
                }
            }
            console.log(this.leftContentTopList);
            console.log(this.rightContentTopList);

          } else {
            // this.$message.error(res.message);
            console.log(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          // this.loadingTop = false;
        });
    },
    goDetail(id) {
      this.$router.push({
          path:'/template3/knowledgein/'+id+ '.html'
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.agent3_home {
  width: 100%;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 头部BG */
.bg {
  width: 100%;
  position: relative;
}
.bg_img {
  display: block;
  width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 2;
}
.bg_box {
  width: 1200px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 250px;
  left: 360px;
  z-index: 4;
}
.bg_box_title {
  margin-bottom: 50px;
  font-size: 42px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}
.bg_box_content {
  font-size: 24px;
  color: #fff;
  letter-spacing: 1px;
}
/* 我的优势 */
.advantage {
  width: 1200px;
  padding: 0 0 90px;
  display: flex;
  justify-content: space-between;
}
.advantage_item {
  width: 304px;
  height: 322px;
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.advantage_item_bg {
  width: 374px;
  height: 322px;
  object-fit: contain;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.advantage_item_content {
  font-size: 20px;
  color: #fff;
  line-height: 28px;
  white-space: pre-line;
  position: relative;
  z-index: 4;
}
/* 平台资源 */
.resource {
  width: 100%;
  padding: 90px 0 70px;
  background-color: #ecf9ff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resource_title {
  margin-bottom: 70px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.resource_box {
  width: 1200px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: transparent;
}
.resource_box_item {
  width: 284px;
  height: 178px;
  margin: 0 0 20px 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.resource_box_item_img {
  width: 72px;
  height: 72px;
  margin-bottom: 13px;
  object-fit: contain;
  display: block;
}
.resource_box_item_name {
  font-size: 20px;
  color: #333;
  font-weight: bold;
}
/* 下单流程 */
.process {
  width: 1200px;
  padding-top: 90px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.process_title {
  margin-bottom: 70px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.process_detail {
  width: 1150px;
}
.process_detail_img {
  width: 100%;
  object-fit: contain;
  display: block;
}
/* 知识学院 */
.knowledge {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.knowledge_title {
  margin-bottom: 98px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.knowledge_box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.knowledge_box_left {
  width: 504px;
  height: 438px;
  padding-bottom: 20px;
  box-shadow: 0px 1px 6px 1px rgba(59, 61, 71, 0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.knowledge_box_left:hover {
  // box-shadow: 0px 6px 12px 1px rgba(59, 61, 71, 0.16);
  // transform: translateY(-8px);
  // transition: 0.2s ease-out;
}
.knowledge_box_left:hover .knowledge_box_left_title {
  color: #00c8c8;
  transition: 0.2s ease-out;
}
.knowledge_box_left_img {
  width: 100%;
  object-fit: contain;
  display: block;
}
.knowledge_box_left_title {
  width: 462px;
  margin: 30px 0 20px;
  font-size: 20px;
  color: #999;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.knowledge_box_left_content {
  width: 462px;
  font-size: 16px;
  color: #999;
  line-height: 24px;
  letter-spacing: 1px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.knowledge_box_right {
  width: 646px;
  height: 454px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}
.knowledge_box_right_item {
  width: 580px;
  padding: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c5c3c3;
  display: flex;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.knowledge_box_right_item:hover {
  // padding: 7px 13px 30px;
  // color: #00c8c8;
  border-bottom: none;
  box-shadow: 0px 6px 12px 1px rgba(59, 61, 71, 0.16);
  transform: translateY(-8px);
  transition: 0.2s ease-out;
}
.knowledge_box_right_item_img {
  width: 0;
  margin-right: 14px;
  object-fit: contain;
  display: block;
  transition: 0.2s ease-out;
}
.knowledge_box_right_item:hover .knowledge_box_right_item_img {
  width: 126px;
  transition: 0.2s ease-out;
}
.knowledge_box_right_item_content {
  display: flex;
  flex-direction: column;
}

.knowledge_box_right_item_content_title {
  width: 560px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  transition: 0.2s ease-out;
}
.knowledge_box_right_item:hover .knowledge_box_right_item_content_title {
  width: 440px;
  transition: 0.2s ease-out;
}
.knowledge_box_right_item_content_title_l {
  width: 400px;
  font-size: 18px;
  color: #999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  transition: 0.2s ease-out;
}
.knowledge_box_right_item:hover .knowledge_box_right_item_content_title_l {
  width: 300px;
  transition: 0.2s ease-out;
}
.knowledge_box_right_item_content_title_r {
  font-size: 12px;
  color: #999;
}
.knowledge_box_right_item_content_text {
  width: 440px;
  height: 0;
  margin-top: 14px;
  font-size: 14px;
  color: #999;
  line-height: 22px;
  letter-spacing: 1px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  transition: 0.2s ease-out;
}
.knowledge_box_right_item:hover .knowledge_box_right_item_content_text {
  height: fit-content;
  transition: 0.2s ease-out;
}
/* 合作平台 */
.cooperation {
  width: 100%;
  margin-top: 20px;
  padding: 0 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cooperation_title {
  margin-bottom: 70px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
}
.cooperation_box {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cooperation_box_img {
  width: 224px;
  margin-bottom: 20px;
  object-fit: contain;
  transition: 0.2s ease-out;
}
.cooperation_box_img:hover {
  box-shadow: 0 3px 8px 1px rgba(9, 130, 249, 0.19);
  transition: 0.2s ease-out;
}
</style>
