
import { mapState } from "vuex";
import {reqPageConfig} from '@/api'
export default {
    computed: {
      ...mapState({
          agentId: (state) => state.global.agentId,
      }),
    },
    data () {
      return {
        agentType: "mtt",
        company_name: "",
        logo: "",
        email: "",
        address: "",
        phone: "",
        qrcode1: "",
        qrcode2: "",
        company_introduce: "",
        beian: "",
        desc: "",
		    keyword: ""
      }
    },
    mounted () {
      console.log(this.$route.path);
      let agent = sessionStorage.getItem("agent");
      if(agent == null){
        agent = 0;
      }
      console.log(agent);
      if(this.$route.path.indexOf('/template1/') == 0  ){
        sessionStorage.setItem("agent", 1);
        this.$store.commit("global/setAgentId", 1);
        this.agentType = "agent1";
        
      }else if(this.$route.path.indexOf('/template2/') == 0 ){
        sessionStorage.setItem("agent", 2);
        this.$store.commit("global/setAgentId", 2);
        this.agentType = "agent2";
      }else if(this.$route.path.indexOf('/template3/') == 0 ){
        sessionStorage.setItem("agent", 3);
        this.$store.commit("global/setAgentId", 3);
        this.agentType = "agent3";
      }else{
        sessionStorage.setItem("agent", 0);
        this.$store.commit("global/setAgentId", 0);
        this.agentType = "mtt";
      }
      this.changeAgentId()
    },
    methods: {
      // 用于判断代理商Id类型
      changeAgentId () {
        reqPageConfig().then((res)=>{
          console.log(res);
          
          if(res.code == 1){
            this.company_name = res.data.company_name;
            this.logo = res.data.logo;
            this.email = res.data.email;
            this.phone = res.data.phone;
            this.address = res.data.address;
            this.qrcode1 = res.data.gz_ewm;
            this.qrcode2 = res.data.lx_ewm;
            this.company_introduce = res.data.company_introduce;
            this.beian = res.data.beian;
            this.desc = res.data.description;
            this.keyword = res.data.keyword;
            this.$store.commit("global/setAgentSiteInfo", res.data);
            this.$store.commit("global/setAgentId", res.data.view_id);
            sessionStorage.setItem("agent", res.data.view_id);
            
            if(this.agentId == 1){
              this.agentType = "agent1"
            }else if(this.agentId == 2){
              this.agentType = "agent2"
            }else if(this.agentId == 3){
              this.agentType = "agent3"
            }else{
              this.agentType = "mtt"
            }
          }else{
            //默认公司信息
            this.company_name = '重庆媒推推网络科技有限公司';
            this.logo = '/upload/file/20221125/3cdfd7888cd05c76181a4d7a8dd54c77.png';
            this.email = '1450602475@qq.com';
            this.phone = '19942227311';
            this.address = '重庆市江北区观音桥街道建新北路65号第8层（自编号301号）';
            this.qrcode1 = '/upload/file/20221125/677ca1cd48456b7991e8e5b0aef00180.jpg';
            this.qrcode2 = '/upload/file/20221125/8e7d780ff8a92fe5f87c948b9dab549a.jpg';
            this.company_introduce = `媒推推隶属于重庆媒推推网络科技有限公司，是一个广告媒体综合管理平台。 媒推推整合了全国上万家主流媒体、自媒体资源、形成强势媒体联盟，提供媒体推广资源、广告投放平台。 凭借多年资源的积累和客户良好口碑的拓展，服务的企业客户行业包括互联网、餐饮、人力资源、银行、汽车、能源、房产、旅游、酒店、零售等，媒推推秉承诚信立足，创新致远的服务理念，始终坚持为全球企业发展提供有价值的性价比服务！`;
            this.beian = '渝ICP备2022008800号-1';
            this.desc ='媒推推,软文推广,短视频推广';
            this.keyword = '媒推推,软文推广,短视频推广';
            // this.agentType = "mtt";
            var agentSiteInfo = {
              company_name:this.company_name,
              logo:this.logo,
              email:this.email,
              phone:this.phone,
              address:this.address,
              gz_ewm:this.qrcode1,
              lx_ewm:this.qrcode2,
              company_introduce:this.company_introduce,
              beian:this.beian,
              desc:this.desc,
              keyword:this.keyword,
              // view_id:0
            };
            this.$store.commit("global/setAgentSiteInfo", agentSiteInfo);
            // this.$store.commit("global/setAgentId", 0);
            // sessionStorage.setItem("agent", 0);
          }
          
         
        });
        
      }
    },
  }