<template>
  <div class="article">
    <div class="article-title">
      <div class="article-title-line">
        <div></div>
        <h1>热门媒体</h1>
      </div>
      <span @click="goMedia">查看更多</span>
    </div>
    <div class="article-content">
      <el-empty
        v-show="hotMediaList.length <= 0"
        image="https://www.mtuitui.com/onlineSource/contentEmpty.png"
        description="暂无媒体"
      ></el-empty>
      <div
        class="article-content-item"
        v-for="(item, index) in hotMediaList"
        :key="index"
      >
        <img
          src="@/assets/mediaSource/netmediadisplay.png"
          :alt="item.name"
          @click="toAnli(item.url)"
        />
        <div class="article-content-item-right">
          <span @click="toAnli(item.url)">{{ item.name }}</span>
          <div class="article-content-item-right-price">
            <span>￥{{ item.price }}</span>
            <button
              @click="shopCartNumAdd(item, index)"
              :class="{
                addCar: shopCar.some((goods) => {
                  return goods.id == item.id;
                }),
              }"
            >
              {{
                shopCar.some((goods) => {
                  return goods.id == item.id;
                })
                  ? "移出购物车"
                  : "加入购物车"
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { reqHotMedia } from "@/api";
export default {
  name: "articles",
  computed: {
    ...mapState({
      shopCar: (state) => state.global.shopCar,
      token: (state) => state.global.token,
    }),
  },
  data() {
    return {
      hotMediaList: [],
    };
  },
  mounted() {
    reqHotMedia()
      .then((res) => {
        if (res.code == 1) {
          this.hotMediaList = res.data.splice(0, 5);
        } else {
          // this.$message.error(res.message);
          console.log(res.message);
        }
      })
      .catch((res) => {
        this.$message.error(res);
      });
  },
  methods: {
    shopCartNumAdd(item) {
      if (!this.token) {
        this.$message({
          message: "请先登录",
          type: "error",
          duration: "1500",
        });
        this.$router.push("/login");
        return;
      }
      if (
        this.shopCar.some((goods) => {
          return goods.id == item.id;
        })
      ) {
        this.$store.dispatch("global/shopCartNumReduce", item);
      } else {
        this.$store.dispatch("global/shopCartNumAdd", item);
      }
    },
    goMedia() {
      let nav = {
        name: "媒体资源",
        color: "transparent",
      };
      this.$store.dispatch("global/navColorChange", nav);
      this.$router.push("/mediasource");
      this.$bus.$emit("mediaSJ", 0);
    },
    toAnli(url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        this.$message.warning("暂无案例");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.article {
  width: 100%;
  // height: 100%;
  max-height: 710rem;
  background-color: #fff;
  cursor: default;
  &-title {
    width: 90%;
    // height: 15px;
    padding: 25rem 0 10rem;
    margin: 0 auto;
    border-bottom: 1px solid #999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-line {
      width: 110rem;
      display: flex;
      align-items: center;
      div {
        width: 4rem;
        height: 22rem;
        margin-right: 3rem;
        background-color: #00d27c;
      }
      h1 {
        font-size: 24rem;
        color: #333;
      }
    }
    span {
      font-size: 16rem;
      color: #999;
      cursor: pointer;
    }
    span:hover {
      color: #0982f9;
    }
  }
  &-content {
    width: 90%;
    margin: 0 auto;
    // display: flex;
    // flex-direction: column;
    &-item {
      width: 100%;
      height: 108rem;
      margin-top: 20rem;
      border-bottom: #f3f3f3 1px solid;
      display: flex;
      justify-content: space-between;
      img {
        width: 124rem;
        height: 93rem;
        object-fit: contain;
        cursor: pointer;
      }
      &-right {
        width: 185rem;
        height: 93rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span {
          font-size: 20rem;
          color: #333;
          cursor: pointer;
        }
        span:hover {
          color: #0982f9;
        }
        &-price {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-size: 24rem;
            color: #ff3852;

            cursor: default;
          }
          span:hover {
            color: #ff3852;
          }
          button {
            // width: 82rem;
            padding: 0 5rem;
            height: 28rem;
            background-color: #ff3852;
            font-size: 14rem;
            color: #fff;
            text-align: center;
            border-radius: 4px;
            white-space: nowrap;
            text-overflow: ellipsis; /*文字超出部分以省略号显示*/
            cursor: pointer;
            outline: none;
            border: none;
          }
          button:hover {
            background-color: rgba($color: #ff3852, $alpha: 0.7);
          }
          button:active {
            background-color: rgba($color: #ff3852, $alpha: 0.9);
          }
          .addCar {
            background-color: rgba($color: #0982f9, $alpha: 0.2);
            color: #666;
          }
        }
      }
    }
  }
}
</style>
