<template>
    <v-barrage
      :arr="bulletChat.arr"
      :isPause="bulletChat.isPause"
      :percent="100"
    ></v-barrage>
</template>

<script>
const chatData = [
  {
    name: "郭大炮",
    content: "我就说了，主播真帅",
  },
  {
    name: "一只小团团",
    content: "主播表演跳舞吗，红红火火恍恍惚惚",
  },
  {
    name: "圆圈它不圆",
    content: "圆圈它不圆圆圈它不圆圆圈它不圆",
  },
  {
    name: "河南加油",
    content: "河南加油河南加油河南加油河南加油",
  },
];
import VBarrage from "./VBarrage/index.vue";
export default {
  components: { VBarrage },
  data() {
    return {
      chatData: chatData, // 弹幕列表
      liveHeight: { height: 0 },
      bulletChat: {
        arr: [
        ], // 传入的弹幕源数组
        isPause: false, // 控制是否暂停弹幕
        sendContent: null, // 自己发送的弹幕内容
        isJs: false, // 是否解析html
        direction: "default",
      },
    };
  },
  mounted() {
    // 初始化弹幕
    this.bulletChatData();
  },
  methods: {
    // 初始化模拟弹幕数据
    bulletChatData() {
      let arr = [
          "好评",
          "不错",
          "严谨认真",
          "准时交付",
          "专业",
          "有创意",
          "超出期望",
          "值得推荐",
          "专业高效",
          "沟通顺畅",
          "完美完成",
          "很好",
          "交付准时",
          "注重细节",
          "非常满意",
          "值得信耐",
          "敬业",
          "性价比高",
          "细致耐心",
          "信誉很好",
      ];
      for (let i = 0; i < 6; i++) {
        for (let index = 0; index < 1000; index++) {
          // if (index % 2 == 0) {
            // this.bulletChat.arr.push({
            //   direction: "top",
            //   content: arr[parseInt(Math.random() * arr.length)],
            // });
          // } else {
            this.bulletChat.arr.push({
              direction: "default",
              content: arr[parseInt(Math.random() * arr.length)],
            });
          // }
        }
      }
    },
    // 发送弹幕
    sendBarrage() {
      if (
        this.bulletChat.arr.length > 1 &&
        this.bulletChat.sendContent != "" &&
        this.bulletChat.sendContent != null
      ) {
        this.bulletChat.arr.unshift({
          content: this.bulletChat.sendContent, // 弹幕内容
          direction: this.bulletChat.direction, // 方向  default | top
          isSelf: true, // 是否是自己发的弹幕
          style: {
            color: "red", // 弹幕颜色
            fontSize: "25px",
          },
          isJs: this.bulletChat.isJs, // 是否解析html
        });
      } else {
        this.bulletChat.arr.push({
          content: this.bulletChat.sendContent,
          direction: this.bulletChat.direction,
          isSelf: true,
          style: {
            color: "red",
          },
          isJs: this.bulletChat.isJs,
        });
      }
      this.bulletChat.sendContent = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.barrage-control {
  text-align: center;
  margin: 10px 0px;
}
</style>