<template>
  <div class="footerPage">
    <img
      class="footerPage-foottt"
      src="@/assets/footer/footer-bg.png"
      alt="页脚"
    />
    <div class="footer">
      <div class="footer_item footer_contact">
        <div>
          <!-- <div class="footer_contact_item">
            <span>{{ footerList.contact_first_title }}：</span>
            <span>{{ footerList.contact_first_mobile }}</span>
          </div> -->
          <div class="footer_contact_item">
            <span>{{ footerList.contact_second_title }}：</span>
            <span>{{ footerList.contact_second_mobile }}</span>
          </div>
          <div class="footer_contact_item">
            <span>{{ footerList.contact_third_title }}：</span>
            <span>{{ footerList.contact_third_mobile }}</span>
          </div>
        </div>
      </div>
      <div class="footer_item footer_Copyright">
        <img class="logo" src="../../assets/page/logo.gif" alt="" />
        <div>
          <p>
            Copyright©2010-2022 mtuitui.com 重庆媒推推网络科技有限公司 版权所有
          </p>
          <p>
            <a href="https://beian.miit.gov.cn/#/Integrated/index"
              >渝ICP备2022008800号-1</a
            >
            <img
              src="../../assets/footer/GongAn.png"
              alt=""
              class="footer-logo-gongan-a-img"
            />
            <a
              href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50011202503686号"
            >
              渝公网安备 50011202503686号
            </a>
          </p>
        </div>
      </div>

      <div class="footer_item footer_QRCode">
        <div class="footer_QRCode_item">
          <img :src="$baseURL + footerList.follow_qrcode_img" alt="" />
          <div>{{ footerList.follow_qrcode_title }}</div>
        </div>
        <div class="footer_QRCode_item">
          <img :src="$baseURL + footerList.kefu_qrcode_img" alt="" />
          <div>{{ footerList.kefu_qrcode_title }}</div>
        </div>
        <div class="footer_QRCode_item">
          <img :src="$baseURL + footerList.contact_qrcode_img" alt="" />
          <div>{{ footerList.contact_qrcode_title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { reqFooter } from '@/api/index.js'
export default {
  computed: {
    ...mapState({
      navBackColor: (state) => state.global.navBackColor,
    }),
  },
  data() {
    return {
      footerList: {
        contact_qrcode_img: '',
        contact_qrcode_title: '',
        follow_qrcode_img: '',
        follow_qrcode_title: '',
        kefu_qrcode_img: '',
        kefu_qrcode_title: '',
        contact_first_mobile: '',
        contact_first_title: '',
        contact_second_mobile: '',
        contact_second_title: '',
        contact_third_mobile: '',
        contact_third_title: '',
      },
      phoneNumber: '19942227311',
      mediaList: [
        '网站',
        '自媒体',
        '微信公众号',
        '新浪微博',
        '短视频',
        '媒体套餐',
        '网站SEO',
        'APP',
        '小程序',
        '百科词条',
      ],
      serviceList: [
        '网站',
        '媒体资源',
        '文案代写',
        '合作加盟',
        '关于我们',
        '知识学院',
      ],
      entryList: ['联系我们', '会员注册/登录'],
    }
  },
  created() {
    reqFooter().then((res) => {
      console.log(res.data, '-------------')
      this.footerList = res.data
    })
  },
  methods: {
    goBeian() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
    },
    goEntry(data) {
      if (data == '联系我们' && data != this.navBackColor.name) {
        let nav = {
          name: data,
          color: 'transparent',
        }
        this.$store.dispatch('global/navColorChange', nav)
        this.$router.push('/contact')
      } else if (data == '会员注册/登录' && data != this.navBackColor.name) {
        this.$router.push('/login')
      }
    },
    hezuo(item) {
      if (item == '合作加盟') {
        this.$store.commit('global/setAgentId', 1)
        sessionStorage.setItem('agent', 1)
        this.$router.go(0)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.footerPage-foottt {
  // background-color: rgb(0, 128, 255);
  position: absolute;
  left: -3rem;
  width: 101%;
  height: 380rem;
  z-index: 222;
}
.footerPage {
  min-width: 1000px;
  width: 100%;
  height: 380rem;
  // background-color: #0982f9;
  // height: 390px;
  position: relative;
  overflow-x: hidden;

  .footer {
    overflow-x: hidden;
    width: 100%;
    // height: 380px;
    transform: translateY(168rem);
    height: 260rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    z-index: 222;
    .footer_item {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .footer_QRCode {
      display: flex;
      .footer_QRCode_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 20rem;
        img {
          width: 100rem;
          height: 100rem;
        }
        div {
          font-size: 12rem;
          color: #fff;
          margin-top: 10rem;
        }
      }
    }
    .footer_Copyright {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #c7c7c7;

      .logo {
        width: 500rem;
        height: 100rem;
        margin-bottom: 10rem;
      }
      div {
        p:nth-child(2) {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 3rem 0;
          a {
            color: #c7c7c7;
            text-decoration: none;
          }
          a:nth-child(1) {
            margin: 0 3rem;
          }
          a:hover {
            color: #c7c7c7 !important;
          }
        }
        p:nth-child(3) {
          text-align: center;
        }
      }
      .footer-logo-gongan-a-img {
        width: 16rem;
        height: 16rem;
        margin: 0 3rem;
      }
    }
    .footer_contact {
      display: flex;
      flex-direction: column;
      .footer_contact_item {
        display: flex;
        margin-bottom: 10rem;
        span {
          font-size: 30rem;
          color: #fff;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
