<template>
  <div class="forgetPsd">
    <div class="forgetPsd-out">
      <span>已有登录账号，</span>
      <span @click="goLogin">立即登录</span>
      <span @click="goHome">返回首页</span>
    </div>
    <div class="forgetPsd-box">
    <div class="forgetPsd-box-process">
        <div v-show="processIndex == 1" class="getback">
          <span class="title">找回密码</span>
          <span class="warn" id="warnTel"></span>

          <div class="inputBox">
            <span>请输入手机账号：</span>
            <input v-model="tel" type="number" oninput="if(value.length > 11)value = value.slice(0, 11)"
              onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))" />
          </div>
          <div class="inputBox">
            <span>
              &nbsp
              &nbsp
              &nbsp
              手机验证码：</span>
            <input class="inputBox-code" v-model="code" type="text" maxlength="4" />
            <div :class="{ telInput: codeTime == 0 && tel.length > 0 }" @click="sendTelCode" id="codeTel">
              发送验证码
            </div>
          </div>
        </div>
        <div class="modify">
          <div class="inputBox">
            <span>
              &nbsp
              &nbsp
              &nbsp
              &nbsp
              &nbsp
              &nbsp

              新密码：</span>
            <input v-model="password" type="text" placeholder="输入密码" />
          </div>
          <div class="inputBox">
            <span>
              &nbsp
              &nbsp
              &nbsp
              &nbsp
              确认密码：</span>
            <input v-model="passwordRe" type="text" placeholder="确认密码" />
          </div>
          <button @click="modifySubmit">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { telFormation } from "@/utils/judge";
import { reqResetpwdcode2, reqResetpwd2 } from "@/api/index";
export default {
  data() {
    return {
      processIndex: 1,

      tel: "",
      code: "",
      password: "",
      passwordRe: "",

      codeTime: 0,
    };
  },
  methods: {
    goLogin() {
      this.$router.push({
        name: "login",
        params: { lorr: 1 },
      });
    },
    goHome() {
      this.$router.push("/");
    },
    //发送验证码
    sendTelCode() {
      if (!this.tel) {
        document.getElementById("warnTel").innerHTML = "请输入手机账号";
        return;
      }
      if (!telFormation(this.tel)) {
        document.getElementById("warnTel").innerHTML = "请检查手机号格式";
        return;
      }

      if (this.codeTime != 0) return;
      this.codeTime = 60;
      document.getElementById("codeTel").innerHTML =
        this.codeTime + "s后重新发送";
      var interval = setInterval(() => {
        this.codeTime -= 1;
        document.getElementById("codeTel").innerHTML =
          this.codeTime + "s后重新发送";
        if (this.codeTime == 0) {
          clearInterval(interval);
          document.getElementById("codeTel").innerHTML = "发送验证码";
        }
      }, 1000);
      reqResetpwdcode2({ mobile: this.tel }).then((res) => {
        if (res.code == 200) {
          this.$message.success("验证码已发送");
        } else {
          this.$message.error("验证码请求失败:" + res.message);
        }
      });
    },

    //修改密码提交
    modifySubmit() {
      document.getElementById("warnTel").innerHTML = "";
      if (!this.tel) {
        document.getElementById("warnTel").innerHTML = "请输入手机账号";
        return;
      }
      if (!telFormation(this.tel)) {
        document.getElementById("warnTel").innerHTML = "请检查手机号格式";
        return;
      }
      if (!this.code) {
        document.getElementById("warnTel").innerHTML = "请输入手机验证码";
        return;
      }
      document.getElementById("warnTel").innerHTML = "";
      if (!this.password || !this.passwordRe) {
        document.getElementById("warnTel").innerHTML = "密码不能为空";
        return;
      }
      // 判断密码强度
      if (this.password.length < 6 || this.password.length > 16) {
        document.getElementById("warnTel").innerHTML = "密码长度6~16位";s
        return
      }
      // 正则判断密码强度
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(this.password)) {
        document.getElementById("warnTel").innerHTML = "密码必须包含数字和字母";
        return
      }
      if (this.password != this.passwordRe) {
        document.getElementById("warnTel").innerHTML = "两次密码输入不一致";
        return;
      }

      reqResetpwd2({
        mobile: this.tel,
        password: this.password,
        code: this.code,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("修改成功，请重新登录");
          localStorage.token = "";
          this.$router.push("/login");
        } else {
          this.$message.error("修改失败:" + res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.forgetPsd {
  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-out {
    width: 60%;
    height: 50rem;
    text-align: end;
    font-size: 18rem;
    color: #999;

    span:nth-child(2) {
      margin-right: 40rem;
      color: #0982f9;
      cursor: pointer;
    }

    span:nth-child(3) {
      cursor: pointer;
    }

    span:nth-child(3):hover {
      color: #0982f9;
    }
  }

  &-box {
    width: 60%;
    padding: 80rem;
    background-color: #fff;
    text-align: center;

    &-label {
      width: 100%;
      height: 80rem;
      color: #fff;
      font-size: 24rem;
      text-align: center;
      line-height: 80rem;
    }

    &-process {
      width: 100%;

      .getback,
      .modify,
      .over {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-size: 22rem;
          color: #333;
          margin: 35rem 0 50rem;
        }

        .inputBox {
          width: 58%;
          height: 50rem;
          margin-bottom: 20rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18rem;
          color: #999;
          // 取消flex-direction
          flex-direction: unset;
          background-color: #ffffff !important;

          input {
            width: 400rem;
            height: 100%;
            padding: 0 10rem;
            background-color: #f5f5f5;
            border: 1px solid #e2e2e2;
            border-radius: 2px;
            outline: none;
            box-sizing: border-box;
          }

          input::placeholder {
            color: #999999;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          div {
            width: 150rem;
            height: 100%;
            margin-left: 10rem;
            background-color: #f5f5f5;
            border: 1px solid #e2e2e2;
            border-radius: 2px;
            text-align: center;
            line-height: 50rem;
            box-sizing: border-box;
          }

          &-code {
            width: 240rem !important;
          }

          .telInput {
            color: #7e7e7e;
            cursor: pointer;
          }
        }

        button {
          width: 260rem;
          height: 50rem;
          margin: 30rem 0;
          background-color: #0982f9;
          border: none;
          border-radius: 4px;
          color: #fff;
          font-size: 24rem;
          text-align: center;
          line-height: 50rem;
          cursor: pointer;
        }

        button:active {
          background-color: rgba($color: #0982f9, $alpha: 0.8);
        }
      }

      .modify {
        .inputBox {
          justify-content: center;
        }
      }

      .over {
        .overbox {
          width: 100%;
          height: 300rem;
          font-size: 24rem;
          color: #333;
          line-height: 300rem;
          text-align: center;

          span:last-child {
            color: #0982f9;
            cursor: pointer;
          }
        }
      }

      .warn {
        font-size: 16rem;
        color: #eb2f06;
      }
    }
  }
}
</style>
