<template>
  <component :is="agentType"></component>
</template>

<script>
import agentMixin from "@/mixins/agentId.mixins";
import KnowledgeIn from "@/pages/KnowledgeIn/index.vue";
import Agent1_knowledgeIn from "@/pages/Agent1_knowledgeIn";
import Agent2_knowledgeIn from "@/pages/Agent2_knowledgeIn";
import Agent3_knowledgeIn from "@/pages/Agent3_knowledgeIn";
export default {
  name: "",
  components: {
    mtt: KnowledgeIn,
    agent1: Agent1_knowledgeIn,
    agent2: Agent2_knowledgeIn,
    agent3: Agent3_knowledgeIn,
  },
  data() {
    return {};
  },
  mixins: [agentMixin],
  methods: {},
};
</script>

<style></style>
