var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agent1_nav"},[_c('div',{staticClass:"left",on:{"click":function($event){return _vm.navSel(_vm.navList[0], 0)}}},[_c('img',{staticClass:"left_logo",attrs:{"src":_vm.logo,"alt":""}})]),_c('div',{staticClass:"right"},[_c('nav',{staticClass:"nav_box"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,staticClass:"nav_box_item",on:{"click":function($event){return _vm.navSel(item, index)}}},[_c('div',{class:[
            _vm.navIndex == index ? 'nav_box_item_cir' : 'nav_box_item_cirW',
          ]},[_c('div',{class:[
              _vm.navIndex == index
                ? 'nav_box_item_cir_point'
                : 'nav_box_item_cirW_point',
            ]})]),_c('span',{staticClass:"nav_box_item_text",class:{ nav_seled: _vm.navIndex == index }},[_vm._v(_vm._s(item.name))])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.token),expression:"!token"}],staticClass:"login_btn",on:{"click":function($event){return _vm.goLogin(1)}}},[_vm._v("登录")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.token),expression:"!token"}],staticClass:"register_btn",on:{"click":function($event){return _vm.goLogin(0)}}},[_vm._v("注册")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.token),expression:"token"}],staticClass:"title-portrait"},[_c('img',{attrs:{"src":require('@/assets/backstage/portrait/' + _vm.portrait),"alt":""},on:{"click":_vm.goBackPage}}),_c('span',{staticClass:"title-portrait-nickname",on:{"click":_vm.goBackPage}},[_vm._v("个人中心")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }