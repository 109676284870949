<template>
  <div class="box">
    <div class="one" @click="goCollection">
      <!-- <img src="@/assets/page/icon1.png" alt="收藏" /> -->
    </div>
    <div class="two" @click="goShopCar">
      <!-- <img src="@/assets/page/icon2.png" alt="购物车" /> -->
      <span v-show="shopCar.length > 0 && shopNum" class="two-num">{{
        shopCar.length
      }}</span>
    </div>
    <div class="three" @click="goBackPage">
      <!-- <img src="@/assets/page/icon3.png" alt="个人中心" /> -->
    </div>
    <div class="four" @click="goBackPageKf">
    </div>
    <div class="five" @click="goTop">
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      //右侧需要的是一个函数，当使用这个计算属性的时候，右侧函数会立即执行一次
      //注入一个参数state，其实即为大仓库中的数据
      shopCar: (state) => state.global.shopCar,
    }),
  },
  data() {
    return {
      shopNum: "",
    };
  },
  mounted() {
    this.shopNum = localStorage.token;
  },
  methods: {
    goShopCar() {
      this.$router.push("/backstage/shopCar");
      this.$parent.$emit("navSideSel", { item1Index: 0, item2Index: 0 });
    },
    goCollection() {
      this.$router.push("/backstage/collection");
      this.$parent.$emit("navSideSel", { item1Index: 0, item2Index: 0 });
    },
    goBackPageKf(){
      // 新开窗口到客服
      console.log(window.location.host);
      window.open(window.location.protocol+'//'+window.location.host+'/customerService/index')
    },
    //暂跳转后台
    goBackPage() {
      this.$router.push("/backstage/homepage");
      this.$parent.$emit("navSideSel", { item1Index: 0, item2Index: 0 });
    },
    goTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 0) {
        const timeTop = setInterval(() => {
          document.documentElement.scrollTop =
            document.body.scrollTop =
            scrollTop -=
              50; //一次减50往上滑动
          if (scrollTop <= 0) {
            clearInterval(timeTop);
          }
        }, 10); //定时调用函数使其更顺滑
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 53px;
  height: 175px;
  background-color: #fff;
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
   
  .one{
    background-image: url('../../assets/page/icon1.png');
  }
  .one:hover{
    background-image: url('../../assets/page/icon1show.png');
  }

  .two{
    background-image: url('../../assets/page/icon2.png');
  }
  .two:hover{
    background-image: url('../../assets/page/icon2show.png');
  }
  .three{
    background-image: url('../../assets/page/icon3.png');
  }
  .three:hover{
    background-image: url('../../assets/page/icon3show.png');
  }
  .four{
    background-image: url('../../assets/page/icon4.png');
  }
  .four:hover{
    background-image: url('../../assets/page/icon4show.png');
  }
  .five{
    background-image: url('../../assets/page/icon5.png');
  }
  .five:hover{
    background-image: url('../../assets/page/icon5show.png');
  }
  .one,
  .two,
  .three,
  .four,
  .five {
    width: 25px;
    height: 25px;
    cursor: pointer;
    background-size: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .two {
    width: 25px;
    height: 25px;
    position: relative;
    &-num {
      display: inline-block;
      min-width: 12px;
      height: 18px;
      padding: 0 3px;
      border-radius: 9px;
      background-color: #dd524d;
      font-size: 10px;
      color: #fff;
      line-height: 18px;
      text-align: center;
      position: absolute;
      right: -9px;
      top: -6px;
    }
  }
}
</style>
