<template>
  <div>
    <div v-for="(p, index) in this.$store.state.chat.chatMsgs" :key="index">
        <ChatMsgMe v-if="p.toId == $store.state.chat.toId && p.fromId == userId" :text="p.content" />
        <ChatMsgOther v-if="p.fromId == $store.state.chat.toId && p.toId == userId" :text="p.content" />
    </div>
  </div>
</template>

<script>
import ChatMsgMe from "./ChatMsgMe";
import ChatMsgOther from "./ChatMsgOther";
export default {
  components: {
    ChatMsgMe,
    ChatMsgOther,
  },
  data() {
    return {
      chatMsgs: this.$store.state.chat.chatMsgs,
      toName: this.$store.state.chat.toName,
      toId: this.$store.state.chat.toId,
      userType:this.$store.state.chat.userType,
      username: window.localStorage.getItem("username"),
      userId: window.localStorage.getItem("id"),
    };
  },
  created() {
    console.log(this.chatMsgs);
  },
  computed: {
    // toRecord(){
    //   this.chatMsgs
    // }
  },
};
</script>

<style scoped>
</style>