//手机格式验证
export function telFormation(tel) {
  let regExp =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  if (!regExp.test(tel)) {
    return false;
  } else {
    return true;
  }
}

//邮箱格式验证
export function emailFormation(email) {
  let regExp =
    /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
  if (!regExp.test(email)) {
    return false;
  } else {
    return true;
  }
}
