<template>
  <div class="couponBox">
    <!-- <div data-v-ab5167d0="" class="coupon-item x-bc">
      <div data-v-ab5167d0="" class="coupon-money">
        <div data-v-ab5167d0="" class="layof">
          <div data-v-ab5167d0="" style="font-size: 16px">
            <span>￥</span>
          </div>
          <div data-v-ab5167d0="" style="font-size: 28px">
            <span>5.00</span>
          </div>
        </div>
        <div data-v-ab5167d0="" class="demand">无门槛优惠券</div>
      </div>
      <div data-v-ab5167d0="" class="coupon-detail">
        <div data-v-ab5167d0="" style="margin-bottom: 6px">
          <div data-v-ab5167d0="" class="tabsTit" style="">
            <span>平台券</span>
          </div>
          <div
            data-v-ab5167d0=""
            class="coupon-title"
            style="font-size: 15px; color: rgb(51, 51, 51)"
          >
            <span></span>
          </div>
        </div>
        <div data-v-ab5167d0="" class="x-bc" style="margin: 0px 10px 6px 0px">
          <div
            data-v-ab5167d0=""
            style="font-size: 12px; color: rgb(102, 102, 102)"
          >
            <span>2022-04-09&nbsp;到期</span>
          </div>
          <div data-v-ab5167d0="">
            <div class="btn-Act" data-v-ab5167d0="" style="">立即兑换</div>
          </div>
        </div>
        <div
          data-v-ab5167d0=""
          style="
            margin-bottom: 6px;
            cursor: pointer;
            color: rgb(153, 153, 153);
            font-size: 12px;
            text-decoration: underline;
          "
          @click="UsageRules()"
        >
          使用规则
        </div>
      </div>
    </div> -->

    <div
      data-v-ab5167d0=""
      class="coupon-item x-bc"
      v-for="(item, index) in list"
      :key="index"
    >
      <!---->
      <div
        data-v-ab5167d0=""
        class="coupon-money"
        :class="types >= 2 ? 'graybg' : ''"
      >
        <div data-v-ab5167d0="" class="layof">
          <div data-v-ab5167d0="" style="font-size: 16px">
            <span>￥</span>
          </div>
          <div data-v-ab5167d0="" style="font-size: 28px">
            <span>{{ item.amount }}</span>
          </div>
        </div>
        <div data-v-ab5167d0="" class="demand">{{ item.description }}</div>
        <!-- <div data-v-ab5167d0="" class="demand">满1000.00可用</div> -->
      </div>
      <div data-v-ab5167d0="" class="coupon-detail">
        <div class="titleBox" data-v-ab5167d0="" style="margin-bottom: 6px">
          <div
            data-v-ab5167d0=""
            class="tabsTit"
            :class="types >= 2 ? 'graybg' : ''"
            style=""
          >
            <span>{{ item.title }}</span>
          </div>
          <div
            data-v-ab5167d0=""
            class="coupon-title"
            style="font-size: 12px; color: #999"
          >
            <span v-if="item.recv_count - item.all_count < 0">
              当前剩余 {{ item.all_count - item.recv_count }} 张</span
            >
          </div>
        </div>
        <div
          data-v-ab5167d0=""
          class="x-bc"
          style="margin: 0px 10px 6px 0px; height: 24px"
        >
          <div
            data-v-ab5167d0=""
            style="font-size: 12px; color: rgb(102, 102, 102)"
          >
            <span v-if="types == 0">{{ item.end_date }}&nbsp; 截止兑换</span>
            <span v-else>{{ item.end_time }}&nbsp; 截止使用</span>
          </div>
          <div data-v-ab5167d0="" class="coupon-item-btn" v-if="types == 0">
            <div v-if="item.recv_count - item.all_count < 0">
              <div
                class="btn-Act"
                data-v-ab5167d0=""
                style=""
                v-if="item.max_exchange_count > item.exchanged_count"
                @click="exchangeCoupon(item.id)"
              >
                兑换
              </div>
              <div class="btn-ActS" data-v-ab5167d0="" style="" v-else>
                兑换达到最大次数
              </div>
            </div>
            <div v-else class="No_more">该优惠券已经领光了</div>
          </div>
          <div data-v-ab5167d0="" v-if="types == 1">
            <div class="btn-Act" data-v-ab5167d0="" style="">立即使用</div>
          </div>
        </div>
        <div class="rule_condition">
          <div
            style="
              cursor: pointer;
              margin-bottom: 6px;
              color: rgb(153, 153, 153);
              font-size: 12px;
              text-decoration: underline;
            "
            @click="UsageRules()"
          >
            使用规则
          </div>

          <div class="coupon-item-condition" v-if="types == 0">
            所需积分: {{ item.score }}
          </div>
        </div>
      </div>
      <div
        v-if="item.isUse == 2"
        data-v-ab5167d0=""
        class="seal"
        style="position: absolute; right: 5px; bottom: 5px"
      >
        <div data-v-ab5167d0="" class="seal-son">
          <span
            data-v-ab5167d0=""
            style="
              position: absolute;
              top: 20px;
              text-align: center;
              font-size: 17px;
              transform: rotate(-12deg);
              left: 4px;
              color: rgb(210, 210, 210);
              font-weight: 900;
            "
            >已使用</span
          >
        </div>
      </div>
      <div
        v-if="item.isUse == 3"
        data-v-ab5167d0=""
        class="seal"
        style="position: absolute; right: 5px; bottom: 5px"
      >
        <div data-v-ab5167d0="" class="seal-son">
          <span
            data-v-ab5167d0=""
            style="
              position: absolute;
              top: 20px;
              text-align: center;
              font-size: 17px;
              transform: rotate(-12deg);
              left: 4px;
              color: rgb(210, 210, 210);
              font-weight: 900;
            "
            >已过期</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_coupon, my_coupon, exchange_coupon } from "@/api";
export default {
  data() {
    return {
      couponList: [], //优惠券列表
      MycouponList: [], //我的优惠券
      couponListed: [], //已使用优惠券
      data: {
        is_free: 2, //免费券0否1是 2全部
        page: 1,
        page_size: 10,
      },
    };
  },
  props: {
    types: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    // if (this.types == 0) {
    //   this.getcoupon();
    // } else if (this.types == 1) {
    //   console.log("使用");
    //   this.getMyCoupon({
    //     coupon_type: "2", //1未使用2已使用3已过期
    //   });
    // }
  },
  methods: {
    // 兑换优惠券
    async exchangeCoupon(id) {
      // 调用父组件的方法
      const res = await exchange_coupon({
        coupon_id: id,
      });
      if (res.code == 1) {
        this.$message.success("兑换成功");
        this.$emit("fatherMethod");
      } else {
        this.$message.error(res.message);
      }
    },
    UsageRules() {
      this.$emit("changeShow");
    },
  },
};
</script>

<style lang="scss" scoped>
.titleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
}
.No_more {
  color: #d41e22;
  font-size: 12px;
  text-align: center;
}
.rule_condition {
  display: flex;
  justify-content: space-between;
}
.coupon-item-condition {
  padding-right: 16px;
  font-size: 12px;
  color: #999;
}
.couponBox {
  width: 100%;
  height: 300px;
  overflow: auto;
}
.seal-son {
  width: 62px;
  height: 62px;
  border: solid 1px #d2d2d2;
  border-radius: 100%;
  background-color: #f5f4f5;
  position: relative;
}
.seal {
  width: 70px;
  height: 70px;
  border: solid 3px #d2d2d2;
  border-radius: 100%;
  background-color: #f5f4f5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabsTit {
  margin-right: 4px;
  background: linear-gradient(
    132deg,
    rgb(193, 25, 32) 0%,
    rgb(238, 38, 38) 100%
  );
  color: rgb(255, 255, 255);
  float: left;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 6px;
}
.graybg {
  background: linear-gradient(132deg, #d2d2d2 0%, #d2d2d2 100%) !important;
}
.btn-Act:hover:after {
  left: 0;
  width: 100%;
}
.btn-Act:hover {
  color: #fff;
}
.btn-Act {
  position: relative;
  cursor: pointer;
  //   width: 60px;
  height: 24px;
  line-height: 24px;
  background-color: rgb(250, 53, 52);
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 12px;
  padding: 0 10px;
}
.btn-Act:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
  //   background-color: #0982f9;
  border-radius: 12px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.coupon-item {
  height: 110px;
  border-radius: 5px;
  margin-top: 11px;
  border: 1px solid #ffffff;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #eeeeee;
}
.x-bc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.coupon-item:before {
  width: 20px;
  height: 10px;
  position: absolute;
  left: 106px;
  bottom: -1px;
  border-radius: 20px 20px 0 0;
  content: "";
  display: block;
  background: #f5f5f7;
  border: 1px solid #eeeeee;
  border-bottom: 0px;
}
.coupon-item:after {
  width: 20px;
  height: 10px;
  position: absolute;
  left: 106px;
  top: -1px;
  border-radius: 0 0 20px 20px;
  content: "";
  display: block;
  background: #f5f5f7;
  border: 1px solid #eeeeee;
  border-top: 0px;
}
.coupon-item .coupon-money {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 116px;
  height: 100%;
  border-style: none dotted none none;
  border-color: #eeeeee;
  background: linear-gradient(132deg, #c11920 0%, #ee2626 100%);
}
.coupon-item .coupon-money .layof {
  width: 100%;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 6px;
}
.coupon-item .coupon-money .layof {
  width: 100%;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 6px;
}
.coupon-item .coupon-money .demand {
  font-size: 12px;
  color: #ffffff;
}
.coupon-detail {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding-left: 20px;
  height: 100%;
}
.coupon-detail {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding-left: 20px;
  height: 100%;
}
.x-bc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
