<template>
  <div class="chatbox">
    <div class="chatbox-title">在线咨询</div>
    <div class="chatbox-chatbox" ref="scroll" @scroll="scrollEvent">
      <div class="chatbox-chatbox-scroll" ref="scroll">
        <div
          :class="[item.type == 'left' ? 'left' : 'right']"
          v-for="(item, index) in chatMsgs"
          :key="index"
        >
          <span>{{ item.type == "left" ? servicer + "：" : "我：" }}</span>
          <span>{{ item.content }}</span>
        </div>
      </div>
    </div>
    <div class="chatbox-send">
      <input
        v-model="chatValue"
        ref="getFocus"
        type="text"
        placeholder="在这输入聊天内容"
        maxlength="100"
        @keydown.enter="inputKeydown"
      />
      <div @click="sendMessage(chatValue)">发送</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
      chatMsgs: (state) => state.global.chatMsgs,
    }),
  },
  data() {
    return {
      ws: null,
      lastSendTime: 0,
      noService: false,

      //传入参数
      toId: 1,
      servicer: "客服",
      page: 1,

      //聊天输入内容
      chatValue: "",
    };
  },
  created() {
    this.websocketConnect();
  },
  mounted() {
    this.$refs.getFocus.focus();
  },
  beforeDestroy() {
    this.websocketDestory();
  },
  methods: {
    scrollEvent() {
      if (this.$refs.scroll.scrollTop <= 0) {
        let data = {
          userId: this.userMessage.uid,
          direction: "center",
          type: "message-list",
          userType: "1",
          pageNum: this.page,
          customerServiceId: this.toId,
        };
        this.ws.send(JSON.stringify(data));
      }
    },
    //发送聊天信息
    sendMessage(data) {
      if (!data.trim()) {
        this.chatValue = "";
        return;
      }
      if (Math.abs(new Date().getTime() - this.lastSendTime) <= 5000) {
        this.$message("发送太频繁了，请稍后再试");
        return;
      }
      if (this.noService) {
        this.$message({
          showClose: true,
          message: "暂时没有客服在线哦",
          type: "warning",
        });
        return;
      }
      let obj = {
        content: data,
        customerServiceId: this.toId,
        fromId: this.userMessage.uid,
        toId: this.toId,
        fromName: this.userMessage.nickname,
        userId: this.userMessage.uid,
        fromUserType: 1,
        toUserType: 2,
        toName: this.userMessage.nickname,
        code: 200,
        type: "chat-message",
      };
      this.ws.send(JSON.stringify(obj));
      let sendMessage = {
        content: data,
        type: "right",
      };
      this.$store.commit("global/append_message", sendMessage);
      // _this.$store.commit("sort_message");
      this.scrolltobottom();
      this.chatValue = "";
      this.$refs.getFocus.focus();
      this.lastSendTime = new Date().getTime();
    },
    inputKeydown() {
      this.sendMessage(this.chatValue);
    },
    //滚动条始终最底部
    scrolltobottom() {
      this.$nextTick(() => {
        this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight;
      });
    },
    //创建webSocket
    async websocketConnect() {
      this.ws = new WebSocket(
        "wss://websocket.mtuitui.com/socket/" +
          this.userMessage.uid +
          "/" +
          localStorage.token
      );
      // 连接建立时（上线）执行函数
      this.ws.onopen = () => {
        console.log("onopen");
        this.$store.commit("global/clear_message");
        let data = {
          userId: this.userMessage.uid,
          direction: "center",
          type: "message-list",
          userType: "1",
          pageNum: this.page,
          customerServiceId: this.toId,
        };
        this.ws.send(JSON.stringify(data));
      };
      // 接收消息时执行函数 离线消息：用一个离线标识一下数据，将离线数据保存在一个list中，等到用户上线标识出现了就再发过来
      this.ws.onmessage = (msg) => {
        let dataStr = msg.data;
        let data = JSON.parse(dataStr);
        console.log(data.type, "——————data.type");
        switch (data.type) {
          case "chat-message":
            this.$message.success(
              "你收到一条来自" + data.content.fromName + "的新消息"
            );
            let sendMessage = {
              content: data.content.content,
              type: "left",
            };
            this.$store.commit("global/append_message", sendMessage);
            this.scrolltobottom();
            break;
          case "message-list":
            if (
              data.content.records instanceof Array &&
              data.content.records.length > 0
            ) {
              // console.log(
              //   data.content.records,
              //   "data.content.records",
              //   this.page
              // );
              this.page++;
              data.content.records.sort((a, b) => {
                return a.age - b.age;
              });
              for (let i = 0; i < data.content.records.length; i++) {
                let msg = {
                  content: data.content.records[i].content,
                  type:
                    data.content.records[i].toId == this.userMessage.uid
                      ? "left"
                      : "right",
                };
                this.$store.commit("global/unshift_message", msg);
              }
              if (this.page <= 2) {
                this.scrolltobottom();
              }
              //  else {
              //   this.$refs.scroll.scrollTop =
              //     (this.$refs.scroll.scrollHeight * this.page) / this.page;
              // }
              // this.$store.commit("global/sort_message");
            }
            break;
          //在线客服列表
          case "online-customer-services":
            //users
            //动态语言，把缺少的属性加上就行了
            if (data.content instanceof Array) {
              if (data.content.length <= 0) {
                this.noService = true;
                this.servicer = "小叮当";
                this.$message({
                  showClose: true,
                  message: "客服离线中···",
                  type: "warning",
                });
                let sendMessage = {
                  content:
                    "你好，暂时无客服在线，有问题请扫码添加上方客服微信咨询😀。",
                  type: "left",
                };
                this.$store.commit("global/append_message", sendMessage);
                this.scrolltobottom();
                this.toId = "";
              } else {
                this.noService = false;
                this.servicer = data.content[0].nickname;
                this.$message.success("客服在线中···");
                let sendMessage = {
                  content:
                    "你好，这里是客服" +
                    this.servicer +
                    "，有问题请扫码添加上方客服微信咨询，或在此处资讯😀。",
                  type: "left",
                };
                this.$store.commit("global/append_message", sendMessage);
                this.scrolltobottom();
                this.toId = data.content[0].id;
              }
            }
            break;
          case "token-error":
            this.$message.error(data.content);
            setTimeout(() => {
              // localStorage.token = "";
              // this.$router.push("/login");
            }, 1000);
            break;
        }
      };
      // 连接关闭时（下线）执行函数
      this.ws.onclose = function () {
        console.log("onclose");
      };
      //连接出错时执行函数
      this.ws.onerror = function (e) {
        console.log("错误是", e);
      };
    },
    //销毁webSocket
    websocketDestory() {
      if (this.ws) {
        console.log("beforeDestroy.ws_close");
        this.ws.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chatbox {
  width: 100%;
  height: 700rem;
  margin: 10rem 0 0;
  background-color: #fff;
  border-radius: 8px;
  &-title {
    width: 100%;
    height: 50rem;
    background-color: #0982f9;
    border-radius: 8px 8px 0 0;
    font-size: 16rem;
    color: #fff;
    text-align: center;
    line-height: 48rem;
  }
  //聊天窗口样式
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  &-chatbox {
    width: 100%;
    height: 590rem;
    overflow-y: scroll;
    &-scroll {
      // min-height: 590px;
      &-errorR {
        width: 40rem;
        height: 40rem;
        background-color: red;
        border-radius: 50%;
      }
      .left {
        width: 70%;
        margin: 16rem 0 16rem 20rem;
        display: flex;
        flex-direction: column;
      }
      .left span {
        font-size: 16rem;
        color: #999;
      }
      .left span:last-child {
        padding: 12rem 16rem;
        margin-top: 10rem;
        border-radius: 6px;
        background-color: #eff3f6;
        color: #444;
      }
      .right {
        width: 70%;
        margin: 16rem 20rem 16rem 90rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .right span {
        font-size: 16rem;
        color: #999;
      }
      .right span:last-child {
        padding: 12rem 16rem;
        margin-top: 10rem;
        border-radius: 6px;
        background-color: rgba($color: #1b8eff, $alpha: 0.6);
        color: #444;
      }
    }
  }
  //发送栏样式
  &-send {
    width: 100%;
    height: 60rem;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px -2px 30px 1px rgba(54, 54, 54, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-send > input {
    width: 55%;
    height: 15rem;
    padding: 15rem 15rem 10rem;
    background-color: #f4f4f4;
    border-radius: 8px;
    font-size: 16rem;
    border: none;
    outline: none;
  }
  &-send > input::placeholder {
    font-size: 16rem;
    color: #999;
  }
  &-send > div {
    width: 25%;
    height: 40rem;
    margin-left: 10rem;
    background-color: rgba($color: #0982f9, $alpha: 0.15);
    border-radius: 8px;
    font-size: 16rem;
    color: #0982f9;
    text-align: center;
    line-height: 45rem;
    cursor: pointer;
     
  }
}
</style>
