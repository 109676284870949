// 节流
export function _throttle(fn, wait = 200) {
  let last, timer,now;
  return function() {
    now = Date.now();
    if (last && now - last < wait) {
      clearTimeout(timer);
      timer = setTimeout(function() {
        last = now;
        fn.call(this, ...arguments);
      }, wait);
    } else {
      last = now;
      fn.call(this, ...arguments);
    }
  };
}
  
// 防抖
export function _debounce(fn, wait = 200) {
  let timer;
    return function () {
      let context = this;
      let args = arguments;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, wait)
    }
}
  
//获取cookie、
export function getCookie(name) {
  var arr, reg = new RegExp( "(^| )" + name + "=([^;]*)(;|$)" );
  if (arr == document.cookie.match(reg))
   return (arr[2]);
  else
   return null ;
}
 
//设置cookie,增加到vue实例方便全局调用
export function setCookie (c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie = c_name + "=" + escape(value) + ((expiredays == null ) ? "" : ";expires=" + exdate.toGMTString());
}
 
//删除cookie
export function delCookie (name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null )
   document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

