<template>
  <div class="usermsg">
    <!-- 第一列 -->
    <div class="list">
      <div class="list-item">
        <span style="margin-left: 16rem">用户名：</span>
        <input type="text" v-model="nickname" />
      </div>
      <div class="list-item">
        <span>用户等级：</span>
        <span class="list-item-black">{{ vip ? "会员" : "普通用户" }}</span>
      </div>
      <div class="list-item">
        <span>手机号码：</span>
        <span @click="bindTel(0)" v-show="!tel" class="list-item-blue"
          >去绑定</span
        >
        <input
          v-show="tel"
          class="list-item-numInput"
          disabled
          type="number"
          v-model="tel"
          oninput="if(value.length > 11)value = value.slice(0, 11)"
          onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
        />
        <span @click="bindTel(1)" v-show="tel" class="list-item-10">解绑</span>
      </div>
      <div class="list-item">
        <span>电子邮箱：</span>
        <span @click="bindEmail" v-show="!email" class="list-item-blue"
          >去绑定</span
        >
        <input v-show="email" disabled type="text" v-model="email" />
        <span @click="bindEmail" v-show="email" class="list-item-10">换绑</span>
      </div>
    </div>
    <!-- 第二列 -->
    <div class="list">
      <div class="list-item">
        <span>账户余额：</span>
        <span class="list-item-black">{{ balance }}元</span>
        <span class="list-item-blue10" @click="goOnlineCharge">充值</span>
      </div>
      <div class="list-item">
        <span>剩余积分：</span>
        <span class="list-item-black">{{ score }}</span>
      </div>
      <div class="list-item">
        <span>我的上级：</span>
        <!-- 当邀请码有数据的时候就判断 禁用input 和隐藏确定按钮 -->
        <input
          :disabled="invite_code_status"
          type="text"
          v-model="referee_nickname"
          placeholder="请填写邀请码"
        />
        <span @click="binding" v-show="!invite_code_status" class="list-item-10"
          >确定</span
        >
      </div>
    </div>
    <div @click="saveMessage" class="save">保存</div>
    <!-- 修改绑定手机弹窗 -->
    <div
      @click="closeTel"
      class="messageBox"
      :class="
        FadeAnimate
          ? 'FriendUrlCreateWindowFadeInM'
          : 'FriendUrlCreateWindowFadeOutM'
      "
      v-show="WrapperTel"
    >
      <div
        @click.stop="wu"
        class="messageBox-content"
        :class="
          FadeAnimate
            ? 'FriendUrlCreateWindowFadeIn'
            : 'FriendUrlCreateWindowFadeOut'
        "
      >
        <img
          @click="closeTel"
          class="messageBox-content-xxx"
          src="@/assets/backstage/xxx.png"
          alt=""
        />
        <span class="messageBox-content-title"
          >{{ tel ? "换绑" : "绑定" }}手机号</span
        >
        <input
          v-show="!tel"
          class="messageBox-content-input"
          v-model="messageBox.newTel"
          type="text"
          placeholder="请输入要绑定的手机号"
        />
        <input
          v-show="tel"
          class="messageBox-content-input"
          v-model="messageBox.oldTel"
          type="text"
          placeholder="请输入要解绑的手机号"
        />
        <div class="messageBox-content-code">
          <input
            v-model="messageBox.telCode"
            type="number"
            maxlength="4"
            placeholder="请输入短信验证码"
            onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
            oninput="if(value.length > 4)value = value.slice(0, 4)"
          />
          <div
            :class="{
              emailInput:
                time == 0 &&
                (messageBox.newTel.length > 0 || messageBox.oldTel.length > 0),
            }"
            id="codeTel"
            @click="sendTelCode"
          >
            发送验证码
          </div>
        </div>
        <button @click="submitTel" class="messageBox-content-btn">确定</button>
        <span id="warnTel" class="messageBox-content-warn"></span>
      </div>
    </div>
    <!-- 修改绑定邮箱弹窗 -->
    <div
      @click="closeEmail"
      class="messageBox"
      :class="
        FadeAnimate
          ? 'FriendUrlCreateWindowFadeInM'
          : 'FriendUrlCreateWindowFadeOutM'
      "
      v-show="WrapperEmail"
    >
      <div
        @click.stop="wu"
        class="messageBox-content"
        :class="
          FadeAnimate
            ? 'FriendUrlCreateWindowFadeIn'
            : 'FriendUrlCreateWindowFadeOut'
        "
      >
        <img
          @click="closeEmail"
          class="messageBox-content-xxx"
          src="@/assets/backstage/xxx.png"
          alt=""
        />
        <span class="messageBox-content-title">修改电子邮箱</span>
        <input
          class="messageBox-content-input"
          v-model="messageBox.newEmail"
          type="text"
          placeholder="请输入邮箱账号"
        />
        <div class="messageBox-content-code">
          <input
            v-model="messageBox.emailCode"
            type="number"
            maxlength="4"
            placeholder="请输入邮箱验证码"
            oninput="if(value.length > 4)value = value.slice(0, 4)"
            onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
          />
          <div
            :class="{ emailInput: time == 0 && messageBox.newEmail.length > 0 }"
            id="code"
            @click="sendCode"
          >
            发送验证码
          </div>
        </div>
        <button @click="submitEmail" class="messageBox-content-btn">
          确定
        </button>
        <span id="warn" class="messageBox-content-warn"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { telFormation, emailFormation } from "@/utils/judge";
import { bind_invite, change_nickname } from "@/api";
export default {
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.nickname = this.userMessage.nickname;
      this.vip = this.userMessage.vip;
      this.tel = this.userMessage.tel;
      this.email = this.userMessage.email;
      this.balance = this.userMessage.balance;
      this.score = this.userMessage.score;
      this.referee_id = this.userMessage.referee_id;
      this.referee_nickname = this.userMessage.referee_nickname;
      if (this.referee_id != 0) {
        this.invite_code_status = true;
      }
    });
    console.log(this.referee_id, "invite_code");
  },
  data() {
    return {
      nickname: "",
      vip: false,
      tel: "",
      email: "",
      balance: 0,
      score: 0,
      referee_id: "",
      referee_nickname: "",
      invite_code_status: false,
      WrapperEmail: false,
      WrapperTel: false,
      telType: 0,
      FadeAnimate: false,

      time: 0,
      messageBox: {
        newEmail: "",
        emailCode: "",

        oldTel: "",
        newTel: "",
        telCode: "",
      },
    };
  },
  methods: {
    saveMessage() {
      change_nickname({ nickname: this.nickname }).then((res) => {
        if (res.code == 1) {
          this.$message.success("修改成功");
          let userMsg = {
            ...this.userMessage,
            nickname: this.nickname,
          };
          this.$store.commit("global/USERMESSAGEMODIFY", userMsg);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    binding() {
      // 排除空格
      bind_invite({ invite_code: this.referee_nickname }).then((res) => {
        console.log(res, "res");
        if (res.code == 1) {
          this.$message.success("绑定成功");
          this.invite_code_status = true;

          let userMsg = {
            ...this.userMessage,
            invite_code: this.invite_code,
          };
          this.$store.commit("global/setUserMessage", userMsg);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //解绑/换绑手机
    submitTel() {
      document.getElementById("warnTel").innerHTML = "";
      if (!this.messageBox.newTel && this.telType == 0) {
        document.getElementById("warnTel").innerHTML = "请输入绑定手机号！";
        return;
      } else if (!this.messageBox.oldTel && this.telType == 1) {
        document.getElementById("warnTel").innerHTML = "请输入解绑手机号！";
        return;
      } else if (!telFormation(this.messageBox.newTel) && this.telType == 0) {
        document.getElementById("warnTel").innerHTML = "请检查手机号格式";
        return;
      } else if (!telFormation(this.messageBox.oldTel) && this.telType == 1) {
        document.getElementById("warnTel").innerHTML = "请检查手机号格式";
        return;
      } else if (!this.messageBox.telCode) {
        document.getElementById("warnTel").innerHTML = "请输入短信验证码！";
        return;
      }

      if (this.telType == 0) {
        this.$store.dispatch("global/telBind", this.messageBox.newTel);
      } else if (this.telType == 1) {
        this.$store.dispatch("global/telBind", "");
      }
      this.tel = this.userMessage.tel;
      this.messageBox.telCode = "";
      this.closeTel();
    },
    //打开手机弹窗
    bindTel(type) {
      this.telType = type; //0为绑定，1为解绑
      this.WrapperTel = true;
      this.FadeAnimate = true;
    },
    //关闭手机弹窗
    closeTel() {
      this.FadeAnimate = false;
      // setTimeout(() => {
      this.WrapperTel = false;
      // }, 200);
    },
    //手机请求验证码
    sendTelCode() {
      document.getElementById("warnTel").innerHTML = "";
      if (!telFormation(this.messageBox.newTel) && this.telType == 0) {
        document.getElementById("warnTel").innerHTML = "请检查手机号格式";
        return;
      } else if (!telFormation(this.messageBox.oldTel) && this.telType == 1) {
        document.getElementById("warnTel").innerHTML = "请检查手机号格式";
        return;
      }
      if (this.time != 0) return;
      this.time = 60;
      document.getElementById("codeTel").innerHTML = this.time + "s后重新发送";
      document.getElementById("code").innerHTML = this.time + "s后重新发送";
      var interval = setInterval(() => {
        this.time -= 1;
        document.getElementById("codeTel").innerHTML =
          this.time + "s后重新发送";
        document.getElementById("code").innerHTML = this.time + "s后重新发送";
        if (this.time == 0) {
          clearInterval(interval);
          document.getElementById("codeTel").innerHTML = "发送验证码";
          document.getElementById("code").innerHTML = "发送验证码";
        }
      }, 1000);
    },

    //绑定/换绑邮箱
    submitEmail() {
      document.getElementById("warn").innerHTML = "";
      if (!this.messageBox.newEmail) {
        document.getElementById("warn").innerHTML = "请输入绑定邮箱账号！";
        return;
      } else if (!emailFormation(this.messageBox.newEmail)) {
        document.getElementById("warn").innerHTML = "请检查邮箱格式";
        return;
      } else if (!this.messageBox.emailCode) {
        document.getElementById("warn").innerHTML = "请输入邮箱验证码！";
        return;
      }
      this.$store.dispatch("global/emailBind", this.messageBox.newEmail);
      this.email = this.userMessage.email;
      this.messageBox.emailCode = "";
      this.closeEmail();
    },
    //打开邮箱弹窗
    bindEmail() {
      this.WrapperEmail = true;
      this.FadeAnimate = true;
    },
    //关闭邮箱弹窗
    closeEmail() {
      this.FadeAnimate = false;
      setTimeout(() => {
        this.WrapperEmail = false;
      }, 200);
    },
    //邮箱请求验证码
    sendCode() {
      document.getElementById("warn").innerHTML = "";
      if (!emailFormation(this.messageBox.newEmail)) {
        document.getElementById("warn").innerHTML = "请检查邮箱格式";
        return;
      }
      if (this.time != 0) return;
      this.time = 60;
      document.getElementById("code").innerHTML = this.time + "s后重新发送";
      document.getElementById("codeTel").innerHTML = this.time + "s后重新发送";
      var interval = setInterval(() => {
        this.time -= 1;
        document.getElementById("code").innerHTML = this.time + "s后重新发送";
        document.getElementById("codeTel").innerHTML =
          this.time + "s后重新发送";
        if (this.time == 0) {
          clearInterval(interval);
          document.getElementById("code").innerHTML = "发送验证码";
          document.getElementById("codeTel").innerHTML = "发送验证码";
        }
      }, 1000);
    },
    //充值
    goOnlineCharge() {
      this.$router.push("/backstage/fundMgt/onlineCharge");
      this.$parent.fatherMethod(5, 0);
    },
    //空白函数
    wu() {},
  },
};
</script>

<style lang="scss" scoped>
.usermsg {
  width: 100%;
  height: 440rem;
  display: flex;
  position: relative;
  .list {
    width: 33%;
    padding: 60rem 0;
    &-item {
      margin: 0 0 25rem 90rem;
      display: flex;
      align-items: center;
      span {
        font-size: 17rem;
        color: #999;
      }
      span:first-child {
        font-size: 18rem;
      }
      &-blue {
        color: #0982f9 !important;
        cursor: pointer;
      }
      &-blue10 {
        color: #0982f9 !important;
        margin-left: 10rem;
        cursor: pointer;
      }
      &-black {
        color: #333 !important;
      }
      &-10 {
        margin-left: 10rem;
        cursor: pointer;
      }
      &-10:hover {
        color: #0982f9;
      }
      input {
        width: 165rem;
        height: 40rem;
        padding: 0 6rem;
        outline: none;
        border: #e5e5e5 1px solid;
        background-color: #f4f4f4;
        font-size: 16rem;
        color: #333;
      }
      &-numInput::-webkit-outer-spin-button,
      &-numInput::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
  .save {
    width: 114rem;
    height: 37rem;
    border-radius: 6px;
    background-color: #0982f9;
    color: #fff;
    font-size: 17rem;
    line-height: 37rem;
    text-align: center;
    cursor: pointer;

    position: absolute;
    left: 346rem;
    bottom: 80rem;
  }
}
//弹窗
.messageBox {
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #565656, $alpha: 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
  &-content {
    width: 60%;
    height: 533rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: relative;
    &-xxx {
      width: 35rem;
      height: 35rem;
      object-fit: contain;
      cursor: pointer;
      position: absolute;
      right: 10rem;
      top: 10rem;
    }
    &-title {
      margin-bottom: 70rem;
      font-size: 24rem;
      color: #333;
    }
    input {
      margin-bottom: 20rem;
      outline: none;
      border: 1px solid #e2e2e2;
      background-color: #f5f5f5;
      border-radius: 2px;
    }
    input::placeholder {
      font-size: 18rem;
      color: #999;
    }
    &-input {
      width: 380rem;
      height: 50rem;
      padding: 0 20rem;
      margin-bottom: 0;
      font-size: 18rem;
      color: #333;
    }
    &-code {
      width: 420rem;
      height: 50rem;
      margin-bottom: 20rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      input {
        width: 210rem;
        height: 50rem;
        padding: 0 20rem;
        margin-bottom: 0;
        font-size: 18rem;
        color: #333;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      div {
        width: 150rem;
        height: 50rem;
        font-size: 18rem;
        color: #999;
        text-align: center;
        line-height: 50rem;
        border: 1px solid #e2e2e2;
        background-color: #f5f5f5;
        border-radius: 2px;
      }
      .emailInput {
        background-color: #0982f9;
        color: #fff;
        cursor: pointer;
      }
    }
    &-btn {
      width: 420rem;
      height: 50rem;
      border-radius: 4px;
      background-color: #0982f9;
      font-size: 24rem;
      line-height: 50rem;
      color: #fff;
      text-align: center;
      border: none;
      cursor: pointer;
    }
    &-warn {
      margin-top: 20rem;
      font-size: 14rem;
      color: #eb2f06;
    }
  }
}
//弹窗动画
.FriendUrlCreateWindowFadeIn {
  animation: FadeIn 0.2s ease-out;
}
.FriendUrlCreateWindowFadeOut {
  animation: FadeOut 0.2s ease-out;
}
@keyframes FadeIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes FadeOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}
.FriendUrlCreateWindowFadeInM {
  // animation: FadeInM 0.2s ease-out;
}
.FriendUrlCreateWindowFadeOutM {
  // animation: FadeOutM 0.2s ease-out;
}
@keyframes FadeInM {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes FadeOutM {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
