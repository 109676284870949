import Vue from "vue";
import App from "./App.vue";

// 全局baseURl
const baseURL = "https://admin.mtuitui.com";
Vue.prototype.$baseURL = baseURL;

import "./utils/rem";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import 'swiper/scss';

// import 'swiper/scss/navigation';
// import 'swiper/scss/pagination';
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

// wowjs
// import 'wowjs/css/libs/animate.css'
import "animate.css";
import wow from "wowjs";
Vue.prototype.$wow = wow;

//导入并使用global.js
import global from "./utils/global"; // 1.先引入
Vue.prototype.$global = global; // 2.直接定义在vue的原型上面

import VueDirectiveImagePreviewer from "vue-directive-image-previewer";
import "vue-directive-image-previewer/dist/assets/style.css";
//先获取客户端显示屏幕屏幕的宽高
var width = screen.width;
var height = screen.height;
Vue.use(VueDirectiveImagePreviewer, {
  previewSize: 10,
  maxWidth: width,
  maxHeight: height,
  zIndex: 999,
  cursor: "auto",
});

// import Vuetify from "vuetify";
// import vuetify from "@/plugins/vuetify";
// import "material-design-icons-iconfont/dist/material-design-icons.css";

// 引入echarts
// import echarts from "echarts";
// Vue.prototype.$echarts = echarts;
// 定义全局指令

//echart自动适应（未使用）
Vue.directive("resize", {
  // 使用局部注册指令的方式
  // 指令的名称
  bind(el, binding) {
    // el为绑定的元素，binding为绑定给指令的对象
    let width = "",
      height = "";

    function isReize() {
      const style = document.defaultView.getComputedStyle(el);
      if (width !== style.width || height !== style.height) {
        binding.value(); // 关键
      }
      width = style.width;
      height = style.height;
    }
    el.__vueSetInterval__ = setInterval(isReize, 300);
  },
  unbind(el) {
    clearInterval(el.__vueSetInterval__);
  },
});

//弹幕插件

import router from "@/router";
import store from "./store";
import { reqYue } from "@/api";
router.beforeEach((to, from, next) => {
  // const head = document.getElementsByTagName("head");
  // const meta = document.createElement("meta");
  // document
  //   .querySelector('meta[name="keywords"]')
  //   .setAttribute(
  //     "content",
  //     "软文发布,软文推广,软文自助发稿平台,新闻媒体发布-发稿平台,微信营销,新闻源发布,网站建设,seo优化,媒推推"
  //   );
  // document
  //   .querySelector('meta[name="description"]')
  //   .setAttribute(
  //     "content",
  //     "媒推推是一个广告媒体综合管理平台。专注于提供媒体、自媒体、网站、公众号、短视频等平台资源，用户可通过在线发布系统，享受一站式投放多种互联网平台，凭借多年资源的积累和客户良好口碑的拓展，服务的企业客户行业包括互联网、餐饮、人力资源、银行、汽车、能源、房产、旅游、酒店、零售等，媒推推秉承诚信立足，创新致远的服务理念，始终坚持为全球企业发展提供有价值的性价比服务！"
  //   );
  // meta.content = {
  //   keywords:
  //     "软文发布,软文推广,软文自助发稿平台,新闻媒体发布-发稿平台,微信营销,新闻源发布,网站建设,seo优化,媒推推",
  //   description:
  //     "媒推推是一个广告媒体综合管理平台。专注于提供媒体、自媒体、网站、公众号、短视频等平台资源，用户可通过在线发布系统，享受一站式投放多种互联网平台，凭借多年资源的积累和客户良好口碑的拓展，服务的企业客户行业包括互联网、餐饮、人力资源、银行、汽车、能源、房产、旅游、酒店、零售等，媒推推秉承诚信立足，创新致远的服务理念，始终坚持为全球企业发展提供有价值的性价比服务！",
  // };
  // head[0].appendChild(meta);
  // document.title = "软文发布_媒体发稿_新闻软文发布平台-媒推推";

  //从缓存读取token
  store.dispatch("global/tokenlocal");
  //从缓存读取个人信息
  // store.dispatch("global/usermsglocal");
  reqYue()
    .then((res) => {
      if (res.code == 1) {
        let userMsg = JSON.parse(localStorage.userMsg);
        userMsg.balance = res.data.balance;
        userMsg.score = res.data.score;
        localStorage.userMsg = JSON.stringify(userMsg);
        store.dispatch("global/usermsglocal");
      } else if (res.code == 2) {
        // 清理缓存
        localStorage.clear();
      } else {
        ElementUI.Mmessage.error(res.message || "请求失败");
      }
    })
    .catch((res) => {
      console.log(res);
    });

  /**
   * if (res.code == 2) {
        next({ path: "/" });
        // 清理缓存
        localStorage.clear();
      } else
  */
 
  console.log(to.meta,'to.meta');
  if (to.meta.requireAuth) {
    if (store.state.global.token) {
      // console.log("main.js");
      store.dispatch("global/shopcarlocal");
      next();
    } else {
      next({ path: "/login" });
      ElementUI.Message({
        message: "请先登录",
        type: "error",
        duration: "1500",
      });
    }
  } else {
    next();
  }
});

Vue.use(ElementUI);
// Vue.use(Vuetify);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this; //安装全局事件总线
  },
  router,
  store,
  // vuetify,
  mounted() {
    document.dispatchEvent(new Event("render-event"));
    // document.dispatchEvent(new Event('custom-render-trigger'))
  },
}).$mount("#app");

// // 引入模块
// import x2js from 'x2js'
// const app = createApp(App)
// // 全局挂载 x2js
// app.config.globalProperties.$x2js = new x2js()
