<template>
  <div class="page">
    <div class="title" :style="headStyle" :class="{ colors: colors }">
      <div class="title_max">
        <img
          @mouseleave="getlogo"
          @mouseenter="isGif = 'logo.gif'"
          :src="require('@/assets/page/' + isGif)"
          alt="媒推推"
          @click="navClick('首页', '/')"
          style="
            width: 16vw;
            min-width: 147px;
            max-width: 167px;
            object-fit: contain;
            cursor: pointer;
          "
        />
        <nav class="title-nav" @mouseleave="navleave()">
          <div v-for="(item, index) in navList" :key="index">
            <span
              @mouseenter="naventer(index)"
              :class="[navIndex == index ? 'navspan-a' : '']"
              @click="navClick(item.name, item.path)"
              >{{ item.name }}</span
            >
            <div
              v-show="navIndex == index"
              v-for="(itm, indx) in item.child"
              :key="indx"
              @click="navClick(itm.name, itm.path)"
            >
              <span>{{ itm.name }}</span>
            </div>
          </div>
        </nav>
        <!-- 未登录 -->
        <div v-if="!token" class="title-btn">
          <button :style="RegistrationButton" @click="goLogin(1)">登录</button>
          <button :style="RegistrationButton" @click="goLogin(0)">注册</button>
        </div>
        <!-- 登录后 -->
        <div v-if="token" class="title-portrait">
          <img
            v-if="
              userMessage.tel != 18227984284 &&
              userMessage.tel != 15023607481 &&
              userMessage.tel != 17723046355
            "
            :src="require('@/assets/backstage/portrait/' + portrait)"
            alt=""
            @click="goBackPage"
          />
          <video
            v-if="userMessage.tel == 18227984284"
            :src="portraitH"
            autoplay
            loop
            muted
            @click="goBackPage"
          ></video>
          <img
            v-if="userMessage.tel == 15023607481"
            :src="portraitHS1"
            alt=""
            @click="goBackPage"
          />
          <img
            v-if="userMessage.tel == 17723046355"
            :src="portraitHS2"
            alt=""
            @click="goBackPage"
          />
          <span class="title-portrait-nickname" :style="RegistrationButton" @click="goBackPage"
            >个人中心</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { _throttle } from "@/utils/tool";
import { mapState } from "vuex";
export default {
  inject: ["reload"],
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
      token: (state) => state.global.token,
    }),
  },
  data() {
    return {
      isGif: "Flogo.png",
      portrait: localStorage.portrait || "protrait_exm.png",
      portraitHS1:
        localStorage.portraitHS1 ||
        "https://www.mtuitui.com/onlineSource/gif10.gif",
      portraitHS2:
        localStorage.portraitHS2 ||
        "https://www.mtuitui.com/onlineSource/gif11.gif",
      portraitH:
        localStorage.portraitH ||
        "https://www.mtuitui.com/onlineSource/portrait10.mp4",

      trans: 0,
      transJ: 0,
      // 导航栏样式
      headStyle: {
        background: "hsla(0,0%,100%,.3)",
        color: "#303133",
      },
      RegistrationButton: {
        color: "#0160fa",
        border: "1px solid #0160fa",
      },
      colors: false,

      navIndex: -1,
      navList: [
        {
          name: "首页",
          path: "/",
          child: [],
        },
        {
          name: "媒体资源",
          path: "/mediasource",
          child: [
            {
              name: "新闻媒体",
              path: "/mediasource?type=1001",
            },
            {
              name: "自媒体",
              path: "/mediasource?type=91",
            },
            {
              name: "短视频",
              path: "/mediasource?type=1069",
            },
            // { name: "微信公众号" },
            // { name: "新浪微博" },
            // { name: "短视频" },
            // { name: "海外媒体" },
            // { name: "媒体套餐" },
          ],
        },
        // {
        //   name: "广告资源",
        //   child: [
        //     { name: "交通工具" },
        //     { name: "路灯站牌" },
        //     { name: "楼梯外墙" },
        //     { name: "商场室内" },
        //     { name: "电梯" },
        //     { name: "互联网平台" },
        //   ],
        // },
        {
          name: "基础服务",
          path: "/baseService?id=7&index=0",
          // child: [
          //   { name: "网站" },
          //   { name: "小程序" },
          //   { name: "APP" },
          //   { name: "百科词条" },
          //   { name: "网站SEO" },
          // ],
        },
        // {
        //   name: "合作加盟",
        //   path: "/cooperation",
        // },
        {
          name: "知识学院",
          path: "/knowledge",
          child: [],
        },
        {
          name: "联系我们",
          path: "/contact",
          child: [],
        },
      ],
      lastScrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); //监听页面滚动
    window.requestAnimationFrame(this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // logo动态效果
    getlogo() {
      // 判断当前滚动条位置
      if (document.documentElement.scrollTop == 0) {
        this.isGif = "Flogo.png";
      } else {
        this.isGif = "logo.gif";
      }
    },
    //后台
    goBackPage() {
      this.$router.push("/backstage/homepage");
      this.$parent.$emit("navSideSel", { item1Index: 0, item2Index: 0 });
    },
    //登录注册页跳转
    goLogin(lorr) {
      this.$router.push({
        name: "login",
        params: { lorr: lorr },
      });
    },
    //导航栏效果
    naventer(index) {
      this.navIndex = index;
    },
    navleave() {
      this.navIndex = -1;
    },
    navClick: _throttle(function (name, path) {
      this.$router.push(
        { path: path },
        () => {},
        () => {}
      );
      if (this.$route.path == path.split("?")[0]) {
        this.reload();
      }
    }, 500),
    // 页面移动导航栏改变颜色
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const transJ = this.transJ;

      // 当滚动条滚动的距离大于250px时，改变导航栏的背景颜色
      if (scrollTop >= 100) {
        this.headStyle.background = "rgba(9, 130, 249, 1)";
        this.headStyle.color = "#FFFFFF";
        this.isGif = "logo.png";
        this.RegistrationButton.color = "#fff";
        this.RegistrationButton.border = "1px solid #fff";
      } else {
        if (transJ <= scrollTop) {
          this.trans += 10;
          if (this.trans >= 100) {
            this.trans = 100;
          }
        } else {
          this.trans -= 10;
          if (this.trans <= 0) {
            this.trans = 0;
          }
        }

        // this.headStyle.background = `rgba(9, 130, 249,${this.trans / 100})`;
      }

      // 判断是否需要重置导航栏背景颜色
      if (scrollTop === 0 && this.lastScrollTop !== 0) {
        this.headStyle.background = "hsla(0,0%,100%,.3)";
        this.headStyle.color = "#303133";
        this.colors = false;
        this.isGif = "Flogo.png";
        this.RegistrationButton.color = "#0160fa";
        this.RegistrationButton.border = "1px solid #0160fa";
      } else if (scrollTop !== 0 && this.lastScrollTop === 0) {
        this.colors = true;
      }

      // 存储当前的滚动位置，以便下一次调用时使用
      this.lastScrollTop = scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
// colors
.page {
  width: 100%;
  // min-width: 100%;
   
  .title{
    width: 100%;
    position: fixed;
      z-index: 999;
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      margin: 0 auto;
  }
  .title_max {
    margin: 0 auto;
    max-width: 1500px;
    min-width: 1500px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .title {
      // background: transparent;
      position: absolute;
      z-index: 999;
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      &-nav {
        width: 50%;
        margin: 0 3% 0 8%;
        display: flex;
        // justify-content: end;
      }

      &-nav > div {
        width: 14.3%;
        height: 70px;
        text-align: center;
        line-height: 68px;

        .navspan-a {
          background-color: #ffc152;
          color: #ffffff;
        }
      }

      &-nav > div > span {
        display: inline-block;
        width: 100%;
        height: 70px;
        font-size: 18px;
        cursor: pointer;
      }

      &-nav > div > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0 5px 20px 1px rgba(123, 123, 123, 0.16);
      }

      &-nav > div > div > span {
        display: inline-block;
        height: 60px;
        background-color: #fff;
        //   background-color: #1aa9fc;
        color: #666;
        font-size: 18px;
        cursor: pointer;
      }

      &-nav > div > div > span:hover {
        background-color: #1aa9fc;
        color: white;
      }

      &-btn {
        width: 135px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-btn button {
        //   min-width: 27px;
        width: 58px;
        //   height: 20px;
        text-align: center;
        // border: 1px solid #0160fa;
        background-color: rgba($color: #fff, $alpha: 0);
        border-radius: 6px;
        font-size: 13pt;
        box-sizing: border-box;
        cursor: pointer;
      }

      &-btn button:hover {
        border: 1px solid #fff;
      }

      &-portrait {
        height: 44px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 44px;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        video {
          width: 44px;
          height: 100%;
          border-radius: 50%;
        }

        &-nickname {
          width: 80px;
          height: 30px;
          margin-left: 20rem;
          border: #fff 1px solid;
          border-radius: 6px;
          font-size: 14px;
          color: #fff;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
