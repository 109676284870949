// import { reqCategoryList } from "@/api";
//global模块的小仓库onsole

//提取TOKNEN
const getLocalToken = () => {
  if (typeof localStorage.token == "undefined") {
    return "";
  } else {
    return localStorage.token;
  }
};

//存储购物车
const setLocalShopCar = (state) => {
  const { shopCar } = state;
  const uid = state.userMessage.uid;
  let shopCarObj = {
    uid: uid,
    shopCar: shopCar,
  };
  let shopCarArr = getLocaShopCar();
  if (
    typeof shopCarArr != "undefined" &&
    shopCarArr &&
    shopCarArr instanceof Array &&
    shopCarArr.length > 0
  ) {
    if (shopCarArr.findIndex((val) => val.uid === shopCarObj.uid) !== -1) {
      let index = shopCarArr.findIndex((val) => val.uid === shopCarObj.uid);
      shopCarArr.splice(index, 1, shopCarObj);
    } else {
      shopCarArr.push(shopCarObj);
    }
    const shopCarString = JSON.stringify(shopCarArr);
    localStorage.shopCar = shopCarString;
  } else {
    let shopCarArr = [];
    shopCarArr.push(shopCarObj);
    const shopCarString = JSON.stringify(shopCarArr);
    localStorage.shopCar = shopCarString;
  }
};
//提取购物车
const getLocaShopCar = () => {
  // { shopId: {shopName:'', productList:{ productId: {} }}}
  if (typeof localStorage.shopCar == "undefined") {
    return [];
  } else {
    return JSON.parse(localStorage.shopCar) || [];
  }
};
//state：仓库储存数据的地方
const state = {
  agentId: 0,
  chatPersons: [],
  //state中数据默认初始值别瞎写，根据接口返回值初始化的
  agentSiteInfo: {
    company_name: "",
    logo: "",
    qrcode1: "",
    qrcode2: "",
    phone: "",
    beian: "",
    company_introduce: "",
    email: "",
    address: "",
    description: "",
    keyword: "",
    view_id: 1,
  },
  chatMsgs: [],
  token: "",
  userMessage: {
    uid: "",
    nickname: "",
    vip: false,
    tel: "",
    email: "",
    balance: "",
    score: "",
    type: 1,
    invite_code: "",
  },
  navBackColor: {
    name: "首页",
    color: "#0982F9",
  },
  shopCar: [],
};
//mutations：修改state的唯一手段
const mutations = {
  //设置agentId
  setAgentId(state, id) {
    state.agentId = id;
  },
  setAgentSiteInfo(state, payload) {
    // console.log(payload);
    console.log(state);
    if (payload == null || payload == undefined) {
      return;
    }
    state.agentSiteInfo.company_name = payload.company_name;
    state.agentSiteInfo.logo = payload.logo;
    state.agentSiteInfo.email = payload.email;
    state.agentSiteInfo.phone = payload.phone;
    state.agentSiteInfo.address = payload.address;
    state.agentSiteInfo.qrcode1 = payload.gz_ewm;
    state.agentSiteInfo.qrcode2 = payload.lx_ewm;
    state.agentSiteInfo.company_introduce = payload.company_introduce;
    state.agentSiteInfo.beian = payload.beian;
    state.agentSiteInfo.description = payload.description;
    state.agentSiteInfo.keyword = payload.keyword;
    state.agentSiteInfo.view_id = payload.view_id;
    window.sessionStorage.setItem("view_id", payload.view_id);
  },
  //清空消息
  clear_message(state) {
    state.chatMsgs.length = 0;
  },
  //向后追加消息
  append_message(state, payload) {
    state.chatMsgs.push(payload);
  },
  //向前追加消息
  unshift_message(state, payload) {
    state.chatMsgs.unshift(payload);
  },
  // sort_message(state){
  //   if(state.chatMsgs instanceof Array){
  //       for(let i = 0;i<state.chatMsgs.length;i++){
  //           let maxIdx = i;
  //           for(let j=i;j<state.chatMsgs.length;j++){
  //               if(state.chatMsgs[maxIdx].id >= state.chatMsgs[j].id){
  //                   maxIdx = j;
  //               }
  //           }
  //           if(maxIdx != i){
  //               let tempMsg = state.chatMsgs[maxIdx];
  //               state.chatMsgs[maxIdx] = state.chatMsgs[i];
  //               state.chatMsgs[i] = tempMsg;
  //           }

  //       }
  //   }
  // },
  //从缓存中获取个人信息
  USERMSGLOCAL(state) {
    if (typeof localStorage.userMsg == "undefined" || !localStorage.userMsg) {
      localStorage.userMsg = JSON.stringify(state.userMessage);
    } else {
      state.userMessage = JSON.parse(localStorage.userMsg);
    }
    // if(!localStorage.balance || typeof(localStorage.balance)=="undefined"){

    // }else{
    //   state.userMessage.balance = localStorage.balance;
    //   console.log(localStorage.balance,"2",state.userMessage.balance)
    //   setTimeout(() => {
    //     localStorage.balance = "";
    //   }, 200);
    // }
  },
  //从缓存中获取token
  TOKENLOCAL(state) {
    state.token = getLocalToken();
  },
  //从缓存获取购物车
  SHOPCARLOCAL(state) {
    let temp = getLocaShopCar().filter(
      (elem) => elem.uid == state.userMessage.uid
    );
    if (temp.length > 0) {
      state.shopCar = temp[0].shopCar;
    } else {
      state.shopCar = [];
    }
  },
  //购物车数量增减
  SHOPCARTNUMADD(state, goods) {
    state.shopCar.push(goods);
    setLocalShopCar(state);
  },
  SHOPCARTNUMREDUCE(state, goods) {
    let arr = state.shopCar.filter((item) => {
      return item.id != goods.id;
    });
    state.shopCar = arr;
    setLocalShopCar(state);
  },
  //购物车清空
  SHOPCARTEMPTY(state) {
    state.shopCar = [];
    setLocalShopCar(state);
  },
  //导航栏背景颜色改变
  NAVCOLORCHANGE(state, navBackColor) {
    state.navBackColor.color = navBackColor.color;
    state.navBackColor.name = navBackColor.name;
  },
  //用户信息修改
  USERMESSAGEMODIFY(state, userMessage) {
    state.userMessage = userMessage;
    localStorage.userMsg = JSON.stringify(userMessage);
    // window.sessionStorage.setItem("uid", res.data);
  },
  //余额修改
  USERBALANCE(state, balance) {
    console.log(balance, "3");
    state.userMessage.balance = balance;
  },
  //绑定邮箱
  EMAILBIND(state, email) {
    state.userMessage.email = email;
  },
  //绑定手机
  TELBIND(state, tel) {
    state.userMessage.tel = tel;
  },
  //绑定邀请码
  INVITECODEBIND(state, inviteCode) {
    state.userMessage.inviteCode = inviteCode;
  },
};
//actions：处理action，可以书写自己的业务逻辑，也可以处理异步
const actions = {
  //从缓存中获取个人信息
  usermsglocal({ commit }, userData) {
    commit("USERMSGLOCAL", userData);
  },
  //从缓存中获取token
  tokenlocal({ commit }) {
    commit("TOKENLOCAL");
  },
  //从缓存获取购物车
  shopcarlocal({ commit }) {
    commit("SHOPCARLOCAL");
  },
  //购物车增减事务处理
  shopCartNumAdd({ commit }, goods) {
    commit("SHOPCARTNUMADD", goods);
  },
  shopCartNumReduce({ commit }, goods) {
    commit("SHOPCARTNUMREDUCE", goods);
  },
  //购物车清空
  shopCartEmpty({ commit }) {
    commit("SHOPCARTEMPTY");
  },
  //导航栏背景颜色改变事务
  navColorChange({ commit }, navBackColor) {
    commit("NAVCOLORCHANGE", navBackColor);
  },
  //用户信息修改
  userMessageModify({ commit }, userMessage) {
    commit("USERMESSAGEMODIFY", userMessage);
  },
  //余额修改
  userBalance({ commit }, balance) {
    console.log(balance, "2");
    commit("USERBALANCE", balance);
  },
  //绑定邮箱
  emailBind({ commit }, email) {
    commit("EMAILBIND", email);
  },
  //绑定手机
  telBind({ commit }, tel) {
    commit("TELBIND", tel);
  },
};
//getters：理解为计算属性，用于简化仓库数据，让组件获取仓库的数据更加方便
const getters = {};
//对外暴露Store类的一个实例
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
