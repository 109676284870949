<template>
  <div class="homepage" v-loading="loading">
    <!-- 左侧数据展示 -->
    <div class="date">
      <!-- 个人卡片 -->
      <div class="date-self">
        <div class="date-self-l">
          <div class="date-self-l-name">
            <span>{{ nickname }}</span>
            <img
              v-show="vip"
              @click="goVip"
              src="@/assets/backstage/Vip.png"
              alt="Vip"
            />
            <div v-show="!vip" @click="goVip" class="date-self-l-name-vip">
              <img src="@/assets/backstage/Vip_none.png" alt="" />
              <span>开通会员</span>
            </div>
          </div>
          <div class="date-self-l-balance">
            <div class="item_right">
              <span>余额：</span><span class="balanceBold">{{ balance }}</span>
              <span style="color: #ec0e0e; margin-left: 2px">元</span>
              <span class="refreshYUE" @click="shuaxin()">刷新</span>
            </div>
            <div class="item_left">
              <span @click="topath('PointsDetails', 3)">积分：{{ score }}</span>
              <div class="yhqExchange" @click="yhqBTn()">优惠券兑换</div>
            </div>
          </div>

          <div class="date-self-l-score">
            <div class="item_right">
              <span>会员等级：{{ level_type_name }}</span>
            </div>
            <div class="item_left">
              <span>距离升级还有：{{ next_level_up_score }} 积分</span>
            </div>
          </div>

          <div class="date-self-l-score">
            <div class="item_right">
              <span class="MyInvitation" @click="topath('MyInvitation', 4)"
                >邀请人数：{{ invite_user_total || '暂无' }}</span
              >
            </div>
            <div class="item_left" @click="CopyBtn()" ref="copy">
              <span>我的邀请码：{{ inviteCode || '暂无' }}</span>
            </div>
          </div>

          <div class="chargebtnMax">
            <div class="date-self-l-chargebtn btn-15" @click="goOnlineCharge">
              立即充值
            </div>
            <!-- <div @click="topath('PointsDetails', 3)">积分明细</div> -->
          </div>
        </div>
        <div class="date-self-r">
          <div class="date-self-r-order">
            <span>我的订单</span>
            <div class="date-self-r-order-box">
              <div class="date-self-r-order-box-ed">
                <span>待安排</span>
                <span>{{ ordering }}</span>
                <div @click="goOrderQuery">查看</div>
              </div>
              <div class="date-self-r-order-box-ing">
                <span>已完成</span>
                <span>{{ ordered }}</span>
                <div @click="goOrderQuery">查看</div>
              </div>
            </div>
          </div>
          <div class="date-self-r-photo">
            <img src="@/assets/backstage/Asuna.png" alt="display" />
          </div>
        </div>
      </div>
      <!-- 数据统计 -->
      <div class="date-date">
        <!-- 数据累计 -->
        <div class="date-date-sum">
          <div class="date-date-sum-nav">
            <el-tabs v-model="activeNameS" @tab-click="handleClick">
              <el-tab-pane
                label="当月数据"
                name="month"
                class="date-date-sum-nav-item fontS"
              >
                <!-- <div class="date-date-sum-nav-item"> -->
                <div class="date-date-sum-nav-item-cash">
                  <img src="@/assets/backstage/times.png" alt="" />
                  <span>{{ cashMonth }}</span>
                  <span>合作金额</span>
                </div>
                <div class="date-date-sum-nav-item-times">
                  <img src="@/assets/backstage/cash.png" alt="" />
                  <span>{{ orderQuantity }}</span>
                  <span>订单数量</span>
                </div>
                <!-- </div> -->
              </el-tab-pane>
              <el-tab-pane
                label="累计数据"
                name="history"
                class="date-date-sum-nav-item"
              >
                <div class="date-date-sum-nav-item-cash">
                  <img src="@/assets/backstage/times.png" alt="" />
                  <span>{{ cashHistory }}</span>
                  <span>合作金额</span>
                </div>
                <div class="date-date-sum-nav-item-times">
                  <img src="@/assets/backstage/cash.png" alt="" />
                  <span>{{ timesHistory }}</span>
                  <span>订单数量</span>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <!-- 数据饼图 -->
        <div class="date-date-pie" id="pie"></div>
      </div>
      <!-- 订单曲线 -->
      <div class="date-line">
        <div class="date-line-nav">
          <div class="date-line-nav-l">
            <!-- <span>当月订单</span> -->
            <span>近七天订单</span>
          </div>
          <div class="date-line-nav-r">
            <!-- <div class="date-line-nav-r-month">
              <el-date-picker
                v-model="monthSel"
                type="month"
                placeholder="选择日期"
                :editable="false"
                style="width: 100%"
              >
              </el-date-picker>
            </div> -->
            <!-- <div class="date-line-nav-r-category">
              <div
                @click="categorySel(item, index)"
                :class="{ categorySel: categoryIndex == index }"
                v-for="(item, index) in categoryList"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div> -->
          </div>
        </div>
        <div class="date-line-charts" id="line"></div>
      </div>
    </div>
    <!-- 右侧客服页面 -->
    <div class="service">
      <div>
        <div class="service-card">
          <div class="service-card-qrcode">
            <div class="service-card-qrcode-div">
              <img
                v-image-preview
                src="@/assets/footer/mtuitui_kefu.png"
                alt="qrcode"
              />
              <span>手机微信咨询</span>
            </div>

            <span style="padding: 10rem; text-align: center; width: 50%">
              <!-- <span class="user-service-blue">微信扫码 手机咨询</span>
              <br />
              <span style="color: gray !important; font-weight: 400"
                >或点击下方链接</span
              > -->
              <sapn
                @click="jumpToWxKefu()"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div class="Online_Service_Box">
                  <img
                    class="kefu"
                    src="../../../assets/backstage/icon-user-service.png"
                    alt=""
                  />
                  <span class="Online_Service">&nbspPC微信咨询</span>
                </div>
              </sapn>
            </span>
          </div>
          <div class="service-card-message">
            <div class="service-card-message-item">
              <span class="user_service_title">紧急客服电话：</span>
              <span class="user_service_phone">{{ service.tel }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <Chatbox v-if="false"></Chatbox>this.userMessage.type == 1 -->
      <Notebox></Notebox>
    </div>
    <!-- <Sideicon class="sideIcon"></Sideicon> -->

    <el-dialog
      title="兑换优惠券"
      :visible.sync="centerDialogVisibleYhq"
      width="600px"
      center
    >
      <div style="box-sizing: border-box; width: 100%">
        <template>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="可领取" name="first">
              <couponIndex
                @changeShow="changeShow"
                :types="0"
                :list="couponList"
                @fatherMethod="getcoupon"
              />
            </el-tab-pane>
            <el-tab-pane label="待使用" name="second">
              <couponIndex
                @changeShow="changeShow"
                :types="1"
                :list="couponList"
              />
            </el-tab-pane>
            <el-tab-pane label="已使用/已过期" name="third">
              <couponIndex
                @changeShow="changeShow"
                :types="2"
                :list="couponList"
              />
              <!-- <couponIndex @changeShow="changeShow" :types="3" /> -->
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </el-dialog>
    <el-dialog
      title="优惠券使用规则"
      :visible.sync="centerDialogVisibleSygz"
      width="400px"
      center
    >
      <div style="box-sizing: border-box; width: 100%">
        <div class="bg-white" style="padding: 20px; text-align: left">
          <div style="margin-bottom: 20px">
            <div style="font-size: 14px; margin-bottom: 4px; font-weight: bold">
              一、商品金额满<span
                style="color: rgb(192, 25, 31); font-weight: 500"
                ><span>1000.00</span></span
              >元可用
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div style="font-size: 14px; margin-bottom: 4px; font-weight: bold">
              二、可与其他券叠加
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div style="font-size: 14px; margin-bottom: 4px; font-weight: bold">
              三、订单取消、退款在优惠券有效期内可退回
            </div>
          </div>
          <!---->
        </div>
        <div
          class="bg-white"
          style="display: flex; justify-content: center; cursor: pointer"
          @click="centerDialogVisibleSygz = false"
        >
          <div class="okisee btnTime1">我知道了</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  reqStatistics,
  reqZhexian,
  reqYue,
  fenxiaoinfo,
  get_coupon,
  my_coupon,
  exchange_coupon,
  thisMonth,
  historyData,
} from '@/api'
import Sideicon from '@/components/Sideicon'
import Chatbox from '@/components/Chatbox'
import Notebox from '@/components/Notebox'
import * as echarts from 'echarts'
import couponIndex from './components/index'
export default {
  inject: ['reloadBTop', 'reloadB'],
  components: {
    Chatbox,
    Notebox,
    couponIndex,
    Sideicon,
  },
  watch: {
    processdata: 'scrolltobottom',
  },
  computed: {
    ...mapState({
      userMessage: (state) => state.global.userMessage,
    }),
  },
  data() {
    return {
      couponList: [], //优惠券列表

      loading: false,

      nickname: '',
      vip: false,
      balance: 0,
      score: 0,
      next_level_up_score: 0, //下一级升级所需积分
      level_type_name: '', //会员等级名称
      invite_user_total: 0, //邀请人数
      inviteCode: '', //邀请码
      service: {
        name: '华华',
        qq: '12334567891',
        tel: '17323923267',
      },

      ordered: 0,
      ordering: 0,

      activeNameS: 'month',
      cashMonth: 0, //当月合作金额
      orderQuantity: 0, //当月订单数量
      cashHistory: 0, //累计使用金额
      timesHistory: 0, //累计订单数量
      accumulatedMediaOrders: 0, //累计媒体订单
      accumulatedBasicOrders: 0, //累计基础订单

      monthSel: '',
      categoryIndex: 0,
      categoryList: [
        {
          name: '媒体',
        },
        {
          name: '自媒体',
        },
        {
          name: '短视频',
        },
        {
          name: '广告',
        },
        {
          name: '基础服务',
        },
      ],
      centerDialogVisibleYhq: false,
      centerDialogVisibleSygz: false,
      activeName: 'first',
    }
  },
  mounted() {
    // this.shuaxin();
    fenxiaoinfo().then((res) => {
      console.log('分销信息', res)
      const {
        next_level_up_score,
        level_type_name,
        invite_user_total,
        referee_id,
        referee_nickname,
        invite_code,
        score,
      } = res.data
      this.score = score
      this.next_level_up_score = next_level_up_score
      this.level_type_name = level_type_name
      this.invite_user_total = invite_user_total

      this.referee_id = referee_id
      this.referee_nickname = referee_nickname
      this.inviteCode = invite_code
      // 获取本地缓存的用户信息
      let userMessage = JSON.parse(localStorage.getItem('userMsg'))

      // 再将用户信息存入缓存
      userMessage.referee_id = referee_id
      userMessage.referee_nickname = referee_nickname
      localStorage.setItem('userMsg', JSON.stringify(userMessage))
    })
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 500)
    this.pageStatistics()
    //获取全局用户信息
    setTimeout(() => {
      this.nickname = this.userMessage.nickname
      this.vip = this.userMessage.vip
      this.balance = this.userMessage.balance
      this.score = this.userMessage.score
    }, 1000)
    console.log(this.userMessage, '邀请码')
    //页面置顶
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  methods: {
    jumpToWxKefu() {
      window.open('https://work.weixin.qq.com/kfid/kfc5c00ee3e757076ea')
    },
    CopyBtn() {
      // 点击copy按钮执行复制操作
      navigator.clipboard.writeText(this.inviteCode).then(
        () => {
          //复制成功的操作
          this.$message({
            message: '复制成功',
            type: 'success',
          })
        },
        () => {
          //复制失败的操作
          console.log('复制失败')
          this.$message({
            message: '复制失败',
            type: 'error',
          })
        }
      )
    },
    yhqBTn() {
      this.centerDialogVisibleYhq = true
      this.getcoupon()
    },
    //获取优惠券列表
    async getcoupon() {
      const res = await get_coupon(this.data)
      this.couponList = res.data.records
      console.log(res, '优惠券列表')
    },
    // 获取我的优惠券
    // 1未使用2已使用3已过期
    async getMyCoupon(data) {
      const res = await my_coupon({
        coupon_type: data,
      })
      console.log(data, 'data')

      const records = res.data.records
      if (data != 1) {
        const isUse = data == 2 ? 2 : 3
        records.forEach((item) => {
          item.isUse = isUse
        })
        this.couponList.push(...records)
      } else {
        this.couponList = records
      }
    },

    topath(path, index) {
      // 根据name跳转路由
      this.$router.push({ name: path })
      this.$parent.$emit('navSideSel', { item1Index: 6, item2Index: index })
      this.$bus.$emit('noteIndex', index)
      setTimeout(() => {
        // 显示对应的二级菜单
        this.$bus.$emit('noteIndex', index)
      }, 500)
    },
    handleClick(tab) {
      this.couponList = []
      if (tab.name == 'first') {
        this.getcoupon()
      } else if (tab.name == 'second') {
        this.getMyCoupon(1)
      } else if (tab.name == 'third') {
        this.getMyCoupon(2)
        this.getMyCoupon(3)
      }

      console.log('tab.name')
    },

    changeShow() {
      this.centerDialogVisibleSygz = true
    },
    goVip() {
      // this.vip = !this.vip; //......
    },
    shuaxin() {
      reqYue()
        .then((res) => {
          if (res.code == 1) {
            let userMsg = JSON.parse(localStorage.userMsg)
            userMsg.balance = res.data.balance
            userMsg.score = res.data.score
            localStorage.userMsg = JSON.stringify(userMsg)
            this.$store.dispatch('global/usermsglocal')
            this.reloadBTop()
            this.reloadB()
          } else {
            this.$message.error(res.message || '请求失败')
          }
        })
        .catch((res) => {
          this.$message.error(res)
        })
    },
    goOrderQuery() {
      this.$router.push('/backstage/orderMgt/orderQuery')
      this.$parent.$emit('navSideSel', { item1Index: 4, item2Index: 0 })
    },
    goOnlineCharge() {
      this.$router.push('/backstage/fundMgt/onlineCharge')
      this.$parent.$emit('navSideSel', { item1Index: 5, item2Index: 0 })
    },
    categorySel(item, index) {
      this.categoryIndex = index
    },
    //饼图初始化
    getPieEchartData(data) {
      var chartDom = document.getElementById('pie')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        tooltip: {
          trigger: 'item',
        },
        title: {
          text: '累计订单数量',
          // subtext: "Fake Data",
          bottom: '20px',
          left: 'center',
        },
        series: [
          {
            name: '累计订单数量',
            type: 'pie',
            radius: '50%',
            // left: "center",
            bottom: '30px',
            data: [
              { value: this.accumulatedMediaOrders, name: '媒体订单' },
              { value: this.accumulatedBasicOrders, name: '基础订单' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
      option && myChart.setOption(option)
    },
    //折线图初始化
    getLineEchartData(data) {
      var chartDom = document.getElementById('line')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: '订单数',
          textStyle: {
            color: '#999',
            fontSize: 16,
            fontWeight: 'normal',
          },
          left: '20px',
          top: '10px',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          icon: 'rect',
          // data: ["已完成", "待安排", "被拒绝", "已撤销", "执行中"],
          data: data.legend,
          right: '50px',
          top: '10px',
          height: 14,
          itemHeight: 14,
          itemWidth: 14,
          itemGap: 20,
        },
        grid: {
          width: 'auto',
          left: '20px',
          bottom: '10px',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data: ["1", "4", "7", "10", "13", "16", "19", "22", "25", "28"],
          data: data.xAxis,
        },
        yAxis: {
          type: 'value',
        },
        series: data.data,
      }

      option && myChart.setOption(option)
    },
    //首页统计(含饼图初始化)
    pageStatistics() {
      // 当月数据
      thisMonth().then((res) => {
        if (res.code == 1) {
          this.orderQuantity =
            res.data.goods_order_count + res.data.media_order_count
          this.cashMonth = res.data.sum_media_price + res.data.sum_goods_price
          setTimeout(() => {
            this.getPieEchartData(res.data.yuan)
          }, 200)
        } else {
          this.$message.error('请求出错')
        }
      })
      reqZhexian().then((res) => {
        if (res.code == 1) {
          let type = []
          res.data.forEach((ele) => {
            type.push(ele.name)
          })
          let temp = {
            legend: type,
            xAxis: this.yesterday(),
            data: res.data,
          }
          setTimeout(() => {
            this.getLineEchartData(temp)
          }, 200)
        } else {
          this.$message.error('请求出错')
        }
      })

      // 累计数据
      historyData().then((res) => {
        if (res.code == 1) {
          this.timesHistory =
            res.data.goods_order_count + res.data.media_order_count
          this.cashHistory = res.data.sum_media_price + res.data.sum_goods_price
          this.accumulatedMediaOrders = res.data.media_order_count
          this.accumulatedBasicOrders = res.data.goods_order_count
          setTimeout(() => {
            this.getPieEchartData(res.data.yuan)
          }, 200)
        } else {
          this.$message.error('请求出错')
        }
      })
    },

    //请求折线数据
    yesterday() {
      let xAxis = []
      let timenow = new Date()
      timenow.setTime(timenow.getTime() - 24 * 60 * 60 * 1000 * 8)
      for (let i = 7; i >= 1; i--) {
        timenow.setTime(timenow.getTime() + 24 * 60 * 60 * 1000 * 1)
        let m = timenow.getMonth() + 1
        m = m < 10 ? '0' + m : m
        let d = timenow.getDate()
        d = d < 10 ? '0' + d : d
        let yesterdayTime = m + '-' + d
        xAxis.push(yesterdayTime)
      }
      return xAxis
    },
    //当月数据统计
  },
}
</script>

<style lang="scss" scoped>
.user-service-blue {
  font-size: 14rem !important;
  font-weight: 600 !important;
  color: #0982f9 !important;
}
.Online_Service_Box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0982f9;
  border-radius: 5rem;
  width: 75%;
  height: 40rem;
}
.kefu {
  width: 25px;
  height: 25px;
}
.Online_Service {
  color: #fff !important;
  // 鼠标
  cursor: pointer;
}
.Online_Service:hover {
  color: #95f379 !important;
}
:deep(.el-dialog__body) {
  padding: 0 20px 20px 20px;
}
.MyInvitation {
  cursor: pointer;
}
.item_right {
  width: 318rem;
}
.item_left {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 300rem;
  margin: 0 20rem;
  cursor: pointer;
}
.bg-white {
  background-color: #ffffff;
  color: #666666;
  // display: flex;
  // justify-content: center;
  .okisee {
    margin: 0 20px 20px 20px;
    background: linear-gradient(132deg, #c11920 0%, #ee2626 100%);
    opacity: 1;
    width: 335px;
    border-radius: 48px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    line-height: 38px;
  }
}
.homepage {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: space-between;
  overflow: hidden;
  .date {
    width: 72%;
    height: 850rem;
    display: flex;
    flex-direction: column;
    // overflow: scroll;
    overflow: hidden;
    &-self {
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      &-l {
        width: 50%;
        // min-width: 315px;
        height: 100%;
        &-name {
          margin: 40rem 0 20rem;
          display: flex;
          align-items: center;
          span {
            margin-left: 12%;
            font-size: 24rem;
            color: #666;
          }
          img {
            width: 36rem;
            height: 36rem;
            margin-left: 3%;
            object-fit: contain;
            cursor: pointer;
          }
          &-vip {
            // width: 96rem;
            // height: 26rem;
            padding: 6rem 10rem;
            margin-left: 3%;
            border: 1px solid #e2e2e2;
            border-radius: 6px;
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              width: 16rem;
              height: 16rem;
              object-fit: contain;
            }
            span {
              font-size: 14rem;
              color: #999;
              margin-left: 4rem;
              white-space: nowrap; /*内容超宽后禁止换行显示*/
              text-overflow: ellipsis; /*文字超出部分以省略号显示*/
            }
          }
        }
        &-balance,
        &-score {
          margin: 0 0 18rem 13%;
          display: flex;
          align-items: center;

          span {
            font-size: 16rem;
            color: #666;
          }
          .yhqExchange {
            width: 77rem;
            height: 22rem;
            margin-left: 20px;
            border-radius: 6px;
            background: linear-gradient(#71b6fa, #0982f9);
            font-size: 12px;
            color: #fff;
            text-align: center;
            line-height: 22rem;
            cursor: pointer;
          }
        }
        .chargebtnMax {
          // width: 200rem;
          height: 29rem;
          margin: 24rem 0 40rem 13%;
          border-radius: 6px;
          font-size: 16rem;
          text-align: center;
          line-height: 29rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &-chargebtn {
          width: 106rem;
          height: 29rem;
          border-radius: 6px;
          background: linear-gradient(#71b6fa, #0982f9);
          font-size: 16rem;
          color: #fff;
          text-align: center;
          line-height: 29rem;
          cursor: pointer;
        }
      }
      &-r {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        &-order {
          height: 100%;
          margin-right: 6%;
          &-box {
            // width: 28%;
            // min-width: 214px;
            display: flex;
            justify-content: space-between;
            &-ed {
              margin-right: 60rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              span:first-child {
                font-size: 18rem;
                color: #666;
              }
              span:nth-child(2) {
                font-size: 28rem;
                color: #f67b33;
                margin: 10px 0 15px;
              }
              div {
                width: 60rem;
                height: 26rem;
                border: #f67b33 1px solid;
                border-radius: 8px;
                font-size: 16rem;
                color: #f67b33;
                text-align: center;
                line-height: 26rem;
                cursor: pointer;
                transition: 0.2s ease;
              }
              div:hover {
                background-color: #f67b33;
                color: #fff;
                transition: 0.2s ease;
              }
            }
            &-ing {
              display: flex;
              flex-direction: column;
              align-items: center;
              span:first-child {
                font-size: 18rem;
                color: #666;
              }
              span:nth-child(2) {
                font-size: 28rem;
                margin: 10px 0 15px;
                color: #0982f9;
              }
              div {
                width: 60rem;
                height: 26rem;
                border: #0982f9 1px solid;
                border-radius: 8px;
                font-size: 16rem;
                color: #0982f9;
                text-align: center;
                line-height: 26rem;
                cursor: pointer;
                transition: 0.2s ease;
              }
              div:hover {
                background-color: #0982f9;
                color: #fff;
                transition: 0.2s ease;
              }
            }
          }
        }
        &-order > span {
          display: inline-block;
          margin: 40rem 0 25rem;
          font-size: 24rem;
          font-weight: bold;
          color: #666;
        }
        &-photo {
          width: 345rem;
          height: 197rem;
          margin: 20rem 4% 33rem 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
          }
        }
      }
    }
    &-date {
      width: 100%;
      height: 306rem;
      margin-top: 20rem;
      display: flex;
      justify-content: space-between;
      &-sum {
        width: 58%;
        height: 100%;
        background-color: #fff;
        &-nav {
          width: 93%;
          margin: 0 auto;

          &-item {
            display: flex;
            &-cash,
            &-times {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              img {
                width: 58rem;
                height: 58rem;
                margin: 5rem 0 10rem;
                object-fit: contain;
              }
              span:nth-child(2) {
                font-size: 26rem;
                color: #ec0e0e;
                font-weight: bold;
              }
              span:nth-child(3) {
                margin: 8rem 0 0;
                font-size: 14rem;
                color: #999;
              }
            }
          }
        }
      }
      &-pie {
        width: 40%;
        height: 100%;
        background-color: #fff;
      }
    }
    &-line {
      width: 100%;
      height: 460rem;
      min-height: 230px;
      margin: 20rem 0;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-nav {
        width: 100%;
        height: 46rem;
        display: flex;
        justify-content: space-between;
        &-l {
          width: 35%;
          margin-left: 2%;
          border-bottom: 1px solid #e2e2e2;
          padding: 16rem 0 5rem;
          span {
            // border-bottom: 1px solid #0982f9;
            font-size: 20rem;
            color: #666;
            line-height: 20rem;
            cursor: default;
          }
        }
        &-r {
          width: 62%;
          height: 100%;
          display: flex;
          // justify-content: space-between;
          justify-content: flex-end;
          align-items: center;
          &-month {
            width: 25%;
            height: 100%;
            font-size: 16rem;
            color: #999;
            cursor: pointer;
          }
          &-category {
            // width: 70%;
            width: auto;
            height: 80%;
            margin-right: 10%;
            display: flex;
            align-items: center;
            border-radius: 8px;
            border: #e2e2e2 1px solid;
            box-sizing: border-box;
            div {
              width: auto;
              height: 100%;
              padding: 0 15rem;
              border-radius: 8px;
              font-size: 15rem;
              color: #999;
              display: flex;
              align-items: center;
              transition: 0.1s ease-out;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
            }
            .categorySel {
              background-color: #bddcfa;
              color: #0982f9;
              border: #bddcfa 1px solid;
              transition: 0.1s ease-out;
            }
          }
        }
      }
      &-charts {
        width: 100%;
        height: 90%;
        min-height: 230px;
      }
    }
  }
  .date::-webkit-scrollbar {
    // display: none !important;
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
  }
  /*定义滚动条轨道内阴影+圆角*/
  .date::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  /*定义滑块内阴影+圆角*/
  .date::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
  .service {
    width: 26%;
    display: flex;
    flex-direction: column;
    margin-left: 25rem;
    &-card {
      width: 100%;
      // height: 140rem;
      height: 250rem;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-message {
        width: 100%;
        text-align: center;
        margin-top: 18rem;
        &-title {
          display: inline-block;
          // margin: 20rem 0 10rem 20rem;
          font-size: 18rem;
          color: #333;
        }
        &-item {
          // margin: 15rem 0 15rem 20rem;
          white-space: nowrap; /*内容超宽后禁止换行显示*/
          text-overflow: ellipsis; /*文字超出部分以省略号显示*/
        }
        &-item span {
          font-size: 14rem;
        }
        &-item span:first-child {
          color: #999;
        }
        &-item span:last-child {
          color: #333;
        }
      }
      &-qrcode {
        background-color: #f4f4f4;
        width: 85%;
        height: 46%;
        border-radius: 5rem;
        display: flex;
        align-items: center;
        padding: 15rem;
        justify-content: space-around;
        &-div {
          width: 90rem;
          height: 90rem;
          margin-bottom: 10rem;
          border: #707070 1px solid;
          text-align: center;
          font-size: 12rem;
          color: #999;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        span {
          text-align: center;
          font-size: 14rem;
          color: #999;
        }
      }
    }
    &-add {
      width: 100%;
      height: 40rem;
      margin-top: 20rem;
      background-color: #fff;
      font-size: 14rem;
      color: #999;
      text-align: center;
      line-height: 40rem;
    }
    &-online {
      width: 100%;
      margin: 0;
      background-color: #fff;
      border-radius: 8px;
      &-title {
        width: 100%;
        height: 50rem;
        background-color: #0982f9;
        border-radius: 8px 8px 0 0;
        font-size: 16rem;
        color: #fff;
        text-align: center;
        line-height: 48rem;
      }
      //聊天窗口样式
      ::-webkit-scrollbar {
        width: 0 !important;
      }
      &-chatbox {
        width: 100%;
        height: 590rem;
        overflow-y: scroll;
        &-scroll {
          // min-height: 590px;
          .left {
            width: 70%;
            margin: 16rem 0 16rem 20rem;
            display: flex;
            flex-direction: column;
          }
          .left span {
            font-size: 16rem;
            color: #999;
          }
          .left span:last-child {
            padding: 12rem 16rem;
            margin-top: 10rem;
            border-radius: 6px;
            background-color: #eff3f6;
            color: #444;
          }
          .right {
            width: 70%;
            margin: 16rem 20rem 16rem 90rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
          .right span {
            font-size: 16rem;
            color: #999;
          }
          .right span:last-child {
            padding: 12rem 16rem;
            margin-top: 10rem;
            border-radius: 6px;
            background-color: rgba($color: #1b8eff, $alpha: 0.6);
            color: #444;
          }
        }
      }
      //发送栏样式
      &-send {
        width: 100%;
        height: 60rem;
        border-radius: 0 0 8px 8px;
        box-shadow: 0px -6px 30px 1px rgba(54, 54, 54, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-send > input {
        width: 55%;
        height: 15rem;
        padding: 15rem 15rem 10rem;
        background-color: #f4f4f4;
        border-radius: 8px;
        border: none;
        outline: none;
      }
      &-send > input::placeholder {
        font-size: 16rem;
        color: #999;
      }
      &-send > div {
        width: 25%;
        height: 40rem;
        margin-left: 10rem;
        background-color: rgba($color: #0982f9, $alpha: 0.15);
        border-radius: 8px;
        font-size: 16rem;
        color: #0982f9;
        text-align: center;
        line-height: 45rem;
        cursor: pointer;
      }
    }
  }
}
.user_service_phone {
  font-size: 24rem !important;
  font-weight: 800;
}
.user_service_title {
  font-size: 22rem !important;
  font-weight: 200;
}
.balanceBold {
  font-size: 24rem !important;
  font-weight: bold;
  color: #f90044 !important;
  letter-spacing: 1px;
}
.refreshYUE {
  margin-left: 15rem;
  font-size: 16rem !important;
  color: #1b8eff !important;
  cursor: pointer;
}
.refreshYUE:hover {
  color: #0982f9 !important;
}

/* 15 */
.btn-15 {
  // background: #b621fe;
  border: none;
  z-index: 1;
  position: relative;
}
.btn-15:after {
  position: absolute;
  content: '';
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #0982f9;
  border-radius: 5px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.btn-15:hover {
  color: #fff;
}
.btn-15:hover:after {
  left: 0;
  width: 100%;
}
.btn-15:active {
  top: 2px;
}
</style>
