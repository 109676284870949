<template>
  <div class="mediasource">
    <Topbg :bgimage="bgimage"></Topbg>
    <!-- 媒体资源导航 -->
    <div class="navbox">
      <nav class="navbox-nav">
        <!-- 一级 -->
        <span
          v-for="(item, index) in mediaNavList"
          :key="index"
          :class="{ navsel: index == navIndex }"
          @click="navSel(index)"
          >{{ item.title }}</span
        >
      </nav>
      <div class="navbox-filter">
        <!-- 二级 -->
        <div
          class="navbox-filter-item"
          v-for="(item, index) in navFilterList1"
          :key="index"
        >
          <div class="navbox-filter-item-left">
            <span>{{ item.title }} </span>
            <span
              @click="navFilterSel(item.id, 0, index, item.screen)"
              :class="{
                navFiltersel: navFilterSeled.some((element) => {
                  return (
                    element.index == index &&
                    element.id == item.id &&
                    element.id1 == 0
                  );
                }),
              }"
              >不限</span
            >
          </div>
          <div class="navbox-filter-item-right">
            <!-- 三级内容 -->
            <span
              v-for="(itm, indx) in item.children"
              :key="indx"
              @click="navFilterSel(item.id, itm.id, index, item.screen)"
              :class="{
                navFiltersel: navFilterSeled.some((element) => {
                  return (
                    element.index == index &&
                    element.id == item.id &&
                    element.id1 == itm.id
                  );
                }),
              }"
              >{{ itm.title }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 媒体资源内容列表 -->
    <div class="listbox" v-loading="loading">
      <Netmedia
        class="netmedia"
        :netmediaList1="netmediaList"
        :loading1="loading"
        @fatherMethod="fatherMethod1"
      ></Netmedia>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { _throttle } from "@/utils/tool";
import { reqFilterNav, reqMediaFilter } from "@/api";

import Topbg from "./Topbg";
import Netmedia from "./Netmedia";
export default {
  components: {
    Topbg,
    Netmedia,
  },
  computed: {
    ...mapState({
      token: (state) => state.global.token,
    }),
  },
  data() {
    return {
      loading: false,
      bgimage: "bg-1.png",
      navIndex: 0,
      mediaNavList: [],
      navFilterList1: [],
      navFilterSeled: [],
      senNavObj: {},
      netmediaList: [],
    };
  },
  mounted() {
    // this.senNavObj["token"] = this.token;
    //从url解析所有参数,放入senNavObj中

    this.paraUrlToSenNavObj();
    this.senNavObj["token"] = "";

    this.bgimage = "bg-1.png";
    this.loading = true;
    reqFilterNav()
      .then((res) => {
        if (res.code == 1) {
          if (
            this.$route.query.type &&
            typeof this.$route.query.type != "undefined"
          ) {
            console.log("111111111112" + this.$route.query.type);

            this.senNavObj["type"] = this.$route.query.type;
            this.senNavObj["index"] = this.$route.query.type;
            if (this.$route.query.type == 1001) {
              this.navIndex = 0;
            } else if (this.$route.query.type == 91) {
              this.navIndex = 1;
            } else if (this.$route.query.type == 1069) {
              this.navIndex = 2;
            }
          } else {
            console.log("222222222");
            this.navIndex = 0;
            this.senNavObj["type"] = 1001;
          }
          this.mediaNavList = res.data;
          this.navFilterList1 = this.mediaNavList[this.navIndex].sons;
          console.log(this.navFilterList1, "navFilterList1");
          this.senNavObj["index"] = res.data[this.navIndex].id;
          for (let i = 0; i < this.navFilterList1.length; i++) {
            if (this.senNavObj[this.navFilterList1[i].screen]) {
              console.log(
                this.navFilterList1[i].screen +
                  "," +
                  this.senNavObj[this.navFilterList1[i].screen]
              );
              //遍历children
              for (let j = 0; j < this.navFilterList1[i].children.length; j++) {
                let t2 = {
                  index: i,
                  id: this.navFilterList1[i].id,
                  id1: this.navFilterList1[i].children[j].id,
                };
                if (
                  this.senNavObj[this.navFilterList1[i].screen] ==
                  this.navFilterList1[i].children[j].id
                ) {
                  if (!this.checkNavFilterSeled(t2)) {
                    this.navFilterSeled.push(t2);
                  }
                }
              }
            } else {
              let t = {
                index: i,
                id: this.navFilterList1[i].id,
                id1: 0,
              };
              if (!this.checkNavFilterSeled(t)) {
                this.navFilterSeled.push(t);
              }
              this.senNavObj[this.navFilterList1[i].screen] = "";
            }
          }
        } else {
          this.$message.error(res.$message);
        }
        console.log(this.senNavObj, "SenNavObj");
        if (
          this.senNavObj["index"] != "" &&
          typeof this.senNavObj["index"] != "undefined"
        ) {
          this.senNavObj["type"] = this.senNavObj["index"];
        }

        reqMediaFilter(this.senNavObj)
          .then((res) => {
            if (res.code == 1) {
              this.netmediaList = res.data;
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((res) => {
            this.$message.error(res);
          })
          .finally(() => {
            this.loading = false;
          });
      })
      .catch((res) => {
        this.$message.error(res);
      })
      .finally(() => {
        this.loading = false;
      });
    //请求
    this.$bus.$on("mediaSJ", (index) => {
      this.navIndex = index;
      this.bgimage = "bg-" + (index + 1) + ".png";
      reqFilterNav()
        .then((res) => {
          this.mediaNavList = res.data;
          this.navFilterList1 = this.mediaNavList[index].sons;
          this.senNavObj["type"] = res.data[index].id;
          for (let i = 0; i < this.navFilterList1.length; i++) {
            let t = {
              index: i,
              id: this.navFilterList1[i].id,
              id1: 0,
            };
            if (!this.checkNavFilterSeled(t)) {
              this.navFilterSeled.push(t);
            }

            this.senNavObj[this.navFilterList1[i].screen] = "";
          }
          console.log(this.senNavObj, "senNavObj5");
          reqMediaFilter(this.senNavObj)
            .then((res) => {
              if (res.code == 1) {
                this.netmediaList = res.data;
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((res) => {
              this.$message.error(res);
            });
        })
        .catch((res) => {
          this.$message.error(res);
        });
    });
    // 获取url参数

    setTimeout(() => {
      // 判断它是否存在就行 参数带齐
      if (this.$route.query.id && typeof this.$route.query.id != "undefined") {
        const { id, ids, indexs, ziduan } = this.$route.query;
        // id,ids,index,字段
        this.navFilterSel(id, ids, indexs, ziduan);
        // 2 1097 1098
      } else {
        // console.log("没有type参数");
      }
    }, 2000);
  },
  methods: {
    updateUrlBySenObj(senObj) {
      history.replaceState(
        null,
        null,
        window.location.href.replace(window.location.search, "")
      );
      if (senObj["type"] && typeof senObj["type"] != "undefined") {
        this.changeURLStatic("type", senObj["type"]);
      }
    },
    updateUrlBySenObj2(senObj) {
      if (senObj["type"] && typeof senObj["type"] != "undefined") {
        this.changeURLStatic("type", senObj["type"]);
      }
      if (senObj["screen_one"] && typeof senObj["screen_one"] != "undefined") {
        this.changeURLStatic("screen_one", senObj["screen_one"]);
      }

      if (senObj["screen_two"] && typeof senObj["screen_two"] != "undefined") {
        this.changeURLStatic("screen_two", senObj["screen_two"]);
      }

      if (
        senObj["screen_three"] &&
        typeof senObj["screen_three"] != "undefined"
      ) {
        this.changeURLStatic("screen_three", senObj["screen_three"]);
      }

      if (
        senObj["screen_four"] &&
        typeof senObj["screen_four"] != "undefined"
      ) {
        this.changeURLStatic("screen_four", senObj["screen_four"]);
      }

      if (
        senObj["screen_five"] &&
        typeof senObj["screen_five"] != "screen_five"
      ) {
        this.changeURLStatic("screen_five", senObj["screen_five"]);
      }

      if (senObj["screen_six"] && typeof senObj["screen_six"] != "undefined") {
        this.changeURLStatic("screen_six", senObj["screen_six"]);
      }

      if (
        senObj["screen_seven"] &&
        typeof senObj["screen_seven"] != "undefined"
      ) {
        this.changeURLStatic("screen_seven", senObj["screen_seven"]);
      }

      if (
        senObj["screen_eight"] &&
        typeof senObj["screen_eight"] != "undefined"
      ) {
        this.changeURLStatic("screen_eight", senObj["screen_eight"]);
      }

      if (
        senObj["screen_nine"] &&
        typeof senObj["screen_nine"] != "undefined"
      ) {
        this.changeURLStatic("screen_nine", senObj["screen_nine"]);
      }
    },
    changeURLStatic(name, value) {
      let url = this.changeURLParam(location.href, name, value); // 修改 URL 参数
      history.replaceState(null, null, url); // 替换地址栏
    },
    changeURLParam(url, name, value) {
      value = value.toString();
      if (typeof value === "string") {
        value = value.toString().replace(/(^\s*)|(\s*$)/, ""); // 移除首尾空格
      }
      let url2;
      if (!value) {
        // remove
        let reg = eval("/(([?|&])" + name + "=[^&]*)(&)?/i");
        let res = url.match(reg);
        if (res) {
          if (res[2] && res[2] === "?") {
            // before has ?
            if (res[3]) {
              // after has &
              url2 = url.replace(reg, "?");
            } else {
              url2 = url.replace(reg, "");
            }
          } else {
            url2 = url.replace(reg, "$3");
          }
        }
      } else {
        let reg = eval("/([?|&]" + name + "=)[^&]*/i");
        if (url.match(reg)) {
          // edit
          url2 = url.replace(reg, "$1" + value);
        } else {
          // add
          let reg = /([?](\w+=?)?)[^&]*/i;
          let res = url.match(reg);
          url2 = url;
          if (res) {
            if (res[0] !== "?") {
              url2 += "&";
            }
          } else {
            url2 += "?";
          }
          url2 += name + "=" + value;
        }
      }
      return url2;
    },
    checkNavFilterSeled(navSelected) {
      //检查是否存在相同数据
      for (let i = 0; i < this.navFilterSeled.length; i++) {
        if (
          navSelected.id == this.navFilterSeled[i].id &&
          navSelected.id1 == this.navFilterSeled[i].id1 &&
          navSelected.index == this.navFilterSeled[i].index
        ) {
          return true;
        }
      }
      return false;
    },
    paraUrlToSenNavObj() {
      if (
        this.$route.query.index &&
        typeof this.$route.query.index != "undefined"
      ) {
        this.senNavObj["index"] = this.$route.query.index;
      }
      if (
        this.$route.query.type &&
        typeof this.$route.query.type != "undefined"
      ) {
        this.senNavObj["type"] = this.$route.query.type;
      }

      if (
        this.$route.query.screen_one &&
        typeof this.$route.query.screen_one != "undefined"
      ) {
        this.senNavObj["screen_one"] = this.$route.query.screen_one;
      }
      if (
        this.$route.query.screen_two &&
        typeof this.$route.query.screen_two != "undefined"
      ) {
        this.senNavObj["screen_two"] = this.$route.query.screen_two;
      }
      if (
        this.$route.query.screen_three &&
        typeof this.$route.query.screen_three != "undefined"
      ) {
        this.senNavObj["screen_three"] = this.$route.query.screen_three;
      }
      if (
        this.$route.query.screen_four &&
        typeof this.$route.query.screen_four != "undefined"
      ) {
        this.senNavObj["screen_four"] = this.$route.query.screen_four;
      }
      if (
        this.$route.query.screen_five &&
        typeof this.$route.query.screen_five != "undefined"
      ) {
        this.senNavObj["screen_five"] = this.$route.query.screen_five;
      }
      if (
        this.$route.query.screen_six &&
        typeof this.$route.query.screen_six != "undefined"
      ) {
        this.senNavObj["screen_six"] = this.$route.query.screen_six;
      }
      if (
        this.$route.query.screen_seven &&
        typeof this.$route.query.screen_seven != "undefined"
      ) {
        this.senNavObj["screen_seven"] = this.$route.query.screen_seven;
      }
      if (
        this.$route.query.screen_eight &&
        typeof this.$route.query.screen_eight != "undefined"
      ) {
        this.senNavObj["screen_eight"] = this.$route.query.screen_eight;
      }

      if (
        this.$route.query.screen_nine &&
        typeof this.$route.query.screen_nine != "undefined"
      ) {
        this.senNavObj["screen_nine"] = this.$route.query.screen_nine;
      }
    },
    fatherMethod1(value1, value2, pagination, searchText) {
      this.senNavObj["price"] = value1;
      this.senNavObj["quanzhong"] = value2;
      this.senNavObj["page"] = pagination;
      this.senNavObj["like"] = searchText;
      console.log(this.senNavObj, "senNavObj4");
      reqMediaFilter(this.senNavObj).then((res) => {
        if (res.code == 1) {
          this.netmediaList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    navSel(index) {
      this.loading = true;
      this.navIndex = index;
      this.navFilterList1 = this.mediaNavList[index].sons;
      this.senNavObj["type"] = this.mediaNavList[index].id;
      Object.keys(this.senNavObj).forEach((key) => {
        if (key != "type") {
          this.senNavObj[key] = "";
        }
      });
      this.navFilterSeled = [];
      for (let i = 0; i < this.navFilterList1.length; i++) {
        let t = {
          index: i,
          id: this.navFilterList1[i].id,
          id1: 0,
        };
        if (!this.checkNavFilterSeled(t)) {
          this.navFilterSeled.push(t);
        }
      }
      this.bgimage = "bg-" + (index + 1) + ".png";
      console.log(this.senNavObj, "senNavObj3");
      this.updateUrlBySenObj(this.senNavObj);
      reqMediaFilter(this.senNavObj)
        .then((res) => {
          if (res.code == 1) {
            this.netmediaList = res.data;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    navFilterSel(id, id1, index, ziduan) {
      let t = {
        index: index,
        id: id,
        id1: id1,
      };
      // console.log(
      //   `/mediasource?index=${this.navIndex}&id=${id}&ids=${id1}&indexs=${index}&ziduan=${ziduan}`,
      //   "URL"
      // );
      // this.showUrlPath();
      if (this.navFilterSeled.length > 0) {
        this.navFilterSeled.forEach((elem) => {
          if (elem.index == index) {
            elem.id = id;
            elem.id1 = id1;
          } else {
            if (!this.checkNavFilterSeled(t)) {
              this.navFilterSeled.push(t);
            }
          }
        });
      } else {
        if (!this.checkNavFilterSeled(t)) {
          this.navFilterSeled.push(t);
        }
      }
      if (id1 == 0) {
        this.senNavObj[ziduan] = "";
      } else {
        this.senNavObj[ziduan] = id1;
      }
      this.loading = true;
      console.log(this.senNavObj, "senNavObj2");
      console.log(this.navFilterSeled);
      this.updateUrlBySenObj2(this.senNavObj);
      reqMediaFilter(this.senNavObj)
        .then((res) => {
          if (res.code == 1) {
            this.netmediaList = res.data;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  beforeDestory() {
    this.$bus.$off("mediaSJ");
  },
};
</script>

<style lang="scss" scoped>
.navFiltersel {
  color: #0982f9 !important;
}
.mediasource {
  width: 100%;
  padding-bottom: 380rem;
  background-color: #f4f4f4;

  .navbox {
    width: 1480rem;
    // height: 550rem;
    margin: -180rem auto 0;
    background-color: #fff;
    position: relative;
    z-index: 30;
    &-nav {
      width: 1320rem;
      padding: 0 80rem;
      margin-bottom: 20rem;
      border-bottom: 1px solid #e3e3e3;
      span {
        display: inline-block;
        height: 80rem;
        font-size: 28rem;
        color: #666;
        margin-right: 80rem;
        line-height: 80rem;
        cursor: pointer;
      }
      span:hover {
        color: rgba($color: #0982f9, $alpha: 0.5);
      }
      .navsel {
        border-bottom: 6px solid #0982f9;
        color: #0982f9;
      }
    }
    &-filter {
      width: 1340rem;
      min-height: 450rem;
      padding: 0 70rem;
      &-item {
        width: 1340rem;
        display: flex;
        &-left {
          width: 150rem;
          display: flex;
          justify-content: flex-end;
          font-size: 18rem;
          color: #585858;
          span {
            white-space: nowrap; /*内容超宽后禁止换行显示*/
            text-overflow: ellipsis; /*文字超出部分以省略号显示*/
          }
          span:first-child {
            font-weight: bold;
          }
          span:last-child:hover {
            color: rgba($color: #0982f9, $alpha: 0.5);
          }
          span:last-child {
            margin-left: 30rem;
            cursor: pointer;
          }
        }
        &-right {
          width: 1170rem;
          word-break: normal;
          span {
            display: inline-block;
            margin: 0 0 25rem 25rem;
            font-size: 18rem;
            color: #999;
            cursor: pointer;
          }
          span:hover {
            color: rgba($color: #0982f9, $alpha: 0.5);
          }
        }
      }
    }
  }
  .listbox {
    width: 100%;
    .netmedia {
      width: 1480rem;
      margin: 30rem auto 0;
    }
  }
}
</style>
