<template>
  <div class="personpage">
    <div class="nav">
      <span>首页 / 个人中心 / </span>
      <span>{{ navList[navIndex].name }}</span>
    </div>
    <div class="message">
      <div class="message-nav">
        <div
          @click="navClick(item, index)"
          :class="{ navSeled: navIndex == index }"
          v-for="(item, index) in navList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="message-routerBox">
        <router-view v-if="routerShow"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$bus.$off("noteIndex");
    this.$bus.$on("noteIndex", (data) => {
      console.log(data, "data");
      if (data && typeof data != "undefined") {
        this.navIndex = data;
        this.routerShow = false;
        this.$nextTick(function () {
          this.routerShow = true;
        });
        return;
      }
    });
    this.$parent.$off("navLocation");
    this.$parent.$on("navLocation", (msg) => {
      if (msg) {
        this.navIndex = msg;
      }
    });
  },
  beforeDestory() {
    this.$bus.$off("noteIndex");
  },
  data() {
    return {
      routerShow: true,
      navIndex: 0,
      navList: [
        {
          name: "用户信息",
          path: "usermsg",
        },
        {
          name: "我的通知",
          path: "mynote",
        },
        {
          name: "密码管理",
          path: "pasManage",
        },
        {
          name: "积分明细",
          path: "PointsDetails",
        },
        {
          name: "我的邀请",
          path: "MyInvitation",
        },
        /*{
          name: "申请入驻",
          path: "applyJoin",
        },*/
      ],
    };
  },
  methods: {
    navClick(item, index) {
      this.navIndex = index;
      this.$parent.$emit("navSideSel", { item1Index: 6, item2Index: index });
      if (this.$route.path == "/backstage/personpage/" + item.path) {
        return;
      }
      this.$router.push("/backstage/personpage/" + item.path);
    },
    //father
    fatherMethod(item1Index, item2Index) {
      this.$parent.$emit("navSideSel", {
        item1Index: item1Index,
        item2Index: item2Index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.personpage {
  width: 100%;
  height: 100%;
  .nav {
    width: 100%;
    height: 50rem;
    background-color: #fff;
    line-height: 50rem;
    span {
      font-size: 14rem;
      color: #999;
    }
    span:first-child {
      margin-left: 20rem;
    }
    span:last-child {
      color: #666;
    }
  }
  .message {
    width: 100%;
    margin-top: 20rem;
    background-color: #fff;
    &-nav {
      width: 97%;
      height: 60rem;
      margin: 0 auto;
      border-bottom: 1px solid #e2e2e2;

      display: flex;
      div {
        width: 114rem;
        height: 100%;
        font-size: 18rem;
        color: #999;
        line-height: 60rem;
        cursor: default;
      }
      div:hover {
        color: #42a0fd;
      }
      .navSeled {
        color: #42a0fd;
      }
    }
    &-routerBox {
      width: 100%;
    }
  }
}
</style>
